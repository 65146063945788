import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
  IconButton,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import swal from "sweetalert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import '../eBook/style.css';

const MobileSlider = (props: any) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States for SetData
  const history = useHistory();
  const [users, setUsers] = useState<any>({ data: [] }) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allPageCount, setAllPageCount] = useState(0);

  const loadUser = async () => {
    setIsLoading(true);
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/mobile_slider/get-slider/?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }
    );
    setIsLoading(false);
    setUsers(res?.data);
    setAllPageCount(res?.data?.numberOfPage);
  };


  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    loadUser();
  }, [page]);

  const deleteUser = async (id: any) => {
    if (!props.user[modules.mobile_slider].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }
    swal({
      buttons: ['No', 'Yes'],
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Image!",
      icon: "error",
      dangerMode: true,
    }).then(async (deleteUser) => {
      if (deleteUser) {
        if (isLoading == false) {
          setIsLoading(true);
          const res1 = await axiosInstance.delete(
            `${process.env.REACT_APP_API_URL}/mobile_slider/${id}`,
            {
              headers: {
                Authorization: `Bearer ${props.token}`,
              }
            }
          );
          console.log(res1);
          setIsLoading(false);
          loadUser();
          swal("Your Image has been deleted!", {
            icon: "success",
          });
        }
      } else {
      }
    });
    loadUser();
  };

  const redirectEdit = (id: any) => {
    if (!props.user[modules.mobile_slider].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    history.push(`/ebookflutter_adminpanel/edit-mobile/${id}`);
  }

  const redirectAdd = () => {
    if (!props.user[modules.mobile_slider].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }
    history.push('add-mobile');
  };

  // Toggle API
  const updateActive = async (id: any) => {
    if (!props.user[modules.mobile_slider].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      setIsLoading(true);
      const res1 = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/mobile_slider/active/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }
      );

      const newData = users.data.map((t: any) => {
        return { ...t, active: t.id === id ? res1.data.active : t.active }
      });
      setUsers((prev: any) => ({ ...prev, data: newData }));
      swal({
        title: "Updated Successfully!",
        icon: "success",
      });
    } catch (e) {
      toast.error('Unable to update! please try again later');
    }
    setIsLoading(false);
  };

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">MOBILE SLIDER IMAGE LIST ({users.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        Add New Mobile Slider Image
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>IMAGE</TableCell>
                          <TableCell>ACTIVE</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {users?.data.map((user: any, index: any) => (
                          <TableRow key={user.id}>
                            <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                            <TableCell>
                              <img
                                style={{ height: "50px", width: "80px" }}
                                src={`${process.env.REACT_APP_S3_APP_API}/mobile_silder/${user.image}`}
                                alt="mobile_silder"
                              />
                            </TableCell>
                            <TableCell>
                              <Switch
                                checked={user.active}
                                onChange={() => updateActive(user.id)}
                                id={user.id}
                                color="primary"
                                name="recommended"
                                value={user.recommended}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton color="primary" onClick={() => redirectEdit(user.id)}>
                                  <EditIcon />
                                </IconButton>
                                <Link
                                  to="#"
                                  onClick={() => deleteUser(user.id)}
                                >
                                  <DeleteIcon />
                                </Link>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={allPageCount}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading && <Loader />}
        {/* {isLoading ? <Loader /> : null} */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    user: state.user,
  }
};

export default connect(mapStateToProps)(MobileSlider);
