// THIS IS LOGIN COMPONENT THAT IS USED IN THE LOGIN PAGE
// IMPORT DEPENDENCY
import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Alert,
  Container,
  Label,
  FormGroup,
} from "reactstrap";
import { connect } from "react-redux";
import axiosInstance from '../../helpers/axios';
import { withRouter, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { registerUser } from '../../store/auth/admins/user';
import { registerToken } from '../../store/auth/admins/token';
import { toast } from "react-toastify";
import images from '../../assets/images'

class Login extends Component {
  // STATES
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  async handleSubmit(event, values) {
    event.preventDefault();
    event.persist();
    try {
      const res = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/admin/login`, { email: this.state.username, password: this.state.password });

      localStorage.setItem('token', res.data.token);
      localStorage.setItem('id', res.data.user.id);
      this.props.registerToken(res.data.token);
      this.props.registerUser(res.data.user);
      this.props.history.replace('/ebookflutter_adminpanel/dashboard');
    } catch (e) {
      toast.error('Unable to login');
    }
  }

  componentDidMount() {
    document.body.classList.add("auth-body-bg");
  }

  componentWillUnmount() {
    document.body.classList.remove("auth-body-bg");
  }
  // THIS IS JSX ALL THE INPUT,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE
  render() {
    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/">
            <i className="mdi mdi-home-variant h2 text-white"></i>
          </Link>
        </div>
        <div>
          <Container fluid className="p-0">
            <Row className="no-gutters">
              <Col lg={4}>
                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                  <div className="w-100">
                    <Row className="justify-content-center">
                      <Col lg={9}>
                        <div>
                          <div className="text-center">
                            <h4 className="font-size-18 mt-4">
                              Welcome Back !
                            </h4>
                            <p className="text-muted">
                              Sign in to continue to E-book.
                            </p>
                          </div>
                          {this.props.loginError && this.props.loginError ? (
                            <Alert color="danger">
                              {this.props.loginError}
                            </Alert>
                          ) : null}
                          <div className="p-2 mt-5">
                            <AvForm
                              className="form-horizontal"
                              onValidSubmit={this.handleSubmit}
                            >
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                <Label>E-mail</Label>
                                <AvField
                                  name="E-mail"
                                  value={this.state.username}
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  onChange={(e) => this.setState({ username: e.target.value })}
                                  validate={{ email: true, required: true }}
                                  placeholder="Enter E-mail"
                                />
                              </FormGroup>
                              <FormGroup className="auth-form-group-custom mb-4">
                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                <Label>Password</Label>
                                <AvField
                                  name="password"
                                  value={this.state.password}
                                  onChange={(e) => this.setState({ password: e.target.value })}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                />
                              </FormGroup>
                              <div>
                                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                                <Label className="custom">
                                  Remember me
                                </Label>
                              </div>
                              <div className="mt-4 text-center">
                                <Button
                                  color="primary"
                                  className="w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Log In
                                </Button>
                              </div>
                              <div className="mt-4 text-center">
                                <Link
                                  to="/ebookflutter_adminpanel/forgot-password"
                                  className="text-muted"
                                >
                                  <i className="mdi mdi-lock mr-1"></i> Forgot
                                  your password?
                                </Link>
                              </div>
                            </AvForm>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={8} style={{ backgroundColor: "rgb(232, 240, 254)", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div>
                  <img style={{ display: 'block', margin: '0 auto' }} src={images?.admin_login} alt=""/>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

// REACT-REDUX
const mapStatetoProps = (state) => {
  return {
    token: state.token,
    user: state.user,
  }
};

export default withRouter(
  connect(mapStatetoProps, { registerToken, registerUser })(Login)
);
