import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import axiosInstance from '../helpers/axios';

const useFetchHook = <T>(
  initialUrl: string, initialData: T, initialOptions: AxiosRequestConfig<any> = {}
): [
    { data: T, isLoading: boolean, isError: boolean,},
    {
      setUrl: React.Dispatch<React.SetStateAction<string>>,
      setOptions: React.Dispatch<React.SetStateAction<AxiosRequestConfig<any>>>,
      setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
      setData: React.Dispatch<React.SetStateAction<T>>,
      fetchData: () => Promise<void>,
    }
  ] => {
  const [data, setData] = useState(initialData);
  const [url, setUrl] = useState(initialUrl);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [options, setOptions] = useState(initialOptions);

  const fetchData = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await axiosInstance.get<T>(url, options);

      setData(result.data);
      setIsLoading(false);
    
    } catch (error) {
      setIsError(true);
    }


  };

  useEffect(() => {
    fetchData();

  }, [url, options]);

  return [{ data, isLoading, isError }, { setUrl, setOptions, setIsLoading, setData, fetchData }];
};

export { useFetchHook };
