const modules = {
  roles: 'roles',
  users: 'users',
  author: 'author',
  books: 'books',
  top_selling: 'top_selling',
  recommended: 'recommended',
  categories: 'categories',
  subcategories: 'subcategories',
  chapters: 'chapters',
  mobile_slider: 'mobile_slider',
  social_media: 'social_media',
  publisher: 'publisher',
  genre: 'genre',
  feedback: 'feedback',
  feed: 'feed',
  author_review: 'author_review',
  book_review: 'book_review',
  coupons: 'coupons',
  email_templates: 'email_templates',
  mobileusers: 'mobileusers',
  log: 'log',
  coach: 'coach',
  coach_review: 'coach_review',
  audio_review: 'audio_review',
  video_review: 'video_review',
  audio: 'audio',
  video: 'video',
  audio_view: 'audio_view',
  notification:'notification',
  admin_notification:'admin_notification',
  notification_template:'notification_template',
  newUser:'newUser',
  portal_users:'portal_users',
  
} as const;

const permissions = {
  READ: 'READ',
  WRITE: 'WRITE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;

const errorMsg = 'You are not allowed to perform this action';

export { modules, permissions, errorMsg };
