import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import Switch from "@material-ui/core/Switch";
import Loader from "../../components/ui/loader/Loader";
import { useFetchHook } from '../../utils/fetchHook';
import { connect } from 'react-redux';
import Select from 'react-select/async';
import { UserRoles } from "../../store/auth/admins/user";
import { MultiValue } from 'react-select';
import { StoreState } from "../../store";
import { Pagination } from "@material-ui/lab";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import '../eBook/style.css';
import { toast } from "react-toastify";

interface Props {
  token: string;
  user: UserRoles;
}

// API data format
type AuthorType = {
  author_email: String,
  public_phone: number,
  cover_image: ImageData,
  authors_id: number,
  author_first_name: String,
  author_last_name: String,
  co_first_name: String,
  co_last_name: String,
  email: String,
  top: boolean

};

const TopAuthor = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // states of setData
  const [dates, setDates] = useState<any>([null, null]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [colastName, setColastName] = useState('');
  const [email, setEmail] = useState('');
  const [genre, setGenre] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [lang, setLang] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(0);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);

  // Custom Hook For API
  const [{ isLoading, isError, data }, { setOptions }] = useFetchHook
    <{ data: AuthorType[]; numberOfPages: number; total: number | null, }>
    (
      `${process.env.REACT_APP_API_URL}/v2_authors/authors`,
      { data: [], numberOfPages: 1, total: null },
      {
        params: { page, topauthor: true, },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          author_first_name: firstName,
          author_last_name: lastName,
          co_first_name: name,
          co_last_name: colastName,
          email: email,
          genre: genre?.map((i) => i.value),
          dates,
          lang: lang?.map((i) => i.value),
          topauthor: true,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page]);


  if (isError) {
    return (
      <p>Something went wrong please try again later</p>
    );
  }

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  // Genre API DropDown
  const loadGenre = async (genre: string) => {
    try {
      const category = await axiosInstance.get('/genre/get-genre', {
        params: {
          genre: genre,
        }
      });

      return category.data;
    } catch (e) { }
  };

  // Language API Dropdown
  const loadLang = async (lang: String) => {
    try {
      const language = await axiosInstance.get('/language/drop-lang', {
        params: {
          lang,
        }
      });

      return language.data;
    } catch (e) { }
  };

  const copyTable = () => {
		const table = tableRef.current;
		if (table) {
			const range = document.createRange();
			range.selectNode(table);
			window.getSelection()?.removeAllRanges();
			window.getSelection()?.addRange(range);
			document.execCommand('copy');
			window.getSelection()?.removeAllRanges();
			toast.info("Copied To Clipboard")
		}
	}

	const exportToExcel = () => {
		const table = document.querySelector('table');
		const html = table?.outerHTML;
		if (html) {
			const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
			const dataUrl = URL.createObjectURL(blob);
			const downloadLink = document.createElement('a');
			downloadLink.href = dataUrl;
			downloadLink.download = 'table.xls';
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(dataUrl);
		}
	}

	const convertTableToCSV = (table:any) => {
		let csv = '';
		const rows = table.querySelectorAll('tr');
		for (let i = 0; i < rows.length; i++) {
		  const cells = rows[i].querySelectorAll('th, td');
		  for (let j = 0; j < cells.length; j++) {
			csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
		  }
		}
		return csv;
	  };


	const handleDownloadCSV = () => {
		const tableNode = document.getElementById('table-data');
		const csvData = convertTableToCSV(tableNode);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
		downloadLink.download = 'table-data.csv';
		downloadLink.click();
	  };

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (firstName.length > 0 || lastName.length > 0 || name.length > 0 || colastName.length > 0 || email.length > 0 || genre != null || lang != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(0);
        setOptions({
          params: {
            page,
            author_first_name: firstName,
            author_last_name: lastName,
            co_first_name: name,
            co_last_name: colastName,
            email: email,
            genre: genre?.map((i) => i.value),
            lang: lang?.map((i) => i.value),
            topauthor: true,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }}


  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (firstName.length > 0 || lastName.length > 0 || name.length > 0 || colastName.length > 0 || email.length > 0 || genre != null || lang != null || (dates[0] != null && dates[1] != null)) {
      e.preventDefault();
      setShowError(false);
      setPage(0);
      setOptions({
        params: {
          page,
          author_first_name: firstName,
          author_last_name: lastName,
          co_first_name: name,
          co_last_name: colastName,
          email: email,
          genre: genre?.map((i) => i.value),
          lang: lang?.map((i) => i.value),
          topauthor: true,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setShowError(true);
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFirstName('');
    setLastName('');
    setName('');
    setColastName('');
    setEmail('');
    setGenre(null);
    setShowError(false);
    setLang(null);
    setDates([null, null])

    setOptions({
      params: {
        page,
        topauthor: true,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">TOP AUTHOR LIST ({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Author First Name" value={firstName} onKeyPress ={handleKeyPress} onChange={setter(setFirstName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder=" Author Last Name" value={lastName}  onKeyPress ={handleKeyPress} onChange={setter(setLastName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="CO AUTHOR First Name" value={name} onKeyPress ={handleKeyPress} onChange={setter(setName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder=" CO AUTHOR Last Name" value={colastName}  onKeyPress ={handleKeyPress} onChange={setter(setColastName)} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Email" value={email} onKeyPress ={handleKeyPress} onChange={setter(setEmail)} />

                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Genre" isMulti value={genre} cacheOptions defaultOptions loadOptions={loadGenre} onChange={setGenre} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range"  onKeyPress ={handleKeyPress} format="MM/dd/yyyy" onChange={setDates} value={dates} />
                      </div>

                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Language" isMulti value={lang} cacheOptions defaultOptions loadOptions={loadLang} onChange={setLang} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
									<button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
									<button className="btn btn-outline-primary mr-2"  onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                  <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>AUTHOR NAME</TableCell>
                          <TableCell>PROFILE PICTURE</TableCell>
                          <TableCell> EMAIL</TableCell>
                          <TableCell>PHONE NUMBER</TableCell>
                          <TableCell>TOP</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.authors_id}>
                              <TableCell>{(page * 25) + index + 1}</TableCell>
                              <TableCell>
                                {user.author_first_name} {user.author_last_name}
                              </TableCell>
                              <TableCell>
                                <img
                                  style={{ height: "50px", width: "80px" }}
                                  src={
                                    user.cover_image ? `${process.env.REACT_APP_S3_APP_API}/author/${user.cover_image}` : `${process.env.REACT_APP_S3_APP_API}/author/author.png`
                                  }
                                  alt="author"
                                />
                              </TableCell>
                              <TableCell>{user.author_email || '--'}</TableCell>
                              <TableCell>{user.public_phone || '--'}</TableCell>
                              <TableCell>
                                <Switch checked={user?.top} color="primary" disabled />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPages}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value - 1)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

// Redux
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};
export default connect(mapStateToProps)(TopAuthor);
