// THIS COMPONENT IS USED FOR API IMPLEMENTATION
// THE API URL IS DEFINED ONCE IN THIS COMPONENT AND USED IN WHOLE PROJECT
import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
  baseURL: apiURL,
});

export default axiosInstance;
