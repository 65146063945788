import React, { Component } from "react";
import { withRouter } from "react-router-dom";


interface Props{
  location:any;
}

class NonAuthLayout extends Component<Props> {
  constructor(props:Props) {
    super(props);
    this.state = {};
    this.capitalizeFirstLetter.bind(this);
  }

  capitalizeFirstLetter = (string:any) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title = currentage + " | E-Book ";
  }
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}

export default withRouter(NonAuthLayout as any);
