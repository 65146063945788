export async function getCategory() {
  const requesturl = `${process.env.REACT_APP_API_URL}/categories/drop-category`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}


export async function gtSubCategory() {
  const requesturl = `${process.env.REACT_APP_API_URL}/sub_categories/subCategory`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}

export async function getAuthor() {
  const requesturl = `${process.env.REACT_APP_API_URL}/v2_authors/get-author`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}
export async function getBook() {
  const requesturl = `${process.env.REACT_APP_API_URL}/books/books`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}
export async function getChapter() {
  const requesturl = `${process.env.REACT_APP_API_URL}/chapters/get-chapter`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}

export async function getPublisher() {
  const requesturl = `${process.env.REACT_APP_API_URL}/publisher/get-publisher`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}

export async function getLanguage() {
  const requesturl = `${process.env.REACT_APP_API_URL}/language/get-language`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}

export async function getLocation() {
  const requesturl = `${process.env.REACT_APP_API_URL}/location/get-location`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}

export async function getGenre() {
  const requesturl = `${process.env.REACT_APP_API_URL}/genre/get-genre`;
  const response = await fetch(requesturl);
  const responseJson = await response.json();
  return responseJson;
}
