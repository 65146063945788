import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
// import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import swal from "sweetalert";
import "../datatables.scss";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { connect } from 'react-redux';
import UnSavedChanges from "../Add/UnSavedChanges";
import EditGenreLog from "./EditGenreLog";

interface Props {
  token: string;
  user: UserRoles;
}

const EditGenre = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [Prompt , setIsSaved,setPristine] = UnSavedChanges() as any;

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState({
    geners_name: "",
  });
  const [valid, setValid] = useState({
    geners_name_Error: "",
  });
  const { geners_name } = users;

  const onInputChange = (e: any) => {
    e.persist();
    setIsSaved() as any;
    setUsers({ ...users, [e.target.name]: e.target.value });
    if ( !e.target.name || !e.target.value || !users.geners_name) {
      setValid({ ...valid, geners_name_Error: "*Enter genre name" });
    } else {
      setValid({ ...valid, geners_name_Error: "" })
    }
  };
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const geners_name_Error = [] as any;

    if (!users.geners_name) {
      geners_name_Error.push("*Enter genre name");
    } else {
      setValid({ ...valid, geners_name_Error: "" })
    }
    // if (users.geners_name.trim().length === 0) geners_name_Error.push("Enter genre name");
    setValid({ ...valid, geners_name_Error });
    if (geners_name_Error.length) return;

    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/genre/${id}`,
          users,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
        setIsLoading(false);
        console.log(res)

        swal({
          title: "Updated Successfully!",
          // text: users.geners_name,
          icon: "success",
          // button: "OK!",
        });

      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');

        }

      }

    }
  };

  const loadUser = async () => {
    const res1 = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/genre/${id}`
    );

    setUsers({ ...users, geners_name: res1.data.geners_name });
  };

  useEffect(() => {
  }, [isLoading]);

  useEffect(() => {
    loadUser();
  }, []);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">EDIT GENRE</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>GENRE NAME</label>
                          <input
                            type="text"
                            className="form-control"
                            name="geners_name"
                            value={geners_name}
                            id="exampleFormControlInput1"
                            placeholder="Enter geners_name"
                            onChange={(e) => onInputChange(e)}
                          />
                          {valid?.geners_name_Error && <div style={{ color: 'red' }}>{valid?.geners_name_Error} </div>}

                          {/* {valid?.geners_name_Error.length
                            ? valid?.geners_name_Error.map((error, i) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <button type="submit" className="btn btn-primary"
                            onClick={()=>{
                              setPristine()
                            }}
                          >
                            Submit
                          </button>
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <Link
                            to="/ebookflutter_adminpanel/genre"
                            style={{ color: "white" }}
                          >
                            <button type="submit" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <EditGenreLog />
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditGenre);

// export default EditGenre;
