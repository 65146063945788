// THIS COMPONENT IS USED TO CREATE COUPON
// IMPORT REACT
import React, { useState } from "react";
// IMPORT REACT ROUTER DOM FOR ROUTING
import { Link } from "react-router-dom";
// REACT-STRAP IS THIRD PARTY PACKAGE THAT IS USED FOR ROW, COL,CARD ETC
import { Row, Col, Card, CardBody, Container } from "reactstrap";
// IMPORT ERROR-SPAN COMPONENT FOR SHOW ERROR MESSAGE
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
// IMPORT CUSTOM LOADER
import Loader from "../../../components/ui/loader/Loader";
// IMPORT AXIOS FOR API IMPLEMENTATION
import axiosInstance from "../../../helpers/axios";
// IMPORT SWAL FOR POPUP MESSAGE
import swal from "sweetalert";
// IMPORT CSS
import "../datatables.scss";
// IMPORT SELECT FROM DROP-DOWN
import Select from 'react-select';
// IMPORT AXIOS-ERROR FOR ERROR MESSAGE
import { AxiosError } from "axios";
// IMPORT TOAST MESSAGE
import { toast } from "react-toastify";
// IMPORT DATE-PICKER FOR SELECT DATE
import DatePicker from 'react-datetime';
import moment from 'moment';
// IMPORT DATE-PICKER CSS
import 'react-datetime/css/react-datetime.css';
// IMPORT CUSTOM COMPONENT
import UnSavedChanges from "./UnSavedChanges";
import AddInapp from "./AddInapp";

const AddCoupon = () => {
  // STATES FOR UPDATING DATA
  const [isLoading, setIsLoading] = useState(false);
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  const [error, setError] = useState<any>(null);
  const [linkType, setLinkType] = useState([] as any);
  const [state, setState] = useState({
    coupon_code: '',
    discount_type: '',
    description: '',
    usage: '',
    discount: '',
    expiry: "" as any,
    duration: "once",

  });

  const [valid, setValid] = useState({
    coupon_code_Error: [] as any,
    discount_type_Error: [] as any,
    description_Error: [] as any,
    usage_Error: [] as any,
    discount_Error: [] as any,
    expiry_Error: [] as any,
    msg_Error: [] as any,
  });
// SELECT OPTION FOR SELECT LINK-TYPE DROP-DOWN
  const linkOptions = [
    { value: '2', label: 'stripe' },
    { value: '3', label: 'in-app' },
  ];

  // ON-INPUT-CHANGE FUNCTION IS USED FOR INPUT ,WHEN ENTER ANY VALUE IN THE INPUT
  // BOX THIS FUNCTION HOLD THE VALUE IN THE STATE
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
    setIsSaved() as any;

    if (!e.target.name || !e.target.value || !state.discount) {
      setValid({ ...valid, discount_Error: " *Enter Discount" });
    } else {
      setValid({ ...valid, discount_Error: "" })
    }
  };

  // WHEN SUBMIT FORM ONSUBMIT FUNCTION IS CALLED, IN THIS FUNCTION ALL THE DATA OF INPUT,DROPDOWN AND
  // OTHER FIELDS ARE SUBMITTED IN THE FORM USING THE POST API, ALSO VALIDATION IS HANDLE FOR SUBMIT FORM
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const coupon_code_Error = [] as any,
      discount_type_Error = [] as any,
      description_Error = [] as any,
      usage_Error = [] as any,
      discount_Error = [] as any,
      msg_Error = [] as any,
      expiry_Error = [] as any;

    if (!state.coupon_code) {
      coupon_code_Error.push("*Enter Coupon Code");
    } else if (state.coupon_code.length < 4) {
      coupon_code_Error.push("*Coupon Code Is Too Short ");
    } else {
      setValid({ ...valid, coupon_code_Error: "" })
    }

    if (!state.description) {
      description_Error.push("*Enter Description");
    } else {
      setValid({ ...valid, description_Error: "" })
    }

    if (!state.usage) {
      usage_Error.push("*Enter Usage");
    } else {
      setValid({ ...valid, usage_Error: "" })
    }

    if (!state.discount_type)
      discount_type_Error.push("Enter Discount Type");

    if (!state.expiry) {
      setError("*Enter Expiry Date");
    } else {
      setError(null);
    }

    if (!state.discount)
      discount_Error.push("*Enter Discount");

    setValid({ ...valid, coupon_code_Error, discount_type_Error, usage_Error, expiry_Error, discount_Error, description_Error });
    if (coupon_code_Error.length || discount_Error.length || expiry_Error.length || usage_Error.length || discount_type_Error.length || description_Error.length) return;
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res1 = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/coupons/`,
          state,
        );
        if (res1?.data?.success === 0) {
          setIsLoading(false);
          msg_Error.push(res1?.data?.message);
          setValid({ ...valid, msg_Error });
          if (msg_Error.length) return;
        }
        setIsLoading(false);
        swal({
          title: "Added Successfully!",
          icon: "success",
        });
      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
    setValid({
      ...valid,
      msg_Error: [] as any,
    });
    setState({
      ...state,
      coupon_code: '' as any,
      discount_type: '' as any,
      description: '' as any,
      usage: '' as any,
      expiry: "" as any,
    });
  };

  // THIS FUNCTION IS USED FOR CALENDER TO HIDE THE PAST DATE FROM THE CALENDAR
  const yesterday = moment().subtract(0, 'day');
  const disablePastDt = (current: { isAfter: (arg0: moment.Moment) => any; }) => {
    return current.isAfter(yesterday);
  };

  // FROM THERE JSX PART IS STARTED ALL THE INPUT, DROPDOWN ,CALENDER , TEXTAREA ARE SHOWN FROM THERE
  // THIS PART IS SHOW IN OUR REACT APPLICATION
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">ADD COUPON</h4>
                  <form name='form1' onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CHOOSE GATEWAY</label>
                          <Select
                            options={linkOptions}
                            value={linkType}
                            isSearchable={false}
                            onChange={(e) => {
                              setLinkType(e)
                              setIsSaved() as any;
                            }}
                            placeholder="Choose Gateway"
                          />
                        </div>
                      </Col>
                    </Row>
                    {(linkType?.length !== 0) ? (
                      <div>
                        {linkType.value === '2' ?
                          <>
                            <Row>
                              <Col xs={6}>
                                <div className="form-group">
                                  <label>COUPON CODE*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="coupon_code"
                                    value={state.coupon_code}
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Coupon Code"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      const selectedValue2 = document.getElementById('exampleFormControlInput1') as HTMLInputElement;
                                      setState({ ...state, [e.target.name]: e.target.value });
                                      if ((selectedValue2.value.length)) {
                                        setValid({ ...valid, coupon_code_Error: "" })
                                      }
                                      else {
                                        setValid({ ...valid, coupon_code_Error: "*Enter Coupon Code" });

                                      }
                                      setIsSaved() as any;
                                      // if (!e.target.name || !e.target.value || !state.coupon_code) {
                                      //   setValid({ ...valid, coupon_code_Error: "*Enter Coupon Code" });
                                      // } else if (state?.coupon_code?.length < 4) {
                                      //   setValid({ ...valid, coupon_code_Error: "*Coupon Code Is Too Short" });
                                      // }
                                      // else {
                                      //   setValid({ ...valid, coupon_code_Error: "" })
                                     // }
                                    }}
                                  />
                                  {valid?.coupon_code_Error && <div style={{ color: 'red' }}>{valid?.coupon_code_Error} </div>}
                                </div>
                              </Col>
                              <Col xs={6}>
                                <div className="form-group">
                                  <label>DISCOUNT TYPE*</label>
                                  <Select name="discount_type"
                                    options={[
                                      { value: 'percent_off', label: 'Percentage' },
                                      { value: 'amount_off', label: 'Amount' },
                                    ]}
                                    placeholder="Select Type"
                                    isSearchable={false}
                                    value={[
                                      { value: 'percent_off', label: 'Percentage' },
                                      { value: 'amount_off', label: 'Amount' },
                                    ].filter(i => i.value === state.discount_type)[0]}
                                    onChange={(e: any) => {
                                      (setState({
                                        ...state, discount_type: e.value
                                      }))
                                      setIsSaved() as any;
                                      setValid({ ...valid, discount_type_Error: [] })
                                    }
                                    }
                                  />
                                  {valid?.discount_type_Error.length
                                    ? valid?.discount_type_Error.map((error: any, i: React.Key | null | undefined) => (
                                      <ErrorSpan key={i} error={error} />
                                    ))
                                    : null}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <div className="form-group">
                                  <label>DISCOUNT*</label>
                                  {
                                    (state?.discount_type === "percent_off")

                                      ?
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="discount"
                                        value={state.discount}
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Discount"
                                        onChange={(e) => {
                                          if ((parseInt(e.target.value) <= 100) || e.target.value === '') {
                                            onInputChange(e);
                                          }
                                        }}
                                      />
                                      :
                                      <input
                                        type="number"
                                        min="0"
                                        className="form-control"
                                        name="discount"
                                        value={state.discount}
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Discount"
                                        onChange={(e) => onInputChange(e)}
                                      />
                                  }
                                  {valid?.discount_Error && <div style={{ color: 'red' }}>{valid?.discount_Error} </div>}
                                </div>
                              </Col>
                              <Col xs={6}>
                                <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
                                  <label>EXPIRY*</label>
                                  <DatePicker
                                    value={state.expiry}
                                    timeFormat={false}
                                    isValidDate={disablePastDt}
                                    onChange={(e: any) => {
                                      if (e) {
                                        setIsSaved() as any;
                                        setState({ ...state, expiry: e });
                                        setError(null);
                                      } else {
                                        setError("*Expiry Date Can Not Be Empty");
                                      }
                                    }}
                                  />
                                  {error && <div style={{ color: 'red' }}>{error}</div>}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={6}>
                                <div className="form-group">
                                  <label>DESCRIPTION*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="description"
                                    value={state.description}
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Coupon Description"
                                    // onChange={(e) => onInputChange(e)}
                                    onChange={(e) => {
                                      setState({ ...state, [e.target.name]: e.target.value });
                                      setIsSaved() as any;
                                      if (!e.target.name || !e.target.value || !state.description) {
                                        setValid({ ...valid, description_Error: "*Enter Description" });
                                      } else {
                                        setValid({ ...valid, description_Error: "" })
                                      }
                                    }
                                    }
                                  />
                                  {valid?.description_Error && <div style={{ color: 'red' }}>{valid?.description_Error} </div>}
                                </div>
                              </Col>
                              <Col xs={6}>
                                <div className="form-group">
                                  <label>USAGE*</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="usage"
                                    value={state.usage}
                                    id="exampleFormControlInput1"
                                    placeholder="How many times can the code be used?"
                                    onChange={(e) => {
                                      setState({ ...state, [e.target.name]: e.target.value });
                                      setIsSaved() as any;
                                      if (!e.target.name || !e.target.value || !state.usage) {
                                        setValid({ ...valid, usage_Error: "*Enter Usage" });
                                      } else {
                                        setValid({ ...valid, usage_Error: "" })
                                      }
                                    }
                                    }
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                  {valid?.usage_Error && <div style={{ color: 'red' }}>{valid?.usage_Error} </div>}
                                </div>
                              </Col>
                            </Row>

                            <div
                              style={{
                                display: "flex",
                                margin: "5px",
                                padding: "10px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "5px",
                                  padding: "10px",
                                }}
                              >
                                <input
                                  name="submit"
                                  type="submit"
                                  value="Submit"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setPristine()
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  margin: "5px",
                                  padding: "10px",
                                }}
                              >
                                {" "}
                                <Link
                                  to="/ebookflutter_adminpanel/coupons"
                                  style={{ color: "white" }}
                                >
                                  <button type="button" className="btn btn-primary">
                                    Back
                                  </button>
                                </Link>
                              </div>
                            </div>

                          </>
                          : <><div>
                            {/* THIS IS ANOTHER COMPONENT TO REDUCE THE SIZE OF THIS COMPONENT MAKE ANOTHER COMPONENT  */}
                            <AddInapp />
                          </div>
                          </>
                        } </div>) : null}
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

export default AddCoupon;
