import React, { useState, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
import swal from "sweetalert";
import { Pagination } from "@material-ui/lab";
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from 'react-redux';
// import { useFetchHook } from '../../utils/fetchHook';
import { toast } from 'react-toastify';
import axiosInstance from '../../helpers/axios';
import { errorMsg, modules, permissions } from '../../utils/constants';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import { AxiosError } from "axios";
import Loader from "../../components/ui/loader/Loader";

interface Props {
  token: string;
  user: UserRoles;
}

// type RolesType = {
//   role_id: number,
//   name: String,
//   active: true,
// };

const Roles = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  // const [page, setPage] = useState(1);
  const [allPageCount, setAllPageCount] = useState(0);
  const [data, setData] = useState<any>({ data: [] }) as any;


  // const [currentPage, setCurrentPage] = useState(1);
  // const [{ isLoading, data }, { setData, fetchData }] = useFetchHook
  //   <{ data: RolesType[]; numberOfPages: number; total: number | null, }>
  //   ('/roles/', { data: [], numberOfPages: 1, total: null },
  //     {
  //       params: { page },
  //       headers: {
  //         Authorization: `Bearer ${props.token}`
  //       }
  //     });


  const loadUser = async () => {
    setIsLoading(true);
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/roles/?page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }
    );
    setIsLoading(false);
    setData(res?.data);
    setAllPageCount(res?.data?.numberOfPages);
  };


  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    loadUser();
  }, [page]);

  const onSwitch = async (id: number) => {
    try {
      if (!props.user.roles.includes('UPDATE')) {
        toast.warn('You are not allowed to perform this operation');
        return;
      }

      const res = await axiosInstance.put(`/roles/active/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${props.token}`
        }
      });

      const newData = data.data.map((t: any) => {
        const temp = { ...t };

        if (temp.role_id === res.data.id) {
          temp.active = res.data.active;
        }

        return temp;
      });
      setData((prev: any) => ({ ...prev, data: newData, numberOfPages: data.numberOfPages }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Unable to switch. please try again later');
      }
    }
  }

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };


  const redirectEdit = (role_id: number) => {
    if (!props.user[modules.roles].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`edit-role/${role_id}`);
    ///history.push(`edit-role/${role_id}`);
  };

  const onDelete = async (id: number) => {
    if (!props.user[modules.roles].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      const confirm = await swal({
        buttons: ['No', 'Yes'],
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Role!",
        icon: "error",
        dangerMode: true,

      });

      if (!confirm) {
        return;
      }

      await axiosInstance.delete(`/roles/${id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      // fetchData();
      loadUser();
      // setData((prev) => ({ ...prev, data: newData, numberOfPages: data.numberOfPages }));
      swal("Your Role has been deleted!", {
        icon: "success",
      });
      // setData({ data: newData });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  const redirectAdd = () => {
    if (!props.user[modules.roles].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    history.push('add-role');
  };

  const redirectView = (id: number) => {
    if (!props.user[modules.roles].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`view-role/${id}`);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">ROLES LIST({data.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        Add Role
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>Role Name</TableCell>
                          <TableCell>Active</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((temp: any, i: any) => (
                          <TableRow key={i}>
                            <TableCell> {((page - 1) * 25) + i + 1}</TableCell>
                            <TableCell>{temp.name}</TableCell>
                            <TableCell>
                              <Switch
                                checked={temp.active}
                                color="primary"
                                name="top_selling"
                                value={temp.active}
                                onChange={() => onSwitch(temp.role_id)}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton
                                  color="primary"
                                  onClick={() => redirectView(temp.role_id)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => redirectEdit(temp.role_id)}
                                  color="primary"
                                >
                                  <EditIcon />
                                </IconButton>
                                <Link
                                  to="#"
                                  onClick={() => onDelete(temp.role_id)}
                                >
                                  <DeleteIcon />
                                </Link>
                              </div>
                            </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={allPageCount}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(e, value) => setPage(value)}
                    />

                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(Roles);
