// THIS IS CUSTOM COMPONENT FOR LOADER THAT IS USED IN ALL THE COMPONENT FOR LOADING
import './style.css';
import React from 'react';

const Loader = () => (
  <div className="app-loader-container">
    <div className="app-loader" />
  </div>
);

export default Loader;
