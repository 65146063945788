import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
// import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import axiosInstance from "../../helpers/axios";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { TextareaAutosize } from "@mui/material";
import UnSavedChanges from "../eBook/Add/UnSavedChanges";
import EditRoleslog from "../eBook/Edit/EditRoleslog";

const initialState = {
  roles: {
    label: 'Roles',
    value: [],
  },
  users: {
    label: 'Users',
    value: [],
  },
  portal_users: {
    label: 'Portal Users',
    value: [],
  },
  author: {
    label: 'Authors',
    value: [],
  },
  books: {
    label: 'Books',
    value: [],
  },
  top_selling: {
    label: 'Top Selling',
    value: [],
  },
  recommended: {
    label: 'Recommended',
    value: [],
  },
  categories: {
    label: 'Categories',
    value: [],
  },
  subcategories: {
    label: 'Sub Category',
    value: [],
  },
  chapters: {
    label: 'Summary',
    value: [],
  },
  mobile_slider: {
    label: 'Mobile Slider',
    value: [],
  },
  social_media: {
    label: 'Social Media',
    value: [],
  },
  publisher: {
    label: 'Publisher',
    value: [],
  },
  genre: {
    label: 'Genre',
    value: [],
  },
  feedback: {
    label: 'Feedback',
    value: [],
  },
  feed: {
    label: 'Feed',
    value: [],
  },
  author_review: {
    label: 'Author Review',
    value: []
  },
  book_review: {
    label: 'Book Review',
    value: []
  },
  coupons: {
    label: 'Coupons',
    value: []
  },
  email_templates: {
    label: 'Email Templates',
    value: []
  },
  mobileusers: {
    label: 'Mobileusers',
    value: []
  },
  admin_notification: {
    label: 'Notification',
    value: []
  },
  coach: {
    label: 'Coach',
    value: []
  },
  coach_review: {
    label: 'Coach Review',
    value: []
  },
  audio: {
    label: 'Audio',
    value: []
  },
  audio_review: {
    label: 'Audio Review',
    value: []
  },
  video: {
    label: 'Video',
    value: []
  },
  video_review: {
    label: 'Video Review',
    value: []
  },
  log: {
    label: 'Log',
    value: []
  },
} as any;

interface Props {
  token: string;
}

const AddRoles = (props: Props) => {
  // States of setData
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [roleName, setRoleName] = useState<any>('');
  const [roleDescription, setRoleDescription] = useState<any>('');
  const [permissions, setPermission] = useState<any>({
    roles: {
      label: 'Roles',
      value: [],
    },
    users: {
      label: 'Users',
      value: [],
    },
    portal_users: {
      label: 'Portal Users',
      value: [],
    },
    author: {
      label: 'Authors',
      value: [],
    },
    books: {
      label: 'Books',
      value: [],
    },
    top_selling: {
      label: 'Top Selling',
      value: [],
    },
    recommended: {
      label: 'Recommended',
      value: [],
    },
    categories: {
      label: 'Categories',
      value: [],
    },
    subcategories: {
      label: 'Sub Category',
      value: [],
    },
    chapters: {
      label: 'Summary',
      value: [],
    },
    mobile_slider: {
      label: 'Mobile Slider',
      value: [],
    },
    social_media: {
      label: 'Social Media',
      value: [],
    },
    publisher: {
      label: 'Publisher',
      value: [],
    },
    genre: {
      label: 'Genre',
      value: [],
    },
    feedback: {
      label: 'Feedback',
      value: [],
    },
    feed: {
      label: 'Feed',
      value: [],
    },
    author_review: {
      label: 'Author Review',
      value: []
    },
    book_review: {
      label: 'Book Review',
      value: []
    },
    coupons: {
      label: 'Coupons',
      value: []
    },
    email_templates: {
      label: 'Email Templates',
      value: []
    },
    mobileusers: {
      label: 'Mobileusers',
      value: []
    },
    admin_notification: {
      label: 'Notification',
      value: []
    },
    coach: {
      label: 'Coach',
      value: []
    },
    coach_review: {
      label: 'Coach Review',
      value: []
    },
    audio: {
      label: 'Audio',
      value: []
    },
    audio_review: {
      label: 'Audio Review',
      value: []
    },
    video: {
      label: 'Video',
      value: []
    },
    video_review: {
      label: 'Video Review',
      value: []
    },
    log: {
      label: 'Log',
      value: []
    }

  });
  const [valid, setValid] = useState({
    role_name_Error: "",
  });
  // const [errors, setErrors] = useState<any>({ nameError: null });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;

  // Get API for data Fetch
  useEffect(() => {
    fetchRole();
  }, []);

  // Fetch API
  const fetchRole = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/roles/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });

        const data = [] as any;
        Object.keys(initialState).forEach((key: any) => {
          data[key] = {
            label: initialState[key].label,
            value: res.data[key],
          };
        });

        setPermission(data);
        setRoleName(res.data.name);
        setRoleDescription(res.data.description);
      } catch (e) {
      }
    }
  }

  // Toggle
  const onSwitchChange = (key: string, value: string) => {
    const temp = { ...permissions };
    if (temp[key].value.includes(value)) {
      if (value === 'READ' && temp[key].value.length >= 2) {
        toast.warn('Remove all other permissions to disable READ');
        return;
      }

      temp[key].value = temp[key].value.filter((val: any) => val !== value);
      setPermission(temp);
    } else {
      if (value !== 'READ' && !temp[key].value.includes('READ')) {
        temp[key].value = ['READ', value, ...temp[key].value];
        setPermission(temp);
        return;
      }
      temp[key].value = [value, ...temp[key].value];
      setPermission(temp);
    }
  };

  // Put API
  const update = async () => {
    const role_name_Error = [] as any;
    if (!roleName) {
      role_name_Error.push("*Please Enter Role Name");
    } else {
      setValid({ ...valid, role_name_Error: "" })
    }

    setValid({ ...valid, role_name_Error });


    if (role_name_Error.length) return;
    // if (roleName.trim().length === 0) {
    //   setErrors({ nameError: 'Please enter role name' });
    //   return;
    // }

    const data = { name: roleName, description: roleDescription } as any;
    Object.keys(permissions).forEach((key) => data[key] = permissions[key].value);
    try {
      await axiosInstance.put(`/roles/${id}`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      swal({
        title: "Role updated Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Unable to create role. Please try again later');
      }
    }
  };

  // Post API
  const post = async () => {
    const role_name_Error = [] as any;
    if (!roleName) {
      role_name_Error.push("*Please Enter Role Name");
    } else {
      setValid({ ...valid, role_name_Error: "" })
    }

    setValid({ ...valid, role_name_Error });


    if (role_name_Error.length) return;
    // if (roleName.trim().length === 0) {
    //   setErrors({ nameError: 'Please Enter Role Name' });
    //   return;
    // }

    const data = { name: roleName, description: roleDescription } as any;

    Object.keys(permissions).forEach((key) => data[key] = permissions[key].value);

    try {
      await axiosInstance.post('/roles', data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      setPermission(initialState);
      setRoleName('');
      setRoleDescription('');
      swal({
        title: "Role Added Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Unable to create role. Please try again later');
      }
    }
  };

  // OnSubmit Function
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      update();
    } else {
      post();
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">ADD ROLE</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={roleName}
                            id="exampleFormControlInput1"
                            placeholder="Enter Role name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

                              setRoleName(e.target.value)
                              setIsSaved() as any;

                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, role_name_Error: "" })
                              } else {
                                setValid({ ...valid, role_name_Error: "*Please enter role name" });

                              }
                              // if (!e.target.value || !roleName) {
                              //   setValid({ ...valid, role_name_Error: "*Please enter role name" });
                              // } else {
                              //   setValid({ ...valid, role_name_Error: "" })
                              // }
                            }}
                          />
                          {valid.role_name_Error && <div style={{ color: 'red' }}>{valid.role_name_Error} </div>}
                          {/* {errors.nameError
                            ? <ErrorSpan error={errors.nameError} />
                            : null} */}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE'S DESCRIPTION...(500 Characters)</label>
                          <TextareaAutosize
                            maxLength={500}
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            minRows={3}
                            name="authors_bio"
                            placeholder="Enter Description"
                            value={roleDescription}
                            onChange={(e) => {
                              setRoleDescription(e.target.value)
                              setIsSaved() as any;
                            }}
                          />
                          <label style={{ color: "#0069d9" }}>{roleDescription.length} characters</label>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <Table component={Paper}>
                          <TableHead>
                            <TableRow>
                              <TableCell>SR NO.</TableCell>
                              <TableCell>MODULE</TableCell>
                              <TableCell>VIEW</TableCell>
                              <TableCell>ADD</TableCell>
                              <TableCell>UPDATE</TableCell>
                              <TableCell>DELETE</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <>
                            {Object.keys(permissions).map((key, i) => (
                              <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{permissions[key].label}</TableCell>
                                <TableCell>
                                  <Switch
                                    checked={permissions[key].value.includes('READ')}
                                    color="primary"
                                    onChange={() => onSwitchChange(key, 'READ')}
                                    name="top_selling"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                  />
                                </TableCell>
                                {(permissions[key].label !== "Log") && (permissions[key]?.label !== "Feedback")
                                 && (permissions[key]?.label !== "Audio")  && (permissions[key]?.label !== "Video")
                                 && (permissions[key]?.label !== "Audio Review")  && (permissions[key]?.label !== "Video Review")
                                 && (permissions[key]?.label !== "Recommended") &&
                                 (permissions[key]?.label !== "Top Selling")?
                                  <TableCell>
                                    <Switch
                                      checked={permissions[key].value.includes('WRITE')}
                                      color="primary"
                                      name="top_selling"
                                      onChange={() => onSwitchChange(key, 'WRITE')}
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  : <TableCell></TableCell>}

                                {(permissions[key].label !== "Log") && (permissions[key]?.label !== "Feedback")
                                 && (permissions[key]?.label !== "Recommended") &&
                                 (permissions[key]?.label !== "Top Selling")?
                                  <TableCell>
                                    <Switch
                                      checked={permissions[key].value.includes('UPDATE')}
                                      color="primary"
                                      onChange={() => onSwitchChange(key, 'UPDATE')}
                                      name="top_selling"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  : <TableCell></TableCell>}

                                {(permissions[key]?.label !== "Log") && (permissions[key]?.label !== "Recommended") &&
                                  (permissions[key]?.label !== "Top Selling") && (permissions[key]?.label !== "Audio")
                                  && (permissions[key]?.label !== "Video") && (permissions[key]?.label !== "Feedback")
                                  && (permissions[key]?.label !== "Mobileusers") && (permissions[key]?.label !== "Users") &&
                                  (permissions[key]?.label !== "Portal Users") ?
                                  <>
                                    <TableCell>
                                      <Switch
                                        checked={permissions[key].value.includes('DELETE')}
                                        color="primary"
                                        name="top_selling"
                                        onChange={() => onSwitchChange(key, 'DELETE')}
                                        inputProps={{
                                          "aria-label": "primary checkbox",
                                        }}
                                      />
                                    </TableCell>
                                  </>
                                  : <TableCell></TableCell>}
                              </TableRow>
                            ))}
                            </>
                          </TableBody>
                        </Table>
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}
                          />

                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}

                          <Link
                            to="/ebookflutter_adminpanel/roles"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id !== undefined ?
        <EditRoleslog /> : <></>}
      </div>{
        Prompt
      }
    </React.Fragment>
  );
};

const mapStateToProps = (state: { token: any; user: any; }) => {
  return {
    token: state.token,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AddRoles);
