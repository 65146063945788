// IMPORT DEPENDENCY
import React, { Component } from 'react';
import { withRouter,RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
// IMPORT REACT-REDUX
import { logoutUser } from '../../store/actions';
import { removeUser } from '../../store/auth/admins/user';
import { removeToken } from '../../store/auth/admins/token';

interface LoginProps extends RouteComponentProps {
    removeToken: (token: string) => void;
    removeUser: (user: any) => void;
  }

class Logout extends Component<LoginProps> {
    constructor(props:LoginProps) {
        super(props);
        this.state = {};
    }

    componentDidMount():void{
        // FIRE ACTION FOR REMOVE ALL ITEM FROM LOCAL-STORAGE AND REDIRECT TO LOGIN PAGE
        localStorage.removeItem('token');
        this.props.removeUser('0');
        this.props.removeToken('0');
        this.props.history.replace('/ebookflutter_adminpanel/login');
    }

    render() {
        return (
            <React.Fragment>
                <h1>&nbsp;</h1>
            </React.Fragment>
        );
    }
}

export default withRouter(connect(null, { logoutUser, removeToken, removeUser })(Logout));

