enum ActionTypes {
  LOGOUT_USER = 'LOGOUT_USER',
  REGISTER_USER = 'REGISTER_USER'
}

type Permissions = 'READ' | 'WRITE' | 'UPDATE' | 'DELETE';

interface UserRoles {
  roles: Permissions[];
  users: Permissions[];
  author: Permissions[];
  books: Permissions[];
  top_selling: Permissions[];
  recommended: Permissions[];
  categories: Permissions[];
  subcategories: Permissions[];
  chapters: Permissions[];
  mobile_slider: Permissions[];
  social_media: Permissions[];
  publisher: Permissions[];
  genre: Permissions[];
  feedback: Permissions[];
  author_review: Permissions[];
  mobileUsers: Permissions[];
  book_review: Permissions[];
  feed: Permissions[];
  log: Permissions[];
  email_templates:Permissions[],
  coupons:Permissions[],
  mobileusers:Permissions[],
  name: string;
  email: string;
  role_id: number;
  active: boolean;
  coach:  Permissions[];
  coach_review: Permissions[];
  audio_review:Permissions[];
  video_review:Permissions[];
  audio:Permissions[];
  video:Permissions[];
  audio_view:Permissions[];
  notification:[];
  admin_notification:[];
  notification_template:[];
  newUser:Permissions[];
  portal_users:Permissions[];
}

interface registerUserAction {
  type: ActionTypes.REGISTER_USER;
  payload: UserRoles;
}

interface logoutAction {
  type: ActionTypes.LOGOUT_USER;
}

const registerUser = (payload: UserRoles): registerUserAction => {
  return {
    type: ActionTypes.REGISTER_USER,
    payload,
  }
};

const removeUser = (): logoutAction => {
  return {
    type: ActionTypes.LOGOUT_USER,
  }
};

export { registerUser, removeUser, ActionTypes };
export type { logoutAction, registerUserAction, UserRoles, Permissions };
