import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import swal from "sweetalert";
import "../datatables.scss";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { connect } from 'react-redux';
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import UnSavedChanges from "./UnSavedChanges";

interface Props {
  token: string;
  user: UserRoles;

}
const AddSocialMedia = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState({
    publisher_name: "",
  });
  const [valid, setValid] = useState({
    publisher_name_Error: [] as any,
    msg_Error: [] as any,
  });
  const { publisher_name } = users;
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setUsers({ ...users, [e.target.name]: e.target.value });
    setIsSaved() as any;
    if (!e.target.name || !e.target.value  ) {
      setValid({ ...valid, publisher_name_Error: "*Enter Publisher Name" });
    } else {
      setValid({ ...valid, publisher_name_Error: "" })
    }
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const publisher_name_Error = [];

    const msg_Error = [];

    if (!users.publisher_name) {
      publisher_name_Error.push("*Enter Publisher Name");
    } else {
      setValid({ ...valid, publisher_name_Error: "" })
    }

    // if (users.publisher_name.trim().length === 0)
    //   publisher_name_Error.push("Enter publisher name");

    setValid({ ...valid, publisher_name_Error });

    if (publisher_name_Error.length) return;

    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res1 = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/publisher/publisher`,
          users,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }

        );

        if (res1?.data?.success === 0) {
          setIsLoading(false);
          msg_Error.push(res1?.data?.message);
          setValid({ ...valid, msg_Error });
          if (msg_Error.length) return;
        }

        setIsLoading(false);
        swal({
          title: "Added Successfully!",
          // text: users.publisher_name,
          icon: "success",
          //button: "OK!",
        });

      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;

        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');

        }
      }
    }

    setValid({
      ...valid,
      msg_Error: "",
    });

    setUsers({
      publisher_name: "",
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id !== undefined ? "UPDATE PUBLISHER" : "ADD PUBLISHER"}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>PUBLISHER NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="publisher_name"
                            value={publisher_name || ""}
                            id="exampleFormControlInput1"
                            placeholder="Enter publisher name"
                            onChange={(e) => onInputChange(e)}
                          />
                          {valid?.publisher_name_Error && <div style={{ color: 'red' }}>{valid?.publisher_name_Error} </div>}

                          {/* {valid?.publisher_name_Error.length
                            ? valid?.publisher_name_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                        {valid.msg_Error.length
                          ? valid.msg_Error.map((error: any, i: React.Key | null | undefined) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/publisher"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AddSocialMedia);


