import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import DraftToHtml from "draftjs-to-html";
import HtmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../datatables.scss";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import UnSavedChanges from "./UnSavedChanges";
import axios from "axios";

const AddTopTen = (props) => {
  const { id, book_id, topid, editId } = useParams();
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const [editorState, onEditorStateChange] = useState(
    EditorState.createEmpty()
  );
  const [audio, setAudio] = useState([]); // {audiotype: {value: string; label: string}; file: null}
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges();
  const [nAudio, setNAudio] = useState([]); // {audiotype: {value: string; label: string}; file: null}[]
  const audioRef = useRef();

  const [errors, setErrors] = useState({
    book_error: null,
    chapter_name_error: null,
    chapter_content_error: null,
  });

  const [title, setTitle] = useState("");

  const token = useSelector((state) => state.token);



  useEffect(() => {
    getChapter();
    getAudioType();

    if (topid == "top10") {
      setTitle("The Top 10 things that make this book unique:")
    }
    else if (topid == "top30") {
      setTitle("Top 30 quotes from this book:")
    }
    else {
      setTitle("The Top 50 take a ways from this book:")
    }
  }, []);

  const customToolbar = {
    options: [
      "inline",
      "blockType",
      "fontFamily",
      "textAlign",
      "colorPicker",
      "emoji",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline", "strikethrough"],
    },
    blockType: {
      inDropdown: true,
      options: [
        "Normal",
        "H1",
        "H2",
        "H3",
        "H4",
        "H5",
        "H6",
        "Blockquote",
        "Code",
      ],
    },
    // list: {
    //   options: ['unordered', 'ordered'],
    // },
    textAlign: {
      options: ["left", "center", "right", "justify"],
    },
    history: {
      inDropdown: false,
    },
  };

  const getAudioType = () => {
    axiosInstance
      .get("multipleupload/audio_types")
      .then((res) => {
        setOptions(res.data);
      })
      .catch(() => null);
  };

  const getChapter = async () => {
    const bookId = localStorage.getItem("bookId");
    if (editId) {
      try {
        const res = await axiosInstance.get(
          `/booktoptopics/getbooktopics/${bookId}/${topid}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const blocksFromHtml = HtmlToDraft(res.data[0].booktop_content);
        const { contentBlocks, entityMap } = blocksFromHtml;

        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        onEditorStateChange(EditorState.createWithContent(contentState));
        setTitle(res.data[0].booktop_title);

        setNAudio(res.data[0].booktop_audio.map((ob) => ({ file: ob.id, audiotype: { value: ob.audiotype, label: ob.audiotype } })))

      } catch (e) {
        console.log(e);
      }
    }
  };

  const post = async () => {


    const raw = convertToRaw(editorState.getCurrentContent());
    const chapterContent = DraftToHtml(raw);


    const chapterdata = chapterContent;
    var convertChapter = chapterdata.replace(/(<([^>]+)>)/g, "");

    const data = new FormData();

    if (title.trim().length === 0) {
      toast.error("Summary Title is Required");
      return;
    }
    // const audiotypeLenght = audio.map((obj) => obj.audiotype.label)

    // if (audiotypeLenght?.length === 0) {
    //   toast.error('Please Select AudioType');
    //   return
    // }

    // for (var i = 0; i < audio.length; i++) {
    //   if (audio.length !== 0 && audio[i].audiotype === "") {
    //     toast.error('Please Select AudioType');
    //     return;
    //   } else if (audio.length !== 0 && audio[i].file === null) {
    //     toast.error('Please Select Audio');
    //     return;
    //   }
    // }

    if (convertChapter.trim().length === 0) {
      toast.error("Summary Content is Required");
      return;
    }

    data.append(
      "info",
      JSON.stringify({
        booktopic_content: chapterContent,
        booktopic_title: title,
        book_id,
        booktopic_id: topid,
        audiotype: audio.map((obj) => obj.audiotype.label),
      })
    );

    audio.forEach((aud) => {
      data.append("myfile", aud.file);
    });

    try {
      setIsLoading(true);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/booktoptopics`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Summary created successfully");
    } catch (e) {
      const error = e;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      } else {
        toast.error("Something went wrong! please try again later");
      }
    }
    setIsLoading(false);
  };

  const update = async () => {
    const raw = convertToRaw(editorState.getCurrentContent());
    const chapterContent = DraftToHtml(raw);

    const chapterdata = chapterContent;
    var convertChapter = chapterdata.replace(/(<([^>]+)>)/g, "");

    const data = new FormData();

    if (title.trim().length === 0) {
      toast.error("Summary Title is Required");
      return;
    }

    // const audiotypeLenght = audio.map((obj) => obj.audiotype.label)
    // const preAudioLength = nAudio.map((ob) => ({
    //   name: ob.file,
    //   audiotype: ob.audiotype.label,
    // }))
    
    // if (audiotypeLenght?.length === 0 && preAudioLength.length=== 0 ) {
    //   toast.error('Please Select AudioType');
    //   return
    // }

    // for (var i = 0; i < audio.length; i++) {
    //   if (audio.length !== 0 && audio[i].audiotype === "") {
    //     toast.error("Please Select AudioType");
    //     return;
    //   } else if (audio.length !== 0 && audio[i].file === null) {
    //     toast.error("Please Select Audio");
    //     return;
    //   }
    // }

    if (convertChapter.trim().length === 0) {
      toast.error("Summary Content is Required");
      return;
    }
    data.append(
      "info",
      JSON.stringify({
        booktopic_content: chapterContent,
        booktopic_title: title,
        book_id,
        booktopic_id: topid,
        audiotype: audio.map((obj) => obj.audiotype.label),
        prev_audio: nAudio.map((ob) => ({
          name: ob.file,
          audiotype: ob.audiotype.label,
        }))
        ,
      })
    );
    

    audio.forEach((aud) => {
      data.append("myfile", aud.file);
    });
    const bookId = localStorage.getItem("bookId");

    try {
      setIsLoading(true);
      await axios.put(
        `${process.env.REACT_APP_API_URL}/booktoptopics/${bookId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Summary updated successfully");
    } catch (e) {
      const error = e;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      } else {
        toast.error("Something went wrong! please try again later");
      }
    }

    setIsLoading(false);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (editId) {
      update();
    } else {
      post();
    }
  };

  const removeAudio = (i) => {
    const prev = [...audio];
    prev.splice(i, 1);
    setAudio(prev);
  };

  const removeNAudio = (i) => {
    const prev = [...nAudio];
    prev.splice(i, 1);
    setNAudio(prev);
  };

  const handleNInput = (e, i) => {
    const prev = [...nAudio];
    prev[i]["audiotype"] = e;
    setNAudio(prev);
  };

  const handleInput = (e, i) => {
    const prev = [...audio];
    prev[i]["audiotype"] = e;
    setAudio(prev);
  };

  const renderAudio = () => {
    return (
      <div>
        {audio.map((obj, i) => {
          return (
            <div key={i} style={{ marginTop: "10px" }}>
              <Select
                options={options}
                placeholder="Select audio type"
                value={audio[i].audiotype}
                onChange={(e) => handleInput(e, i)}
              />
              <Button color="primary" onClick={() => removeAudio(i)}>
                Remove Audio
              </Button>

              {obj.file ? (
                <>
                  <audio controls style={{ marginTop: "2px" }}>
                    <source src={URL.createObjectURL(obj.file)} />
                  </audio>
                </>
              ) : (
                <Button
                  color="primary"
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  onClick={() => {
                    setCurrentStep(i);
                    audioRef.current.value = null;
                    audioRef.current.click();
                  }}
                >
                  Select Audio
                </Button>
              )}
            </div>
          );
        })}
        {nAudio.map((obj, i) => {
          return (
            <div key={i} style={{ marginTop: "10px" }}>
              <Select
                options={options}
                placeholder="Select audio type"
                value={obj.audiotype}
                onChange={(e) => handleNInput(e, i)}
              />
              <Button color="primary" onClick={() => removeNAudio(i)}>
                Remove Audio
              </Button>
              {obj.file ? (
                <>
                  <audio controls style={{ marginTop: "2px" }}>
                    <source
                      src={`${process.env.REACT_APP_AUDIO}/audio/${obj.file}.mp3`}
                    />
                  </audio>
                </>
              ) : (
                <Button
                  color="primary"
                  style={{ marginTop: "5px", marginLeft: "5px" }}
                  onClick={() => {
                    setCurrentStep(i);
                    audioRef.current.value = null;
                    audioRef.current.click();
                  }}
                >
                  Select Audio
                </Button>
              )}
            </div>
          );
        })}
      </div>
    );
    // if (nAudio) {
    //   return (
    //     <div>
    //       <audio controls>
    //         <source src={`${process.env.REACT_APP_API_URL}/chapters/audio/${nAudio}`} />
    //       </audio>
    //       <Button color="primary" onClick={(e) => {
    //         e.preventDefault();
    //         setNAudio(null);
    //       }}>remove</Button>
    //     </div>
    //   )
    // }
  };

  const editorStyle = {
    height: "150px",
    border: "1px solid gray",
    padding: "10px",
    borderRadius: "5px",
    overflow: "hidden",
    resize: "vertical", // Add this line to enable vertical resizing
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ color: "black" }}>
        <form onSubmit={onSubmit}>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>
                            {id !== undefined
                              ? "UPDATE SUMMARY TITLE*"
                              : "ADD SUMMARY TITLE*"}
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            name="chapter_name"
                            value={title}
                            onChange={(e) => {
                              setTitle(e.target.value);
                              setIsSaved();
                            }}
                            id="exampleFormControlInput1"
                            placeholder="Enter Summary Title"
                          />
                          {errors.chapter_name_error ? (
                            <ErrorSpan error={errors.chapter_name_error} />
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        { }
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept="audio/*"
                          ref={audioRef}
                          onChange={(e) => {
                            const files = e.target.files;
                            if (
                              files &&
                              files[0] &&
                              files[0].name.match(/\.(mp3)$/i)
                            ) {
                              const prev = [...audio];
                              prev[currentStep].file = files[0];
                              setAudio(prev);
                            }
                          }}
                        />
                        <Button
                          style={{ width: "150px" }}
                          color="primary"
                          onClick={(e) => {
                            e.preventDefault();

                            setAudio((prev) => [
                              ...prev,
                              { audiotype: "", file: null },
                            ]);
                          }}
                        >
                          Add Audio
                        </Button>
                        {renderAudio()}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Editor
                  editorState={editorState}
                  editorStyle={editorStyle}
                  onEditorStateChange={onEditorStateChange}
                  toolbar={customToolbar}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                />

                {errors.chapter_name_error ? (
                  <ErrorSpan error={errors.chapter_name_error} />
                ) : null}
                <div
                  style={{
                    display: "flex",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Submit"
                      onClick={() => {
                        // setTitle("")
                        setPristine();
                      }}
                    />
                  </div>
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <Link
                      to={`/ebookflutter_adminpanel/edit-book/${book_id}`}
                      style={{ color: "white" }}
                    >
                      <button type="text" className="btn btn-primary">
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

export default AddTopTen;
