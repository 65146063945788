enum ActionTypes {
  REGISTER_TOKEN = 'REGISTER_TOKEN',
  REMOVE_TOKEN = 'REMOVE_TOKEN',
}

interface registerTokenAction {
  type: ActionTypes.REGISTER_TOKEN;
  payload: string;
}

interface removeTokenAction {
  type: ActionTypes.REMOVE_TOKEN;
}

const registerToken = (payload: string): registerTokenAction => {
  return {
    type: ActionTypes.REGISTER_TOKEN,
    payload,
  }
};

const removeToken = (): removeTokenAction => {
  return {
    type: ActionTypes.REMOVE_TOKEN,
  }
};

export {registerToken, removeToken, ActionTypes};
export type {registerTokenAction, removeTokenAction};
