// ALL THE COMPONENTS ARE LINKED WITH THE APP.JS COMPONENTS

// IMPORT REACT
import React, { Component } from "react";
// IMPORT REACT-REDUX
import { connect } from "react-redux";
import { registerUser } from './store/auth/admins/user';
import { registerToken } from './store/auth/admins/token';
// IMPORT ROUTES FOR ROUTING
import { authProtectedRoutes, publicRoutes } from "./routes/";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import AppRoute from "./routes/route";
// IMPORT AXIOS FOR API IMPLEMENTATION
import axiosInstance from './helpers/axios';
// IMPORT VERTICAL AND HORIZONTAL LAYOUT FOR ADD HEADER , FOOTER AND SIDEBAR IN PROJECT.
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import "./theme.scss";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
    this.getLayout = this.getLayout.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    this.props.registerToken(token);

    this.setState({ loading: false });
    if (token) {
      axiosInstance.get(`${process.env.REACT_APP_API_URL}/admin/token`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((res) => {
        this.props.registerUser(res.data.user);
      }).catch((e) => console.log(e));
    }
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  render() {
    const Layout = this.getLayout();

    if (this.state.loading) {
      return (
        <p>loading...</p>
      );
    }

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, { registerToken, registerUser })(App);
