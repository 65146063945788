// IMPORT ACTION-TYPES
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  TOGGLE_RIGHT_SIDEBAR,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  CHANGE_PRELOADER,
  HIDE_RIGHT_SIDEBAR,
} from "./actionTypes";

// EXPORT ALL THE ACTION
export const changeLayout = (layout:any) => ({
  type: CHANGE_LAYOUT,
  payload: layout,
});

export const changePreloader = (layout:any) => ({
  type: CHANGE_PRELOADER,
  payload: layout,
});

export const changeLayoutWidth = (width:any, layoutType:any) => ({
  type: CHANGE_LAYOUT_WIDTH,
  payload: { width, layoutType },
});

export const changeSidebarTheme = (theme:any, layoutType:any) => ({
  type: CHANGE_SIDEBAR_THEME,
  payload: { theme, layoutType },
});

export const changeSidebarType = (sidebarType:any, isMobile:any) => {
  return {
    type: CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  };
};

export const changeTopbarTheme = (topbarTheme:any) => ({
  type: CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleRightSidebar = () => ({
	type: TOGGLE_RIGHT_SIDEBAR,
	payload: null
});

export const showRightSidebar = () => ({
	type: SHOW_RIGHT_SIDEBAR,
	payload: null
});

export const hideRightSidebar = () => ({
	type: HIDE_RIGHT_SIDEBAR,
	payload: null
});