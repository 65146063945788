// IMPORT ALL DEPENDENCY HERE
import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from "react-router-dom";
import './sidebar.css';
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import images from '../../assets/images'
import { Navigation } from 'react-minimal-side-navigation';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';


const SidebarContent = (props) => {
  // STATES
  const history = useHistory();
  const location = useLocation();
  const [inactive, setInactive] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // PROPS
  const {
    categories, subcategories, author, books, feed, genre, recommended, top_selling,portal_users,
    mobile_slider, social_media, publisher, roles, users, mobileusers, feedback, author_review, book_review, coupons, email_templates, log,
    admin_notification ,coach, coach_review, audio, video_review, video,
  } = props.user;

// USE-EFFECT ALL THE TITLE AND PATHS ARE DEFINED HERE IN THE FOR OF ARRAY
  useEffect(() => {
    const arr =
      [
        {
          title: 'Dashboard',
          itemId: '/ebookflutter_adminpanel/dashboard',
           elemBefore: () => <img src={images.dashboard} />,
        },

        {
          title: 'Categories',
          itemId: '/ebookflutter_adminpanel/dashboard/category',
          elemBefore: () => <img src={images.category} />,
          subNav: [
            {
              title: 'Category',
              itemId: '/ebookflutter_adminpanel/category',
              elemBefore: () => <img src={images.category} /> ,
            },

            {
              title: 'Sub Category',
              itemId: '/ebookflutter_adminpanel/sub-category',
              elemBefore: () => <img src={images.subcategory} />,

            },
            {
              title: 'Top Category',
              itemId: '/ebookflutter_adminpanel/top_category',
              elemBefore: () => <img src={images.topcategory} />,
            },
          ],
        },

        {
          title: 'Author',
          itemId: '/ebookflutter_adminpanel/dashboard/author',
          elemBefore: () => <img src={images.authoricon} />
          ,
          subNav: [
            {
              title: 'Author',
              itemId: '/ebookflutter_adminpanel/author',
              elemBefore: () => <img src={images.authoricon} />
            },
            {
              title: 'Top Author',
              itemId: '/ebookflutter_adminpanel/top_author',
              elemBefore: () => <img src={images.top_Author} />
            },
            {
              title: 'Author Review',
              itemId: '/ebookflutter_adminpanel/author_review',
              elemBefore: () => <img src={images.author_review} />
            },
          ],
        },
        {
          title: 'Book',
          itemId: '/ebookflutter_adminpanel/dashboard/book',
          elemBefore: () => <img src={images.booknavigation} />,
          subNav: [
            {
              title: 'Book',
              itemId: '/ebookflutter_adminpanel/book',
              elemBefore: () => <img src={images.booknavigation} />
            },
            {
              title: 'Top Selling',
              itemId: '/ebookflutter_adminpanel/top-selling',
              elemBefore: () => <img src={images.topselling} />
            },
            {
              title: 'Recommended',
              itemId: '/ebookflutter_adminpanel/recommended',
              elemBefore: () => <img src={images.recommended} />
            },
            {
              title: 'Book Review',
              itemId: '/ebookflutter_adminpanel/book_review',
              elemBefore: () => <img src={images.bookreview} />
            },
          ],
        },
        {
          title: 'Notification',
          itemId: '/ebookflutter_adminpanel/dashboard/notification',
          elemBefore: () => <img src={images.notification} />
          ,
          subNav: [

            {
              title: 'Notification',
              itemId: '/ebookflutter_adminpanel/notification',
              elemBefore: () => <img src={images.notification} />
            },
            {
              title: 'Notification Templates',
              itemId: '/ebookflutter_adminpanel/notificationTemplate',
              elemBefore: () => <img src={images.notification_templates} />
            },

          ],
        },
        {
          title: 'Coach',
          itemId: '/ebookflutter_adminpanel/dashboard/coach',
          elemBefore: () => <img src={images.coach} />
          ,
          subNav: [

            {
              title: 'Coach',
              itemId: '/ebookflutter_adminpanel/coach',
              elemBefore: () => <img src={images.coach} />
            },
            {
              title: 'Top Coach',
              itemId: '/ebookflutter_adminpanel/top_coach',
              elemBefore: () => <img src={images.top_coach} />
            },
            {
              title: 'Coach Review',
              itemId: '/ebookflutter_adminpanel/CoachReview',
              elemBefore: () => <img src={images.audioreview} />
            },

          ],
        },
        {
          title: 'Audio',
          itemId: '/ebookflutter_adminpanel/dashboard/audio',
          elemBefore: () => <img src={images.audio_main} />
          ,
          subNav: [
            {
              title: 'Audio',
              itemId: '/ebookflutter_adminpanel/audio',
              elemBefore: () => <img src={images.audio} />
            },
            {
              title: 'Audio Review',
              itemId: '/ebookflutter_adminpanel/audioReview',
              elemBefore: () => <img src={images.audioreview} />
            },

          ],
        },
        {
          title: 'Video',
          itemId: '/ebookflutter_adminpanel/dashboard/video',
          elemBefore: () => <img src={images.video} />
          ,
          subNav: [
            {
              title: 'Video',
              itemId: '/ebookflutter_adminpanel/video',
              elemBefore: () => <img src={images.video} />
            },
            {
              title: 'Video Review',
              itemId: '/ebookflutter_adminpanel/videoReview',
              elemBefore: () => <img src={images.video_review} />
            },
          ],
        },
        {
          title: 'Users',
          itemId: '/ebookflutter_adminpanel/dashboard/users',
          elemBefore: () => <img src={images.users} />
          ,
          subNav: [
            {
              title: 'Portal Users',
              itemId: '/ebookflutter_adminpanel/Users',
              elemBefore: () => <img src={images.portal_user}  />
            },
            {
              title: 'Admin Users',
              itemId: '/ebookflutter_adminpanel/admin_user',
              elemBefore: () => <img src={images.admin_user} />
            },
            {
              title: 'Mobile User',
              itemId: '/ebookflutter_adminpanel/mobileusers',
              elemBefore: () => <img src={images.mobile_user} />,
            },

          ],
        },
        {
          title: 'Feed',
          itemId: '/ebookflutter_adminpanel/feed',
          elemBefore: () => <img src={images.feeds} />,
        },
        {
          title: 'Coupons',
          itemId: '/ebookflutter_adminpanel/coupons',
          elemBefore: () => <img src={images.coupon} />,
        },
        {
          title: 'Email Template',
          itemId: '/ebookflutter_adminpanel/email_templates',
          elemBefore: () => <img src={images.email} />,
        },
        {
          title: 'Mobile Slider',
          itemId: '/ebookflutter_adminpanel/mobile',
          elemBefore: () => <img src={images.mobileslider} />
          ,

        },
        {
          title: 'Social media',
          itemId: '/ebookflutter_adminpanel/socialMedia',
          elemBefore: () => <img src={images.socialmedia} />
          ,

        },

        {
          title: 'Publisher',
          itemId: '/ebookflutter_adminpanel/publisher',
          elemBefore: () => <img src={images.publisher} />
          ,

        },
        {
          title: 'Genre',
          itemId: '/ebookflutter_adminpanel/genre',
          elemBefore: () => <img src={images.genre} />,
        },
        {
          title: 'Roles',
          itemId: '/ebookflutter_adminpanel/roles',
          elemBefore: () => <img src={images.roles} />
          ,

        },

        {
          title: 'Feed Back',
          itemId: '/ebookflutter_adminpanel/feedback',
          elemBefore: () => <img src={images.feedbacks} />,
        },
        {
          title: 'Log',
          itemId: '/ebookflutter_adminpanel/log',
          elemBefore: () => <img src={images.log} />
          ,

        },
      ];

    let newArray = [];
    for (var i = 0; i < arr?.length; i++) {

      var b = arr[i];

      if (arr[i]?.subNav != undefined) {
        var sub = [];
        for (var j = 0; j < arr[i]?.subNav.length; j++) {

          if (arr[i]?.subNav[j]?.title === "Category" || arr[i]?.subNav[j]?.title === "Top Category") {
            if (categories?.length !== 0) {

              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else if (arr[i]?.subNav[j]?.title === "Sub Category") {
            if (subcategories?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          } else if (arr[i]?.subNav[j]?.title === "Author" || arr[i]?.subNav[j]?.title === "Top Author") {
            if (author?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
             }else if (arr[i]?.subNav[j]?.title === "Admin Users") {
            if (users?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else if (arr[i]?.subNav[j]?.title === "Portal Users") {
            if (portal_users?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else if (arr[i]?.subNav[j]?.title === "Mobile User") {
            if (mobileusers?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
           else if (arr[i]?.subNav[j]?.title === "Author Review") {
            if (author_review?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }

          else if (arr[i]?.subNav[j]?.title === "Book") {
            if (books?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }

          else if (arr[i]?.subNav[j]?.title === "Top Selling") {
            if (top_selling?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else if (arr[i]?.subNav[j]?.title === "Recommended") {
            if (recommended?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else if (arr[i]?.subNav[j]?.title === "Book Review") {
            if (book_review?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }

          else if (arr[i]?.subNav[j]?.title === "Coach" || arr[i]?.subNav[j]?.title === "Top Coach") {
            if (coach?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }
          else if (arr[i]?.subNav[j]?.title === "Coach Review") {
            if (coach_review?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }
          else if (arr[i]?.subNav[j]?.title === "Audio") {
            if (audio?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }

          else if (arr[i]?.subNav[j]?.title === "Audio Review") {
            if (author_review?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }
          else if (arr[i]?.subNav[j]?.title === "Video") {
            if (video?.length !== 0) {
              sub.push(arr[i].subNav[j]);
            } else {

            }

          }

          else if (arr[i]?.subNav[j]?.title === "Notification") {
            // notification !== undefined &&
             if (admin_notification?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }
          else if (arr[i]?.subNav[j]?.title === "Notification Templates") {
            // notification_template !== undefined &&
            if (admin_notification?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }

          }
          else if (arr[i]?.subNav[j]?.title === "Video Review") {
            if (video_review?.length !== 0) {
              sub.push(arr[i]?.subNav[j]);
            } else {

            }
          }
          else {
            sub.push(arr[i]?.subNav[j]);

            // newArray.push(arr[i])
          }
        }
        b.subNav = sub;
      }

      if (arr[i]?.subNav != undefined) {

        if (b?.subNav?.length != 0) {
          newArray?.push(b)
        }
      } else {
        if (arr[i]?.title === "Feed") {
          if (feed?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        else if (arr[i]?.title === "Publisher") {
          if (publisher?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        } else if (arr[i]?.title === "Coupons") {
          if (coupons?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        } else if (arr[i]?.title === "Email Template") {
          if (email_templates?.length !== 0) {
            newArray.push(arr[i]);
          } else {

          }
        } else if (arr[i]?.title === "Social media") {
          if (social_media?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        } else if (arr[i]?.title === "Genre") {
          if (genre?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        else if (arr[i]?.title === "Roles") {
          if (roles?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        // else if (arr[i]?.title === "Admin Users") {
        //   if (users?.length !== 0) {
        //     newArray?.push(arr[i]);
        //   } else {

        //   }
        // }
        // else if (arr[i]?.title === "Users") {
        //   if (newUser?.length !== 0) {
        //     newArray?.push(arr[i]);
        //   } else {

        //   }
        // }
        else if (arr[i]?.title === "Mobile User") {
          if (mobileusers?.length !== 0) {
            newArray.push(arr[i]);
          } else {

          }
        }
        else if (arr[i]?.title === "Feed Back") {
          if (feedback?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        else if (arr[i]?.title === "Log") {
          if (log?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        else if (arr[i]?.title === "Mobile Slider") {
          if (mobile_slider?.length !== 0) {
            newArray?.push(arr[i]);
          } else {

          }
        }
        else {
          newArray?.push(b)
        }
      }
    }
   setInactive(newArray);

  }, [categories])


  return (
    <>
      <div
        onClick={() => setIsSidebarOpen(false)}
        className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${isSidebarOpen ? "block" : "hidden"
          }`}
      />
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0 bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${isSidebarOpen ? "ease-out translate-x-0" : "ease-in -translate-x-full"
          }`}
      >

        <Navigation
          activeItemId={location?.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
            // maybe push to the route
          }}
          items={inactive}
        />
      </div>
    </>
  );
};

const mapStatetoProps = (state) => {
  return { user: state.user };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
