import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./datatables.scss";
import { Pagination } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import Select from 'react-select';
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { useFetchHook } from '../../utils/fetchHook';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import '../eBook/style.css'

interface Props {
  token: string;
  user: UserRoles;
}
// API data format
type CouponType = {
  id: number,
  coupon_code: String,
  description: String,
  discount_type: number,
  gateway_name:String,
  expiry: Date,
}

const Coupon = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [couponCode, setCouponCode] = useState('');
  const [discountType, setDiscountType] = useState<{ value: string; label: string } | null>(null);
  const [expiryDate, setExpiryDate] = useState('');
  const [dates, setDates] = useState<any>([null, null]);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [isExpanded, setIsExpanded] = useState([] as any);
  const [gateway, setGateway] = useState<{ value: string; label: string } | null>(null);
  const [users, setUsers] = useState({
    discount: null as any,
    gateway: null as any,
  })
  const limit = 50;

  const [{ isLoading, data }, { setOptions }] =
    useFetchHook
      <{ data: CouponType[]; numberOfPage: number; total: number | null, }>
      (`${process.env.REACT_APP_API_URL}/coupons`,
        { data: [], numberOfPage: 1, total: null },
        {
          params: { page },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });


  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          couponCode: couponCode,
          //planType: planType,
          discountType: discountType?.value,
          expiryDate: expiryDate,
          gateway: gateway?.value,

        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
  }, [page]);

  const redirectAdd = () => {
    if (!props.user[modules.genre].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    history.push('add-coupon');
  };

  const options5 = [
    {
      label: "Percentage",
      value: "percent_off",
    },
    {
      label: "Amount",
      value: "amount_off",
    },
  ];

  const gatewayoption = [
    {
      label: "Stripe",
      value: "Funding",
    },
    {
      label: "in app purchase",
      value: "Inapppurchase",
    },
  ];

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };


  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (couponCode.length > 0 || gateway != null || discountType != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setOptions({
          params: {
            page,
            couponCode: couponCode,
            /// planType: planType,
            discountType: discountType?.value,
            expiryDate: expiryDate,
            gateway: gateway?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }
  }

  useEffect(() => {
    onFilterApply(null as any, false)
  }, [users])


  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {

    if (couponCode.length > 0 || gateway != null || discountType != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setOptions({
        params: {
          page,
          couponCode: couponCode,
          // planType: planType,
          discountType: discountType?.value,
          expiryDate: expiryDate,
          gateway: gateway?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };


  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setCouponCode('');
    //setPlanType('');
    setDiscountType(null);
    setExpiryDate('');
    setShowError(false);
    setDates([null, null]);
    setGateway(null);

    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  const removeElement = (id: any) => {
    const temp = [isExpanded];
    temp.splice(temp.indexOf(id), 1);
    setIsExpanded(temp);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">COUPON LIST({data.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>

                      <Button
                        variant="contained"
                        // style={{ color: "blue" }}

                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        Add COUPON
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Coupon Code" value={couponCode} onKeyPress={handleKeyPress} onChange={setter(setCouponCode)} />

                    </Col>
                    <Col xs={3}>
                      <div className="form-group">
                        <Select placeholder="DiscountType" name="discountType" options={options5} value={discountType}
                          //  onChange={setDiscountType}
                          onChange={(value) => {
                            setDiscountType(value);
                            setUsers({ ...users, discount: value })

                          }}
                        />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Gateway" name="gateway" options={gatewayoption} value={gateway}
                        // onChange={setGateway}
                        onChange={(value) => {
                          setGateway(value);
                          setUsers({ ...users, gateway: value })
                        }} />
                    </Col></Row><Row>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>

                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>COUPON CODE</TableCell>
                          <TableCell>DESCRIPTION</TableCell>
                          <TableCell>DISCOUNT TYPE</TableCell>
                          <TableCell>EXPIRY DATE</TableCell>
                          <TableCell>GATEWAY</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (

                            <TableRow key={user.id}>
                              <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                              <TableCell>{user.coupon_code}</TableCell>
                              {/* <TableCell>{user.description}</TableCell> */}
                              <TableCell>{user.description.length < 50 ? user.description :
                              <>
                                <div style={{ width: "500px" }}>
                                  {isExpanded.includes(user.id) ? user.description : user.description.slice(0, limit) + '...'}
                                  {user.description.length > limit && (
                                    <button  className="desc-btn" onClick={() => {
                                      isExpanded.includes(user.id) ? removeElement(user.id) : setIsExpanded([...isExpanded, user.id])
                                    }}>
                                      {isExpanded.includes(user.id) ? 'Read Less' : 'Read More'}
                                    </button>

                                  )}</div>
                              </>}
                            </TableCell>
                              <TableCell>{user.discount_type}</TableCell>

                              <TableCell>{`${new Date(user.expiry).getDate() + "  "} - ${new Date(user.expiry).getMonth() + 1} - ${new Date(user.expiry).getFullYear()}`}</TableCell>
                              <TableCell>{user.gateway_name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => {
                        setPage(value)
                        removeElement(_e)
                      }}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Coupon);
