/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Row, Col, Card, CardBody, Container, InputGroup, InputGroupAddon, Input } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
//  import axios from '../../utils/axios';
import "../../eBook/style.css"
import Select from "react-select";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import Image from "../../../components/Common/Image";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/actions";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { AxiosError } from "axios";
// import isURL from 'validator/lib/isURL';
import AsyncSelect from 'react-select/async';
import { MultiValue } from 'react-select';
import { TextareaAutosize } from "@mui/material";
import Compress from "browser-image-compression";
import UnSavedChanges from "./UnSavedChanges";



interface Props {
    token: string;
    user: UserRoles;
}

const AddCoach = (props: Props) => {
    const { id } = useParams() as {
        id?: number | undefined;
    }
    const [subcategories, setSubcategories] = useState([] as any);
    const [country, setCountry] = useState([] as any);
    const [niche, setNiche] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [sta, setSta] = useState<{ value: number; label: string } | null>(null);
    const [coaching, setCoaching] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [social, setSocial] = useState([] as any);
    const [socialData, setSocialData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [language, setLanguage] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [imgcompressor, setImgcompressor] = useState<any>({ imageUrl: null });
    const [users, setUsers] = useState({
        coach_first_name: "",
        coach_last_name: "",
        coaching_certificate: [""] || null as any,
        niche_id: [0] as any,
        format_id: [0] as any,
        lang_id: [0] as any,
        address: "",
        zip: "",
        country_id: 0,
        state_id: '' as any,
        public_mobileno: "",
        private_mobileno: "",
        coaching_program_title: "",
        coaching_philosophy: "",
        coach_bio: "",
        social_id: "",
        created_at: "",
        updated_at: "",
        created_by: 1,
        updated_by: "",
        deleted: false,
        approved_by: "",
        approved_at: "",
        approval_status: true,
        niche_name: "",
    })

    const [valid, setValid] = useState({
        coach_first_name_Error: [] as any,
        coach_last_name_Error: [] as any,
        coach_bio_Error: [] as any,
        authors_phone_number: [] as any,
        private_phone_number: [] as any,
        social_Error: [] as any,
    });
    const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
    const [showing, setimg] = useState([] as any);
    const [networkShowing, setNetworkingShow] = useState([]);
    const [coverImage, setCoverImage] = useState<any>(null);
    const [networkCover, setNetworkImage] = useState<any>(null);
    const imgRef = React.useRef<HTMLInputElement>(null);
    const coverRef = React.useRef<HTMLInputElement>(null);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

        e.persist();
        setIsSaved() as any;
        setUsers({ ...users, [e.target.name]: e.target.value })


        if ((selectedValue.value.length)) {
            setValid({ ...valid, coach_first_name_Error: "" })
        } else {
            setValid({ ...valid, coach_first_name_Error: "*Enter Coach First Name" });

        }
    };

    const onInputChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        e.persist();
        setIsSaved() as any;
        setUsers({ ...users, [e.target.name]: e.target.value });

        if (!e.target.name || !e.target.value || users.coach_bio === "") {
            setValid({ ...valid, coach_bio_Error: "*Enter Coach Bio" });
        } else {
            setValid({ ...valid, coach_bio_Error: "" })
        }
    };

    const handleRemoveClick = (index: any) => {
        const rows = users.coaching_certificate;
        rows.splice(index, 1);
        setUsers({ ...users, coaching_certificate: rows });
    }

    const addFields = () => {
        setUsers({ ...users, coaching_certificate: [...users.coaching_certificate, ""] })
    }

    const postAuthor = async () => {
        const coach_first_name_Error = [] as any;
        const coach_last_name_Error = [] as any;
        const coach_bio_Error = [] as any;
        const social_Error = [] as any;

        if (!users.coach_first_name) {
            coach_first_name_Error.push("*Enter Coach First Name");
        } else {
            setValid({ ...valid, coach_first_name_Error: "" })
        }

        if (!users.coach_last_name) {
            coach_last_name_Error.push("*Enter Coach Last Name");
        } else {
            setValid({ ...valid, coach_last_name_Error: "" })
        }
        if (!users.coach_bio) {
            coach_bio_Error.push("*Enter Coach Bio");
        } else {
            setValid({ ...valid, coach_bio_Error: "" })
        }


        // if (users.coach_first_name === "") {
        //     coach_first_name_Error.push("Enter Coach First Name");
        // }
        // if (users.coach_last_name === "") {
        //     coach_last_name_Error.push("Enter Coach Last Name");
        // }
        // if (users.coach_bio === "") {
        //     coach_bio_Error.push("Enter Coach Bio");
        // }
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        let foundInvalidUrl = false;

        social.forEach((s: { value: any; url: any; }) => {
            if (!s.url) {
                foundInvalidUrl = true;
                // social_Error.push("Please Enter Valid URL");
            } else if (!urlRegex.test(s.url)) {
                foundInvalidUrl = true;
                // social_Error.push("Invalid URL");
            }
        });
        if (foundInvalidUrl) {
            social_Error.push("Please Enter A Valid URL For All Social Links");
        }
        setValid({
            ...valid,
            coach_first_name_Error,
            coach_last_name_Error,
            coach_bio_Error,
            social_Error,
        });

        if (
            coach_first_name_Error.length ||
            coach_last_name_Error.length ||
            social_Error.length ||
            coach_bio_Error.length
        )
            return;

        let cc1 = users.coaching_certificate;
        if (users.coaching_certificate[0]?.trim()?.length === 0) {
            cc1 = null as any;

        }

        const formData = new FormData();
        const data = {
            coach_first_name: users.coach_first_name,
            coach_last_name: users.coach_last_name,
            coaching_certificate: cc1,
            country_id: country?.value,
            state_id: sta?.value,
            zip: users.zip,
            address: users.address,
            niche_id: niche ? niche.map((ni: { value: any; }) => ni.value) : [] as any,
            format_id: coaching ? coaching.map((coaching: { value: any; }) => coaching.value) : [] as any,
            lang_id: language ? language.map((lang: { value: any; }) => lang.value) : [] as any,
            public_mobileno: users.public_mobileno,
            private_mobileno: users.private_mobileno,
            coaching_program_title: users.coaching_program_title,
            coaching_philosophy: users.coaching_philosophy,
            coach_bio: users.coach_bio,
            social: social?.map((s: { value: any; url: any; }) => ({ social_id: s.value, url: s.url })),
        }

        formData.append('info', JSON.stringify(data));

        if (showing.length !== 0) {
            Array.from(showing).forEach((f: any) => {
                formData.append('coach_images', f);
            });
        }

        if (coverImage) {
            formData.append('primary_image', coverImage);
        }

        if (isLoading === false) {
            setIsLoading(true);
            try {
                await axiosInstance.post(
                    `${process.env.REACT_APP_API_URL}/coach/admin-coach`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${props.token}`
                        }
                    }
                );
                swal({
                    title: "Coach Added Successfully!",
                    icon: "success",

                });
                setUsers({
                    ...users,
                    coach_first_name: "",
                    coach_last_name: "",
                    coaching_certificate: [""],
                    country_id: 0,
                    state_id: "" as any,
                    zip: "",
                    address: "" as any,
                    niche_id: [0],
                    format_id: [0],
                    lang_id: [0],
                    public_mobileno: "",
                    private_mobileno: "",
                    coaching_program_title: "",
                    coaching_philosophy: "",
                    coach_bio: ""
                });
                setimg([]);
                setCountry([]);
                setLanguage([]);
                setSta(null);
                setSocial([]);
                setCoverImage(null);
                setNetworkImage(null);
                setNetworkingShow([])
                setNiche(null);
                setCoaching([]);
                setSubcategories([]);

            } catch (e) {
                const error = e as AxiosError;
                if (error.response?.data.error) {
                    toast.error(error.response?.data.error);
                }
                else {
                    toast.error('Something went wrong. Please try again later');
                }
            }
            setIsLoading(false);
        }
    };

    const updateAuthor = async () => {
        const coach_first_name_Error = [] as any;
        const coach_last_name_Error = [] as any;
        const coach_bio_Error = [] as any;
        const social_Error = [] as any;

        if (!users.coach_first_name) {
            coach_first_name_Error.push("*Enter Coach First Name");
        } else {
            setValid({ ...valid, coach_first_name_Error: "" })
        }

        if (!users.coach_last_name) {
            coach_last_name_Error.push("*Enter Coach Last Name");
        } else {
            setValid({ ...valid, coach_last_name_Error: "" })
        }
        if (!users.coach_bio) {
            coach_bio_Error.push("*Enter Coach Bio");
        } else {
            setValid({ ...valid, coach_bio_Error: "" })
        }
        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        let foundInvalidUrl = false;

        social.forEach((s: { value: any; url: any; }) => {
            console.log("URL", s.url)
            if (!s.url) {
                foundInvalidUrl = true;
                // social_Error.push("Please Enter Valid URL");
            } else if (!urlRegex.test(s.url)) {
                foundInvalidUrl = true;
                // social_Error.push("Invalid URL");
            }
        });
        if (foundInvalidUrl) {
            social_Error.push("Please Enter A Valid URL For All Social Links");
        }
        setValid({
            ...valid,
            coach_first_name_Error,
            coach_last_name_Error,
            coach_bio_Error,
            social_Error,
        });
        if
            (
            coach_first_name_Error.length ||
            coach_last_name_Error.length ||
            social_Error.length ||
            coach_bio_Error.length
        )
            return;

        let cc1 = users.coaching_certificate;
        if (users.coaching_certificate[0].trim().length === 0) {
            cc1 = null as any;

        }
        const formData = new FormData();
        const data = {
            coach_first_name: users.coach_first_name,
            coach_last_name: users.coach_last_name,
            coaching_certificate: cc1,
            country_id: country?.value,
            state_id: sta?.value,
            zip: users.zip,
            address: users.address,
            niche_id: niche ? niche.map((ni: { value: any; }) => ni.value) : [] as any,
            format_id: coaching ? coaching.map((coa: { value: any; }) => coa.value) : [] as any,
            lang_id: language ? language.map((lang: { value: any; }) => lang.value) : [] as any,
            public_mobileno: users.public_mobileno,
            private_mobileno: users.private_mobileno,
            coaching_program_title: users.coaching_program_title,
            coaching_philosophy: users.coaching_philosophy,
            coach_bio: users.coach_bio,
            social: social?.map((s: { value: any; url: any; }) => ({ social_id: s.value, url: s.url })),
            cover_to_keep: networkCover,
            images_to_keep: networkShowing,
        }

        formData.append('info', JSON.stringify(data));

        if (showing.length !== 0) {
            Array.from(showing).forEach((f: any) => {
                formData.append('coach_images', f);
            });
        }

        if (coverImage) {
            formData.append('primary_image', coverImage);
        }

        if (isLoading === false) {
            setIsLoading(true);
            try {
                await axiosInstance.put(
                    `${process.env.REACT_APP_API_URL}/coach/admin-coach/${id}`,
                    formData,
                    {
                        headers: {
                            Authorization: `Bearer ${props.token}`
                        }
                    }
                );
                swal({
                    title: "Coach Updated Successfully!",
                    icon: "success",

                });

            } catch (e) {
                const error = e as AxiosError;


                if (error.response?.data.error) {
                    toast.error(error.response?.data.error);
                }
                else {
                    toast.error('Something went wrong. Please try again later');
                }
            }

            setIsLoading(false);
        }

    };

    const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (id) {
            updateAuthor();
        } else {
            postAuthor();
        }
    };

    useEffect(() => {
        getSocialData();
        getAuthor();
    }, []);

    const getAuthor = async () => {

        if (id) {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/coach/admin-coach/${id}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }
                })

                const data = await res.json();
                setUsers({
                    ...data,
                    coach_first_name: data.data.coach_first_name,
                    coach_last_name: data.data.coach_last_name,
                    country_id: data.data.country_id,
                    zip: data.data.zip,
                    address: data.data.address,
                    private_mobileno: data.data.private_mobileno,
                    public_mobileno: data.data.public_mobileno,
                    coaching_philosophy: data.data.coaching_philosophy,
                    coaching_program_title: data.data.coaching_program_title,
                    coach_bio: data.data.coach_bio,
                    //    coaching_certificate: data.data.coaching_certificate,
                    coaching_certificate: data.data.coaching_certificate === null ? [""] : data.data.coaching_certificate,
                });

                setNetworkImage(data.data.primary_image);
                setNetworkingShow(data.data.coach_images);
                setLanguage(data.data.lang);
                setCoaching(data.data.format);
                setSocial(data.data.social);
                setNiche(data.data.niche)
                if (data.data.con[0].label !== undefined) {
                    setCountry(data.data.con[0]);
                    await loadState(data.data.con[0].value);
                    if (data.data.state[0].label !== undefined) {
                        setSta(data.data.state[0]);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    const getSocialData = async () => {
        try {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/socialMedia/get-socialMedia`);
            const dataRaw = await req.json();
            const data = dataRaw.data;
            const mapData = data.map((s: { social_name: any; social_id: any; iconpath: any; }) => ({
                label: s.social_name,
                value: s.social_id,
                iconpath: s.iconpath,
                url: '',
            }));

            setSocialData(mapData);
        } catch (e) {
            console.log(e);
        }
    };

    const renderCover = () => {
        if (networkCover) {
            return (
                <Row>
                    <Col xs="auto">
                        <Image src={`${process.env.REACT_APP_S3_APP_API}/coach/${networkCover}`} height="auto" width="100px" alt="cover" onPress={() => setNetworkImage(null)} />
                    </Col>
                </Row>
            )
        }

        if (coverImage) {
            return (
                <Row>
                    <Col xs="auto">
                        <Image src={URL.createObjectURL(coverImage)} height="auto" width="100px" alt="cover" onPress={() => setCoverImage(null)} />
                    </Col>
                </Row>
            )
        }

        return (
            <button className="btn btn-primary" onClick={(e) => {
                e.preventDefault();
                coverRef?.current?.click();
            }}>Add Image</button>
        );
    };

    const renderShowing = () => {
        return (
            <Row>
                {networkShowing.map((img) => (
                    <Col xs="auto">
                        <Image src={`${process.env.REACT_APP_S3_APP_API}/coach/${img}`} height="auto" width="100px" alt="cover" onPress={() => {
                            const newImg = networkShowing.filter((t) => t !== img);

                            setNetworkingShow(newImg);
                        }} />
                    </Col>
                ))}
                {showing.map((img: any, i: any) => {
                    return (
                        <Col xs="auto" key={i}>
                            <Image src={URL.createObjectURL(img)} height="auto" width="100px" alt="profile" onPress={() => {
                                const newArr = showing.filter((file: any) => file.name !== img.name && file.lastModified !== img.lastModified);
                                setimg(newArr);
                            }} />
                        </Col>
                    )
                })}
            </Row>
        )
    }

    const loadCountry = async (country: String) => {
        try {
            const count = await axiosInstance.get('/web_coach/drop-countries', {
                params: {
                    country,
                }
            });
            return count.data;
        } catch (e) { }
    };


    const loadState = async (country_id: String) => {
        try {
            const statess = await axiosInstance.get('/web_coach/drop-states', {
                params: {
                    country_id: country_id,
                }
            });

            setSubcategories(statess.data);

        } catch (e) { }
    };

    const loadCoaching = async (coaching: string) => {
        try {
            const coachings = await axiosInstance.get('/web_coach/coaching-format', {
                params: {
                    coaching,
                }
            });
            return coachings.data;


        } catch (e) {
        }
    };

    const loadNiche = async (niche: string) => {
        try {
            const niches = await axiosInstance.get('/web_coach/niche', {
                params: {
                    niche,
                }
            });
            return niches.data;
        } catch (e) { }
    };

    const loadLang = async (lang: string) => {
        try {
            const language = await axiosInstance.get('/language/drop-lang', {
                params: {
                    lang,
                }
            });

            return language.data;
        } catch (e) { }
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h3 className="card-title">Coach Information</h3>
                                    <form onSubmit={onSubmit} encType="multipart/form-data">
                                        <Row>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH FIRST NAME*</label>
                                                    <input

                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="First Name*"

                                                        name="coach_first_name"
                                                        value={users.coach_first_name}
                                                        onChange={onInputChange}
                                                    />
                                                    {valid?.coach_first_name_Error && <div style={{ color: 'red' }}>{valid?.coach_first_name_Error} </div>}


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH LAST NAME*</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput2"
                                                        placeholder="Last Name*"

                                                        name="coach_last_name"
                                                        value={users.coach_last_name}
                                                        // onChange={onInputChange}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const selectedValue2 = document.getElementById('exampleFormControlInput2') as HTMLInputElement;

                                                            e.persist();
                                                            setIsSaved() as any;
                                                            setUsers({ ...users, [e.target.name]: e.target.value })

                                                            if ((selectedValue2.value.length)) {
                                                                setValid({ ...valid, coach_last_name_Error: "" })
                                                            } else {
                                                                setValid({ ...valid, coach_last_name_Error: "*Enter Coach Last Name" });

                                                            }

                                                            // if (!e.target.name || !e.target.value || users.coach_last_name === "") {
                                                            //     setValid({ ...valid, coach_last_name_Error: "*Enter Coach Last Name" });
                                                            // } else {
                                                            //     setValid({ ...valid, coach_last_name_Error: "" })
                                                            // }

                                                        }}
                                                    />

                                                    {valid?.coach_last_name_Error && <div style={{ color: 'red' }}>{valid?.coach_last_name_Error} </div>}


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A COUNTRY</label>
                                                    <AsyncSelect placeholder="Choose A Country" value={country} cacheOptions defaultOptions loadOptions={loadCountry}
                                                        onChange={(e: any) => {
                                                            setCountry(e);
                                                            setSta(null);
                                                            setSubcategories([])
                                                            loadState(e.value);
                                                            setIsSaved() as any;
                                                            setUsers({ ...users, ['country_id']: e?.value as any })

                                                        }}
                                                    />
                                                </div>

                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A STATE</label>
                                                    <Select options={subcategories} value={sta} placeholder="Choose A State"
                                                        // onChange={setSta}
                                                        onChange={(e: any) => {
                                                            setSta(e) as any;
                                                            setIsSaved() as any;

                                                        }}

                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>ADDRESS</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Address"
                                                        name="address"
                                                        value={users.address}
                                                        onChange={onInputChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Zip Code"
                                                        name="zip"
                                                        value={users.zip}
                                                        onChange={onInputChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A NICHE</label>
                                                    <AsyncSelect placeholder="Choose A Niche" isMulti
                                                        value={niche} cacheOptions defaultOptions loadOptions={loadNiche}
                                                        // onChange={setNiche}
                                                        onChange={(e) => {
                                                            setNiche(e)
                                                            setIsSaved() as any;
                                                        }} />
                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A COACHING FORMATE</label>





                                                    <AsyncSelect placeholder="Choose A Coaching Formate"
                                                        isMulti value={coaching} cacheOptions defaultOptions
                                                        loadOptions={loadCoaching}
                                                        //  onChange={setCoaching}
                                                        onChange={(e) => {
                                                            setCoaching(e)
                                                            setIsSaved() as any;
                                                        }} />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>LANGUAGE</label>
                                                    <AsyncSelect placeholder="Language" isMulti value={language} cacheOptions defaultOptions loadOptions={loadLang}
                                                        //  onChange={setLanguage}
                                                        onChange={(e) => {
                                                            setLanguage(e)
                                                            setIsSaved() as any;
                                                        }} />

                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group phone-input">
                                                    <label>PUBLIC MOBILE NUMBER</label>
                                                    <PhoneInput
                                                        defaultCountry="US"
                                                        placeholder="Enter Your Mobile No"
                                                        style={{
                                                            border: "1px solid #ccc",
                                                            padding: "5px 10px",
                                                            borderRadius: "5px",
                                                        }}
                                                        name="public_mobileno"
                                                        value={users.public_mobileno}
                                                        onChange={(e) => {
                                                            setIsSaved() as any;
                                                            try {
                                                                if ((e as string).length > 0) {

                                                                    if (!isValidPhoneNumber(e as string)) {
                                                                        setValid({ ...valid, authors_phone_number: ["Invalid Phone Number"] });
                                                                    } else {
                                                                        setValid({ ...valid, authors_phone_number: [] });
                                                                    }


                                                                }

                                                                setUsers({ ...users, ['public_mobileno']: e as any });

                                                            } catch (error) {
                                                                setValid({ ...valid, authors_phone_number: [] });

                                                            }
                                                        }
                                                        }

                                                    />
                                                    {valid?.authors_phone_number?.length
                                                        ? valid?.authors_phone_number.map((error: any, i: React.Key | null | undefined) => (
                                                            <ErrorSpan key={i} error={error} />
                                                        ))
                                                        : null}
                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group phone-input">
                                                    <label>PRIVATE MOBILE NUMBER</label>
                                                    <PhoneInput
                                                        defaultCountry="US"
                                                        placeholder="Enter Your Mobile No"
                                                        name="public_phone"
                                                        style={{
                                                            border: "1px solid #ccc",
                                                            padding: "5px 10px",
                                                            borderRadius: "5px",
                                                        }}
                                                        value={users.private_mobileno}
                                                        onChange={(e) => {
                                                            setIsSaved() as any;
                                                            try {
                                                                if ((e as string).length > 0) {


                                                                    if (!isValidPhoneNumber(e as string)) {
                                                                        setValid({ ...valid, private_phone_number: ["Invalid Phone Number"] });
                                                                    } else {
                                                                        setValid({ ...valid, private_phone_number: [] });
                                                                    }
                                                                }

                                                                setUsers({ ...users, ['private_mobileno']: e as any });

                                                            } catch (error) {
                                                                setValid({ ...valid, private_phone_number: [] });

                                                            }
                                                        }
                                                        }
                                                    />
                                                    {valid?.private_phone_number?.length
                                                        ? valid?.private_phone_number.map((error: any, i: React.Key | null | undefined) => (
                                                            <ErrorSpan key={i} error={error} />
                                                        ))
                                                        : null}
                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACHING PROGRAM TITLE</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Coaching Program Title"
                                                        name="coaching_program_title"
                                                        value={users.coaching_program_title}
                                                        onChange={onInputChange}
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACHING PHILOSOPHY</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Coaching Philosophy"
                                                        name="coaching_philosophy"
                                                        value={users.coaching_philosophy}
                                                        onChange={onInputChange}
                                                    />

                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <label>CHOOSE A COACHING CERTIFICATE</label>
                                                {users.coaching_certificate?.map((ddssss, index) => {
                                                    return (

                                                        <div className="form-group" key={index}>

                                                            <input

                                                                type="text"
                                                                className="form-control"
                                                                id="exampleFormControlInput1"
                                                                placeholder="Coaching Certificate"

                                                                name="coaching_certificate"
                                                                value={users.coaching_certificate[index]}
                                                                // onChange={onInputChangeOne}
                                                                // onChange={e => {
                                                                //     setIsSaved() as any;

                                                                //     const temp = users.coaching_certificate;
                                                                //     temp[index] = e.target.value;
                                                                //     setUsers({ ...users, coaching_certificate: temp })
                                                                // }}
                                                                onChange={e => {
                                                                    setIsSaved(true)
                                                                    const temp = users.coaching_certificate;
                                                                    temp[index] = e.target.value;
                                                                    setUsers({ ...users, coaching_certificate: temp })
                                                                }}


                                                            />

                                                            {users.coaching_certificate.length !== 1 && (
                                                                <button type="button" className='btn btn-primary'

                                                                    onClick={() => handleRemoveClick(index)}>Remove</button>
                                                            )}{" "}
                                                            {users.coaching_certificate.length - 1 === index && (
                                                                <button type="button" className='btn btn-primary' onClick={addFields}>Add More..</button>
                                                            )}

                                                        </div>
                                                    )
                                                })

                                                }
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH BIO*</label>
                                                    <TextareaAutosize
                                                        maxLength={500}
                                                        // type="text"
                                                        // className="form-control"
                                                        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                                                        minRows={4}
                                                        // id="exampleFormControlInput1"
                                                        placeholder="Short Bio:*"
                                                        name="coach_bio"
                                                        value={users.coach_bio}
                                                        onChange={onInputChangeText}
                                                    />
                                                    {valid?.coach_bio_Error && <div style={{ color: 'red' }}>{valid?.coach_bio_Error} </div>}

                                                    {/* {valid?.coach_bio_Error?.length
                                                        ? valid?.coach_bio_Error.map((error: any, i: React.Key | null | undefined) => (
                                                            <ErrorSpan key={i} error={error} />
                                                        ))
                                                        : null} */}
                                                    <label style={{ color: "#0069d9" }}>{users.coach_bio.length} characters</label>

                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>SOCIAL MEDIA</label>

                                                    <Select
                                                        className="select"
                                                        name="genre_id"
                                                        placeholder="Select Social Links"
                                                        isMulti
                                                        value={social}
                                                        // onChange={setSocial}
                                                        onChange={(e) => {
                                                            setSocial(e)
                                                            setIsSaved() as any;
                                                        }}
                                                        options={socialData}
                                                    />
                                                    {social?.map((temp: { iconpath: any; }, i: any) => (
                                                        <InputGroup style={{ marginTop: '4px' }}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <img src={`${process.env.REACT_APP_S3_APP_API}/social/${temp.iconpath}`} height="38px" width="30px" alt="sc" />

                                                            </InputGroupAddon>
                                                            <Input placeholder="Place Your Link Here" value={social[i].url} onChange={(e) => {
                                                                const t = [...social];
                                                                t[i]['url'] = e.target.value;

                                                                setSocial(t);
                                                            }} />
                                                        </InputGroup>

                                                    ))}
                                                    {valid?.social_Error?.length
                                                        ? valid?.social_Error.map((error: any, i: React.Key | null | undefined) => (
                                                            <ErrorSpan key={i} error={error} />
                                                        ))
                                                        : null}

                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <label>PRIMARY COACH IMAGE</label>
                                                <input
                                                    type="file"
                                                    ref={coverRef}
                                                    className="form-control"
                                                    id="exampleFormControlInput1"
                                                    name="primary_image"
                                                    style={{ display: 'none' }}
                                                    accept="image/*"
                                                    onChange={(e: any) => {
                                                        const file = e.target.files[0];
                                                        setIsSaved() as any;
                                                        if (e.target.files) {
                                                            if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                                                toast.error('only PNG/JPEG/JPG are supported');
                                                                return;
                                                            }
                                                            if (e.target.files[0].size > 5000_000) {
                                                                toast.error('File too large. Please select file less than 5MB');
                                                                return;
                                                            }
                                                            const options = {
                                                                maxSizeMB: 1,
                                                                useWebWorker: true
                                                            }
                                                            Compress(file, options)
                                                                .then((compressedBlob) => {

                                                                    setImgcompressor({
                                                                        imageUrl: URL.createObjectURL(compressedBlob)
                                                                    })
                                                                    console.log(imgcompressor)
                                                                    const convertedBlobFile = new File([compressedBlob], file.name, {
                                                                        type: file.type,
                                                                        lastModified: Date.now(),
                                                                    });
                                                                    setCoverImage(convertedBlobFile)
                                                                })
                                                                .catch((e) => {
                                                                });

                                                            // setCoverImage(e.target.files[0]);
                                                        }
                                                    }}
                                                />
                                                <br />
                                                {renderCover()}
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">
                                                    {" "}
                                                    <label>COACH IMAGES</label>
                                                    <input
                                                        type="file"
                                                        ref={imgRef}
                                                        className="form-control"
                                                        id="exampleFormControlInput1"

                                                        name="coach_images"
                                                        style={{ display: 'none' }}
                                                        accept="image/*"
                                                        multiple
                                                        onChange={async (e) => {
                                                            setIsSaved() as any;
                                                            const files: File[] = []
                                                            const previews = []
                                                            if (e.target.files) {
                                                                if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                                                    toast.error('only PNG/JPEG/JPG are supported');
                                                                    return;
                                                                }
                                                                if (e.target.files[0].size > 5000_000) {
                                                                    toast.error('File too large. Please select file less than 5MB');
                                                                    return;
                                                                }
                                                                const y = e.target.files;
                                                                for (var i = 0; i < y.length; i++) {
                                                                    const file = y[i];
                                                                    if (file.name.match(/\.(png|jpeg|jpg)$/i)) {
                                                                        const options = {
                                                                            maxSizeMB: 1,
                                                                            useWebWorker: true
                                                                        };

                                                                        await Compress(file, options)
                                                                            .then((compressedBlob) => {
                                                                                setImgcompressor({
                                                                                    imageUrl: URL.createObjectURL(compressedBlob)
                                                                                })
                                                                                console.log(imgcompressor);
                                                                                const convertedBlobFile = new File([compressedBlob], file.name, {
                                                                                    type: file.type,
                                                                                    lastModified: Date.now(),
                                                                                });

                                                                                files.push(convertedBlobFile);

                                                                                previews.push(URL.createObjectURL(convertedBlobFile))

                                                                            })
                                                                            .catch((e) => {
                                                                            });

                                                                    }
                                                                }
                                                                files.map(e =>
                                                                    console.log("File Path: ", URL.createObjectURL(e))
                                                                )
                                                                setimg(files)
                                                            }

                                                        }}

                                                    />
                                                    <br />
                                                    <button className="btn btn-primary" onClick={(e) => {
                                                        e.preventDefault();

                                                        imgRef?.current?.click();
                                                    }}>Add Images</button>
                                                    {renderShowing()}
                                                </div>
                                            </Col>

                                            <div
                                                style={{
                                                    display: "flex",
                                                    margin: "5px",
                                                    padding: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <input
                                                        name="submit"
                                                        type="submit"
                                                        value="Submit"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            setPristine()
                                                        }}

                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    <Link
                                                        to="/ebookflutter_adminpanel/coach"
                                                        style={{ color: "white" }}
                                                    >
                                                        <button type="button" className="btn btn-primary">
                                                            Back
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {isLoading ? <Loader /> : null}
            </div>
            {Prompt}
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        token: state.token!,
        user: state.user,
    }
};

export default connect(mapStateToProps)(AddCoach);


