
// THIS COMPONENT IS USED FOR IMAGE IN ALL THE PROJECT
import React from 'react';

const Image = ({src, height, width, alt, onPress, showClose}) => {
  return (
    <div style={{height, width}}>
      <img src={src} height={height} width={width} alt={alt} />
      {showClose ? (
        <div style={{
        padding: '10px', color: 'white', backgroundColor: 'red', borderColor: 'red', borderRadius: 10, height: 10, width: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 0, right: 0, cursor: 'pointer'
      }} onClick={onPress}>x</div>
      ) : null}
    </div>
  )
}
Image.defaultProps = {
  showClose: true,
};

export default Image;
