import React, { useState } from 'react'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Row } from 'rsuite';
import { Card, Grid, TextField, Autocomplete, Container, CardContent, Switch } from '@mui/material';
import { Input } from "reactstrap";
import { connect } from 'react-redux';
import images from '../../assets/images';
import { useSelector } from 'react-redux';
import './NotificationTemplate.css'
import axiosInstance from '../../helpers/axios';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import Loader from '../../components/ui/loader/Loader';

function Notification(props) {
    const token = useSelector((state) => state.token);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState(new Date())
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [platform, setPlatform] = useState(null)
    const [plan, setPlan] = useState(null)
    const [selectedCard, setSelectedCard] = useState(null);
    const [showInputs, setShowInputs] = useState(false);

    const platformtype = [
        { label: 'Android', value: 'android' },
        { label: 'Ios', value: 'ios' },
        { label: 'All', value: 'All' },

    ]
    const plans = [
        { label: 'Premium Yearly', value: 'Premium Yearly' },
        { label: 'Premium Monthly', value: 'Premium Monthly' },
        { label: 'All', value: 'null' },

    ]
    const cards = [
        {
            id: 1,
            title: 'Register User',
            image: images?.Register_User,
            className: 'notification card-1',

        },
        {
            id: 2,
            title: 'Guest User',
            image: images?.Guest_User,
            className: 'notification card-1',

        },
        {
            id: 3,
            title: 'Coach',
            image: images?.Coach_notify,
            className: 'notification card-1',

        },
        {
            id: 4,
            title: 'Author',
            image: images?.Author_notify,
            className: 'notification card-1',

        }
    ];

    const onSubmit = async (e) => {
        e.preventDefault();
        if (selectedCard === null) {
            toast.error("Select Atleast Any User");
            return;
        }
        let userType = selectedCard === 0 ? "user" : selectedCard === 1 ? "guest" : selectedCard === 2 ? "coach" : selectedCard === 3 ? "author" : null;
        const data = {
            title,
            notification_message: description,
            platform: (platform?.value) ? platform?.value : selectedCard === 0 ? "All" : selectedCard === 1 ? "All" : null,
            plan: plan?.value ? plan?.value : null,
            user_type: userType,
        }
        if (isLoading === false) {
            setIsLoading(true);
            try {
                const api = await axiosInstance.post(`${process.env.REACT_APP_API_URL}/notification/send_notification`
                    , data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
                ).then(() => {
                    swal({
                        title: "Notification Added Successfully!",
                        icon: "success",
                    });
                })
                setTitle("");
                setDescription("");
                setPlan(null);
                setPlatform(null);

            } catch (e) {
                console.log(e)
                const error = e.response.data.error;
                if (error) {
                    toast.error(error.details[0].message);
                } else {
                    toast.error('Something went wrong. Please try again later');
                }
            }
            setIsLoading(false);
        }
    }


    const handleClick = (index) => {
        setSelectedCard(index);
        if (cards[index].title === 'Register User') {
            setShowInputs(true);
        } else {
            setShowInputs(false);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <form onSubmit={(e) => onSubmit(e)}>
                    <Card sx={{ p: 3 }} >
                        <h3 style={{ padding: "10px 0" }} className='heading'>Send New Notification</h3><hr />
                        <h4 style={{ padding: "10px 0" }} className='heading'>Choose Your Audience</h4>
                        <p style={{ padding: "10px 0" }} className='heading'>Who should receive this message? </p><hr /><br />
                        <div>
                            <Container>
                                <Row className='notification row-1'>
                                    <Grid container spacing={3}>
                                        {cards.map((card, index) => (
                                            <Grid item xs={4} md={4} key={card.id} className="notification grid-1">
                                                <Card style={{ cursor: "pointer" }}
                                                    className={`${card.className} ${selectedCard === index ? 'active' : ''}`}
                                                    onClick={() => handleClick(index,)}
                                                >
                                                    <img src={card.image} alt="" />
                                                    <CardContent>
                                                        <h5>{card.title}</h5>
                                                    </CardContent>
                                                </Card>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Row>
                            </Container>
                        </div>
                    </Card>
                    <br />

                    <Grid container spacing={3} >
                        <Grid item xs={12} md={6}>
                            <Card sx={{ p: 3 }} style={{ minHeight: "560px", cursor: "pointer" }}>
                                <h5 style={{ padding: "10px 0" }} className='heading'> Notification Title:</h5>
                                <TextField
                                    id="outlined-textarea"
                                    label="Notification Title"
                                    placeholder="Notification Title...(50 Character)"
                                    multiline
                                    value={title}
                                    inputProps={{ maxLength: 50 }}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                                <label style={{ color: "#0069d9" }}>{title.length} characters</label>
                                <h5 style={{ padding: "10px 0" }} className='heading'> Notification Message:</h5>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Notification Message"
                                    placeholder="Notification Message...(250 Character)"
                                    multiline
                                    rows={4}
                                    value={description}
                                    inputProps={{ maxLength: 250 }}
                                    onChange={(e) => setDescription(e.target.value)}

                                /><br />

                                <label style={{ color: "#0069d9" }}>{description.length} characters</label>
                                {selectedCard === 2 || selectedCard === 3 ? <></> : <>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>
                                            <h5 style={{ padding: "10px 0" }} className='heading'>Platform Type:</h5>
                                            <Autocomplete
                                                id="combo-box-demo"
                                                options={platformtype}
                                                value={platform}
                                                onChange={(e, value) => setPlatform(value)}
                                                renderInput={(params) => <TextField {...params} label="Platform Type" />}
                                            /></Grid>
                                        <Grid item xs={6}>
                                            {selectedCard === 0 ?
                                                <>
                                                    <h5 style={{ padding: "10px 0" }} className='heading'>Plan:</h5>
                                                    <Autocomplete
                                                        id="combo-box-demo"
                                                        options={plans}
                                                        value={plan}
                                                        onChange={(e, value) => setPlan(value)}
                                                        // sx={{ width: 300 }}
                                                        renderInput={(params) => <TextField {...params} label="Plan" />}
                                                    />
                                                </> : null
                                            }
                                        </Grid>
                                    </Grid></>}
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}><Card sx={{ p: 3 }} style={{ minHeight: "500px" }}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", position: 'relative' }} className='mobile-box'>
                                <h4 style={{ padding: "10px 0", textAlign: 'center' }} className='heading'>Preview Notification</h4>

                                <img className='preview-img' style={{ position: "relative" }} src={images.mobile} alt="" />
                                {!title && !description ? <></> :
                                    <>
                                        <card style={{
                                            position: "absolute",
                                            padding: '2px',
                                            top: '21%',
                                            width: '22%',
                                            borderRadius: '8px',
                                            zIndex: "1",
                                            background: "#fff"
                                        }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <img style={{ width: '25px' }} src={images.notify} alt="" />
                                                <p style={{ wordBreak: 'break-word', fontSize: "11px", fontWeight: '600', marginLeft: '5px', marginBottom: '0' }}>{title}</p>
                                            </div>
                                            <p maxLength="10" style={{
                                                wordBreak: 'break-word', fontSize: "10px", marginBottom: '0', paddingLeft: '6px'
                                            }}>{description}</p>
                                        </card></>}
                                <button
                                    style={{ background: "#1790bc", color: "#fff", padding: "12px 20px", width: "80%", margin: "0 auto", textAlign: "center", display: "block", border: "none", fontSize: "15px", }}
                                    name="submit"
                                    type="submit"
                                    value="Submit"
                                >
                                    SEND NOW</button>
                            </div>
                        </Card></Grid>
                    </Grid>
                </form>
                {isLoading ? <Loader /> : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        user: state.user,
    }
};
export default connect(mapStateToProps)(Notification);