// IMPORT ALL DEPENDENCY
import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import { useState } from 'react';
import { Card } from '@mui/material';
import { toast } from "react-toastify";
import axiosInstance from '../../helpers/axios';
import logodark from "../../assets/images/login_logo.png";

const ForgetPassword = () => {
  // STATES
  const history = useHistory();
  const [newUser, setNewUser] = useState({
    verify_field: "",
  })
  const [isLoading, setIsLoading] = useState(false);

  // THIS FUNCTION IS USED IN ONCHANGE EVENT TO SET THE STATE VALUE
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/admin/verifying-identity`,
          newUser,

        );

        if (res.status === 200) {
          toast.success(res.data.message)
        }
        const id = res.data.user.id;
        history.push(`/ebookflutter_adminpanel/send-otp/${id}`);

      } catch (e: any) {
        setIsLoading(false);
        const error = e;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
    setNewUser({
      ...newUser,
      verify_field: "",
    });

  };

  // CUSTOM CSS
  const testForget = {
    width: "500px", margin: "0 auto"
  };

  // THIS IS JSX ALL THE INPUT,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE
  return (
    <React.Fragment>
      <div className="page-content container">
        <Card sx={{ p: 3 }}>
          <CardBody>
            <span className="logo-lg">
              <img style={{ width: "20%" }} src={logodark} alt=""

              />
            </span>
            <form className="forget-pass"
              style={testForget}

              onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0", textAlign: "center" }} className='heading'> Enter Your Email Address</h4>
              <hr /><br />
              <Row>
                <Col >
                  <div className="form-group" style={{ textAlign: "center" }}>
                    <input
                      style={{ height: "55px" }}
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      name="verify_field"
                      value={newUser.verify_field}
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  margin: "5px",
                  padding: "10px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                    textAlign: "center"
                  }}
                >
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />

                </div>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  <Link
                    to="/ebookflutter_adminpanel/login"
                    style={{ color: "white" }}
                  >
                    <button type="button" className="btn btn-primary">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
              <div className="mt-4 text-center">
                <Link
                  to="/ebookflutter_adminpanel/forget-password-mobilenumber"
                  className="text-muted"
                >
                  <i className="mdi mdi-lock mr-1"></i> Or reset by Mobile Number
                </Link>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ForgetPassword;
