import { ActionTypes, registerTokenAction, removeTokenAction } from './token';

const initialState = null;

/**
 * GLOBALLY USE FOR AUTHENTICATION
 * @param state 
 * @param action 
 * @returns 
 */
export const tokenReducer = (
  state: string | null = initialState, action: registerTokenAction | removeTokenAction
) => {
  switch (action.type) {
    case ActionTypes.REGISTER_TOKEN:
      return action.payload;
    case ActionTypes.REMOVE_TOKEN:
      return null;
    default:
      return state;
  }
};
