import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import swal from "sweetalert";
import Select from 'react-select';
import { Row, Col, Card, CardBody, Container, Button, Input } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import "../style.css";
import "../datatables.scss";
 import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Image from '../../../components/Common/Image';
import Loader from "../../../components/ui/loader/Loader";
import { toast } from "react-toastify";
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { AxiosError } from "axios";
import isURL from 'validator/lib/isURL';
import '../style.css';
import Compress from "browser-image-compression";
import EditMobileSliderLog from "../Edit/EditMobileSliderLog";

interface Props {
  token: string;
  user: UserRoles;
}

const AddMobileSlider = (props: Props) => {
  const history = useHistory();
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [authorData, setAuthorData] = useState([]);
  const [bookData, setBookData] = useState([] as any);
  const [author, setAuthor] = useState<any>(null);
  const [book, setBook] = useState<any>(null);
  const [external, setExternal] = useState<any>(null);
  const [image, setImage] = useState<any>(null);
  const [nImage, setNImage] = useState<any>(null);
  const [linkType, setLinkType] = useState([] as any);
  const [imgcompressor, setImgcompressor] = useState<any>({imageUrl:null});
  const [valid, setValid] = useState({
      goodread_url_Error: [] as any,
    });

  const imgRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    getData();
  }, []);

  // const flush = () => {
  //   setAuthor([] as any);
  //   setBook([] as any);
  //   setExternal([] as any);
  // }

  const update = async () => {
  const goodread_url_Error = [] as any;
    try {
      if (!(image || nImage)) {
        toast.error('Please select a image');
        return
      }

      if (!(author || book || external)) {

        toast.error('Please enter a link');
            return;
        }

        if(external){
          if (!isURL(external)) {

            goodread_url_Error.push("Invalid URL")
          }
        }



      setValid({
        ...valid,
      goodread_url_Error,

      });

      if(goodread_url_Error.length)
        return;

      setIsLoading(true);
      const data = new FormData();

      data.append('info', JSON.stringify({
        author: author?.value,
        book: book?.value,
        external,
      }));

      data.append('image', image);

      const res = await axiosInstance.put(`/mobile_slider/${id}`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      // flush();
      swal({
        title: res.data.message,
        icon: "success",
        /// button: "OK!",
      })
    } catch (e) {
      setIsLoading(false);
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }else{
        // toast.error('Something went wrong. Please try again later');
      }
     }
   setIsLoading(false);
    };

  const postSlider = async () => {
  const goodread_url_Error = [] as any;
    try {
      if (!image) {
        toast.error('Please select a image');
        return;
      }


      if (!(author || book || external)) {

      toast.error('Please enter a link');
          return;
      }

      if(external){
        if (!isURL(external)) {

          goodread_url_Error.push("Invalid URL")
        }
      }


      setValid({
        ...valid,
      goodread_url_Error,
       });

      if(goodread_url_Error.length)
        return;

      setIsLoading(true);
      const data = new FormData();

      data.append('info', JSON.stringify({
        author: author?.value,
        book: book?.value,
        external,
      }));

      data.append('image', image);

      await axiosInstance.post('/mobile_slider', data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      // flush();
      swal({
        title: "Added Successfully!",
        icon: "success",
        //button: "OK!",
      })
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {

        toast.error('Something went wrong. Please try again later');
      }
    }

    setIsLoading(false);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      update();
    } else {
      postSlider();
    }
  };

  const onRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkType(e.currentTarget.value)
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.get(`/v2_authors/get-author`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      const bookRes = await axiosInstance.get(`/books/drop-books`);

      setBookData(bookRes.data);
      setAuthorData(res.data);
    } catch (e) {
      toast.error('Unable to load data. Some features might not work');
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const getSlider = async () => {
      try {
        if (id && !isLoading) {
          const res = await axiosInstance.get(`/mobile_slider/${id}`, {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          });

          const { type } = res.data;

          if (type === 'author') {
            const authorToSelect = authorData.find((t: any) => t.value === parseInt(res.data.link, 10))
            setAuthor(authorToSelect);
          }

          if (type === 'book') {

            const bookToSelect = bookData.find((t: any) => t.value === parseInt(res.data.link, 10))

            setBook(bookToSelect);
          }

          if (type === 'external') {
            setExternal(res.data.link);
          }

          setLinkType(res.data.type);
          setNImage(res.data.image);
        }
      } catch (e) {
        toast.error('Unable to load data');
      }
    };

    getSlider();
  }, [isLoading, authorData, bookData]);

  const renderLinkInput = () => {
    if (linkType === 'author') {
      return (
        <Select
          className="select"
          name="author_id"
          placeholder="Select Author"
          options={authorData}
          onChange={(e) => {
            setAuthor(e);
            setBook(null);
            setExternal(null);
          }}
          value={author}
        />
      )
    }

    if (linkType === 'book') {
      return (
        <Select
          className="select"
          name="book_id"
          placeholder="Select Book"
          value={book}
          onChange={(e) => {
            setAuthor(null);
            setBook(e);
            setExternal(null);
          }}
          options={bookData}
        />
      )
    }

    if (linkType === 'external') {
      return (
        <Input type="text" placeholder="https://example.com" value={external} onChange={(e) => {
          setExternal(e.target.value);
          setAuthor(null);
          setBook(null);
        }} />
      )
    }
  };

  const renderImage = () => {
    if (nImage) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setNImage(null)}
              src={`${process.env.REACT_APP_S3_APP_API}/mobile_silder/${nImage}`}
              height="100px"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      )
    }

    if (image) {
      return (
        <Row>
          <Col xs="auto">
            <Image
               onPress={() => setImage(null as any)}
              src={URL.createObjectURL(image)}
              height="100px"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      )
    }

    return (
      <Button color="primary" onClick={(e) => {
        e.preventDefault();
        if (imgRef != null) {
          imgRef?.current?.click();
        }

        ///imgRef.current.click();
      }}>Add Image</Button>
    )
  };

  return (
    <React.Fragment>
      <div className="page-content" >
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id ? 'Edit Mobile Slider Image' : 'ADD MOBILE SLIDER IMAGE'}</h4>
                  <form onSubmit={onSubmit}>
                    <Row>
                      <Col xs={8}>
                        <div className="form-group add-mobile">
                          <Row>
                            <Col xs={12} xl="6">
                              {" "}
                              <label> </label>
                              <br />
                              <input
                                type="file"
                                className="form-control"
                                id="exampleFormControlInput1"
                                name="mobslider_image"
                                onChange={(e:any) => {
                                  const file = e.target.files[0];
                                  if (e.target.files) {
                                    if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                        toast.error('only PNG/JPEG/JPG are supported');
                                        return;
                                    }
                                    if (e.target.files[0].size > 5000_000) {
                                        toast.error('File too large. Please select file less than 5MB');
                                        return;
                                      }
                                      const options = {
                                        maxSizeMB: 1,
                                        useWebWorker: true
                                      }
                                      Compress(file, options)
                                      .then((compressedBlob) => {


                                         setImgcompressor({imageUrl: URL.createObjectURL(compressedBlob)
                                        })
                                        console.log(imgcompressor)
                                        const convertedBlobFile = new File([compressedBlob], file.name, {
                                          type: file.type,
                                          lastModified: Date.now(),
                                        });
                                        setImage(convertedBlobFile)
                                       })
                                      .catch((e) => {
                                      });

                                    // setCoverImage(e.target.files[0]);
                                } }}
                                accept="image/*"
                                style={{ display: 'none' }}
                                ref={imgRef}
                              />
                              {renderImage()}
                            </Col>

                            <Col xs="12" xl="6">
                              <label>*LINKS TO</label>
                              <br />
                              <label form="author">Author</label>
                              <input
                              className="author-input"
                                type="radio"
                                id="author"
                                name="radio"
                                value="author"
                                onChange={onRadioChange}
                                checked={linkType === 'author'}
                              />{'  '}
                              <label htmlFor="book">Book</label>
                              <input
                               className="author-input"
                                type="radio"
                                id="book"
                                name="radio"
                                value="book"
                                onChange={onRadioChange}
                                checked={linkType === 'book'}
                              />{'  '}
                              <label htmlFor="external">External</label>
                              <input
                               className="author-input"
                                type="radio"
                                id="external"
                                name="radio"
                                value="external"
                                onChange={onRadioChange}
                                checked={linkType === 'external'}
                              />{'  '}

                              <br />
                              {renderLinkInput()}
                               {valid?.goodread_url_Error?.length
                            ? valid?.goodread_url_Error.map((error: any, i: React.Key | null | undefined) => (
                             <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                            </Col>

                            <Col xs="6" style={{ marginTop: '50px' }}>
                              <Button color="primary">Submit</Button>
                              <Button color="primary" style={{ marginLeft: '30px' }} onClick={(e) => {
                                e.preventDefault();

                                history.goBack();
                              }}>Back</Button>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id !== undefined ?
          <EditMobileSliderLog /> : <></>}
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
}

export default connect(mapStateToProps)(AddMobileSlider);
