/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, InputGroup, InputGroupAddon, Input } from "reactstrap";
import "../../eBook/style.css"
import Select from "react-select";
import Image from "../../../components/Common/Image";
// import validate from "../../../components/utils/validations";
import { connect } from 'react-redux';
// import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/actions";
import 'react-phone-number-input/style.css';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import { TextareaAutosize } from "@mui/material";

interface Props {
    token: string;
    user: UserRoles;
}

const ViewCoach = (props: Props) => {

    const [networkShowing, setNetworkingShow] = useState([]);



    const { id } = useParams() as {
        id?: number | undefined;
    }
    const [genre, setGenre] = useState<{ value: number; label: string } | null>(null);
    const [state, setState] = useState<{ value: number; label: string } | null>(null);
    const [niche, setNiche] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [coaching, setCoaching] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [language, setLanguage] = useState<MultiValue<{ value: number; label: string }> | null>(null);
    const [social, setSocial] = useState([] as any);
    const [socialData, setSocialData] = useState([]);
    const [users, setUsers] = useState({
        "coach_first_name": "",
        "coach_last_name": "",
        "coaching_certificate": [] as any,
        "niche_id": [] as any,
        "format_id": [] as any,
        "lang_id": [0] as any,
        "address": "",
        "zip": "",
        "country_id": 15,
        "state_id": 15,
        "public_mobileno": "",
        "private_mobileno": "",
        "coaching_program_title": "",
        "coaching_philosophy": "",
        "coach_bio": "",
        "social_id": "",
        "created_at": "",
        "updated_at": "",
        "created_by": 1,
        "updated_by": "",
        "deleted": false,
        "approved_by": "",
        "approved_at": "",
        "approval_status": true
    })
    // const [coverImage, setCoverImage] = useState<any>(null);
    const [networkCover, setNetworkImage] = useState<any>(null);
    // const imgRef = React.useRef<HTMLInputElement>(null);
    // const coverRef = React.useRef<HTMLInputElement>(null);
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.persist();
        setUsers({ ...users, [e.target.name]: e.target.value });
    };

    const onInputChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {

        e.persist();
        setUsers({ ...users, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        getSocialData();
        getAuthor();
    }, []);

    const getAuthor = async () => {

        if (id) {
            try {
                const res = await fetch(`${process.env.REACT_APP_API_URL}/coach/admin-coach/${id}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`
                    }
                })
                const data = await res.json();
                setUsers({
                    ...data,
                    coach_first_name: data.data.coach_first_name,
                    coach_last_name: data.data.coach_last_name,
                    country_id: data.data.country_id,
                    state_id: data.data.state_id,
                    zip: data.data.zip,
                    address:data.data.address,
                    private_mobileno: data.data.private_mobileno,
                    public_mobileno: data.data.public_mobileno,
                    coaching_philosophy: data.data.coaching_philosophy,
                    coaching_program_title: data.data.coaching_program_title,
                    coach_bio: data.data.coach_bio,

                    coaching_certificate: data.data.coaching_certificate,
                });
                setNetworkImage(data.data.primary_image);
                setNetworkingShow(data.data.coach_images);
                setLanguage(data.data.lang);
                setCoaching(data.data.format);
                setSocial(data.data.social);
                setState(data.data.state);
                setGenre(data.data.con);
                setNiche(data.data.niche)
            } catch (e) {
                console.log(e);
            }
        }
    }
    const getSocialData = async () => {
        try {
            const req = await fetch(`${process.env.REACT_APP_API_URL}/socialMedia/get-socialMedia`);
            const dataRaw = await req.json();

            const data = dataRaw.data;
            const mapData = data.map((s: { social_name: any; social_id: any; iconpath: any; }) => ({
                label: s.social_name,
                value: s.social_id,
                iconpath: s.iconpath,
                url: '',
            }));

            setSocialData(mapData);
        } catch (e) {
            console.log(e);
        }
    };


    const renderCover = () => {
        if (networkCover) {
            return (
                <Row>
                    <Col xs="auto">
                        <Image src={`${process.env.REACT_APP_S3_APP_API}/coach/${networkCover}`} height="auto" width="100px" alt="cover" onPress=""/>
                    </Col>
                </Row>
            )
        }


    };

    const renderShowing = () => {
        return (
            <Row>
                {networkShowing.map((img) => (
                    <Col xs="auto">
                        <Image src={`${process.env.REACT_APP_S3_APP_API}/coach/${img}`} height="auto" width="100px" alt="cover" onPress=""

                        />
                    </Col>
                ))}

            </Row>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h3 className="card-title">Coach Information</h3>
                                    <form encType="multipart/form-data">
                                        <Row>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH FIRST NAME*</label>
                                                    <input

                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="First Name"
                                                        disabled
                                                        name="coach_first_name"
                                                        value={users.coach_first_name}
                                                        onChange={onInputChange}
                                                    />


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH LAST NAME*</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Last Name"
                                                        disabled
                                                        name="coach_last_name"
                                                        value={users.coach_last_name}
                                                        onChange={onInputChange}
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A COUNTRY</label>
                                                    <AsyncSelect placeholder="Choose A Country" value={genre} isDisabled onChange={setGenre} />
                                                </div>

                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">

                                                    <label>CHOOSE A STATE</label>
                                                    <AsyncSelect placeholder="Choose A State" value={state} isDisabled onChange={setState} />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>ADDRESS</label>
                                                    <input
                                                        type="text"
                                                        disabled
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Address"
                                                        name="address"
                                                        value={users.address}
                                                        onChange={onInputChange}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>ZIP CODE</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Zip Code"
                                                        disabled
                                                        name="zip"
                                                        value={users.zip}
                                                        onChange={onInputChange}
                                                    />


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A COACHING CERTIFICATE</label>
                                                    <input

                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Choose A Coaching Certificate"
                                                        disabled
                                                        name="coaching_certificate"
                                                        value={users.coaching_certificate}
                                                        onChange={onInputChange}
                                                    />

                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">

                                                    <label>CHOOSE A NICHE</label>
                                                    <AsyncSelect placeholder="Choose A Niche" isMulti value={niche} isDisabled onChange={setNiche} />
                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>CHOOSE A COACHING FORMATE</label>
                                                    <AsyncSelect placeholder="Choose A Coaching Formate" isMulti value={coaching} isDisabled onChange={setCoaching} />

                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>LANGUAGE</label>
                                                    <AsyncSelect placeholder="Language" isMulti value={language} isDisabled onChange={setLanguage} />

                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group phone-input">
                                                    <label>PUBLIC MOBILE NUMBER</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Enter your Mobile No"
                                                        disabled
                                                        name="public_mobileno"
                                                        value={users.public_mobileno}
                                                    />

                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group phone-input">
                                                    <label>PRIVATE MOBILE NUMBER</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Enter your Mobile No"
                                                        disabled
                                                        name="private_mobileno"
                                                        value={users.private_mobileno}
                                                    />



                                                </div>

                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACHING PROGRAM TITLE</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Coaching Program Title"
                                                        name="coaching_program_title"
                                                        value={users.coaching_program_title}
                                                        disabled
                                                        onChange={onInputChange}
                                                    />


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACHING PHILOSOPHY</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Coaching Philosophy"
                                                        name="coaching_philosophy"
                                                        value={users.coaching_philosophy}
                                                        onChange={onInputChange}
                                                        disabled
                                                    />


                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>COACH BIO*</label>
                                                    <TextareaAutosize
                                                        // type="text"
                                                        // className="form-control"
                                                        // id="exampleFormControlInput1"
                                                        maxLength={500}
                                                        style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                                                        minRows={4}
                                                        placeholder="Short Bio:"
                                                        name="coach_bio"
                                                        value={users.coach_bio}
                                                        onChange={onInputChangeText}
                                                        disabled
                                                    />
                                                    <label style={{ color: "#0069d9" }}>{users.coach_bio.length} characters</label>


                                                </div>
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>SOCIAL MEDIA</label>
                                                    <Select
                                                        className="select"
                                                        name="genre_id"
                                                        placeholder="Select Social Links"
                                                        isMulti
                                                        value={social}
                                                        onChange={setSocial}
                                                        options={socialData}
                                                        isDisabled
                                                    />
                                                    {social?.map((temp: { iconpath: any; }, i: any) => (
                                                        <InputGroup disable style={{ marginTop: '4px' }}>
                                                            <InputGroupAddon addonType="prepend">
                                                                <img src={`${process.env.REACT_APP_S3_APP_API}/social/${temp.iconpath}`} height="38px" width="30px" alt="sc" />

                                                            </InputGroupAddon>
                                                            <Input placeholder="Place Your Link Here" value={social[i].url} 
                                                            />

                                                        </InputGroup>

                                                    ))}
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <label>PRIMARY COACH IMAGE</label>

                                                <br />
                                                {renderCover()}
                                            </Col>

                                            <Col xs={6}>
                                                <div className="form-group">
                                                    {" "}
                                                    <label>COACH IMAGES</label>
                                                    <br />
                                                    {renderShowing()}
                                                </div>
                                            </Col>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    margin: "5px",
                                                    padding: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                   
                                                </div>
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    <Link
                                                        to="/ebookflutter_adminpanel/coach"
                                                        style={{ color: "white" }}
                                                    >
                                                        <button type="button" className="btn btn-primary">
                                                            Back
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        token: state.token!,
        user: state.user,
    }
};

export default connect(mapStateToProps)(ViewCoach);


