import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Button, UncontrolledCarousel } from "reactstrap";
import Loader from "../../components/ui/loader/Loader";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import axiosInstance from "../../helpers/axios";
import Switch from "@material-ui/core/Switch";
import { Pagination } from "@material-ui/lab";
import { toast } from 'react-toastify';
import { StoreState } from "../../store";
import { UserRoles } from "../../store/auth/admins/user";
import { connect } from 'react-redux';
import Select from 'react-select/async';
import DeleteIcon from "@material-ui/icons/Delete";
import swal from "sweetalert";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { useFetchHook } from "../../utils/fetchHook";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'

interface Props {
  token: string;
  user: UserRoles;
}


type feeddata = {
  active: boolean,
  back_image: string,
  background: string,
  bookmarks: string,
  category_name: string,
  created_at: string,
  id: any,
  images: string,
  likes: boolean,
  text: string;
}

// Dropdown
const sellingtopp = [
  { value: true, label: "Active" },
  { value: false, label: "InActive" }
]
const Feed = (props: Props) => {
  // States of setData
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState(null) as any;
  const [dates, setDates] = useState<any>([null, null]);
  const [currentPage, setCurrentPage] = useState(1);
  const [top, setTop] = useState<{ value: boolean; label: string } | null>(null);
  const [users, setUsers] = useState({
    category: null as any,
    top: null as any,
  })
  // Custom Hook
  const [{ isLoading, data }, { setIsLoading, setOptions, setData, fetchData }] =
    useFetchHook<{
      data: feeddata[]; numberOfPage: number; numOffeeds: number | null,
    }>(`${process.env.REACT_APP_API_URL}/feed/admin-feed`,
      { data: [], numberOfPage: 1, numOffeeds: null },
      {
        params: { page },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      useEffect(() => {
        onFilterApply(null as any)
      }, [users])

  // UseEffect for SetData on page load
  useEffect(() => {
    setOptions({
      params: {
        page,
        active: top?.value,
        category: category?.value,
        dates,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
    setCurrentPage(page + 1);
  }, [page]);

  // Delete API
  const deleteUser = async (id: number) => {
    if (!props.user[modules.feed].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      const req = await swal({
        buttons: ['No', 'Yes'],
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Category Name!",
        icon: "error",
        dangerMode: true,

      });

      if (!req) {
        return;
      }
      setIsLoading(true);
      const res = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/feed/${id}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }

        });
      setIsLoading(false);

      if (res.data.error) {
        toast.error(res.data.error, { autoClose: false, style: { width: '500px' } });
        return;
      }

      fetchData();
      swal("Your feed  has been deleted!", {
        icon: "success",
      });
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    }
  };

  // Toggle API
  const toggle = async (feed_id: any) => {
    try {
      const res = await axiosInstance.put('/feed/feed/active', { feed_id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }

      });
      const newData = data.data.map((t: any) => {
        if (t.id === res.data.feed_id) {
          t.active = res.data.active;
        }
        return t;
      });
      setData((u) => ({ ...u, data: newData }));
    } catch (e) {
      toast.error('Unable to update. Please try again later');
    }
  };

  // Redirection
  const redirectAdd = () => {
    if (!props.user[modules.feed].includes(permissions.WRITE)) {
			toast.error(errorMsg);
			return;
		}
    history.push("add-feed");
  };
  const redirectEdit = (id: any) => {
    if (!props.user[modules.feed].includes(permissions.UPDATE)) {
			toast.error(errorMsg);
			return;
		}
    history.push(`edit-feed/${id}`);
  };

  // Category Dropdown
  const loadCategory = async (category_name: any) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });
      return category.data;
    } catch (e) { }
  };
  
  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      e.preventDefault();
      setPage(0);
      setOptions({
        params: {
          page,
          active: top?.value,
          category: category?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }}

  // Filter
  const onFilterApply = (e: any) => {
    if (e !== null) {
      e.preventDefault();
    }
    setPage(0);
    setOptions({
      params: {
        page,
        active: top?.value,
        category: category?.value,
        dates,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  };

  // Reset
  const onResetApply = (e: any) => {
    e.preventDefault();
    setCategory(null);
    setTop(null);
    setDates([null, null])
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">Feed({data.numOffeeds})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        FEED
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Select placeholder="Parent Category Name" value={category} cacheOptions defaultOptions loadOptions={loadCategory}
                     
                       onChange={(value) => {
                        setCategory(value);
                        setUsers({ ...users, category: value })
                      }}
                       />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Top Feed" value={top} cacheOptions defaultOptions={sellingtopp}
                        onChange={(value) => {
                          setTop(value);
                          setUsers({ ...users, top: value })
                        }}
                      //  onChange={setTop} 
                       />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy"   onKeyPress ={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <br />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {data.data.map((fd: any, i: any) => {
              let carouselItems = [];
              if (fd.images && fd.images[0]) {

                carouselItems = fd.images.map((item: any, index: any) => {
                  return {
                    altText: "",
                    caption: "",
                    key: (page * 25) + index + 1,
                    src: `${process.env.REACT_APP_S3_APP_API}/feeds/${item}`,
                  }
                });
              }

              return (
                <Col xs={4}>
                  <Card key={i}>
                    <div style={{ padding: "10px" }}>{fd.category_name}</div>
                    {fd.images && fd.images.length > 0 ? (
                      <>
                        {/* <img src={`${process.env.REACT_APP_S3_APP_API}/feeds/${fd.images[0]}`}/> */}
                        <UncontrolledCarousel items={carouselItems} />
                      </>
                    ) : (
                      <div
                        style={{
                          background: fd.back_image
                            ? `url(
                          ${process.env.REACT_APP_API_URL}/feed/template/${fd.back_image}
                        )`
                            : fd.background,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          color: "whitesmoke",
                          minHeight: "150px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        dangerouslySetInnerHTML={{ __html: fd.text }}
                      >
                      </div>
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-around",
                        paddingRight: "10px",
                      }}
                    >
                      <IconButton
                        color="primary"
                        onClick={() => redirectEdit(fd.id)}
                      >
                        <EditIcon />
                      </IconButton>
                      <Link
                        to="#"
                        onClick={() => {
                          deleteUser(fd.id);
                        }}  >
                        <DeleteIcon />
                      </Link>
                      <div>{new Date(fd.created_at).toDateString()}</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <label style={{ margin: "0px 5px" }}>
                          {fd.likes ? fd.likes : "0"}
                        </label>
                        <FavoriteBorderIcon />
                      </div>
                      <div>
                        <Switch color="primary" checked={fd.active} onChange={() => toggle(fd.id)} />
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}

          </Row>
          <Pagination
            count={data?.numberOfPage}
            page={currentPage}
            color="primary"
            variant="outlined"
            showFirstButton={true}
            showLastButton={true}
            onChange={(_e: any, value: number) => setPage(value - 1)}
          />
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};
export default connect(mapStateToProps)(Feed);

