import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user"
interface Props {
  token: string;
  user: UserRoles;
}
const AddUsers = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [role, setRole] = useState<any>({ label: '' });

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });

        setName(res.data.name);
        setEmail(res.data.email);
        setNote(res.data.note);
        setRole({
          label: res.data.role_name,
          value: res.data.role_id,
        })
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">USER</h4>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>NAME</label>
                          <br />
                          {name}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>E-mail</label>
                          <br />
                          {email}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>NOTE</label>
                          <br />
                          {note}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE</label>
                          <p>{role.label}</p>
                        </div>
                      </Col>
                    </Row>
                    <div
                      style={{
                        margin: "5px",
                        padding: "10px",
                      }}
                    >
                      {" "}
                      <Link
                        to="/ebookflutter_adminpanel/admin_user"
                        style={{ color: "white" }}
                      >
                        <button type="button" className="btn btn-primary">
                          Back
                        </button>
                      </Link>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(AddUsers);
