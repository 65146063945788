import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import "../datatables.scss";
import swal from "sweetalert";
import Loader from "../../../components/ui/loader/Loader";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { connect } from 'react-redux';
import Select from 'react-select/async';
import UnSavedChanges from "../Add/UnSavedChanges";
import EditSubCategoryLogs from "./EditSubCategoryLogs";

interface Props {
  token: string;
  user: UserRoles;
}

const EditSubCategory = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  // states of setData
  const [users, setUsers] = useState<any>({
    category_id: "" as any,
    subcategory_name: "" as any,
    category_id_Error: [] as any,
    subcategory_name_Error: [] as any,
    msg_Error: [] as any,
  }) as any;

  const [isLoading, setIsLoading] = useState(false);
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  const [category, setCategory] = useState<{ value: string; label: string } | null>(null);
  const [isUpdateCalled, setIsUpdateCalled] = useState(false); // New state variable
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
     setIsSaved() as any
     var temp = { ...users, [e.target.name]: e.target.value };
    if (!e.target.value || !e.target.value) {
      temp = { ...temp, subcategory_name_Error: "*Enter Sub Category Name" };
    } else {
      temp = { ...temp, subcategory_name_Error: "" }
    }
    setUsers(temp);
  };

  useEffect(() => {
    loadUser();
  }, []);


  useEffect(() => {
    if (users[0]) {
      setUsers({
        category_id: category?.value,
        subcategory_name: users[0].subcategory_name,
      });
    }
  }, [users]);

  // Put API
  const onSubmit = async (e: any) => {
    e.preventDefault();
    const category_id_Error = [] as any;
    const subcategory_name_Error = [] as any;
    const msg_Error = [] as any;
    if (!users.category_id) {
      category_id_Error.push("*Enter Category Name");
    }
    if (!users.subcategory_name) {
      subcategory_name_Error.push("*Enter Sub Category Name");
    } else {
      setUsers({ ...users, subcategory_name_Error: "" })
    }
    if (category_id_Error?.length) return;
    if (subcategory_name_Error?.length) return;

    if (isLoading === false) {
      setIsLoading(true);
      setIsUpdateCalled(true);

      try {
        const res = await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/sub_categories/${id}`,
          {
            subcategory_name: users.subcategory_name,
            category_id: category?.value,
          },
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
      if (res?.data?.success === 0) {
          msg_Error.push(res?.data?.message);
          setUsers({ ...users, msg_Error });
          if (msg_Error.length) return;
        }
        setIsLoading(false);
        swal({
          title: "Sub Category Updated Successfully!",
          icon: "success",

        });


      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
     }
     setIsLoading(false);
     setIsUpdateCalled(false);
    }
  };
  const loadUser = async () => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/sub_categories/subcategory/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    }
    );
  setCategory({ value: res?.data[0].category_id, label: res?.data[0].category_name });
    setUsers(res?.data[0]);
};


// Category DropDown API
  const loadCategory = async (category_name: String) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });

      return category.data;
    } catch (e) { }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">EDIT SUB CATEGORY</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CATEGORY NAME*</label>
                          <Select placeholder="Parent Category Name" value={category}
                            cacheOptions defaultOptions loadOptions={loadCategory}
                            onChange={(e: any) => {
                              setCategory(e);
                              setIsSaved() as any;
                              setUsers({ ...users, ['category_id']: e?.value as any })
                              setUsers({...users , category_id_Error: []})
                            }} ></Select>

                          {users?.category_id_Error && <div style={{ color: 'red' }}>{users?.category_id_Error}</div>}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>SUB CATEGORY NAME*</label>
                          <input
                            type="text"
                            name="subcategory_name"
                            value={users ? users?.subcategory_name : ""}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter category_name"
                            onChange={(e) => onInputChange(e)}
                          />
                           {users?.subcategory_name_Error && <div style={{ color: 'red' }}>{users?.subcategory_name_Error}</div>}

                          {/* {users.subcategory_name_Error?.length
                            ? users.subcategory_name_Error.map((error: any, i: any) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                      </Col>
                      {users.msg_Error?.length
                        ? users.msg_Error.map((error: any, i: any) => (
                          <ErrorSpan key={i} error={error} />
                        ))
                        : null}
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <button type="submit"
                           className="btn btn-primary"
                           value="Submit"
                           onClick={() => {
                            setPristine()
                          }}
                           >
                            Submit
                          </button>
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <Link
                            to="/ebookflutter_adminpanel/sub-category"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id == undefined || isUpdateCalled ?
          <></> : <EditSubCategoryLogs />}
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

// Redux
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EditSubCategory);

