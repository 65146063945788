// IMPORT ALL DEPENDENCY
import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import { useState } from 'react';
import { Card } from '@mui/material';
import { toast } from "react-toastify";
// import { useSelector } from 'react-redux';
import axiosInstance from '../../helpers/axios';
import OtpInput from 'react-otp-input';
import '../eBook/style.css'
import logodark from "../../assets/images/login_logo.png";


const Sendotp = () => {
  // STATES
  const history = useHistory();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  // const token = useSelector((state) => state.token);
  const [otp, setOtp] = useState();

  // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      otp: otp,
      user_id: id,
    }

    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/admin/verifying-user`,
          data,
        );
        if (res.status === 200) {
          toast.success(res.data.message)
        }
        const id = res.data.user.id;
        history.push(`/ebookflutter_adminpanel/change-password/${id}`);
      } catch (e) {
        setIsLoading(false);
        const error = e;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
  };
  // THIS IS JSX ALL THE INPUT,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE

  return (
    <React.Fragment>
      <div className="page-content container">
        <Card sx={{ p: 3 }}>
          <CardBody>
            <span className="logo-lg">
              <img style={{ width: "20%" }} src={logodark} alt=""
              />
            </span>
            <form className="forget-pass-sendotp"
              onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0", textAlign: "center" }} className='heading'> Enter Your OTP</h4>
              <br />
              <Row>
                <Col className='otptext'>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    autoFocus
                    OTPLength={6}
                    numInputs={6}
                    otpType="number"
                    secure
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  margin: "5px",
                  padding: "10px",

                }}
              >
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                    textAlign: "center"
                  }}
                >
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />

                </div>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  <Link
                    to="/ebookflutter_adminpanel/login"
                    style={{ color: "white" }}
                  >
                    <button type="button" className="btn btn-primary">
                      Back
                    </button>
                  </Link>
                </div>

              </div>
              <div className="mt-4 text-center">
                <Link
                  to="/ebookflutter_adminpanel/login"
                  className="text-muted"
                >
                  <i className="mdi mdi-lock mr-1"></i> Already Have An Account
                </Link>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>

  )
}

export default Sendotp;
