import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./datatables.scss";
import Select from 'react-select/async';
import Button from "@material-ui/core/Button";
import { Pagination } from "@material-ui/lab";
import DeleteIcon from "@material-ui/icons/Delete";
import axiosInstance from "../../helpers/axios";
import EditIcon from "@material-ui/icons/Edit";
import "./style.css";
import swal from "sweetalert";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import { useFetchHook } from "../../utils/fetchHook";
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { TableSortLabel } from "@mui/material";

type SortOrder = "asc" | 'desc' | undefined;

interface Props {
  token: string;
  user: UserRoles;
}

// API data format
type SubCategoryType = {
  subcategory_id: number;
  category_name: string;
  subcategory_name: String;
  noOfBooks: number;
  category: boolean;
};

// States Of SetData
const SubCategory = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [category, setCategory] = useState<{ value: boolean; label: string } | null>(null);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
  const [filter, setFilter] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<SortOrder>("asc");

  // Custom Hook For API
  const [{ isLoading, data }, { setIsLoading, setOptions, fetchData }] =
    useFetchHook
      <{ data: SubCategoryType[]; numberOfPage: number; total: number | null, }>
      (`${process.env.REACT_APP_API_URL}/sub_categories/admin-subcategory`,
        { data: [], numberOfPage: 1, total: null },
        {
          params: { page },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });


  const tableHeaders = [
    { id: "subcategory_name", label: "SUB CATEGORY NAME" },
    // { id: "category_name", label: "CATEGORY NAME" },
  ];
  tableHeaders.forEach((item, index) => {
    otherFunction(item.id, index);
  });

  function otherFunction(id: string, index: number) {
  }

  const handleSort = async (id: string) => {
    setFilter(id);
    setSelectedOrder(sortOrder);
    // const columnType = id === 'top' ? "null" : 'string';
    setOptions({
      params: {
        page,
        order: sortOrder,
        column_name: id,
        column_type: "string",
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });

    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    if (pagedata) {
      var payload = {
        page,
        name: name,
        category: category?.value,
        dates: dates,
      } as any;

      if (filter != "") {
        payload["order"] = selectedOrder;
        payload["column_name"] = filter;
        // payload["column_type"] = type === 'string' ? "string" : null;
        payload["column_type"] = "string"

      }
      setOptions({
        params: payload,
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
  }, [page]);

  // useEffect(() => {
  //   if (pagedata) {

  //     setOptions({
  //       params: {
  //         page,
  //         name: name,
  //         category: category?.value,
  //         dates: dates,
  //       },
  //       headers: {
  //         Authorization: `Bearer ${props.token}`,
  //       }
  //     });
  //   }
  //   else {
  //     setPagedata(true);

  //   }
  // }, [page]);


  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  // Delete API
  const deleteUser = async (subcategory_id: number) => {
    if (!props.user[modules.subcategories].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }

    const confirm = await swal({
      buttons: ['No', 'Yes'],
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this SUB CATEGORY NAME!",
      icon: "error",
      dangerMode: true,

    });

    if (!confirm) {
      return;
    }

    setIsLoading(true);
    const res = await axiosInstance.delete(
      `${process.env.REACT_APP_API_URL}/sub_categories/${subcategory_id}`,
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

    setIsLoading(false);

    if (res.data.error) {
      toast.error(res.data.error, { autoClose: 10000 });
      return;
    }

    fetchData();
    swal("Your SUB CATEGORY NAME has been deleted!", {
      icon: "success",
    });
  };

  const redirectEdit = (id: number) => {
    if (!props.user[modules.subcategories].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`/ebookflutter_adminpanel/edit-subcategory/${id}`, "_blank")

  };

  const redirectAdd = () => {
    if (!props.user[modules.subcategories].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    history.push('add-subcategory');
  }

  // Category DropDown API
  const loadCategory = async (category_name: String) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });

      return category.data;
    } catch (e) { }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (name.length > 0 || category != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(1);
        setOptions({
          params: {
            page,
            name: name,
            category: category?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);

      }
    }
  }

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, b: any = null) => {
    var i;
    if (b === null) {
      i = (category?.value)
    } else {
      i = (b['value'])
    }
    if (name.length > 0 || i != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setPage(1);
      setOptions({
        params: {
          page,
          name: name,
          category: i,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setShowError(true);

    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setName('');
    setCategory(null);
    setDates([null, null])
    setShowError(false);

    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title ">SUB CATEGORY LIST ({data.total})</h4>

                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        // style={{ float: "right", color: "blue" }}
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        Add SubCategory
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>

                    <Col xs={3}>
                      <Select placeholder="Parent Category Name" value={category} cacheOptions defaultOptions loadOptions={loadCategory}
                        //  onChange={setCategory}
                        onChange={(value) => {
                          setCategory(value);
                          onFilterApply(null as any, value);
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Sub Category Name" onKeyPress={handleKeyPress} value={name} onChange={setter(setName)} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>

                          {tableHeaders.map((header) => (
                            <TableCell key={header.id}>
                              <TableSortLabel
                                active={header.id === "category_name"}
                                direction={sortOrder}
                                // {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
                                onClick={() => handleSort(header.id)}
                              >
                                {header.label}
                              </TableSortLabel>
                            </TableCell>
                          ))}
                           <TableCell>CATEGORY NAME</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.subcategory_id}>
                              <TableCell>{(index + 1) + (25 * (page - 1))}</TableCell>
                              <TableCell>{user.subcategory_name}</TableCell>
                              <TableCell>{user.category_name}</TableCell>

                              <TableCell>
                                <div className="innercell">
                                  <IconButton color="primary" onClick={() => redirectEdit(user.subcategory_id)}>
                                    <EditIcon />
                                  </IconButton>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deleteUser(user.subcategory_id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    <Pagination
                      count={data.numberOfPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(SubCategory);
