// IMPORT ALL DEPENDENCY
import React from 'react'
import { Link,useParams ,useHistory } from 'react-router-dom';
import { CardBody, Input } from "reactstrap";
import { useState } from 'react';
import { Card } from '@mui/material';
import { toast } from "react-toastify";
import axiosInstance from '../../helpers/axios';
import '../eBook/style.css'
import logodark from "../../assets/images/login_logo.png";

const NewPassword = () => {
  // STATES
  const history = useHistory();
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [change_password, setChangePassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

   // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  const onSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      change_password,
      confirm_password,
      user_id: id,
    }
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/admin/change-password`,
          data,
        );
        if (res.status === 200) {
          toast.success(res.data.message)
        }
        // const id = res.data.user.id;
        history.push(`/ebookflutter_adminpanel/login`);
      } catch (e:any) {
        setIsLoading(false);
        const error = e;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
  };

  // CUSTOM CSS
  const testForget = {
    width: "500px", margin: "0 auto"
  };

 // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  return (
    <React.Fragment>
      <div className="page-content container">
        <Card sx={{ p: 3 }}>
          <CardBody>
            <span className="logo-lg">
              <img style={{ width: "20%" }} src={logodark} alt=""
              />
            </span>
            <form
              style={testForget}
              onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0", textAlign: "center" }} className='heading'> Enter New Password</h4>
              <hr /><br />
              <div className='new-pswd' ><label>New Password : </label>
                <Input placeholder="New Password"
                  type="text"
                  className="form-control"
                  name="change_password"
                  value={change_password || ""}
                  id="exampleFormControlInput1"
                  onChange={(e) =>
                    setChangePassword(e.target.value)}
                />
              </div>
              <div className='new-pswd'> <label>Confirm Password : </label>
                <Input placeholder="Confirm Password"
                  type="text"
                  className="form-control"
                  name="confirm_password"
                  value={confirm_password || ""}
                  id="exampleFormControlInput1"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "5px",
                  padding: "10px",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                    textAlign: "center"
                  }}
                >
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  <Link
                    to="/ebookflutter_adminpanel/login"
                    style={{ color: "white" }}
                  >
                    <button type="button" className="btn btn-primary">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default NewPassword;
