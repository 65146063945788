import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import swal from "sweetalert";
import Image from '../../../components/Common/Image';
import "../datatables.scss";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { AxiosError } from "axios";
import UnSavedChanges from "./UnSavedChanges";
import Compress from "browser-image-compression";
import EditSocialMediaLog from "../Edit/EditSocialMediaLog";

interface Props {
  token: string;
  user: UserRoles;
}

const AddSocialMedia = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [social_name, setSocialName] = useState('');
  const [icon, setIcon] = useState<any>(null);
  const [networkIcon, setNetworkIcon] = useState(null);
  const [imgcompressor, setImgcompressor] = useState<any>({imageUrl:null});
  const [valid, setValid] = useState({
    social_name_Error: [] as any,
    iconpath_Error: [] as any,
    msg_Error: [] as any,
  });

  const iconRef = React.useRef<HTMLInputElement>(null);
  const [Prompt , setIsSaved,setPristine] = UnSavedChanges() as any;

  useEffect(() => {
    if (id) {
      fetch(`${process.env.REACT_APP_API_URL}/socialMedia/${id}`)
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }

          return res.json()
        })
        .then((data) => {
          setNetworkIcon(data[0].iconpath);
          setSocialName(data[0].social_name);
        })
        .catch(() => null)
    }
  }, [id]);

  const updateIcon = async () => {
    const social_name_Error = [] as any;
    const iconpath_Error = [] as any;

    if (!social_name) {
      social_name_Error.push("*Enter Social Name");
    } else {
      setValid({ ...valid, social_name_Error: "" })
    }

    if (!icon && !networkIcon) {
      iconpath_Error.push("*Upload The Icon");
    } else {
      setValid({ ...valid, iconpath_Error: "" })
    }

    // if (social_name.trim().length === 0) social_name_Error.push("Enter social name");
    // if (!icon && !networkIcon) iconpath_Error.push("Upload the icon");

    setValid({ ...valid, social_name_Error, iconpath_Error });

    if (social_name_Error.length) return;
    if (iconpath_Error.length) return;

    const form = new FormData();

    form.append('info', JSON.stringify({ social_name }));
    form.append('icon', icon);

    if (isLoading === false) {
      setIsLoading(true);

      try {
        const res1 = await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/socialMedia/${id}`,
          form, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }
        );
        console.log(res1);

        // setIsLoading(false);
        swal({
          title: "Updated Successfully!",
          // text: users.social_name,
          icon: "success",
          //b//utton: "OK!",
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');


        }

      }
      setIsLoading(false);
    }
    setValid({
      ...valid,
      msg_Error: "",
    });
    // setIcon("");
    // setSocialName("");
  }

  const postIcon = async () => {
    const social_name_Error = [];
    const iconpath_Error = [];

    if (!social_name) {
      social_name_Error.push("*Enter Social Name");
    } else {
      setValid({ ...valid, social_name_Error: "" })
    }

    if (!icon && !networkIcon) {
      iconpath_Error.push("*Upload The Icon");
    } else {
      setValid({ ...valid, iconpath_Error: "" })
    }

    // if (social_name.trim().length === 0) social_name_Error.push("Enter social name");
    // if (!icon) iconpath_Error.push("Upload the icon");

    setValid({ ...valid, social_name_Error, iconpath_Error });

    if (social_name_Error.length) return;
    if (iconpath_Error.length) return;

    const form = new FormData();

    form.append('info', JSON.stringify({ social_name }));
    form.append('icon', icon);

    if (isLoading === false) {
      setIsLoading(true);

      try {
        const res1 = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/socialMedia/socialMedia`,
          form,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
        console.log(res1);

        setIsLoading(false);
        if (res1.data.error){}else{
          setSocialName("");
          setIcon("");
        }
        swal({
          title: "Added Successfully!",
          // text: users.social_name,
          icon: "success",
          // button: "OK!",
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
      setIsLoading(false);
    }

    setValid({
      ...valid,
      msg_Error: "",
    });
    setIcon("");
    setSocialName("");
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      updateIcon();
    } else {
      postIcon();
    }
  };

  const renderIcon = () => {
    if (networkIcon) {
      return (
        <Row>
          <Col xs="auto">
            <Image src={`${process.env.REACT_APP_S3_APP_API}/social/${networkIcon}`} height="auto" width="50px" onPress={() => setNetworkIcon(null)} alt="img" />
          </Col>
        </Row>
      )
    }

    if (icon) {
      return (
        <Row>
          <Col xs="auto">
            <Image src={URL.createObjectURL(icon)} height="auto" width="50px" onPress={() => setIcon("")} alt="img" />
          </Col>
        </Row>
      )
    }

    return (
      <button className="btn btn-primary" onClick={(e) => {
        e.preventDefault();
        if (iconRef !== null) {
          iconRef?.current?.click();
        }
        // iconRef.current.click();

      }}>Upload Icon</button>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id !== undefined ? "UPDATE SOCIAL MEDIA" : "ADD SOCIAL MEDIA"}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SOCIAL MEDIA NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="social_name"
                            value={social_name || ""}
                            id="exampleFormControlInput1"
                            placeholder="Enter social media name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

                              setSocialName(e.target.value)
                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, social_name_Error: "" })
                            } else {
                                setValid({ ...valid, social_name_Error: "*Enter Social Name" });

                              }
                              setIsSaved() as any;
                              // if (!e.target.value || !social_name) {
                              //   setValid({ ...valid, social_name_Error: "*Enter Social Name" });
                              // } else {
                              //   setValid({ ...valid, social_name_Error: "" })
                              // }
                            }
                            }
                          />
                          {valid?.social_name_Error && <div style={{ color: 'red' }}>{valid?.social_name_Error} </div>}

                          {/* {valid?.social_name_Error.length
                            ? valid?.social_name_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                        {valid.msg_Error.length
                          ? valid.msg_Error.map((error: any, i: React.Key | null | undefined) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>UPLOAD ICON*</label>
                          <input
                            type="file"
                            className="form-control"
                            name="iconpath"
                            ref={iconRef}
                            style={{ display: 'none' }}
                            id="exampleFormControlInput1"
                            placeholder="Enter icon path"
                            onChange={(e: any) => {
                              setIsSaved() as any;
                              // const files = e.target.files;
                              const file = e.target.files[0];
                              if (!file) {
                                setValid({...valid, iconpath_Error:"*Upload The Icon"})
                               } else {
                                 setValid({ ...valid, iconpath_Error: "" })
                               }
                              if (e.target.files) {
                                if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                  toast.error('only PNG/JPEG/JPG are supported');
                                  return;
                                }
                                 if (e.target.files[0].size > 5000_000) {
                                  toast.error('File too large. Please select file less than 5MB');
                                  return;
                                 }

                                 const options = {
                                  maxSizeMB: 1,
                                  useWebWorker: true
                                }
                                Compress(file, options)
                                .then((compressedBlob) => {
                                   setImgcompressor({imageUrl: URL.createObjectURL(compressedBlob)
                                  })
                                  console.log(imgcompressor)
                                  const convertedBlobFile = new File([compressedBlob], file.name, {
                                    type: file.type,
                                    lastModified: Date.now(),
                                  });
                                  setIcon(convertedBlobFile)
                                 })
                                .catch((e) => {
                                });
                                // setIcon(files[0]);
                              }
                            }}
                          />
                          <br />
                          {renderIcon()}
                          {valid?.iconpath_Error && <div style={{ color: 'red' }}>{valid?.iconpath_Error} </div>}

                          {/* {valid?.iconpath_Error.length
                            ? valid?.iconpath_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                      </Col>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={()=>{
                              setPristine()
                            }}
                          />
                          {/* <button type="submit" className="btn btn-primary">
                            Submit
                          </button> */}
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/socialMedia"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                    <p>Only png/jpg/jpeg files are accepted</p>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id !== undefined ?
        <EditSocialMediaLog /> : <></>}
        {isLoading ? <Loader /> : null}
      </div>{Prompt}
    </React.Fragment>
  );
};
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
}

export default connect(mapStateToProps)(AddSocialMedia);
