import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
// import axiosInstance from "../../../helpers/axios";
import { Editor } from '@tinymce/tinymce-react';
import "../datatables.scss";
// import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
// import { toast } from "react-toastify";
// import { useSelector } from 'react-redux';
import { UserRoles } from "../../../store/auth/admins/user";
import axiosInstance from "../../../helpers/axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";


interface Props {
  token: string;
  user: UserRoles;
}

const EditTemplate = (props: Props) => {
  //   const location = useLocation() as any;
  const editorRef = useRef(null) as any;
  //   const id = location.state && location.state.id ? location.state.id : null;
  const [isLoading, setIsLoading] = useState(false);


  const [name, setName] = useState('');
  const [valid, setValid] = useState({
    name_Error: "",
    template_content_error: ""
  });


  const token = useSelector((state: any) => state.token);
  const user_id = useSelector((state: any) => state.user.id);


  const post = async () => {
    const templateContent = editorRef.current.getContent()
    const data = {
      name: name,
      content: templateContent,
      updated_by: user_id,
    }
    try {
      setIsLoading(true);
      await axiosInstance.post(`/email_templates/new_template`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Template Added successfully');
    } catch (e) {
      toast.error('Something went wrong! please try again later');
    }

    setIsLoading(false);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    // setIsLoading(true);
    const templateContent = editorRef.current.getContent()
    var changingcontant = templateContent.replace(/(<([^>]+)>)/g, "")
    const name_Error = [] as any;
    const template_content_error = [] as any;
    if (!name) {
      name_Error.push("*Enter Templates Name");
    } else {
      setValid({ ...valid, name_Error: "" })
    }
    if (changingcontant.length === 0) {
      template_content_error.push("*Enter Templates Content");
    } else {
      setValid({ ...valid, template_content_error: "" })
    }


    setValid({ ...valid, name_Error, template_content_error });

    if (name_Error.length) return;

    if (template_content_error.length) return;
    post();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <form onSubmit={onSubmit}>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">ADD EMAIL TEMPLATES</h4>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>NAME OF TEMPLATE</label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={name}
                            id="exampleFormControlInput1"
                            placeholder="Enter Template Name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

                              setName(e.target.value);

                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, name_Error: "" })
                              } else {
                                setValid({ ...valid, name_Error: "*Enter Templates Name" });

                              }
                              // if ( !e.target.value || !name) {
                              //   setValid({ ...valid, name_Error: "*Enter Templates Name" });
                              // } else {
                              //   setValid({ ...valid, name_Error: "" })
                              // }
                            }}

                          />
                          {valid?.name_Error && <div style={{ color: 'red' }}>{valid?.name_Error} </div>}

                          {/* {valid?.category_name_Error && <div style={{ color: 'red' }}>{valid?.category_name_Error} </div>} */}

                        </div>

                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Editor
                  onInit={(evt, editor) => editorRef.current = editor}
                  //   initialValue={initEditor}
                  init={{
                    // plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
                    plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template  codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap',
                    imagetools_cors_hosts: ['picsum.photos'],
                    menubar: 'file edit view format table tools help',
                    toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat', // remove the last 7 options
                    // menubar: 'file edit view insert format tools table help',
                    // toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                    toolbar_sticky: true,
                    autosave_ask_before_unload: true,
                    autosave_interval: '30s',
                    autosave_prefix: '{path}{query}-{id}-',
                    autosave_restore_when_empty: false,
                    autosave_retention: '2m',
                    image_advtab: true,
                    link_list: [
                      { title: 'My page 1', value: 'https://www.tiny.cloud' },
                      { title: 'My page 2', value: 'http://www.moxiecode.com' }
                    ],
                    image_list: [
                      { title: 'My page 1', value: 'https://www.tiny.cloud' },
                      { title: 'My page 2', value: 'http://www.moxiecode.com' }
                    ],
                    image_class_list: [
                      { title: 'None', value: '' },
                      { title: 'Some class', value: 'class-name' }
                    ],
                    importcss_append: true,
                    file_picker_callback: function (callback, value, meta) {
                      /* Provide file and text for the link dialog */
                      if (meta.filetype === 'file') {
                        callback('https://www.google.com/logos/google.jpg', { text: 'My text' });
                      }

                      /* Provide image and alt text for the image dialog */
                      if (meta.filetype === 'image') {
                        callback('https://www.google.com/logos/google.jpg', { alt: 'My alt text' });
                      }

                      /* Provide alternative source and posted for the media dialog */
                      if (meta.filetype === 'media') {
                        callback('movie.mp4', { source2: 'alt.ogg', poster: 'https://www.google.com/logos/google.jpg' });
                      }
                    },
                    templates: [
                      { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
                      { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
                      { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
                    ],
                    template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                    template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                    height: 600,
                    min_height:100,
                    image_caption: true,
                    quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                    noneditable_noneditable_class: 'mceNonEditable',
                    toolbar_mode: 'sliding',
                    contextmenu: 'link imagetools table',
                    // contextmenu: 'link image imagetools table',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
                {valid?.template_content_error && <div style={{ color: 'red' }}>{valid?.template_content_error} </div>}

                <div
                  style={{
                    display: "flex",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Submit"
                    />
                  </div>
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <Link
                      to={`/ebookflutter_adminpanel/email_templates`}
                      style={{ color: "white" }}
                    >
                      <button type="button" className="btn btn-primary">
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

export default EditTemplate;
