import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { Row, Col, Card, CardBody, Container, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "../datatables.scss";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Image from "../../../components/Common/Image";
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { MultiValue } from 'react-select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/material";

interface Props {
  token: string;
  user: UserRoles;
}
const AddBook = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [val, set_val] = useState<{ value: number; label: string } | null>(null);

  const [author, setAuthor] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [language, setLanguage] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [publisher, setPublisher] = useState<{ value: number; label: string } | null>(null);
  // const [links, setLinks] = useState([]);

  const [nFrontCover, setNFrontCover] = useState([]);
  const [nBackCover, setNBackCover] = useState([]);
  const [nPrimaryFront, setNPrimaryFront] = useState(null);
  const [nPrimaryBack, setNPrimaryBack] = useState(null);
  const [frontModal, setFrontModal] = useState(false);
  const [backModal, setBackModal] = useState(false);
  const [likes, setLikes] = useState(null);

  const [user, setUser] = useState({
    category_id: 0,
    subcategory_id: "",
    author_id: "",
    category_id_Error: [],
    subcategory_id_Error: [],
    author_id_Error: [],
    top_selling: false,
    recommended: false,
    book_name: "",
    book_title: "",
    publisher: "",
    price: "",
    dop: "",
    language: "",
    language_Error: [],
    discount: "",
    description: "",
    asin_number: "",
    book_name_Error: [],
    book_title_Error: [],
    dop_Error: [],
    description_Error: [],
    asin_number_Error: [],
    cover_type: "",
    category_name: "",
    isbn: "",
    pages: "",

  });

  const toggleFront = () => setFrontModal(!frontModal);
  const toggleBack = () => setBackModal(!backModal);

  useEffect(() => {
    getBook();
  }, []);

  const getBook = async () => {
    if (id) {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/books/admin-findone/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`
          }
        });
        const data = await res.json();

        // console.log(data);
        set_val({ value: data.category_id, label: data.category_name });
        setUser({
          ...data,
          subcategory_id: { value: data.subcategory_id, label: data.subcategory_name },
          cover_type: { value: data.cover_type, label: data.cover_type }
        });
        setAuthor(data.author);
        setLikes(data.likes);
        setLanguage(data.language);
        setPublisher(data.publisher ? { value: data.publisher, label: data.publisher_name } : null);
        // setLinks(data.links);
        setNPrimaryFront(data.primary_front);
        setNPrimaryBack(data.primary_back);
        setNFrontCover(data.front_image);
        setNBackCover(data.back_image);
      } catch (e) { }
    }
  };

  const renderPrimaryFront = () => {
    if (nPrimaryFront) {
      return (
        <Row>
          <Col xs="auto">

            <Image
              onPress={() => setNPrimaryFront(null)}
              src={`${process.env.REACT_APP_S3_APP_API}/books/${nPrimaryFront}`}
              showClose={false}
              height="100px"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      )
    }
  }

  const renderPrimaryBack = () => {
    if (nPrimaryBack) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setNPrimaryBack(null)}
              showClose={false}
              src={`${process.env.REACT_APP_S3_APP_API}/books/${nPrimaryBack}`}
              height="100px"
              width="100px"
              alt="front"
            />

          </Col>
        </Row>
      )
    }
  }

  const renderFront = () => {
    return (
      <Row>
        {nFrontCover.map((img, i) => (
          <Col xs="auto">
            <Image
              src={`${process.env.REACT_APP_API_URL}/profile/${img}`}
              height="100px"
              width="100px"
              alt="cover"
              key={i}
              onPress={() => {
                const temp = nFrontCover.filter((t) => t !== img);
                setNFrontCover(temp);
              }}
            />
          </Col>
        ))}
      </Row>
    )
  };

  const renderBack = () => {
    return (
      <Row>
        {nBackCover.map((img, i) => (
          <Col xs="auto">
            <Image
              src={`${process.env.REACT_APP_API_URL}/profile/${img}`}
              height="100px"
              width="100px"
              alt="cover"
              key={i}
              onPress={() => {
                const temp = nBackCover.filter((t) => t !== img);
                setNBackCover(temp);
              }}
            />
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">BOOK (❤️: {likes})</h4>
                  <Row>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>BOOK NAME*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Book Name"
                          name="book_name"
                          value={user.book_name}
                          disabled
                        />
                        {user?.book_name_Error?.length
                          ? user?.book_name_Error.map((error, i) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>BOOK TITLE*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Book Title"
                          name="book_title"
                          value={user.book_title}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>BOOK PUBLISHER</label>

                        <Select
                          className="select"
                          name="publisher_id"
                          placeholder="Select Publisher"
                          value={publisher}
                          onChange={setPublisher}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>SELECT AUTHOR*</label>

                        <Select
                          className="select"
                          name="author_id"
                          placeholder="Select Author"
                          isMulti
                          value={author}
                          onChange={setAuthor}
                          isDisabled
                        />
                        {user?.author_id_Error?.length
                          ? user?.author_id_Error.map((error, i) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>SELECT CATEGORY*</label>
                        <Select
                          className="select"
                          name="category_id"
                          placeholder="Select Category"
                          value={val}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>SELECT SUB CATEGORY*</label>
                        <Select
                          className="select"
                          name="subcategory_id"
                          placeholder="Select SubCategory"
                          value={user.subcategory_id}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>PRICE</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Price"
                          name="price"
                          value={user.price}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>DATE OF PUBLICATION*</label>
                        {/* <input
                          type="date"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Date of Publication"
                          name="dop"
                          value={user.dop}
                          disabled
                        /> */}


                        <LocalizationProvider dateAdapter={AdapterDateFns}>

                          <MobileDatePicker
                            disabled

                            label="Date of Publication"

                            value={user.dop}
                            // styles={{padding: "5px"} as StylesConfig}

                            onChange={date => {
                              setUser({ ...user, dop: date! });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>

                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>SELECT LANGUAGE*</label>
                        <Select
                          className="select"
                          name="language_id"
                          placeholder="Select Language"
                          isMulti
                          value={language}
                          onChange={setLanguage}
                          isDisabled
                        />
                      </div>
                      {user?.language_Error?.length
                        ? user?.language_Error.map((error, i) => (
                          <ErrorSpan key={i} error={error} />
                        ))
                        : null}
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>COVER TYPE</label>
                        <Select
                          className="select"
                          placeholder="Select Cover Type"
                          value={user.cover_type}
                          isDisabled
                        />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>PRIMARY FRONT COVER</label>
                        <br />
                        {renderPrimaryFront()}
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>PRIMARY BACK COVER</label>
                        <br />
                        {renderPrimaryBack()}
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <Row>
                          {/* <Col xs={8}>
                            {" "}
                            <label>UPLOAD FRONT COVER</label>
                            <br />
                            <>
                              <p style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', textDecorationColor: 'blue' }} onClick={toggleFront}>
                                {`${nFrontCover.length} images selected`}
                              </p>
                            </>
                          </Col> */}
                          {/* <Col xs={4}>
                            <img
                              id="img1"
                              src="https://rukminim1.flixcart.com/image/416/416/j26gmfk0-1/showpiece-figurine/z/g/b/vsn-1-vishal-props-original-imaeth4ryfctescr.jpeg?q=70"
                              alt="profile"
                              style={{ height: "75px", width: "100px" }}
                            />
                          </Col> */}
                        </Row>
                      </div>
                    </Col>
                    <Col xs={6}><br/>
                      <div className="form-group">
                        <Row>
                          {/* <Col xs={8}>
                            {" "}
                            <label>UPLOAD BACK COVER</label>
                            <br />
                            <>
                              <p style={{ cursor: 'pointer', textDecoration: 'underline', color: 'blue', textDecorationColor: 'blue' }} onClick={toggleBack}>
                                {`${nBackCover.length} images selected`}
                              </p>
                            </>

                          </Col>
                          <Col xs={4}>
                            <img
                              id="img2"
                              src="https://rukminim1.flixcart.com/image/416/416/j26gmfk0-1/showpiece-figurine/z/g/b/vsn-1-vishal-props-original-imaeth4ryfctescr.jpeg?q=70"
                              alt="profile"
                              style={{ height: "75px", width: "100px" }}
                            />
                          </Col> */}
                        </Row>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>NUMBER OF PAGES</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter number of pages"
                          name="pages"
                          value={user.pages}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>ISBN NUMBER</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter ISBN Number"
                          name="isbn"
                          value={user.isbn}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>BOOK DESCRIPTION*</label>
                        <TextareaAutosize
                          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                          minRows={4}
                          maxLength={500}
                          // className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Book Description"
                          name="description"
                          value={user.description || ''}
                          disabled
                        />
                         <label style={{ color:"#0069d9"}}>{user.description.length} characters</label>
                            
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>ASIN NUMBER</label>
                        <input
                          type="text"
                          className="form-control"
                          name="asin_number"
                          id="exampleFormControlInput1"
                          placeholder="Enter ASIN Number"
                          value={user.asin_number}
                          disabled
                        />
                      </div>
                    </Col>
                    {/* <Col xs={6}>
                      <div className="form-group">
                        <label>LINKS</label>
                        {links.map((item, i) => {
                          return (
                            <input
                              type="text"
                              className="form-control"
                              name="links"
                              id="exampleFormControlInput1"
                              placeholder="Place your link here"
                              value={item}
                              style={{ marginTop: 5 }}
                              disabled
                            />
                          )
                        })}
                        <br />
                      </div>
                    </Col> */}
                    <Col xs={6}>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <Link to="/ebookflutter_adminpanel/book" style={{ color: "white" }}>
                            <button type="button" className="btn btn-primary">

                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={frontModal} toggle={toggleFront}>
            <ModalHeader toggle={toggleFront}>Front Cover images</ModalHeader>
            <ModalBody>
              {renderFront()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleFront}>Close</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={backModal} toggle={toggleBack}>
            <ModalHeader toggle={toggleBack}>Back Cover images</ModalHeader>
            <ModalBody>
              {renderBack()}
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleBack}>Close</Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => ({
  token: state.token!,
  user: state.user,

});

export default connect(mapStateToProps)(AddBook);
