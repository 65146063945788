import {useState,useEffect} from 'react'
import { Prompt } from "react-router-dom";

const UnSavedChanges = (message ="Are you sure want to discard changes?") => {
    const [isSaved, setIsSaved] = useState(false)

useEffect(() => {
  window.onbeforeunload = isSaved && (()=>message) as any
  return() =>{
    window.onbeforeunload = null;
  }
}, [isSaved])

const routerPrompt = (
  <Prompt when={isSaved} message={message}/>
) 
 return [routerPrompt,()=>setIsSaved(true),()=>setIsSaved(false)];
  
}

export default UnSavedChanges;
