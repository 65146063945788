// THIS COMPONENT IS USED FOR IMPORTING ALL THE IMAGE THAT IS USED IN  LIKE SIDEBAR , LOGIN SCREEN , DASHBOARD ETC .
import dummy_book from "./images/dummy_book.png";
import dummy_author from "./images/dummy_author.jpg";
import dummy_img_1 from "./images/dummy_book_1.png";
import user from "./images/user.png";
import categories from "./images/total-categopries.png";
import book from "./images/total-book.png";
import author from "./images/total-author.png";
import feedback from "./images/feedback.png";
import feed from "./images/feed.png";
import review from "./images/author-review.png";
import bookReview from "./images/book-review.png";
import customer from "./images/new-customer-icon.png";
import count from "./images/count.png";
import freeplan from "./images/customer-freeplan.png";
import today from "./images/today-earning.png";
import total from "./images/total-earning.png";
import authoricon from "./images/sidebaricon/author.png";
import booknavigation from "./images/sidebaricon/book-navigation.png";
import email from "./images/sidebaricon/email.png";
import mobileslider from "./images/sidebaricon/mobileslider.png";
import publisher from "./images/sidebaricon/publisher.png";
import recommended from "./images/sidebaricon/recommended.png";
import roles from "./images/sidebaricon/roles.png";
import socialmedia from "./images/sidebaricon/social-media.png";
import subcategory from "./images/sidebaricon/subcategory.png";
import topselling from "./images/sidebaricon/top-selling.png";
import authorreview from "./images/sidebaricon/author-review.png";
import bookreview from "./images/sidebaricon/book-review.png";
import coupon from "./images/sidebaricon/coupon.png";
import feeds from "./images/sidebaricon/feed.png";
import feedbacks from "./images/sidebaricon/feedbacks.png";
import genre from "./images/sidebaricon/genre.png";
import log from "./images/sidebaricon/log.png";
import users from "./images/sidebaricon/user.png";
import dashboard from "./images/sidebaricon/dashboard-icon.png";
import category from "./images/sidebaricon/category.png";
import topcategory from "./images/sidebaricon/top-category.png";
import rgisteruser from "./images/sidebaricon/rgisteruser.png";
import imageauthor from './images/Dashboardimages/total-author.png';
import Author_notify from './images/Dashboardimages/Author_notify.png';
import Coach_notify from './images/Dashboardimages/Coach_notify.png';
import Guest_User from './images/Dashboardimages/Guest-User.png';
import Register_User from './images/Dashboardimages/Register-User.png';
import mobile from './images/Dashboardimages/mobile.png';
import coach from './images/sidebaricon/coach.png';
import audioreview from './images/sidebaricon/audio-review.png';
import audio from './images/sidebaricon/audio.png';
import video from './images/sidebaricon/video.png';
import notification from './images/sidebaricon/notification.png';
import apple from './images/sidebaricon/apple.png';
import android from './images/sidebaricon/android.png';
import pending_author from './images/Pending-Author.png';
import Pending_Book from './images/Pending-Book.png';
import Pending_Coach from './images/Pending-Coach.png';
import Pending_Coach_audio from './images/Pending-Coach-Audio.png';
import Pending_Coach_Video from './images/Pending-Coach-Video.png';
import weblogo_png from './images/sidebar/login_logo.png';
import audio_main from './images/sidebaricon/audio_main.png';
import top_coach from './images/sidebaricon/Top-Coach.png';
import notification_templates from './images/sidebaricon/Notification-Templates.png';
import top_Author from './images/sidebaricon/Top-Author.png';
import video_review from './images/sidebaricon/Video-Review.png';
import author_review from './images/sidebaricon/author-review.png';
import admin_user from './images/sidebaricon/Admin-Users.png';
import portal_user from './images/sidebaricon/Portal-Users.png';
import mobile_user from './images/sidebaricon/Mobile-User.png';
import admin_login from './images/admin-login.png';
import notify from './images/notify.png';

// WITH THE HELP OF THIS IMAGES VARIABLE,IMAGES CAN WE USED IN THE ANOTHER COMPONENT BY IMPORT THE IMAGES COMPONENT
const images = {
  dummy_book,
  dummy_author,
  dummy_img_1,
  user,
  categories,
  book,
  author,
  feedback,
  feed,
  review,
  bookReview,
  customer,
  count,
  freeplan,
  today,
  total,
  authoricon,
  booknavigation,
  email,
  mobileslider,
  publisher,
  recommended,
  roles,
  socialmedia,
  subcategory,
  topselling,
  coupon,
  feeds,
  feedbacks,
  genre,
  log,
  users,
  authorreview,
  bookreview,
  dashboard,
  category,
  topcategory,
  rgisteruser,
  imageauthor,
  mobile,
  coach,
  audioreview,
  audio,
  video,
  notification,
  android,
  apple,
  pending_author,
  Pending_Book,
  Pending_Coach,
  Pending_Coach_audio,
  Pending_Coach_Video,
  weblogo_png,
  audio_main,
  top_coach,
  notification_templates,
  top_Author,
  video_review,
  author_review,
  admin_user,
  portal_user,
  mobile_user,
  admin_login,
  notify,
  Author_notify,
  Coach_notify,
  Guest_User,
  Register_User,
};

export default images;
