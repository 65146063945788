// IMPORT ALL DEPENDENCY
import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import { Card, CardContent, Typography } from '@material-ui/core';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withRouter } from "react-router-dom";
import axiosInstance from "../../helpers/axios";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import '../eBook/style.css'
import { Link, useHistory } from "react-router-dom";
import images from "../../assets/images";


const Dashboard = (props) => {
    // STATES
    const history = useHistory();
    const [state, setState] = useState({});
    const [approve, setApprove] = useState(null);

    // USE-EFFECT TO CALL THE API
    useEffect(() => {
        getDashboardData();
    }, [0])

    // THIS IS AND ARRAY THAT IS USED TO SET THE DASHBOARD SIDE HEADINGS
    const breadcrumbItems = [
        { title: "E-Book", link: "#" },
        { title: "Dashboard", link: "#" },
    ];

    // API CALL
    const getDashboardData = async () => {
        const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/dashboard`);
        setState(res.data);
    }
    
    // THIS IS PROPS AND THIS IS COME FROM USER.TS
    const {
        categories, subcategories, author, books, feed, chapters, genre, recommended, top_selling, audio, video,
        coach, mobile_slider, social_media, publisher, roles, users, mobileusers, feedback, author_review, book_review, coupons, email_templates, log,
    } = props.user;

    const handleCardClick = (value) => {
        setApprove({ value: value, label: value });
        history.push(`/ebookflutter_adminpanel/author?approve=${value}`);
      }

     const handleCard = (value) =>{
        setApprove({ value: value, label: value });
        history.push('/ebookflutter_adminpanel/book?approve=${value}')
     }
     const handleClick = (value) =>{
        setApprove({ value: value, label: value });
        history.push('/ebookflutter_adminpanel/coach?approve=${value}')
     }
     const handleCardBody = (value) =>{
        setApprove({ value: value, label: value });
        history.push('/ebookflutter_adminpanel/audio?approve=${value}')
     }
     const handleCardBodyClick = (value) =>{
        setApprove({ value: value, label: value });
        history.push('/ebookflutter_adminpanel/video?approve=${value}')
     }

    // THIS IS JSX ALL THE CARDS,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs
                        title="Dashboard"
                        breadcrumbItems={breadcrumbItems}
                    />
                    <Row style={{ justifyContent: "flex-start", marginBottom: "40px" }}>
                        {mobileusers?.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/mobileUsers') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.user} />
                                <CardContent className="card-area">
                                    <h1>{state.users || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }} >
                                        <Link to="/#">Total User</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {categories.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/category') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.categories} />
                                <CardContent className="card-area">
                                    <h1>{state.categories || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#" >Total Category</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {books?.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/book') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.book} />
                                <CardContent className="card-area">
                                    <h1>{state.books || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#">Total Book</Link>
                                    </Typography>
                                </CardContent>
                            </Card >
                        ) : null}
                        {author.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/author') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.author} />
                                <CardContent className="card-area">
                                    <h1>{state.authors || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#" > Total Author</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {feedback.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/feedback') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.feedback} />
                                <CardContent className="card-area">
                                    <h1>{state.feedbacks || 0}</h1>

                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>

                                        <Link to="/#" >Feedback</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {feed?.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/feed') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.feed} />
                                <CardContent className="card-area">
                                    <h1>{state.feed || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>

                                        <Link to="/#" >Feed</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {author_review.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/author_review') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.review} />
                                <CardContent className="card-area">
                                    <h1>{state.authorLikes || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>

                                        <Link to="/#">Author Review</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {book_review.length !== 0 ? (
                            <Card onClick={() => { history.push('/ebookflutter_adminpanel/book_review') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.bookReview} />
                                <CardContent className="card-area">
                                    <h1>{state.bookLikes || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#">Book Review</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                    </Row>
                </Container>
                <Container fluid>
                    <Breadcrumbs
                        title="Pending Approval"
                        breadcrumbItems={[]}
                    />

                    <Row style={{ justifyContent: "flex-start", marginBottom: "40px" }}>

                        {author?.length !== 0 ? (

                            <Card onClick={() => handleCardClick('approve')}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.pending_author} />
                                <CardContent className="card-area">
                                    <h1>{state.pendingAuthors || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#" >Author</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {books.length !== 0 ? (

                            <Card onClick={() => handleCard('approve')}
                                // onClick={() => { history.push('/ebookflutter_adminpanel/book') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>

                                <img src={images.Pending_Book} />
                                <CardContent className="card-area">
                                    <h1>{state.pendingBooks || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#">Book</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}

                        {coach?.length !== 0 ? (
                            <Card onClick={() => handleClick('approve')}
                            // <Card onClick={() => { history.push('/ebookflutter_adminpanel/coach') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.Pending_Coach} />
                                <CardContent className="card-area">
                                    <h1>{state.pendingCoaches || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>
                                        <Link to="/#" >Coach</Link>
                                    </Typography>
                                </CardContent>
                            </Card >
                        ) : null}
                        {audio.length !== 0 ? (
                            <Card onClick={() => handleCardBody('approve')}
                            // <Card onClick={() => { history.push('/ebookflutter_adminpanel/audio') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.Pending_Coach_audio} />
                                <CardContent className="card-area">
                                    <h1>{state.pendingAudios || 0}</h1>
                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>

                                        <Link to="/#" > Coach Audio</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}
                        {video.length !== 0 ? (
                            <Card onClick={() => handleCardBodyClick('approve')}
                            // <Card onClick={() => { history.push('/ebookflutter_adminpanel/video') }}
                                className="dashboard-row" style={{ display: 'flex', alignItems: 'center', width: "100%", maxWidth: "300px", marginBottom: "40px", cursor: 'pointer' }}>
                                <img src={images.Pending_Coach_Video} />
                                <CardContent className="card-area">
                                    <h1>{state.pendingVideos || 0}</h1>

                                    <Typography variant="body2" style={{ color: 'text.secondary' }}>

                                        <Link to="/#" >Coach Video</Link>
                                    </Typography>
                                </CardContent>
                            </Card>
                        ) : null}


                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

{/* REACT-REDUX */ }
const mapStatetoProps = (state) => {
    return { user: state.user };
};

export default withRouter(connect(mapStatetoProps, {
})(withNamespaces()(Dashboard))

);
