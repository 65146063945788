import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from "@material-ui/core/Switch";
// import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import axiosInstance from '../../../helpers/axios';
import { modules, permissions, errorMsg } from '../../../utils/constants';
import Loader from '../../../components/ui/loader/Loader';
import { AxiosError } from 'axios';
interface Props {
  token: string;
  user: UserRoles;
}
const VideoView = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get(`/coach/admin-video/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    }).then((res) => {
      setData(res.data);
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
      alert('Something went wrong please try again later');
    })
  }, []);

  const updateToggle = async () => {
    if (!props.user[modules.video].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res = await axiosInstance.put(`/coach/video-approval`, { video_id: data.data.video_id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      // setIsLoading(false);
      setData({ ...data, data:{ ...data.data, approval_status: res.data.status, approved_by: res.data.approved_by} });

      // setData((t: any) => ({ ...t, approval_status: res.data.status, approved_by: res.data.approved_by }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);

      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
               
                <CardBody style={{ padding: '40px 50px' }}>
                  {/* <h6>Coach: {data.fname}</h6> */}
                  <h6><b>Coach Name</b>: {data.data.coach_first_name}{' '}{data.data.coach_last_name}</h6>
                  <h6><b>Video Name</b>: {data.data.video_name}</h6>
                  <h6><b>Description</b> : {data.data.video_description}</h6>
                  <h6><b>Likes Count </b> : {data.data.likes}</h6>
                  {/* <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h6 style={{ marginTop: '8px' }}><b>Rating:</b></h6>
                    <StarRatings
                      numberOfStars={5}
                      rating={data.data.rating}
                      starRatedColor="#FDCC0D"
                      starDimension="20px"
                      starSpacing="2px"
                    />
                  </div> */}
                
                  {/* <h6><b>Approval Status:</b> {data.data.approval_status}</h6> */}

                  <h6><b>Created Date:</b>  {`${new Date(data.data.created_at).getDate() + "  "} - ${new Date(data.data.created_at).getMonth() + 1} - ${new Date(data.data.created_at).getFullYear()}`}</h6>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h6 style={{ marginTop: '8px' }}><b>Approved </b> </h6>
                 
                    <Switch checked={data?.data?.approval_status} color="primary" onChange={updateToggle} value={data?.data?.approval_status} />
                  </div>
                  <video width="320" height="240" controls>
                    <source src={`${process.env.REACT_APP_S3_APP_API}/coach_video/${data.data.video_file}`} type="video/mp4" />
                  </video>
                  {/* <h6><b>Approved By:</b> {data.data.approved_by ? data.data.approved_by : '--'}</h6> */}

                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <Link
                      to="/ebookflutter_adminpanel/video"
                      style={{ color: "white" }}
                    >
                      <button type="button" className="btn btn-primary">
                        Back
                      </button>
                    </Link>
                  </div>
                  {/* <p>{data.data.description}</p> */}
                </CardBody>

              </Card>
            </Col>

          </Row>

        </Container>
      </div>
      {isLoading ? <Loader /> : null}
    </React.Fragment>
  )
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(VideoView);
