import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Select from 'react-select';
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import axiosInstance from "../../helpers/axios";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { StoreState } from "../../store";
import { UserRoles } from "../../store/auth/admins/user";
import { AxiosError } from "axios";
import Modal from 'react-modal';
import '../eBook/style.css'
import validator from 'validator';
import UnSavedChanges from '../eBook/Add/UnSavedChanges';

// Props
interface Props {
    token: string;
    user: UserRoles;
}

const AddUsers = (props: Props) => {
    const { id } = useParams() as {
        id?: number | undefined;
    }

    // States
    const [author, setAuthor] = useState(null as any);
    const [coach, setCoach] = useState(null as any);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [note, setNote] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [linkType, setLinkType] = useState([] as any);
    const [authorData, setAuthorData] = useState<any>({ data: [], loaded: false });
    const [coachData, setCoachData] = useState<any>({ data: [], loaded: false });
    const [errors, setErrors] = useState({
        nameError: "" as any,
        emailError: "" as any,
        passwordError: "" as any,
        roleError: "" as any,
        userTypeError: "" as any,
        authorCoachError: "" as any,

    });
    const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [input, setInput] = useState({
        username: '' as any,
        password: '' as any,
        confirmPassword: '' as any,
    });
    const [error, setError] = useState({
        username: '',
        password: '',
        confirmPassword: ''
    })
    let subtitle: any;

    // UseEffect for Fetch API call
    useEffect(() => {
        fetchUser();
    }, []);

    // Custom Style for designing
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '540px',
            padding: '60px',
        },
    };

    // Fetch API
    const fetchUser = async () => {
        if (id) {
            try {
                const res = await axiosInstance.get(`admin/new_register/${id}`, {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
                setName(res.data.data[0].name);
                setEmail(res.data.data[0].email);
                setNote(res.data.data[0].note);
                const temp = res.data.data[0].user_type_name;
                const a = [];
                for (var i = 0; i < temp.length; i++) {
                    a.push({ value: temp[i].type_id.toString(), label: temp[i].user_type_name.toString().charAt(0).toUpperCase() + temp[i].user_type_name.toString().slice(1) })
                }
                setLinkType(a);
                setAuthor(res.data.author.result);
                setCoach(res.data.coach.result);

            } catch (e) {
                console.log(isLoading);

            }
        }
    };

    // Function of empty States
    const flush = () => {
        setName('');
        setEmail('');
        setNote('');
        setPassword('');
        setLinkType([]);
    };

    // Update Users API
    const update = async () => {
        const err = [] as any;
        let link_to_author = null;
        let link_to_coach = null;

        if (!name) {
            err.nameError = 'Enter Name';
        } else {
            setErrors({ ...errors, nameError: "" })
        }
        if (!email) {
            err.emailError = 'Enter valid E-mail';
        } else {
            setErrors({ ...errors, nameError: "" })
        }

        if (linkType.length === 0) {
            err.userTypeError = '*Select User Type'
        }
        else {
            if (author === undefined || coach === undefined) {
                err.authorCoachError = '*Please Select';
            }
        }

        if (linkType.map((e: any) => e.value === "2")) {
            link_to_author = author?.value;
        }

        if (linkType.map((e: any) => e.value === "3")) {
            link_to_coach = coach?.value;
        }


        if (err.emailError || err.nameError || err.userTypeError || err.authorCoachError) {
            setErrors(err);
            return;
        } else {
            setErrors([] as any);
        }
        const data = {
            name,
            email,
            note: note === null ? "" : note,
            role_id: 0,
            user_type: linkType.map((e: any) => e.value),
            link_to_author: link_to_author === 0 ? null : link_to_author,
            link_to_coach: link_to_coach === 0 ? null : link_to_coach,
        };
        try {
            await axiosInstance.put(`/admin/new_register/${id}`, data, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });

            swal({
                title: "User Updated Successfully!",
                icon: "success",
            });
        } catch (e) {
            const error = e as AxiosError;
            if (error.response?.data.error) {
                toast.error(error.response?.data.error);
            }
            else {
                toast.error('Something went wrong. Please try again later');
            }
        }
    };

    // ADD Users API
    const post = async () => {
        const err = [] as any;
        let link_to_author = null;
        let link_to_coach = null;

        if (!name) {
            err.nameError = 'Enter Name';
        } else {
            setErrors({ ...errors, nameError: "" })
        }
        if (!email) {
            err.emailError = 'Enter valid E-mail';
        } else {
            setErrors({ ...errors, nameError: "" })
        }

        if (!password) {
            err.passwordError = 'Enter Password';
        } else if (password.length <= 5) {
            err.passwordError = 'Password Too Short';
        } else {
            setErrors({ ...errors, passwordError: "" })
        }
        if (linkType.length === 0) {
            err.userTypeError = 'Select User Type '
        }
        else {
            if (author === null && coach === null) {
                err.authorCoachError = '*Please Select';
            }
        }

        if (linkType.map((e: any) => e.value === "2")) {
            link_to_author = author?.value;
        }

        if (linkType.map((e: any) => e.value === "3")) {
            link_to_coach = coach?.value;
        }
        if (err.emailError || err.nameError || err.passwordError || err.userTypeError || err.authorCoachError) {
            setErrors(err);
            return;
        } else {
            setErrors([] as any);
        }

        const data = {
            name,
            email,
            password,
            note,
            role_id: 0,
            user_type: linkType.map((e: any) => e.value),
            link_to_author,
            link_to_coach,
        };

        try {
            await axiosInstance.post('/admin/new_register', data, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });

            flush();
            swal({
                title: "User Added Successfully!",
                icon: "success",
            });
        } catch (e) {
            const error = e as AxiosError;

            if (error.response?.data.error) {
                toast.error(error.response?.data.error);
            }
            else {
                toast.error('Something went wrong. Please try again later');
            }
        }
    };

    // OnChange function
    const onInputChange = (e: any) => {
        const { name, value } = e.target;
        setInput(prev => ({
            ...prev,
            [name]: value
        }));
        setIsSaved() as any;
        // validateInput(e);
    }

    // Password And Confirm Password Validation
    const validateInput = (e: any) => {
        let { name, value } = e.target as any;
        setError(prev => {
            const stateObj = { ...prev, [name]: "" } as any;

            switch (name) {
                case "username":
                    if (!value) {
                        stateObj[name] = "Please Enter Username.";
                    }
                    break;

                case "password":
                    if (!validator.isStrongPassword(value, {
                        minLength: 8, minLowercase: 1,
                        minUppercase: 1, minNumbers: 1, minSymbols: 1
                    })) {
                        stateObj[name] = "please Enter Strong Password";

                    } else if (!value) {
                        stateObj[name] = "Please Enter Password.";
                    } else if (input.confirmPassword && value !== input.confirmPassword) {
                        stateObj["confirmPassword"] = "Password and Confirm Password Does Not Match.";
                    } else {
                        stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
                    }
                    break;

                case "confirmPassword":
                    if (!value) {
                        stateObj[name] = "Please Enter Confirm Password.";
                    } else if (input.password && value !== input.password) {
                        stateObj[name] = "Password And Confirm Password Does Not Match.";
                    }
                    break;

                default:
                    break;
            }

            return stateObj;
        });
    }

    // Submit Function
    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (id) {
            update();
        } else {
            post();
        }
    };

    // Change Password Open Model Function
    function openModal() {
        setIsOpen(true);
    }

    // After Open Model Function
    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    // Close model Function
    function closeModal() {
        setIsOpen(false);
    }

    // Change Password API
    const passwordSubmit = async (e: any) => {
        try {
            const res = await axiosInstance.post(
                `${process.env.REACT_APP_API_URL}/web_register/change-password`,
                {
                    user_id: id,
                    change_password: input.password,
                    confirm_password: input.confirmPassword,
                },
                {
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
            if (res?.data?.success === 0) {
                setInput({ ...input });
                return;
            }
            setIsLoading(false);
            swal({
                title: "Password Successfully Change!",
                icon: "success",
            });

        } catch (e) {
            setIsLoading(false);
            const error = e as AxiosError;
            if (error.response?.data.error) {
                toast.error(error.response?.data.error);
            }
        }
    };

    // Author Coach DropDown
    const linkOptions = [
        { value: '2', label: 'Author' },
        { value: '3', label: 'Coach' },

    ];

    // Particular Author And Coach Dropdown
    useEffect(() => {
        if (id !== undefined) {
            axiosInstance.get(`/v2_authors/get-unlinked-authors/${id}`, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            }).then((res) => {
                setAuthorData(res.data);
            }).catch(() => toast.error('Unable to load data'));
            // }

            // if (linkType?.value === 'coach' && !coachData.loaded) {
            axiosInstance.get(`/coach/get-unlinked-coaches/${id}`, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            }).then((res) => {
                setCoachData(res.data);
            }).catch(() => toast.error('Unable to load data'))

        } else {
            axiosInstance.get(`/v2_authors/get-unlinked-authors`, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            }).then((res) => {
                setAuthorData(res.data);
            }).catch(() => toast.error('Unable to load data'));
            axiosInstance.get(`/web_coach/get-unlinked-coaches`, {
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            }).then((res) => {
                setCoachData(res.data);
            }).catch(() => toast.error('Unable to load data'))
        }
    }, []);

    return (
        <React.Fragment>
            <div className="page-content" >
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <h4 className="card-title">{id !== undefined ? "UPDATE NEW USER" : "ADD NEW USER"}</h4>
                                    <form onSubmit={(e) => onSubmit(e)}>
                                        <Row>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>NAME*</label>
                                                    <input
                                                        // autoComplete="off"
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        value={name}
                                                        id="exampleFormControlInput1"
                                                        placeholder="Enter name"
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

                                                            setName(e.target.value)
                                                            setIsSaved() as any;

                                                            if ((selectedValue.value.length)) {
                                                                setErrors({ ...errors, nameError: "" })
                                                            } else {
                                                                setErrors({ ...errors, nameError: "Enter Name" });

                                                            }
                                                            // if (!e.target.value || !name) {
                                                            //     setErrors({ ...errors, nameError: "Enter Name" });
                                                            // } else {
                                                            //     setErrors({ ...errors, nameError: "" })
                                                            // }
                                                        }
                                                        }
                                                    />
                                                    {errors.nameError ? (<ErrorSpan error={errors.nameError} />) : null}
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>E-mail*</label>
                                                    <input
                                                        autoComplete="off"
                                                        type="email"
                                                        className="form-control"
                                                        name="email"
                                                        id="exampleFormControlInput2"
                                                        placeholder="Enter E-mail"
                                                        value={email}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const selectedValue = document.getElementById('exampleFormControlInput2') as HTMLInputElement;

                                                            setEmail(e.target.value)
                                                            setIsSaved() as any;

                                                            if ((selectedValue.value.length)) {
                                                                setErrors({ ...errors, emailError: "" })
                                                            } else {
                                                                setErrors({ ...errors, emailError: "Enter Valid E-mail" });

                                                            }
                                                            // if (!e.target.value || !email) {
                                                            //     setErrors({ ...errors, emailError: "Enter Valid E-mail" });
                                                            // } else {
                                                            //     setErrors({ ...errors, emailError: "" })
                                                            // }
                                                        }
                                                        }
                                                    />
                                                    {errors.emailError ? (<ErrorSpan error={errors.emailError} />) : null}
                                                </div>
                                            </Col>
                                            <Col xs={6}>
                                                <div className="form-group">
                                                    <label>ADD NOTE</label>
                                                    <textarea
                                                        typeof="textarea"
                                                        className="form-control"
                                                        name="authors_bio"
                                                        id="exampleFormControlInput1"
                                                        placeholder="Add Note"
                                                        value={note}
                                                        onChange={(e) => {
                                                            setNote(e.target.value)
                                                            setIsSaved() as any;
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            {id !== undefined ?
                                                <Col xs={6}>
                                                    <div className="form-group">
                                                        <br />
                                                        <button type="button"
                                                            className="password-btn"
                                                            onClick={openModal}>
                                                            Change Password
                                                        </button>
                                                    </div>
                                                </Col> : null}
                                            <Col xs={6}>
                                                <label>USER TYPE*</label>
                                                <div className="form-group">
                                                    <Select
                                                        isMulti
                                                        options={linkOptions}
                                                        value={linkType}
                                                        isSearchable={false}
                                                        onChange={(e) => {
                                                            setLinkType(e)
                                                            setIsSaved() as any;
                                                            if (!linkType) {
                                                                // err.userTypeError = 'Select User Type '
                                                                setErrors({ ...errors, userTypeError: "Select User Type" });
                                                            }
                                                            else {
                                                                if (author === null && coach === null) {
                                                                    setErrors({ ...errors, authorCoachError: "*Please Select" });
                                                                    // err.authorCoachError = 'Please Select';
                                                                }

                                                            } setErrors({ ...errors, userTypeError: "" });

                                                        }}
                                                        placeholder="Choose Link Type"
                                                    />
                                                    {errors.userTypeError ? (<ErrorSpan error={errors.userTypeError} />) : null}
                                                </div>


                                            </Col>


                                            <Col xs={6}>
                                                <div className="form-group">
                                                    {id !== undefined ?
                                                        <>
                                                        </> : <><label>PASSWORD*</label>
                                                            <input
                                                                type="password"
                                                                className="form-control"
                                                                name="category_name"
                                                                value={password}
                                                                id="exampleFormControlInput3"
                                                                placeholder="Enter password"
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                    const selectedValue2 = document.getElementById('exampleFormControlInput3') as HTMLInputElement;

                                                                    setPassword(e.target.value)
                                                                    setIsSaved() as any;

                                                                    if ((selectedValue2.value.length)) {
                                                                        setErrors({ ...errors, passwordError: "" })
                                                                    } else if ((selectedValue2.value.length <= 5)) {
                                                                        setErrors({ ...errors, passwordError: "Enter Password" });

                                                                    } else {
                                                                        setErrors({ ...errors, passwordError: "Password Too Short" });

                                                                    }
                                                                    // if (!e.target.value || password.trim().length === 0) {
                                                                    //     setErrors({ ...errors, passwordError: "Enter Password" });
                                                                    // } else if (password.length <= 5) {
                                                                    //     setErrors({ ...errors, passwordError: "Password Too Short" });
                                                                    // } else {
                                                                    //     setErrors({ ...errors, passwordError: "" })
                                                                    // }
                                                                }}
                                                            />
                                                            {errors.passwordError ? (<ErrorSpan error={errors.passwordError} />) : null}</>}

                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                {(linkType?.length !== 0) ? (
                                                   <>
                                                        {(linkType.filter((e: any) => (e.value === '2'))).length === 1 ?
                                                            <><label>SELECT EXISTING AUTHOR</label><Select options={authorData}
                                                                // styles={{ marginTop: '10px' } as StylesConfig}
                                                                value={author}
                                                                onChange={(e) => {
                                                                    setIsSaved() as any;
                                                                    setAuthor(e);
                                                                    setErrors({ ...errors, authorCoachError: [] })

                                                                }}
                                                                placeholder="Select Author" /></> : null}
                                                  </>
                                                ) : null}
                                                {(linkType.filter((e: any) => (e.value === '3'))).length === 1 ?
                                                    <><label>SELECT EXISTING COACH</label><Select options={coachData}
                                                        value={coach}
                                                        onChange={(e) => {
                                                            setIsSaved() as any;
                                                            setCoach(e);

                                                        }}
                                                        placeholder="Select Coach" /></> : null}
                                                        {errors.authorCoachError && <div style={{ color: 'red' }}>{errors.authorCoachError}</div>}

                                            </Col>

                                        </Row>

                                        <Row>
                                            {/* {errors.authorCoachError ? (<ErrorSpan error={errors.authorCoachError} />) : null} */}
                                            <div
                                                style={{
                                                    display: "flex",
                                                    margin: "5px",
                                                    padding: "10px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <input
                                                        type="submit"
                                                        value="Submit"
                                                        className="btn btn-primary"
                                                        onClick={() => {
                                                            setPristine()
                                                        }} />
                                                </div>
                                                <div
                                                    style={{
                                                        margin: "5px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    {" "}
                                                    <Link to="/ebookflutter_adminpanel/users"
                                                        style={{ color: "white" }}>
                                                        <button type="button" className="btn btn-primary"
                                                        >
                                                            Back
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </Row>
                                    </form>
                                    {id !== undefined ?
                                        <Modal
                                            isOpen={modalIsOpen}
                                            onAfterOpen={afterOpenModal}
                                            onRequestClose={closeModal}
                                            style={customStyles}
                                            contentLabel="Example Modal">
                                            <form className="modal-passwd"
                                                onClick={(e) => passwordSubmit(e)}
                                            >
                                                <h3 ref={(_subtitle) => (subtitle = _subtitle)}>PassWord</h3>
                                                <input
                                                    className="modal-input"
                                                    type="password"
                                                    name="password"
                                                    placeholder='Enter Password'
                                                    value={input.password}
                                                    onChange={onInputChange}
                                                    onBlur={validateInput}
                                                />
                                                {error.password ? (
                                                    <ErrorSpan error={error.password} />
                                                ) : null}
                                                <br /><br />
                                                <h3 >Confirm PassWord</h3>

                                                <input
                                                    className="modal-input"
                                                    type="password"
                                                    name="confirmPassword"
                                                    placeholder='Enter Confirm Password'
                                                    value={input.confirmPassword}
                                                    onChange={onInputChange}
                                                    onBlur={validateInput}
                                                />
                                                {error.confirmPassword ? (
                                                    <ErrorSpan error={error.confirmPassword} />
                                                ) : null}

                                                <div className="submit-section text-center mt-3">
                                                    <button className="modal-submit" onClick={closeModal}>Update</button></div>
                                            </form>
                                        </Modal>
                                        : null}
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {Prompt}
        </React.Fragment>
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        token: state.token!,
        user: state.user,
    }
};

export default connect(mapStateToProps)(AddUsers);
