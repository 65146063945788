import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Switch from "@material-ui/core/Switch";
import StarRatings from 'react-star-ratings';
import { toast } from 'react-toastify';

import axiosInstance from '../../../helpers/axios';
import { modules, permissions, errorMsg } from '../../../utils/constants';
import Loader from '../../../components/ui/loader/Loader';
// import { StoreState } from "../../../store";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";


interface Props {
  token: string;
  user: UserRoles;
}
const AuthorReviewView = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [data, setData] = useState({
    id: "",
    approved_by: "",
    status: true,
    book_name: "",
    rating: 5,
    description: "",
    user: "",


  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axiosInstance.get(`/books/review/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    }).then((res) => {
     
      setData(res.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsLoading(false);
      alert('Something went wrong please try again later');
    })
  }, []);

  const updateToggle = async () => {
    if (!props.user[modules.book_review].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      setIsLoading(true);
      const res = await axiosInstance.put(`/books/review/approve`, { id: data.id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      setIsLoading(false);

      setData((t) => ({ ...t, status: res.data.status, approved_by: res.data.approved_by }));
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading) {
    return <Loader />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody style={{padding:'40px 50px'}}>
                  <h6><b>Book:</b> {data.book_name}</h6>
                  <h6><b>Review By:</b> {data.user}</h6>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h6 style={{ marginTop: '8px'}}><b>Rating:</b> </h6>
                    <StarRatings
                      numberOfStars={5}
                      rating={data.rating}
                      starRatedColor="#FDCC0D"
                      starDimension="20px"
                      starSpacing="2px"
                    />
                  </div>
                  <h6><b>Approved by:</b> {data.approved_by ? data.approved_by : '--'}</h6>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h6 style={{ marginTop: '8px' }}><b>Approved:</b> </h6>
                    <Switch checked={data.status} color="primary" onChange={updateToggle} value={data.status} />
                  </div>
                  <p>{data.description}</p>
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <Link
                      to="/ebookflutter_adminpanel/book_review"
                      style={{ color: "white" }}
                    >
                      <button type="button" className="btn btn-primary">
                        Back
                      </button>
                    </Link>
                  </div>
                 
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {isLoading ? <Loader /> : null}
    </React.Fragment>
  )
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(AuthorReviewView);
