import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import swal from "sweetalert";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { MultiValue } from 'react-select';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import { toast } from 'react-toastify';
import axiosInstance from "../../helpers/axios";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import Switch from "@material-ui/core/Switch";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { useFetchHook } from '../../utils/fetchHook';
import { connect } from 'react-redux';
import { modules, permissions, errorMsg } from '../../utils/constants';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import '../eBook/style.css';
import { AxiosError } from "axios";

interface Props {
  token: string;
  user: UserRoles;
}

type AuthorType = {
  loc: String
  genre: []
  social: []
  likes: number,
  authors_id: number,
  author_first_name: String,
  author_last_name: String,
  coauthor_fname: String,
  coauthor_lname: String,
  public_phone: number,
  private_phone: number,
  author_email: String,
  goodread_url: String,
  authors_dob: String,
  member_since: Number,
  authors_bio: String
  authors_image: ImageData,
  images: String,
  cover_image: ImageData,
  lang_id: number,
  top: boolean,
  top_selling: boolean;
  profile_approval: any;
};

// Select Option
const TopAuthorOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
]

const Author = (props: Props) => {
  const location = useLocation();
  const tableRef = useRef<HTMLTableElement>(null);
  // States of SetData
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState<any>([null, null]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [colastName, setColastName] = useState('');
  const [email, setEmail] = useState('');
  const [genre, setGenre] = useState<MultiValue<{ label: string; value: number }> | null>(null);
  const [lang, setLang] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [showError, setShowError] = useState(false);
  const [topAuthor, setTopAuthor] = useState<{ value: boolean; label: string } | null>(null)
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const [pending, setPending] = useState<{ value: boolean; label: string } | null>(null);
  const [users, setUsers] = useState({
    approve: null as any,
    topAuthor: null as any,
    pending: null as any
  })

  // Custom Hook
  const [{ isLoading, isError, data }, { fetchData, setOptions, setData }] = useFetchHook
    <{ data: AuthorType[]; numberOfPages: number; total: number | null, }>
    (
      `${process.env.REACT_APP_API_URL}/v2_authors/authors`,
      { data: [], numberOfPages: 1, total: null },
      {
        params: { page },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

  // Select Option
  const approveOption = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const approveValue = searchParams.get('approve');
    if (approveValue) {
      const value = approveValue === 'true' ? true : false;
      const option = approveOption.find((o) => o.value === value.toString());
      if (option) {
        setApprove(option);
        setOptions({
          params: {
            approve: option.value
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
      }
    }
  }, [location]);

  // useEffect
  useEffect(() => {
    setOptions({
      params: {
        page,
        author_first_name: firstName,
        author_last_name: lastName,
        co_first_name: name,
        co_last_name: colastName,
        email: email,
        topauthor: topAuthor?.value,
        genre: genre?.map((i) => i.value),
        dates,
        lang: lang?.map((i) => i.value),
        approve: approve?.value,
        pending: pending?.value,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
    setCurrentPage(page + 1);
  }, [page, approve?.value]);


  const pendingOption = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  // Delete Api
  const deleteUser = async (author_id: number) => {
    if (!props.user[modules.author].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }
    try {
      const dUser = await swal({
        buttons: ['No', 'Yes'],
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Author!",
        icon: "error",
        dangerMode: true,
      });

      if (!dUser) {
        return;
      }
      const res = await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/v2_authors/${author_id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      if (res.data.error) {
        toast.error(res.data.error, { autoClose: 10000 });
        return;
      }

      fetchData();
      swal(" Your Author has been deleted!", {
        icon: "success",
      });
    } catch (e) {
      toast.error('Something went wrong! Please try again later');
    }
  };

  // Author Approval API
  const updateToggle = async (authors_id: number) => {
    if (!props.user[modules.author].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res = await axiosInstance.put(`/v2_authors/author-approval`, { authors_id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      // setIsLoading(false);
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.authors_id === authors_id) {
          toast.success(res.data.message)
          return { ...t, profile_approval: res.data.status, approved_by: res.data.approved_by };
        }
        return t;
      });

      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPages }));
    } catch (e) {
      console.log(e);
    }
  };

  const redirectEdit = (id: number) => {
    if (!props.user[modules.author].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`/ebookflutter_adminpanel/edit-author/${id}`);
  };

  const redirectAdd = () => {
    if (!props.user[modules.author].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }
    history.push('add-author');
  };

  // Top Author API
  const topSwitchChange = async (id: number) => {
    try {
      const res = await axiosInstance.put(`/v2_authors/top/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      const newState = data.data.map((u) => {
        if (u.authors_id === res.data.authors_id) {
          return { ...u, top: res.data.top };
        }

        return u;
      });
      setData((prev) => ({ ...prev, data: newState, numberOfPages: data.numberOfPages }));

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  // Genre DropDown API
  const loadGenre = async (genre: String) => {
    try {
      const gen = await axiosInstance.get('/genre/get-genre', {
        params: {
          genre: genre,
        }
      });

      return gen.data;
    } catch (e) {
    }
  };

  // Language Dropdown API
  const loadLang = async (lang: String) => {
    try {
      const language = await axiosInstance.get('/language/drop-lang', {
        params: {
          lang,
        }
      });
      return language.data;
    } catch (e) { }
  };

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };


  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (firstName.length > 0 || lastName.length > 0 || name.length > 0 || topAuthor != null || approve != null || colastName.length > 0 || email.length > 0 || genre != null || lang != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(0);
        setOptions({
          params: {
            page,
            author_first_name: firstName,
            author_last_name: lastName,
            co_first_name: name,
            co_last_name: colastName,
            email: email,
            topauthor: topAuthor?.value,
            genre: genre?.map((i) => i.value),
            lang: lang?.map((i) => i.value),
            approve: approve?.value,
            pending: pending?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      } else {
        setShowError(true);
      }
    }
  }
  useEffect(() => {
    onFilterApply(null as any, false)
  }, [users])

  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {

    if (firstName.length > 0 || lastName.length > 0 || name.length > 0 || users.topAuthor != null || users.approve != null || users.pending != null || colastName.length > 0 || email.length > 0 || genre != null || lang != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setPage(0);
      setOptions({
        params: {
          page,
          author_first_name: firstName,
          author_last_name: lastName,
          co_first_name: name,
          co_last_name: colastName,
          email: email,
          topauthor: users?.topAuthor?.value,
          genre: genre?.map((i) => i.value),
          lang: lang?.map((i) => i.value),
          approve: users?.approve?.value,
          pending: users?.pending?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }

    }
  };


  // Filter


  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowError(false);
    setFirstName('');
    setLastName('');
    setName('');
    setApprove(null);
    setPending(null);
    setColastName('');
    setEmail('');
    setTopAuthor(null);
    setGenre(null);
    setLang(null);
    setDates([null, null])
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  if (isError) {
    return (
      <p>Something went wrong please try again later</p>
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">AUTHOR LIST ({data.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button className="custom-btn" variant="contained"
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        ADD AUTHOR
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Author First Name" value={firstName} onKeyPress={handleKeyPress} onChange={setter(setFirstName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder=" Author Last Name" value={lastName} onKeyPress={handleKeyPress} onChange={setter(setLastName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="CO AUTHOR First Name" value={name} onKeyPress={handleKeyPress} onChange={setter(setName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder=" CO AUTHOR Last Name" value={colastName} onKeyPress={handleKeyPress} onChange={setter(setColastName)} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Email" value={email} onKeyPress={handleKeyPress} onChange={setter(setEmail)} />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Genre" isMulti value={genre} cacheOptions defaultOptions loadOptions={loadGenre} onChange={setGenre} />
                    </Col>
                    <Col xs={3}>
                      <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <AsyncSelect placeholder="Language" isMulti value={lang} cacheOptions defaultOptions loadOptions={loadLang} onChange={setLang} />
                      </div>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <Select placeholder="Top Author" options={TopAuthorOptions} value={topAuthor}
                        onChange={(value) => {
                          setTopAuthor(value);
                          setUsers({ ...users, topAuthor: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approved" options={approveOption} value={approve} defaultValue={approve}
                        onChange={(value) => {
                          setApprove(value);
                          setUsers({ ...users, approve: value })
                        }}
                      />
                    </Col>

                    <Col xs={3}>
                      <Select placeholder="Author Pending Profile" options={pendingOption} value={pending}
                        onChange={(value) => {
                          setPending(value);
                          setUsers({ ...users, pending: value })
                        }}
                      />
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>AUTHOR NAME</TableCell>
                          <TableCell>PROFILE PICTURE</TableCell>
                          <TableCell>EMAIL</TableCell>
                          <TableCell>PHONE NUMBER</TableCell>
                          <TableCell>LIKES</TableCell>
                          <TableCell>APPROVAL</TableCell>
                          <TableCell>TOP</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.authors_id}>
                              <TableCell>{(page * 25) + index + 1}</TableCell>
                              <TableCell>
                                {user.author_first_name} {user.author_last_name}
                              </TableCell>
                              <TableCell>
                                <img
                                  style={{ height: "50px", width: "80px" }}
                                  src={
                                    user.cover_image ? `${process.env.REACT_APP_S3_APP_API}/author/${user.cover_image}` :
                                      `${process.env.REACT_APP_S3_APP_API}/author/author.png`
                                  }
                                  alt="author"
                                />
                              </TableCell>
                              <TableCell>{user.author_email || '--'}</TableCell>
                              <TableCell>{user.public_phone || '--'}</TableCell>
                              <TableCell>{user.likes}</TableCell>
                              <TableCell>
                                <Switch checked={user.profile_approval} color="primary" onChange={() => updateToggle(user.authors_id)} />
                              </TableCell>

                              <TableCell>
                                <Switch checked={user.top} color="primary" onChange={() => topSwitchChange(user.authors_id)} />
                              </TableCell>
                              <TableCell>
                                <div className="innercell">
                                  <Link
                                    to={`/ebookflutter_adminpanel/view-author/${user.authors_id}`}
                                  >
                                    <VisibilityIcon />
                                  </Link>
                                  <IconButton
                                    aria-label="edit"
                                    color="primary"
                                    onClick={() => redirectEdit(user.authors_id)}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <Link
                                    to="#"
                                    onClick={() => deleteUser(user.authors_id)}
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPages}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value - 1)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
      <div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};


export default connect(mapStateToProps)(Author);
