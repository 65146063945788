import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import AsyncSelect from 'react-select/async';
import StarRatings from 'react-star-ratings';
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import { connect } from 'react-redux';
import Select from 'react-select';
import VisibilityIcon from "@material-ui/icons/Visibility";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { toast } from "react-toastify";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { useFetchHook } from "../../utils/fetchHook";
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { AxiosError } from "axios";
import '../eBook/style.css'

// props Declaration
interface Props {
  token: string;
  user: UserRoles;
}
// API data format
type BookReviewType = {
  rating: number,
  description: string,
  book_id: number,
  user_id: number,
  status: any,
  id: number,
  approved_by: number,
  book_name: string,
  approve: string;
  user: string,

}
const BookReview = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);

  // States for SetData
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState<any>([null, null]);
  const [bookName, setBookName] = useState('');
  const [userName, setUserName] = useState('');
  const [rating, setRating] = useState<{ value: string; label: string } | null>(null);
  const [users, setApproveby] = useState<{ value: string; label: string } | null>(null);
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [isExpanded, setIsExpanded] = useState([] as any);

  const[newUsers,setNewusers]=useState({
    approve:null as any,
    rating:null as any,
    users:null as any,
  })
  const limit = 50;
  // Hook
  const [{ isLoading, isError, data }, { setOptions, setData }] = useFetchHook
    <{ data: BookReviewType[]; numberOfPage: number; total: number | null, }>
    (`${process.env.REACT_APP_API_URL}/books/review`,
      { data: [], numberOfPage: 1, total: null },
      {
        params: { page },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      useEffect(()=>{
        onFilterApply(null as any, false)
      },[newUsers])

// UseEffect
  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          userName: userName,
          rating: rating?.value,
          approve: approve?.value,
          users: users?.value,
          bookName: bookName,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page]);

  // Select Option
  const ratingOptions = [
    {
      label: "One Star",
      value: "1",
    },
    {
      label: "Two Star",
      value: "2",
    },
    {
      label: "Three Star",
      value: "3",
    },
    {
      label: "Four star",
      value: "4",
    },
    {
      label: "Five star",
      value: "5",
    },

  ];

  const approvedOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  if (isError) {
    return (
      <p>Something went wrong please try again later</p>
    );
  }

  // Book Approval API
  const updateToggle = async (id: number) => {
    if (!props.user[modules.book_review].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    try {
      // setIsLoading(true);
      const res = await axiosInstance.put(`/books/review/approve`, { id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      // setIsLoading(false);
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.id === id) {
          return { ...t, status: res.data.status, approved_by: res.data.approved_by };
        }
        return t;
      });
      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
      // setData({ data: newData, numberOfPage: data.numberOfPage });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  const redirect = (id: number) => {
    if (!props.user[modules.author_review].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }
    history.push(`/ebookflutter_adminpanel/view/book_review/${id}`);
  }

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (userName.length > 0 || bookName.length > 0 || rating != null || users != null || approve != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(1);
        setOptions({
          params: {
            page,
            userName: userName,
            rating: rating?.value,
            approve: approve?.value,
            users: users?.value,
            bookName: bookName,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }}

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement> ,isFilterapply: boolean) => {
    if (userName.length > 0 || bookName.length > 0 || rating != null || users != null || approve != null || (dates[0] != null && dates[1] != null)) {
      if(e !== null){
        e.preventDefault();
      }
      setShowError(false);
      setPage(1);
      setOptions({
        params: {
          page,
          userName: userName,
          rating: rating?.value,
          approve: approve?.value,
          users: users?.value,
          bookName: bookName,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setUserName('');
    setShowError(false);
    setRating(null);
    setApprove(null);
    setApproveby(null);
    setDates([null, null]);
    setBookName('');
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  // Approval API DropDown
  const loadApprovedby = async (users: String) => {
    try {
      const pubs = await axiosInstance.get('/users/drop-users', {
        params: {
          users,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      return pubs.data;
    } catch (e) { }
  };

  const copyTable = () => {
		const table = tableRef.current;
		if (table) {
			const range = document.createRange();
			range.selectNode(table);
			window.getSelection()?.removeAllRanges();
			window.getSelection()?.addRange(range);
			document.execCommand('copy');
			window.getSelection()?.removeAllRanges();
			toast.info("Copied To Clipboard")
		}
	}

	const exportToExcel = () => {
		const table = document.querySelector('table');
		const html = table?.outerHTML;
		if (html) {
			const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
			const dataUrl = URL.createObjectURL(blob);
			const downloadLink = document.createElement('a');
			downloadLink.href = dataUrl;
			downloadLink.download = 'table.xls';
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(dataUrl);
		}
	}

	const convertTableToCSV = (table:any) => {
		let csv = '';
		const rows = table.querySelectorAll('tr');
		for (let i = 0; i < rows.length; i++) {
		  const cells = rows[i].querySelectorAll('th, td');
		  for (let j = 0; j < cells.length; j++) {
			csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
		  }
		}
		return csv;
	  };


	const handleDownloadCSV = () => {
		const tableNode = document.getElementById('table-data');
		const csvData = convertTableToCSV(tableNode);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
		downloadLink.download = 'table-data.csv';
		downloadLink.click();
	  };

    const removeElement = (id: any) => {
      const temp = [...isExpanded];
      temp.splice(temp.indexOf(id), 1);
      setIsExpanded(temp);
    };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">BOOK REVIEW LIST ({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Book Name" name="bookname" value={bookName}  onKeyPress ={handleKeyPress} onChange={setter(setBookName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="User Name" name="username" value={userName}   onKeyPress ={handleKeyPress} onChange={setter(setUserName)} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Rating" name="rating" options={ratingOptions} value={rating}
                       onChange={(value)=>{
												setRating(value);
                        setNewusers({...newUsers, rating: value})
											}}
                       />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approve" options={approvedOptions} value={approve}  onChange={(value)=>{
												setApprove(value);
                        setNewusers({...newUsers, approve: value})
											}} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <AsyncSelect placeholder="All Users" value={users} name="users"
                        cacheOptions defaultOptions loadOptions={loadApprovedby}
                        onChange={(value)=>{
                          setApproveby(value);
                          setNewusers({...newUsers, users: value})
                        }}  />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range"  onKeyPress ={handleKeyPress} format="MM/dd/yyyy" onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
									<button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
									<button className="btn btn-outline-primary mr-2"  onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                  <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>USER</TableCell>
                          <TableCell>DESCRIPTION</TableCell>
                          <TableCell>BOOK NAME</TableCell>
                          <TableCell>RATING</TableCell>
                          <TableCell>APPROVED</TableCell>
                          <TableCell>APPROVED BY</TableCell>
                          <TableCell>VIEW</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((t, index) => (
                          <TableRow key={t.id}>
                            <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                            <TableCell>{t.user}</TableCell>
                            {/* <TableCell>{t.description}</TableCell> */}
                            <TableCell>{t.description.length < 50 ? t.description : <>
                                <div style={{ width: "500px" }}>
                                  {isExpanded.includes(t.id) ? t.description : t.description.slice(0, limit) + '...'}
                                  {t.description.length > limit && (
                                    <button className="desc-btn" onClick={() => {
                                      isExpanded.includes(t?.id) ? removeElement(t?.id) : setIsExpanded([...isExpanded, t?.id])
                                    }}>
                                      {isExpanded.includes(t?.id) ? 'Read Less' : 'Read More'}
                                    </button>

                                  )}</div>

                              </>}</TableCell>
                            <TableCell  style={{width:"300px"}}>{t.book_name}</TableCell>
                            <TableCell>
                              <StarRatings
                                numberOfStars={5}
                                rating={t.rating}
                                starRatedColor="#FDCC0D"
                                starDimension="20px"
                                starSpacing="2px"
                              />
                            </TableCell>
                            <TableCell>
                              <Switch checked={t.status} color="primary" onChange={() => updateToggle(t.id)} />
                            </TableCell>
                            <TableCell>
                              {t.approved_by ? t.approved_by : '--'}
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton color="primary" onClick={() => redirect(t.id)}>
                                  <VisibilityIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage - 1}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(BookReview);
