
import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import SettingsBackupRestoreTwoToneIcon from '@mui/icons-material/SettingsBackupRestoreTwoTone';
import "../../eBook/style.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditGenreLog = () => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const token = useSelector((state: any) => state.token);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [data, setData] = useState([]) as any;

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/log/admin-logs/${id}?page=1&modulename=genre&action=update&author_name=&dates[]=null&dates[]=null`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => toast.error(error));

  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className='container-fluid'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            LOGS ACTIVITY
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                {data?.data?.map((t: any, index: any) => (
                  <>
                    <TableRow className='row-sec' key={index}>
                      <TableRow className='row-sec'>
                        <TableCell width={200}><span>{`${new Date(t.createdAt).getDate() + "  "} - ${new Date(t.createdAt).getMonth() + 1} - ${new Date(t.createdAt).getFullYear()}`} </span> </TableCell>
                        <TableCell width={200}><div className="border-section"> </div> </TableCell>
                      </TableRow>
                      <TableRow className='row-sec bg-new'>
                        <TableCell width={400}>Genre Name:{t.values["Genre Name"]}</TableCell>
                        <TableCell width={400}>Updated By:{t.action_performed_by.name}</TableCell>
                        <TableCell width={400}><SettingsBackupRestoreTwoToneIcon /></TableCell>
                      </TableRow>
                    </TableRow></>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default EditGenreLog;