import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./datatables.scss";
import Switch from "@material-ui/core/Switch";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { UserRoles } from '../../store/auth/admins/user';
import { StoreState } from '../../store';
import { Pagination } from "@material-ui/lab";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { toast } from "react-toastify";


interface Props {
  token: string;
  user: UserRoles;
}

// API data format
type CategoryType = {
  category_id: number;
  category_name: string;
  noOfBooks: number;
  image: string;
  top: boolean;
};

// States Of SetData
const Category = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [dates, setDates] = useState<any>([null, null]);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);

  // Custom Hook For API
  const [{ isLoading, data }, { setOptions }] =
    useFetchHook<{
      data: CategoryType[], numberOfPage: number, total: number | null,
    }>(`${process.env.REACT_APP_API_URL}/categories/get-category`,
      { data: [], numberOfPage: 1, total: null },
      {
        params: { page, top: true },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });


  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          name: name,
          dates: dates,
          top: true,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);

    }
  }, [page]);

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(1);
        setOptions({
          params: {
            page: 1,
            name: name,
            dates: dates,
            top: true,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);

      }
    }
  }

  const copyTable = () => {
		const table = tableRef.current;
		if (table) {
			const range = document.createRange();
			range.selectNode(table);
			window.getSelection()?.removeAllRanges();
			window.getSelection()?.addRange(range);
			document.execCommand('copy');
			window.getSelection()?.removeAllRanges();
			toast.info("Copied To Clipboard")
		}
	}

	const exportToExcel = () => {
		const table = document.querySelector('table');
		const html = table?.outerHTML;
		if (html) {
			const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
			const dataUrl = URL.createObjectURL(blob);
			const downloadLink = document.createElement('a');
			downloadLink.href = dataUrl;
			downloadLink.download = 'table.xls';
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(dataUrl);
		}
	}

	const convertTableToCSV = (table:any) => {
		let csv = '';
		const rows = table.querySelectorAll('tr');
		for (let i = 0; i < rows.length; i++) {
		  const cells = rows[i].querySelectorAll('th, td');
		  for (let j = 0; j < cells.length; j++) {
			csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
		  }
		}
		return csv;
	  };


	const handleDownloadCSV = () => {
		const tableNode = document.getElementById('table-data');
		const csvData = convertTableToCSV(tableNode);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
		downloadLink.download = 'table-data.csv';
		downloadLink.click();
	  };

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
      e.preventDefault();
      setShowError(false);
      setPage(1);
      setOptions({
        params: {
          page: 1,
          name: name,
          dates: dates,
          top: true,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setShowError(true);

    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setName('');
    setDates([null, null])
    setShowError(false);

    setOptions({
      params: {
        page,
        top: true,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>

                      <h4 className="card-title"> TOP CATEGORY({data.total})</h4>

                    </Col>
                  </Row>
                  <hr />
                  <Row>

                    <Col xs={3}>
                      <Input placeholder="Category Name" value={name}  onKeyPress ={handleKeyPress} onChange={setter(setName)} />
                    </Col>

                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress ={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>

                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
									<button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
									<button className="btn btn-outline-primary mr-2"  onClick={handleDownloadCSV}>CSV</button>

                  <TableContainer component={Paper}>
										<Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>IMAGE</TableCell>
                          <TableCell>CATEGORY NAME</TableCell>
                          <TableCell>TOP</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.category_id}>
                              <TableCell>{(index + 1) + (25 * (page - 1))}</TableCell>
                              <TableCell>
                                <img
                                  className="rounded-circle avatar-sm"
                                  // style={{ height: "50px", width: "80px" }}
                                  src={
                                    user.image ? `${process.env.REACT_APP_S3_APP_API}/category/${user.image}` : `${process.env.REACT_APP_S3_APP_API}/demobook`
                                  }
                                  alt="profile"
                                />
                              </TableCell>
                              <TableCell>{user.category_name}</TableCell>

                              <TableCell>
                                <Switch checked={user.top} color="primary" disabled />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    <Pagination
                      count={data.numberOfPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      // page={currentPage}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

// Redux
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Category);
