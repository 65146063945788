import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Select from 'react-select';
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import axiosInstance from "../../helpers/axios";
import swal from "sweetalert";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { StoreState } from "../../store";
import { UserRoles } from "../../store/auth/admins/user";
import { AxiosError } from "axios";
import Modal from 'react-modal';
import '../eBook/style.css'
import validator from 'validator';
import UnSavedChanges from '../eBook/Add/UnSavedChanges';
import Loader from "../../components/ui/loader/Loader";

// Props
interface Props {
  token: string;
  user: UserRoles;
}

const AddAdminUser = (props: Props) => {
  // States of Set Data
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [note, setNote] = useState('');
  const [role, setRole] = useState<any>('');
  const [roleData, setRoleData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    nameError: "" as any,
    emailError: "" as any,
    passwordError: "" as any,
    roleError: [] as any,
  });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  let subtitle: any;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [input, setInput] = useState({
    username: '' as any,
    password: '' as any,
    confirmPassword: '' as any,
  });
  const [error, setError] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  })

  // useEffect
  useEffect(() => {
    fetchRoles();
    fetchUser();
  }, []);

  // CSS
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '540px',
      padding: '60px',
    },
  };

  // Get API
  const fetchUser = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/admin/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });

        setName(res.data.name);
        setEmail(res.data.email);
        setNote(res.data.note);
        setRole({
          label: res.data.role_name,
          value: res.data.role_id,
        });
      } catch (e) {
      }
    }
  };

  // Function of Empty State
  const flush = () => {
    setName('');
    setEmail('');
    setNote('');
    setPassword('');
    setRole('');
  };

  // Get API
  const fetchRoles = async () => {
    try {
      const res = await axiosInstance.get('/roles', {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      const data = res.data.data.map((t: any) => ({ value: t.role_id, label: t.name }));
      setRoleData(data);
    } catch (e) {
      console.log(e);
    }
  };

  // Put API
  const update = async () => {
    const err = [] as any;

    if (!name) {
      err.nameError = 'Enter Name';
    } else {
      setErrors({ ...errors, nameError: "" })
    }

    if (!email) {
      err.emailError = 'Enter valid E-mail';
    } else {
      setErrors({ ...errors, nameError: "" })
    }

    // if (!password) {
    //   err.passwordError = 'Enter Password';
    // } else if (password.length <= 5) {
    //   err.passwordError = 'Password Too Short';
    // }else {
    //   setErrors({ ...errors, passwordError: "" })
    // }

    if (!role) {
      err.roleError = '*Enter Role';
    }

    // if (name.trim().length === 0) {
    //   err.nameError = 'Enter Name';
    // }

    // if (email.trim().length === 0) {
    //   err.emailError = 'Enter valid E-mail';
    // }

    // if (!role) {
    //   err.roleError = '*Please Enter Valid Role';
    // }
    if (err.emailError || err.nameError || err.passwordError || err.roleError) {
      setErrors(err);
      return;
    } else {
      setErrors([] as any);
    }

    const data = { name, email, note, role_id: role.value };
    try {
      await axiosInstance.put(`/admin/${id}`, data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      swal({
        title: "User Updated Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };
  // Post API
  const post = async () => {
    const err = [] as any;
    // const role_error = [] as any;
    // if (name.trim().length === 0) {
    //   err.nameError = 'Enter Name';
    // }
    if (!name) {
      err.nameError = 'Enter Name';
    } else {
      setErrors({ ...errors, nameError: "" })
    }

    if (!email) {
      err.emailError = 'Enter valid E-mail';
    } else {
      setErrors({ ...errors, nameError: "" })
    }
    // if (email.trim().length === 0) {
    //   err.emailError = 'Enter valid E-mail';
    // }

    if (!password) {
      err.passwordError = 'Enter Password';
    } else if (password.length <= 5) {
      err.passwordError = 'Password Too Short';
    } else {
      setErrors({ ...errors, passwordError: "" })
    }
    // if (password.trim().length === 0) {
    //   err.passwordError = ' Enter Password';
    // } else if (password.length <= 5) {
    //   err.passwordError = 'Password Too Short';
    // }
    if (!role) {
      err.roleError = '*Enter Role';
    }

    if (err.emailError || err.nameError || err.passwordError || err.roleError) {
      setErrors(err);
      return;
    } else {
      setErrors([] as any);
    }

    const data = { name, email, password, note, role_id: role.value };

    try {
      await axiosInstance.post('/admin/register', data, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      flush();
      swal({
        title: "User Added Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  // OnInput Change Set Target Value
  const onInputChange = (e: any) => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    setIsSaved() as any;
    validateInput(e);
  }

  // Password and Confirm Password Validation
  const validateInput = (e: any) => {
    let { name, value } = e.target as any;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" } as any;

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please Enter Username.";
          }
          break;

        case "password":
          if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {
            stateObj[name] = "please Enter Strong Password";
          } else if (!value) {
            stateObj[name] = "Please Enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password Does Not Match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please Enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password And Confirm Password Does Not Match.";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  }

  // OnSubmit Funcation
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      update();
    } else {
      post();
    }
  };

  // Model Functions
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  // Change Password API
  const passwordSubmit = async (e: any) => {

    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/admin/changepassword/${id}`,
        {

          change_password: input.password,
          confirm_password: input.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      if (res?.data?.success === 0) {
        setInput({ ...input });
        return;
      }
      setIsLoading(false);
      swal({
        title: "Password Successfully Change!",
        icon: "success",
      });

    } catch (e) {
      setIsLoading(false);
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      } else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };


  return (
    <React.Fragment>
      <div className="page-content" >
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id !== undefined ? "UPDATE ADMIN USER" : "ADD ADMIN USER"}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={name}
                            id="exampleFormControlInput1"
                            placeholder="Enter name"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue2 = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

                              setName(e.target.value)
                              setIsSaved() as any;

                              if ((selectedValue2.value.length)) {
                                setErrors({ ...errors, nameError: "" })
                              } else {
                                setErrors({ ...errors, nameError: "Enter Name" });

                              }
                              // if (!e.target.value || !name) {
                              //   setErrors({ ...errors, nameError: "Enter Name" });
                              // } else {
                              //   setErrors({ ...errors, nameError: "" })
                              // }
                            }
                            }
                          />
                          {errors.nameError ? (<ErrorSpan error={errors.nameError} />) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>E-mail*</label>
                          <input
                            type="email"
                            className="form-control"
                            name="authors_bio"
                            id="exampleFormControlInput2"
                            placeholder="Enter E-mail"
                            value={email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue2 = document.getElementById('exampleFormControlInput2') as HTMLInputElement;
                              setEmail(e.target.value)
                              setIsSaved() as any;

                              if ((selectedValue2.value.length)) {
                                setErrors({ ...errors, emailError: "" })
                              } else {
                                setErrors({ ...errors, emailError: "Enter Valid E-mail" });

                              }
                              // if (!e.target.value || !email) {
                              //   setErrors({ ...errors, emailError: "Enter Valid E-mail" });
                              // } else {
                              //   setErrors({ ...errors, emailError: "" })
                              // }
                            }
                            }
                          />
                          {errors.emailError ? (<ErrorSpan error={errors.emailError} />) : null}
                        </div>
                      </Col>

                      {id ? null : (
                        <Col xs={6}>
                          <div className="form-group">
                            <label>PASSWORD*</label>
                            <input
                              type="password"
                              className="form-control"
                              name="category_name"
                              value={password}
                              id="exampleFormControlInput3"
                              placeholder="Enter password"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const selectedValue2 = document.getElementById('exampleFormControlInput3') as HTMLInputElement;
                                setPassword(e.target.value)
                                setIsSaved() as any;

                                if ((selectedValue2.value.length)) {
                                  setErrors({ ...errors, passwordError: "" })
                                } else {
                                  setErrors({ ...errors, passwordError: "Enter Password" });

                                }
                                // if (!e.target.value || password.trim().length === 0) {
                                //   setErrors({ ...errors, passwordError: "Enter Password" });
                                // } else if (password.length <= 5) {
                                //   setErrors({ ...errors, passwordError: "Password Too Short" });
                                // } else {
                                //   setErrors({ ...errors, passwordError: "" })
                                // }
                              }}
                            />
                            {errors.passwordError ? (<ErrorSpan error={errors.passwordError} />) : null}
                          </div>
                        </Col>
                      )}

                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE FOR USER*</label>
                          <Select
                            className="select"
                            name="publisher_id"
                            placeholder="Select Role"
                            value={role}
                            onChange={(e) => {
                              setRole(e)
                              setIsSaved() as any;
                              setErrors({ ...errors, roleError: [] })
                            }}

                            options={roleData}
                          />
                          {errors.roleError && <span className="error" style={{ display: 'block' }}>
                            {errors.roleError}
                          </span>}

                          {/* {errors.roleError ? (<ErrorSpan error={errors.roleError} />) : null} */}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ADD NOTE</label>
                          <textarea
                            typeof="textarea"
                            className="form-control"
                            name="authors_bio"
                            id="exampleFormControlInput1"
                            placeholder="Add Note"
                            value={note}
                            onChange={(e) => {
                              setNote(e.target.value)
                              setIsSaved() as any;
                            }}
                          />
                        </div>
                      </Col>
                      {id !== undefined ?
                        <Col xs={6}>
                          <div className="form-group">
                            <br />
                            <button type="button"
                              className="password-btn"
                              onClick={openModal}>
                              Change Password
                            </button>
                          </div>
                        </Col> : null}
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}

                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link to="/ebookflutter_adminpanel/admin_user"
                            style={{ color: "white" }}>
                            <button type="button" className="btn btn-primary"
                            >
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <form className="modal-passwd"
                      onClick={(e) => passwordSubmit(e)}
                    >
                      <h3 ref={(_subtitle) => (subtitle = _subtitle)}>PassWord</h3>
                      <input
                        className="modal-input"
                        type="password"
                        name="password"
                        placeholder='Enter Password'
                        value={input.password}
                        onChange={onInputChange}
                        onBlur={validateInput}
                      />
                      {error.password ? (
                        <ErrorSpan error={error.password} />
                      ) : null}
                      <br /><br />
                      <h3 >Confirm PassWord</h3>

                      <input
                        className="modal-input"
                        type="password"
                        name="confirmPassword"
                        placeholder='Enter Confirm Password'
                        value={input.confirmPassword}
                        onChange={onInputChange}
                        onBlur={validateInput}
                      />
                      {error.confirmPassword ? (
                        <ErrorSpan error={error.confirmPassword} />
                      ) : null}

                      <div className="submit-section text-center mt-3">
                        <button className="modal-submit" onClick={closeModal}>Update</button></div>
                    </form>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(AddAdminUser);
