import React, { useEffect, useState ,useRef} from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import "./datatables.scss";
import { Pagination } from "@material-ui/lab";
import axiosInstance from "../../helpers/axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import SearchIcon from "@material-ui/icons/Search";
// import InputBase from "@material-ui/core/InputBase";
import swal from "sweetalert";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { useFetchHook } from "../../utils/fetchHook";
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

interface Props {
  token: string;
  user: UserRoles;
}

type GenreType = {
  success: number,
  page: number,
  genre_id: number,
  geners_name: String,
}

const Genre = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);

  const [{ isLoading, data }, { setIsLoading, setOptions, fetchData }] =
    useFetchHook
      <{ data: GenreType[]; numberOfPage: number; total: number | null, }>
      (`${process.env.REACT_APP_API_URL}/genre/genre/`,
        { data: [], numberOfPage: 1, total: null },
        {
          params: { page },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });


  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          name: name,
          dates: dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      setPagedata(true);
    }
  }, [page]);

  const deleteUser = async (genre_id: number) => {
    if (!props.user[modules.genre].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      const confirm = await swal({
        buttons: ['No', 'Yes'],
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Category Name!",
        icon: "error",
        dangerMode: true,

      });

      if (!confirm) {
        return;
      }

      setIsLoading(true);
      const res = await axiosInstance.delete(
        `${process.env.REACT_APP_API_URL}/genre/${genre_id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }
      );
      setIsLoading(false);

      if (res.data.error) {
        toast.error(res.data.error, { autoClose: 10000 });
        return;
      }

      fetchData();

      swal("Your Genre has been deleted!", {
        icon: "success",
      });
    } catch (e) {
      toast.error('Something went wrong. Please try again later');
    }


  };
  useEffect(() => {

  }, [isLoading]);

  const redirectEdit = (id: number) => {
    if (!props.user[modules.genre].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    //history.push(`/ebookflutter_adminpanel/edit-genre/${id}`);
    window.open(`/ebookflutter_adminpanel/edit-genre/${id}`);

  };

  const redirectAdd = () => {
    if (!props.user[modules.genre].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    history.push('add-genre');
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };


  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);

        setPage(1);

        setOptions({
          params: {
            page: 1,
            name: name,
            dates: dates,

          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });

      } else {
        setShowError(true);
      }
    }}

  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
      e.preventDefault();
      setShowError(false);

      setPage(1);

      setOptions({
        params: {
          page: 1,
          name: name,
          dates: dates,

        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

    } else {
      setShowError(true);
    }
  };

  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setName('');
    setDates([null, null]);
    setShowError(false);
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }


  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">GENRE LIST ({data.total})</h4>

                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        style={{ color: "blue" }}
                        onClick={redirectAdd}
                      >
                        Add GENRE
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Genre Name" value={name}  onKeyPress ={handleKeyPress} onChange={setter(setName)} />

                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy"  onKeyPress ={handleKeyPress} onChange={setDates} value={dates} />
                      </div>

                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                    <div className='control-pane'>
                      <div className='control-section'>
                        <div className='daterangepicker-control-section'>

                        </div>
                      </div>
                    </div>

                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>GENRE NAME</TableCell>

                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.genre_id}>
                              <TableCell>{(index + 1) + (25 * (page - 1))}</TableCell>
                              <TableCell>{user.geners_name}</TableCell>

                              <TableCell>
                                <div className="innercell">
                                  <IconButton color="primary" onClick={() => redirectEdit(user.genre_id)}>
                                    <EditIcon />
                                  </IconButton>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      deleteUser(user.genre_id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      // count={allPageCount}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(Genre);
