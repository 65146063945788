import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import styled from 'styled-components';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import DraftToHtml from 'draftjs-to-html';
import HtmlToDraft from "html-to-draftjs";
import Select, { StylesConfig } from 'react-select';
import { ChromePicker } from 'react-color';
import axiosInstance from '../../../helpers/axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Image from "../../../components/Common/Image";
import Switch from "@material-ui/core/Switch";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { connect } from 'react-redux';
import UnSavedChanges from './UnSavedChanges';
import '../../eBook/style.css'
import EditFeedLog from '../Edit/EditFeedLog';
// import { AxiosError } from 'axios';


interface Props {
  token: string;
  user: UserRoles;
}
const ImageDiv = styled.div`
  height: 100px;
  width: 100px;
  border: 2px solid black;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = {
  color: {
    width: '50px',
    height: '50px',
    borderRadius: '2px',
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.4)',
    display: 'inline-block',
    cursor: 'pointer',
  },
  popover: {
    position: 'absolute',
    zIndex: '2',
  } as React.CSSProperties,
  cover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  } as React.CSSProperties,
};

const typeOptions = [
  { value: 'txt', label: 'Text' },
  { value: 'img', label: 'Image' }
];

// const typeOptionss = [
//   { value: 'txt', label: 'Text' },
//   { value: 'img', label: 'Image' }
// ];

const linkOptions = [
  { value: 'author', label: 'Author' },
  { value: 'book', label: 'Book' },
  { value: null, label: 'None' }
];

const textOptions = [
  { value: 'color', label: 'Color' },
  { value: 'image', label: 'Image' },
];

const AddFeed = (props: Props) => {
  const { id } = useParams() as {
    id?: any;
  }

  const [editorState, onEditorStateChange] = useState(EditorState.createEmpty());
  const [type, setType] = useState([] as any);
  const [linkType, setLinkType] = useState([] as any);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [templateVisible, setTemplateVisible] = useState(false);

  const [authorData, setAuthorData] = useState<any>({ data: [], loaded: false });
  // const [authorData, setAuthorData] = useState();
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;

  const [bookData, setBookData] = useState<any>({ data: [], loaded: false });
  const [templateImages, setTemplateImages] = useState([]);
  const [categoryData, setCategoryData] = useState([]);

  const [author, setAuthor] = useState("" as any);
  const [book, setBook] = useState("" as any);
  const [category, setCategory] = useState([] as any);
  const [active, setActive] = useState(false);
  const [likes, setLikes] = useState(null);

  const [images, setImages] = useState([] as any);
  const [nImage, setNImages] = useState([] as any);
  // value:"value"
  const [feedImage, setFeedImage] = useState({ value: "img" });



  const [color, setColor] = useState('#2B2929');
  const [currentTemplate, setCurrentTemplate] = useState(null);

  const [textType, setTextType] = useState([] as any);

  const token = useSelector((state: any) => state.token);
  // let userData = useSelector((state: RootState) => {
  //   return state.user.data;
  // });

  const imgRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    axiosInstance.get('/feed/templates')
      .then((res) => setTemplateImages(res.data)
      )
      .catch((e) => { console.log(e) })

    axiosInstance.get('/categories/drop-category',
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      })
      .then((res) => {

        const tmp = res.data.map((i: any) => ({
          value: i.value, label: i.label
        })

        );
        setCategoryData(tmp);
      })
      .catch((e) => console.log(e));


    if (id) {
      axiosInstance.get(`/feed/get-one/${id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      })
        .then((res) => {
          let type = '';
          setCategory(res.data.category);
          setLikes(res.data.likes);
          setActive(res.data.active);
          if (res.data.images.length > 0) {
            setType(typeOptions[1]);
            type = 'img';
          } else {
            setType(typeOptions[0]);
            type = 'txt';
          }
          // setColor(res.data.background);
          setLinkType(linkOptions.find((v) => v.value === res.data.link_type));

          if (res.data.book) {
            setBook(res.data.book);
          }

          if (res.data.back_image === null && type === 'txt') {
            setTextType({ value: "color", label: "Color" })
            setColor(res.data.background)
          }
          if (res.data.back_image != null && type === 'txt') {
            setTextType({ value: "image", label: "Image" })
            setCurrentTemplate(res.data.back_image)
            setNImages(res.data.back_image)
          }

          if (res.data.author) {
            setAuthor(res.data.author);
          }

          if (res.data.images) {
            setNImages(res.data.images);
          }

          if (res.data.text) {
            const blocksFromHtml = HtmlToDraft(res.data.text);
            const { entityMap, contentBlocks } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            onEditorStateChange(EditorState.createWithContent(contentState));
          }
        })
        .catch((e) => console.log(e))
    }
  }, []);

  useEffect(() => {
    if (linkType?.value === 'author' && !authorData.loaded) {
      axiosInstance.get('/v2_authors/get-author', {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((res) => {
        setAuthorData(res.data);
      }).catch(() => toast.error('Unable to load data'));
    }

    if (linkType?.value === 'book' && !bookData.loaded) {
      axiosInstance.get(`/books/drop-books`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }).then((res) => {
        setBookData(res.data);
      }).catch(() => toast.error('Unable to load data'))
    }
  }, [linkType]);

  const onImageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = [] as any;
      Array.from(e.target.files).forEach((file: any) => {
        if (file.name.match(/\.(png|jpeg|jpg)$/i)) {
          files.push(file);
        }
      });
      setImages((prev: any) => [...prev, ...files]);
    }
  };

  const toggleTemplate = () => {
    setTemplateVisible((prev) => !prev);
  };

  const renderContent = () => {
    if (type?.value === 'txt') {
      return (
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          toolbar={{
            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'remove'],
          }}
          onEditorStateChange={onEditorStateChange}
        // name="chapter_content"
        />
      )
    }

    if (type?.value === 'img') {
      return (
        <Row>
          {nImage.map((img: any) => {
            return (
              <Col xs="auto">
                <Image
                  src={`${process.env.REACT_APP_S3_APP_API}/feeds/${img}`}
                  height="100px"
                  width="100px"
                  alt="cover"
                  onPress={() => {
                    const newArr = nImage.filter(
                      (item: any) => item !== img
                    );
                    setNImages(newArr);
                  }}
                />
              </Col>
            )
          }
          )}
          {images.map((img: any) => {
            return (
              <Col xs="auto">
                <Image
                  src={URL.createObjectURL(img)}
                  height="100px"
                  width="100px"
                  alt="cover"
                  onPress={() => {
                    const newArr = images.filter(
                      (file: { name: any; lastModified: any; }) =>
                        file.name !== img.name &&
                        file.lastModified !== img.lastModified
                    );
                    setImages(newArr);
                  }}
                />
              </Col>
            )
          })}
          <input type="file" accept="image/*" max={10} multiple style={{ display: 'none' }} ref={imgRef} onChange={onImageInputChange} />
          <Col xs={3}>
            <ImageDiv onClick={(e) => {
              e.preventDefault();

              if (imgRef != null) {
                imgRef?.current?.click();
              }
            }}>
              <span style={{ fontSize: '35px', fontWeight: 'bold' }}>+</span>
            </ImageDiv>
          </Col>
        </Row>
      );
    }

    return (
      <h3>Please select feed type first</h3>
    )
  }

  const toggle = async () => {
    try {
      const res = await axiosInstance.put('/feed/active', { feed_id: parseInt(id, 10) },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });


      setActive(res.data.active);
    } catch (e) {
      toast.error('Unable to update. Please try again later');
    }
  };

  const post = async () => {

    if (category.length === 0) {
      toast.error('category is required');
      return
    }
    if (linkType.length === 0) {
      toast.error('linkType is required');
      return
    }

    if (linkType.value === "author" && author === "") {
      toast.error('Please Select The Type');
      return;
    } else if (linkType.value === "book" && book === "") {
      toast.error('Please Select The Type');
      return;
    } else if (linkType.value === "null") {
      toast.error('Please Select The Type');
      return;
    }

    if (images.length > 10) {
      toast.error('You cannot have more than 10 images');
      return;
    }

    if (!category) {
      toast.error('Please select a category to continue');
      return;
    }
    const raw = convertToRaw(editorState.getCurrentContent());
    const text = DraftToHtml(raw);
    if (type?.length === 0) {
      toast.error('Feed Type Is required');
      return;
    }

    else if (type?.value === 'txt') {
      if (text.length <= 8) {
        toast.error('Content Can Not Be Blank');
        return
      }
    }


    else if (type?.value === 'img') {

      if (images?.length === 0) {
        toast.error('Image Can Not Be Empty');
        return
      }
    }

    const data = new FormData();
    let link_to = null;
    let feed_type = null;

    if (linkType?.value === 'author') {
      link_to = author?.value;
    }

    if (linkType?.value === 'book') {
      link_to = book?.value;
    }

    if (type?.value === 'img') {
      feed_type = feedImage?.value;

    }
    if (type?.value === "txt") {
      feed_type = type?.value;

    }

    if (images.length >= 1) {
      data.append('info', JSON.stringify({
        category_id: category.value,
        link_type: linkType?.value,
        link_to,
        feed_type,
      }))
      Array.from(images).forEach((img: any) => {
        data.append('images', img);
      })
    } else {
      data.append('info', JSON.stringify(
        {
          category_id: category.value,
          text,

          background: color,
          back_image: currentTemplate,
          link_type: linkType?.value,
          link_to,
          feed_type,
        }
      ));
    }

    try {
      await axiosInstance.post('/feed', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      });

      toast.success('Feed added successfully');
      setFeedImage({ ...feedImage });
    } catch (e) {
      toast.error('Something went wrong, try again later');

    }
  };
  const put = async () => {
    if (category.length === 0) {
      toast.error('category is required');
      return
    }
    if (linkType.length === 0) {
      toast.error('linkType is required');
      return
    }

    if (linkType.value === "author" && author === "") {
      toast.error('Please Select The Type');
      return;
    } else if (linkType.value === "book" && book === "") {
      toast.error('Please Select The Type');
      return;
    } else if (linkType.value === "null") {
      toast.error('Please Select The Type');
      return;
    }

    if (images.length > 10) {
      toast.error('You cannot have more than 10 images');
      return;
    }

    if (!category) {
      toast.error('Please select a category to continue');
      return;
    }
    const raw = convertToRaw(editorState.getCurrentContent());
    const text = DraftToHtml(raw);

    if (type?.length === 0) {
      toast.error('Feed Type Is required');
      return;
    }

    else if (type?.value === 'txt') {
      if (text.length <= 8) {
        toast.error('Content Can Not Be Blank');
        return
      }
    }


    else if (type?.value === 'img') {

      if (images?.length === 0) {
        toast.error('Image Can Not Be Empty');
        return
      }
    }


    const data = new FormData();

    let link_to = null;
    let feed_type = null;


    if (linkType?.value === "author") {
      link_to = author?.value;
    }

    if (linkType?.value === "book") {
      link_to = book?.value;
    }
    if (type?.value === 'img') {
      feed_type = feedImage?.value;

    }
    if (type?.value === "txt") {
      feed_type = type?.value;

    }
    if (images !== null && images.length >= 1) {
      data.append(
        "info",
        JSON.stringify({
          category_id: category?.value,
          link_type: linkType?.value,
          link_to,
          feed_type,
          prev_images: nImage,
        })
      );
      //(item: any)
      Array.from(images).filter((item: any) => !item.url).forEach((img: any) => {
        data.append("images", img);
      });
    } else {
      data.append(
        "info",
        JSON.stringify({
          category_id: category.value,
          text,
          background: color,
          back_image: currentTemplate,
          link_type: linkType?.value,
          link_to,
          feed_type,

        })
      );
    }
    try {
      await axiosInstance.put(`/feed/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      toast.success("Feed Updated successfully");
    } catch (e) {
      toast.error("Something went wrong, try again later");
    }
  };

  const onSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    if (id) {
      put();
    } else {
      post();
    }
  };

  const onPickColorPress = () => {
    setPickerVisible((prev) => !prev);
  };

  const onPickImagePress = () => {
    setTemplateVisible((prev) => !prev);
  };

  const renderTemplate = () => {
    return (
      <Row>
        {templateImages.map((img, i) => (
          <>
            <img
              src={`${process.env.REACT_APP_S3_APP_FEED_API}/feed/template/${img}`}
              height="100px"
              width="100px"
              key={i}
              alt="template"
              style={{ margin: '10px', cursor: 'pointer' }}
              onClick={() => setCurrentTemplate(img)}
            />
          </>
        ))}
      </Row>
    )

  };

  const renderColorImage = () => {
    if (textType?.value === 'color' && type?.value === 'txt') {
      return (
        <div style={{ marginTop: '10px' }}>
          <div style={styles.swatch} onClick={onPickColorPress}>
            <div style={{ ...styles.color, backgroundColor: color }} />
          </div>

          {pickerVisible ? (
            <div style={styles.popover}>
              <div style={styles.cover} onClick={onPickColorPress} />
              <ChromePicker color={color} onChange={(e: { hex: React.SetStateAction<string>; }) => setColor(e.hex)} />
            </div>
          ) : null}
        </div>
      )
    }

    if (textType?.value === 'image' && type?.value === 'txt') {
      return (
        <div style={{ marginTop: '10px' }}>
          <button className="btn btn-primary" onClick={(e) => {
            e.preventDefault();

            if (currentTemplate) {
              setCurrentTemplate(null);
            } else {
              onPickImagePress();
            }
          }}>
            {currentTemplate ? 'Remove Image' : 'Pick Image'}
          </button>

          {currentTemplate ? (

            <img
              height="100px"
              width="100px"
              alt="template"
              style={{ marginLeft: '10px' }}
              src={`${process.env.REACT_APP_S3_APP_FEED_API}/feed/template/${currentTemplate}`}
            />
          ) : null}

        </div>
      )

    }
  };

  const renderTextComponent = () => {
    if (type?.value === 'txt') {
      return (
        <div style={{ marginTop: '10px' }}>
          <Select
            options={textOptions}
            placeholder="select"
            isSearchable={false}
            value={textType}
            onChange={setTextType}
          />
        </div>
      )
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <form onSubmit={onSubmit}>
          <Container fluid>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">{id ? `UPDATE FEED (❤️: ${likes ? likes : ''})` : 'ADD FEED'}</h4>
                    <div>
                      <Row>
                        <Col xs={6}>
                          <Select
                            placeholder="Select Category"
                            options={categoryData}
                            value={category}
                            // onChange={setCategory}
                            onChange={(e) => {
                              setCategory(e)
                              setIsSaved() as any;
                            }}
                          />
                        </Col>
                        {id ? (
                          <Col xs={6}>
                            <span>FEED STATUS: </span>
                            <Switch color="primary" checked={active} onChange={toggle} />
                          </Col>
                        ) : null}
                      </Row>
                      <Row style={{ marginTop: '10px' }}>
                        <Col xs={6}>
                          <Select
                            options={typeOptions}
                            value={type}

                            isSearchable={false}
                            // onChange={setType}
                            onChange={(e) => {
                              setType(e)
                              setIsSaved() as any;
                            }}

                            placeholder="Choose Feed Type"
                          />
                        </Col>

                        <Col xs={6}>
                          <Select
                            options={linkOptions}
                            value={linkType}
                            isSearchable={false}
                            // onChange={setLinkType}
                            onChange={(e) => {
                              setLinkType(e)
                              setIsSaved() as any;
                            }}

                            placeholder="Choose Link Type"
                          />

                        </Col>
                      </Row>
                    </div>

                    <Row>
                      <Col xs={6}>
                        <div className='type-link'>
                          {renderTextComponent()}
                          {renderColorImage()}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className='type-link'>
                          {linkType?.value ? (
                            <div style={{ marginTop: '10px' }}>

                              <Select options={(linkType?.value === 'author' ?
                                authorData : bookData)}
                                styles={{ marginTop: '10px' } as StylesConfig}
                                value={linkType?.value === 'author' ? author : book}
                                onChange={(e) => {
                                  setIsSaved() as any;
                                  if (linkType?.value === 'author') {
                                    setAuthor(e);
                                  }

                                  if (linkType?.value === 'book') {
                                    setBook(e);
                                  }
                                }}
                                placeholder="Select"
                              />
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                {renderContent()}
                <Modal isOpen={templateVisible} toggle={toggleTemplate}>
                  <ModalHeader toggle={toggleTemplate}>Templates images</ModalHeader>
                  <ModalBody>
                    {renderTemplate()}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="secondary" onClick={toggleTemplate}>Close</Button>
                  </ModalFooter>
                </Modal>
                <div
                  style={{
                    display: "flex",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <input
                      type="submit"
                      className="btn btn-primary"
                      value="Submit"
                      onClick={() => {
                        setPristine()
                      }}
                    />
                  </div>
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <Link
                      to="/ebookflutter_adminpanel/feed"
                      style={{ color: "white" }}
                    >
                      <button type="button" className="btn btn-primary">
                        Back
                      </button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

        </form>

      </div>{
        Prompt
      }
      {id !== undefined ?
        <EditFeedLog /> : <></>}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};
export default connect(mapStateToProps)(AddFeed);
