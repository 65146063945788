// IMPORT ALL DEPENDENCY
import React from 'react'
import { Link } from 'react-router-dom';
import { CardBody, Input } from "reactstrap";
import { useState } from 'react';
import { Card } from '@mui/material';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import axiosInstance from '../../helpers/axios';
import '../eBook/style.css'
import logodark from "../../assets/images/login_logo.png";

const ChangePassword = () => {
  // STATES
  const token = useSelector((state: any) => state.token);
  const [old_password, setOldPassword] = useState('');
  const [change_password, setChangePassword] = useState('');
  const [confirm_password, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // const user_id = localStorage.getItem('id');
    e.preventDefault();
    const data = {
      old_password,
      change_password,
      confirm_password,
    }

    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/admin/change-old-password`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res.status === 200) {
          toast.success(res.data.message)
        }
      } catch (e: any) {
        setIsLoading(false);
        const error = e;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
  };

  // CUSTOM CSS
  const testForget = {
    width: "500px", margin: "0 auto"
  };

  // THIS IS JSX ALL THE INPUT,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE
  return (
    <React.Fragment>
      <div className="page-content container">
        <Card sx={{ p: 3 }}>
          <CardBody>
            <span className="logo-lg">
              <img style={{ width: "20%" }} src={logodark} alt="" />
            </span>
            <form
              style={testForget}
              onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0", textAlign: "center" }} className='heading'> Change Password</h4>
              <hr /><br />
              <div className='new-pswd'> <label>Old Password : </label><Input placeholder="Old Password"
                type="text"
                className="form-control"
                name="old_password"
                value={old_password || ""}
                id="exampleFormControlInput1"
                onChange={(e) => setOldPassword(e.target.value)} />
              </div>
              <div className='new-pswd' ><label>New Password : </label><Input placeholder="New Password"
                type="text"
                className="form-control"
                name="change_password"
                value={change_password || ""}
                id="exampleFormControlInput1"
                onChange={(e) => setChangePassword(e.target.value)} />
              </div>
              <div className='new-pswd'> <label>Confirm Password : </label><Input placeholder="Confirm Password"
                type="text"
                className="form-control"
                name="confirm_password"
                value={confirm_password || ""}
                id="exampleFormControlInput1"
                onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "5px",
                  padding: "10px",
                  justifyContent: "center",
                }}>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                    textAlign: "center"
                  }}
                >
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  <Link
                    to="/ebookflutter_adminpanel/dashboard"
                    style={{ color: "white" }}
                  >
                    <button type="button" className="btn btn-primary">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

export default ChangePassword; 
