import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "@material-ui/core";
import './style.css';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import EditIcon from "@material-ui/icons/Edit";
// import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import Loader from "../../components/ui/loader/Loader";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
// import { errorMsg, modules, permissions }


interface Props {
    user: UserRoles;
    token: string | null;
}
// API data format
type UserType = {
    id: number;
    name: string;
    active: boolean;
    email: string;
    user_type: String[];
}

// States Of SetData
const UserFile = (props: Props) => {
    const tableRef = useRef<HTMLTableElement>(null);

    // const { id } = useParams() as {
    //     id?: number | undefined;
    // }
    const history = useHistory();
    const [dates, setDates] = useState<any>([null, null]);
    const [page, setPage] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [name, setName] = useState('');
    const [showError, setShowError] = useState(false);

    // Custom Hook For API
    const [{ isLoading, data }, { setOptions }] = useFetchHook
        <{ data: UserType[]; numberOfPages: number; TotalCount: number | null, }>
        ('admin/new_register',
            { data: [], numberOfPages: 1, TotalCount: null }, {
            headers: {
                Authorization: `Bearer ${props.token}`,
            },
            params: {
                page
            }
        });

    useEffect(() => {
        setOptions({
            params: {
                page,
                name: name,
                dates,
            },
            headers: {
                Authorization: `Bearer ${props.token}`,
            }
        });


        setCurrentPage(page + 1);
    }, [page]);


    const redirectEdit = (id: number) => {
        if (!props.user[modules.portal_users].includes(permissions.UPDATE)) {
            toast.error(errorMsg);
            return;
        }

        window.open(`/ebookflutter_adminpanel/edit-user/${id}`);
    };

    const redirectAdd = () => {
        if (!props.user[modules.portal_users].includes(permissions.WRITE)) {
            toast.error(errorMsg);
            return;
        }

        history.push(`add-Users`);
    };

    const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        action(e.target.value);
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
                setShowError(false);
                e.preventDefault();
                setPage(0);
                setOptions({
                    params: {
                        page,
                        name: name,
                        dates,
                    },
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
            }
            else {
                setShowError(true);

            }
        }
    }

    // Filter
    const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (name.length > 0 || (dates[0] != null && dates[1] != null)) {
            setShowError(false);
            e.preventDefault();
            setPage(0);
            setOptions({
                params: {
                    page,
                    name: name,
                    dates,
                },
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });
        }
        else {
            setShowError(true);

        }
    };

    // Reset
    const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setName('');
        setShowError(false);
        setDates([null, null])
        setOptions({
            params: {
                page,
            },
            headers: {
                Authorization: `Bearer ${props.token}`,
            }
        });
    }

    const copyTable = () => {
        const table = tableRef.current;
        if (table) {
            const range = document.createRange();
            range.selectNode(table);
            window.getSelection()?.removeAllRanges();
            window.getSelection()?.addRange(range);
            document.execCommand('copy');
            window.getSelection()?.removeAllRanges();
            toast.info("Copied To Clipboard")
        }
    }

    const exportToExcel = () => {
        const table = document.querySelector('table');
        const html = table?.outerHTML;
        if (html) {
            const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
            const dataUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = 'table.xls';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(dataUrl);
        }
    }

    const convertTableToCSV = (table: any) => {
        let csv = '';
        const rows = table.querySelectorAll('tr');
        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].querySelectorAll('th, td');
            for (let j = 0; j < cells.length; j++) {
                csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
            }
        }
        return csv;
    };


    const handleDownloadCSV = () => {
        const tableNode = document.getElementById('table-data');
        const csvData = convertTableToCSV(tableNode);
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        downloadLink.download = 'table-data.csv';
        downloadLink.click();
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={6}>
                                            <h4 className="card-title"> PORTAL USER LIST ({data.TotalCount})</h4>

                                        </Col>
                                        <Col xs={6}
                                            style={{ display: "flex", justifyContent: "flex-end" }} >
                                            <Button
                                                variant="contained"
                                                style={{ color: "blue", }}
                                                onClick={redirectAdd}
                                            >
                                                Add Users
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>

                                        <Col xs={3}>
                                            <Input placeholder="User Name" value={name} onKeyPress={handleKeyPress} onChange={setter(setName)} />
                                        </Col>

                                        <Col xs={3}>
                                            <div>
                                                <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />

                                            </div>
                                        </Col>
                                        <Col xs="auto">
                                            <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                                        </Col>
                                        <Col xs="auto">
                                            <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                                        </Col>

                                    </Row>
                                    <div className="msg-box">
                                        {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                                    </div>

                                    <br />
                                    <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                                    <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                                    <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                                    <TableContainer component={Paper}>
                                        <Table id="table-data" ref={tableRef}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SR NO</TableCell>
                                                    <TableCell>NAME</TableCell>
                                                    <TableCell>EMAIL</TableCell>
                                                    <TableCell>USER TYPE</TableCell>
                                                    {/* <TableCell>APPROVAL STATUS</TableCell> */}
                                                    <TableCell>ACTION</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data.data.map((temp, i) => (

                                                    <TableRow key={temp.id}>
                                                        <TableCell>{(page * 10) + i + 1}</TableCell>

                                                        <TableCell>
                                                            {temp.name}

                                                        </TableCell>
                                                        <TableCell>{temp.email}</TableCell>

                                                        <TableCell>
                                                            {(temp.user_type.length === 2) ? `Author-Coach` : (temp?.user_type[0] === "2") ? "Author" : "Coach"}                                                         {/* {temp.user_type.length ===  2 ?Author Coach: null} */}
                                                            {/* <Switch checked={user.top} color="primary" onChange={() => topSwitchChange(user.category_id)} /> */}
                                                        </TableCell>
                                                        {/* <TableCell>{temp.active}</TableCell> */}
                                                        <TableCell>
                                                            <div className="innercell">
                                                                <IconButton color="primary"
                                                                    onClick={() => redirectEdit(temp.id)}
                                                                >
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>
                                                        </TableCell>
                                                    </TableRow>

                                                ))}   </TableBody>
                                        </Table>
                                        <Pagination
                                            count={data.numberOfPages}
                                            page={currentPage}
                                            color="primary"
                                            variant="outlined"
                                            showFirstButton={true}
                                            showLastButton={true}

                                            onChange={(_e, value) => setPage(value - 1)}

                                        />
                                    </TableContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                {isLoading ? <Loader /> : null}
            </div>
        </React.Fragment>
    );
};

// Redux
const mapStateToProps = (state: StoreState) => {
    return {
        token: state.token!,
        user: state.user,
    };
};

export default connect(mapStateToProps)(UserFile);
