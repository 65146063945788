import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Button } from "reactstrap";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import swal from "sweetalert";
import "../datatables.scss";
import Image from '../../../components/Common/Image';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { AxiosError } from "axios";
import UnSavedChanges from "./UnSavedChanges";
import Compress from "browser-image-compression";
import EditCategoryLogs from "../Edit/EditCategoryLogs";

interface Props {
  token: string;
  user: UserRoles;

}
const AddCategory = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [category_name, setCategoryName] = useState('');
  const [image, setImage] = useState<any>(null);
  const [imgcompressor, setImgcompressor] = useState<any>({ imageUrl: null });
  const [netImage, setNetImage] = useState(null);
  const [valid, setValid] = useState({
    category_name_Error: "",
    iconpath_Error: [] as any,
    msg_Error: [] as any,
  });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  const [isUpdateCalled, setIsUpdateCalled] = useState(false); // New state variable


  useEffect(() => {
    if (id) {

      axiosInstance.get(`/categories/${id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }).then((res) => {
        setNetImage(res.data[0].image);
        setCategoryName(res.data[0].category_name);
        {

        }
      }).catch((e) => console.log(e))
    }

  }, []);

  const imageRef = React.useRef<HTMLInputElement>(null);

  const post = async () => {
    const category_name_Error = [] as any;
    const iconpath_Error = [] as any;
    if (!category_name) {
      category_name_Error.push("*Enter category name");
    } else {
      setValid({ ...valid, category_name_Error: "" })
    }
    if (!image && !netImage) {
      iconpath_Error.push("*Upload The Icon");
    } else {
      setValid({ ...valid, iconpath_Error: "" })
    }
    setValid({ ...valid, category_name_Error, iconpath_Error });

    if (category_name_Error.length) return;
    if (iconpath_Error.length) return;
    const data = new FormData();

    data.append('info', JSON.stringify({ category_name }));

    if (image) {
      data.append('image', image);
    }

    if (isLoading === false) {
      setIsLoading(true);
      var test = {} as any;
      try {
        test = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/categories/category`,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
        if (test.data.error) {
        } else {
          setCategoryName('');
          setImage(null);
          swal({
            title: "Added Successfully!",
            icon: "success",
          });
        }
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }
      setIsLoading(false);
    }
  };

  const update = async () => {
    const category_name_Error = [] as any;
    const iconpath_Error = [];

    if (!category_name) {
      category_name_Error.push("*Enter Category Name");
    } else {
      setValid({ ...valid, category_name_Error: "" })
    }
    if (!image && !netImage) {
      iconpath_Error.push("*Upload The Icon");
    } else {
      setValid({ ...valid, iconpath_Error: "" })
    }
    setValid({ ...valid, category_name_Error, iconpath_Error });
    if (category_name_Error.length) return;
    if (iconpath_Error.length) return;
    const data = new FormData();

    data.append('info', JSON.stringify({ category_name }));
    if (image) {
      data.append('image', image);
    }

    if (isLoading === false) {
      setIsLoading(true);
      setIsUpdateCalled(true);
      try {
        await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/categories/${id}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
        setValid({
          ...valid,
          msg_Error: [],
        });
        swal({
          title: "Update Successfully!",
          // text: users.category_name,
          icon: "success",
          // button: "OK!",
        });
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }

      setIsLoading(false);
      setIsUpdateCalled(false);
    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      update();
    } else {
      post();
    }
  };

  const handleInputPaste = (e: any) => {
    const pastedValue = e.clipboardData.getData('text');
    const inputValue = category_name + pastedValue;
    setCategoryName(inputValue);
    if (!e.target.value || category_name?.length === 0) {
      setValid({ ...valid, category_name_Error: "*Enter Category Name" });
    } else {
      setValid({ ...valid, category_name_Error: "" })
    }
  };


  const onFileChange = (e: any) => {
    setIsSaved() as any;
    const file = e.target.files[0];

    if (!file) {
      setValid({ ...valid, iconpath_Error: "*Upload The Icon" })
    } else {
      setValid({ ...valid, iconpath_Error: "" })
    }
    if (e.target.files && e.target.files[0]) {
      if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
        toast.error('only PNG/JPEG/JPG are supported');
        return;
      }

      if (e.target.files[0].size > 5000_000) {
        toast.error('File too large. Please select file less than 5MB');
        return;
      }

      const options = {
        maxSizeMB: 1,
        useWebWorker: true
      };

      Compress(file, options)
        .then((compressedBlob) => {

          setImgcompressor({
            imageUrl: URL.createObjectURL(compressedBlob)
          })
          console.log(imgcompressor)
          const convertedBlobFile = new File([compressedBlob], file.name, {
            type: file.type,
            lastModified: Date.now(),
          });
          setImage(convertedBlobFile)
        })
        .catch((e) => {
        });
    }
  };

  const renderImage = () => {
    if (netImage) {
      // src, height, width, alt, onPress, showClose
      return (
        <Image
          height="100px"
          width="100px"
          src={`${process.env.REACT_APP_S3_APP_API}/category/${netImage}`}
          alt="img" onPress={() => setNetImage(null)}
        />
      );
    }

    if (image) {
      return (
        <Image
          height="100px"
          width="100px"
          src={URL.createObjectURL(image)}
          onPress={() => setImage(null)}
          alt="img"
        />
      )
    }

    return (
      <>
        <Button color="primary" style={{ marginTop: 30 }} onClick={(e) => {
          e.preventDefault();

          if (imageRef !== null) {
            imageRef?.current?.click();
          }
        }}>Add Image</Button>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id !== undefined ? "UPDATE CATEGORY" : "ADD CATEGORY"}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CATEGORY NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={category_name}
                            id="autosuggest-input"
                            // id="exampleFormControlInput1"
                            placeholder="Enter category name"
                            onPaste={handleInputPaste}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              setIsSaved() as any
                              const selectedValue = document.getElementById('autosuggest-input') as HTMLInputElement;
                              setCategoryName(e.target.value)
                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, category_name_Error: "" });
                              } else {
                                setValid({ ...valid, category_name_Error: "*Enter Category Name" })

                              }
                            }
                            }
                          />
                          {valid?.category_name_Error && <div style={{ color: 'red' }}>{valid?.category_name_Error}
                          </div>}
                          {/* {valid?.category_name_Error.length
                            ? valid?.category_name_Error.map((error, i) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                        {valid.msg_Error.length
                          ? valid.msg_Error.map((error: any, i: React.Key | null | undefined) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </Col>

                      <Col xs="auto">
                        <div className="form-group">
                          <input onChange={onFileChange}
                            type="file"
                            accept="image/*" style={{ display: 'none' }} ref={imageRef} />
                          {renderImage()}
                          {valid?.iconpath_Error && <div style={{ color: 'red' }}>{valid?.iconpath_Error}
                          </div>}

                        </div>
                      </Col>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/category"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id == undefined || isUpdateCalled ?
          <></> : <EditCategoryLogs />}
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AddCategory);


