import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../../components/ui/loader/Loader";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import "../datatables.scss";
import swal from "sweetalert";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import Select from 'react-select/async';
import { connect } from 'react-redux';
import UnSavedChanges from "./UnSavedChanges";

interface Props {
  token: string;
  user: UserRoles;
}

const AddSubCategory = (props: Props) => {
  // States of SetData
  const [users, setUsers] = useState({
    category_id_Error: [],
    subcategory_name_Error: [] as any,
    msg_Error: [],
  });
  const [newUser, setNewUser] = useState({
    category_id: "",
    subcategory_name: "",
  })
  const [category, setCategory] = useState<{ value: string; label: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    setNewUser({ ...newUser, [e.target.name]: e.target.value });
    setIsSaved() as any
    if (!e.target.value || !e.target.value) {
      setUsers({ ...users, subcategory_name_Error: "*Enter Sub Category Name" });
    } else {
      setUsers({ ...users, subcategory_name_Error: "" })
    }
  };

  const handleInputPaste = (e:any) => {
    const pastedValue = e.clipboardData.getData('text');
    const inputValue = newUser.subcategory_name +pastedValue
    setNewUser({ ...newUser, [inputValue]: inputValue });
    if (!e.target.value || !e.target.value) {
      setUsers({ ...users, subcategory_name_Error: "*Enter Sub Category Name" });
    } else {
      setUsers({ ...users, subcategory_name_Error: "" })
    }
  };

  // Post API
  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const category_id_Error = [] as any;
    const subcategory_name_Error = [] as any;
    const msg_Error = [] as any;
    if (!newUser.category_id) {
      category_id_Error.push("*Enter Category Name");
    }
    if (!newUser.subcategory_name) {
      subcategory_name_Error.push("*Enter Sub Category Name");
    } else {
      setUsers({ ...users, subcategory_name_Error: "" })
    }
    setUsers({ ...users, category_id_Error, subcategory_name_Error });
    if (category_id_Error?.length) return;
    if (subcategory_name_Error?.length) return;
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/sub_categories/subCategory`,
          newUser,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );

        if (res?.data?.success === 0) {
          msg_Error.push(res?.data?.message);
          setUsers({ ...users, msg_Error });
          if (msg_Error.length) return;
        }
        setIsLoading(false);
        setCategory(null);

        swal({
          title: "Sub Category Added Successfully!",
          icon: "success",
        });
      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
    setNewUser({
      ...newUser,
      category_id: "",
      subcategory_name: "",
    });

  };


  useEffect(() => {
  }, [isLoading]);

  // Category API
  const loadCategory = async (category_name: String) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });

      return category.data;
    } catch (e) { }
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">ADD SUB CATEGORY</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CATEGORY NAME*</label>
                          <Select placeholder="Parent Category Name" value={category} cacheOptions defaultOptions loadOptions={loadCategory}
                            onChange={(e: any) => {
                              setCategory(e);
                              setIsSaved() as any;
                              setNewUser({ ...newUser, ['category_id']: e?.value as any })
                              setUsers({ ...users, category_id_Error: [] })

                            }} ></Select>
                          {users?.category_id_Error && <div style={{ color: 'red' }}>{users?.category_id_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SUB CATEGORY NAME*</label>
                          <input
                            type="text"
                            name="subcategory_name"
                            value={newUser.subcategory_name}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Subcategory Name"
                            onPaste={handleInputPaste}

                            onChange={(e) => onInputChange(e)}
                          />
                          {users?.subcategory_name_Error && <div style={{ color: 'red' }}>{users?.subcategory_name_Error}</div>}
                        </div>
                      </Col>
                      {users?.msg_Error?.length
                        ? users?.msg_Error.map((error, i) => (
                          <ErrorSpan key={i} error={error} />
                        ))
                        : null}
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            className="btn btn-primary"
                            value="Submit"
                            onClick={() => {
                              setPristine()
                            }}
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <Link
                            to="/ebookflutter_adminpanel/sub-category"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};
// Redux
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AddSubCategory);

