import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Pagination } from "@material-ui/lab";
import swal from "sweetalert";
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import { connect } from 'react-redux';
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { toast } from "react-toastify";
import { useFetchHook } from "../../utils/fetchHook";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user'
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import '../../pages/eBook/style.css'
import { AxiosError } from "axios";

interface Props {
  token: string;
  user: UserRoles;
}
// API data format
type BookRes = {
  book_id: number;
  book_name: string;
  recommended: boolean;
  top_selling: boolean;
  publisher: string;
  category_name: string;
  primary_front: string;
  likes: number;
  approval_status: boolean;
  summary_status: string;
};

const Book = (props: Props) => {
  const location = useLocation();
  const tableRef = useRef<HTMLTableElement>(null);

  // States of setData
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [bookName, setBookName] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const [publisher, setPublisher] = useState<{ value: number; label: string } | null>(null);
  const [author, setAuthor] = useState<{ value: number; label: string } | null>(null);
  const [category, setCategory] = useState<{ value: number; label: string } | null>(null);
  const [subcategories, setSubcategories] = useState([] as any);//useState<{ value: number; label: string } | null>(null);
  const [subcategory, setSubcategory] = useState<{ value: number; label: string } | any>(null);
  const [language, setLanguage] = useState<{ value: number; label: string } | null>(null);
  const [coverType, setCoverType] = useState<{ value: string; label: string } | null>(null);
  const [topSelling, setTopSelling] = useState<{ value: boolean; label: string } | null>(null);
  const [recommended, setRecommended] = useState<{ value: boolean; label: string } | null>(null);
  const [page, setPage] = useState(0);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const [pending, setPending] = useState<{ value: boolean; label: string } | null>(null);
  const [summary, setSummary] = useState<{ value: boolean; label: string } | null>(null);

  const [newUsers, setNewusers] = useState({
    publisher: null as any,
    category: null as any,
    subcategory: null as any,
    language: null as any,
    author: null as any,
    topSelling: null as any,
    recommended: null as any,
    coverType: null as any,
    approve: null as any,
    pending: null as any,
    summary: null as any,
  })

  // Custom Hook For API
  const [{ isLoading, isError, data }, { setOptions, setData, fetchData }] = useFetchHook<{
    data: BookRes[]; numberOfPage: number; total: number | null,
  }>(`${process.env.REACT_APP_API_URL}/books/admin-books`,
    { data: [], numberOfPage: 1, total: null },
    {
      params: { page, summary_status: "All" },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });

  useEffect(() => {
    onFilterApply(null as any, false)
  }, [newUsers])

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const approveValue = searchParams.get('approve');
    if (approveValue) {
      const value = approveValue === 'true' ? true : false;
      const option = approvedOptions.find((o) => o.value === value.toString());
      if (option) {
        setApprove(option);
        setOptions({
          params: {
            approve: option.value
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          book_name: bookName,
          book_title: bookTitle,
          publisher: publisher?.value,
          author: author?.value,
          category: category?.value,
          subcategory: subcategory?.value,
          language: language?.value,
          coverType: coverType,
          topselling: topSelling?.value,
          recommended: recommended?.value,
          cover_type: coverType?.value,
          approve: approve?.value,
          pending: pending?.value,
          summary_status: summary?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page, approve?.value]);

  const coverTypeOptions = [
    { value: 'Hard', label: 'All Hard' },
    { value: 'Soft', label: 'Soft' },
    { value: 'Paper Back', label: 'Paperback' }
  ]

  const booleanOptions = [
    { value: true, label: 'Active' },
    { value: false, label: 'Inactive' }
  ]

  const pendingOption = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  const summaryOption = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ]
  // Approved API
  const setRecommandedStatus = async (book_id: number) => {
    if (!props.user[modules.books].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res2 = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/books/recommended/${book_id}`
        , {}, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }
      );

      const newData = data.data.map((book) => {
        return { ...book, recommended: book.book_id === book_id ? res2.data.recommended : book.recommended }
      });
      swal({
        title: "Recommended Updated Successfully!",
        icon: "success",
      });
      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  // TopBook API
  const setTopStatus = async (book_id: number) => {
    if (!props.user[modules.books].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res1 = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/books/topselling/${book_id}`, {}, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      }
      );

      const newData = data.data.map((book) => {
        return { ...book, top_selling: book.book_id === book_id ? res1.data.top_selling : book.top_selling }
      });
      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
      swal({
        title: "Top Selling Updated Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
    // setIsLoading(false);
  };

  // Delete API
  const deleteUser = async (book_id: number) => {
    if (!props.user[modules.books].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      const res = await swal({
        buttons: ['No', 'Yes'],
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this Book!",
        icon: "error",
        dangerMode: true,
      });

      if (!res) {
        return;
      }
      // setIsLoading(true);
      await axiosInstance.delete(`${process.env.REACT_APP_API_URL}/books/book/${book_id}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }
      );
      fetchData();
      swal("Your Book has been deleted!", {
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
    // setIsLoading(false);
  };

  const redirectEdit = (id: number) => {
    if (!props.user[modules.books].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`/ebookflutter_adminpanel/edit-book/${id}`);

  };

  const redirectAdd = () => {
    if (!props.user[modules.books].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`add-book`)
  };
  if (isError) {
    return (
      <p>Something went wrong please try again later</p>
    );
  }

  const updateToggle = async (book_id: number) => {
    if (!props.user[modules.books].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res = await axiosInstance.put(`/books/book/book-approval`, { book_id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      console.log(res)
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.book_id === book_id) {
          // swal("Your Book has been deleted!", {
          //   icon: "success",
          // });
          return { ...t, approval_status: res.data.status, approved_by: res.data.approved_by };
        }
        return t;
      });
      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  // Publisher DropDown API
  const loadPublisher = async (publisher: string) => {
    try {
      const pubs = await axiosInstance.get('/publisher/drop-publisher', {

        params: {
          publisher,
        }

      });
      return pubs.data;

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  // Author Dropdown API
  const loadAuthor = async (author: string) => {
    try {
      const authors = await axiosInstance.get('/v2_authors/get-author', {
        params: {
          name: author,
        }
      });

      return authors.data;
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  // Category Dropdown API
  const loadCategory = async (category_name: string) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });

      return category.data;

    }
    catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  // Subcategory Dropdown API
  const loadSubCategory = async (category_id: any) => {
    try {
      const subcategory = await axiosInstance.get('/sub_categories/drop-sub', {
        params: {
          category_id: category_id,
        }
      });

      setSubcategories(subcategory.data);

    }
    catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  // Language Dropdown API
  const loadLang = async (lang: string) => {
    try {
      const language = await axiosInstance.get('/language/drop-lang', {
        params: {
          lang,
        }
      });

      return language.data;

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };
  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  const approvedOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (bookName.length > 0 || bookTitle.length > 0 || publisher != null || author != null || approve != null || category != null || subcategory != null || language != null || topSelling != null || recommended != null || coverType != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(0);
        setOptions({
          params: {
            page,
            book_name: bookName,
            book_title: bookTitle,
            publisher: publisher?.value,
            author: author?.value,
            category: category?.value,
            subcategory: subcategory?.value,
            language: language?.value,
            top_selling: topSelling?.value,
            recommended: recommended?.value,
            cover_type: coverType?.value,
            approve: approve?.value,
            pending: pending?.value,
            summary_status: summary?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);

      }
    }
  }

  // Filter API
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isFilterapply: boolean) => {

    if (bookName.length > 0 || bookTitle.length > 0 || publisher != null || pending != null || summary != null || author != null || approve != null || category != null || subcategory != null || language != null || topSelling != null || recommended != null || coverType != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setPage(0);
      setOptions({
        params: {
          page,
          book_name: bookName,
          book_title: bookTitle,
          publisher: publisher?.value,
          author: author?.value,
          category: category?.value,
          subcategory: subcategory?.value,
          language: language?.value,
          top_selling: topSelling?.value,
          recommended: recommended?.value,
          cover_type: coverType?.value,
          approve: approve?.value,
          pending: pending?.value,
          summary_status:(summary?.value) === true ? true : (summary?.value) === false ? false :"All",
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };


  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setBookName('');
    setBookTitle('');
    setPublisher(null);
    setAuthor(null);
    setCategory(null);
    setShowError(false);
    setSubcategory(null);
    setLanguage(null);
    setPending(null);
    setSummary(null);
    setCoverType(null);
    setTopSelling(null);
    setApprove(null);
    setRecommended(null);
    setDates([null, null]);
    setCoverType(null);

    setOptions({
      params: {
        page,
        summary_status: "All"
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">BOOK LIST ({data.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        onClick={redirectAdd}
                        style={{ color: "blue" }}
                      >
                        Add Book
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Book Name" value={bookName} onKeyPress={handleKeyPress} onChange={setter(setBookName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Book Title" value={bookTitle} onKeyPress={handleKeyPress} onChange={setter(setBookTitle)} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" onKeyPress={handleKeyPress} format="MM/dd/yyyy" onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={publisher}
                        loadOptions={loadPublisher}
                        placeholder="Publisher"
                        onChange={(value) => {
                          setPublisher(value);
                          setNewusers({ ...newUsers, publisher: value })
                        }}
                      />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Category" value={category} cacheOptions defaultOptions loadOptions={loadCategory} onChange={(e: any) => {
                        setCategory(e);
                        setSubcategory(null);
                        setSubcategories([]);
                        loadSubCategory(e.value);
                        setNewusers({ ...newUsers, category: e })
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Sub Category" value={subcategory} options={subcategories}
                        onChange={(value) => {
                          setSubcategory(value);
                          setNewusers({ ...newUsers, subcategory: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Language" value={language} cacheOptions defaultOptions loadOptions={loadLang}
                        onChange={(value) => {
                          setLanguage(value);
                          setNewusers({ ...newUsers, language: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Author" value={author} cacheOptions defaultOptions loadOptions={loadAuthor}
                        onChange={(value) => {
                          setAuthor(value);
                          setNewusers({ ...newUsers, author: value })
                        }} />
                    </Col>

                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <Select placeholder="Top Selling" options={booleanOptions} value={topSelling}
                        onChange={(value) => {
                          setTopSelling(value);
                          setNewusers({ ...newUsers, topSelling: value })
                        }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Recommended" options={booleanOptions} value={recommended}
                        onChange={(value) => {
                          setRecommended(value);
                          setNewusers({ ...newUsers, recommended: value })
                        }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approved" options={approvedOptions} value={approve}
                        onChange={(value) => {
                          setApprove(value);
                          setNewusers({ ...newUsers, approve: value })
                        }} />
                    </Col>

                    <Col xs={3}>
                      <Select placeholder="Cover Type" options={coverTypeOptions} value={coverType}
                        onChange={(value) => {
                          setCoverType(value);
                          setNewusers({ ...newUsers, coverType: value })
                        }} />
                    </Col>
                  </Row>
                  <br />
                  <Row>


                    <Col xs={3}>
                      <Select placeholder="Book Pending Profile" options={pendingOption} value={pending}
                        onChange={(value) => {
                          setPending(value);
                          setNewusers({ ...newUsers, pending: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Summary Status" options={summaryOption} value={summary}
                        onChange={(value) => {
                          setSummary(value);
                          setNewusers({ ...newUsers, summary: value })
                        }}
                      />
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>

                  </Row> <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>FRONT IMAGE</TableCell>
                          <TableCell>BOOK NAME</TableCell>
                          <TableCell>PUBLISHER</TableCell>
                          <TableCell>CATEGORY</TableCell>
                          <TableCell>LIKES</TableCell>
                          <TableCell>TOP SELLING</TableCell>
                          <TableCell>RECOMMENDED</TableCell>
                          <TableCell>APPROVED</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.book_id}>
                              <TableCell>{(page * 25) + index + 1}</TableCell>
                              <TableCell>
                                <img
                                  style={{ height: "50px", width: "80px" }}

                                  src={
                                    user.primary_front ? `${process.env.REACT_APP_S3_APP_API}/books/${user.primary_front}` : `${process.env.REACT_APP_S3_APP_API}/books/books.png`
                                  }
                                  alt="books"
                                />
                              </TableCell>
                              <TableCell style={{ width: "300px" }}>{user.book_name}</TableCell>
                              <TableCell>{user.publisher}</TableCell>
                              <TableCell>{user.category_name}</TableCell>
                              <TableCell>{user.likes}</TableCell>
                              <TableCell>
                                <Switch
                                  checked={user.top_selling ? true : false}
                                  onChange={() => setTopStatus(user.book_id)}
                                  color="primary"
                                  name="top_selling"
                                  value={user.top_selling}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Switch
                                  checked={user.recommended ? true : false}
                                  onChange={() => setRecommandedStatus(user.book_id)}
                                  color="primary"
                                  name="recommended"
                                  value={user.recommended}
                                  inputProps={{
                                    "aria-label": "primary checkbox",
                                  }}
                                />
                              </TableCell>
                              <TableCell>
                                <Switch checked={user?.approval_status} color="primary" onChange={() => updateToggle(user?.book_id)} />
                              </TableCell>

                              <TableCell>
                                <div className="innercell">
                                  <Link
                                    to={`/ebookflutter_adminpanel/view-book/${user.book_id}`}
                                  >
                                    <VisibilityIcon />
                                  </Link>
                                  <IconButton color="primary" onClick={() => redirectEdit(user.book_id)}>
                                    <EditIcon />
                                  </IconButton>

                                  <Link
                                    to="#"
                                    onClick={() => deleteUser(user.book_id)}
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value - 1)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

// Redux
const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Book);
