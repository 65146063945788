import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
import axiosInstance from "../../../helpers/axios";
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user"
interface Props {
  token: string;
  user: UserRoles;
}
const initialState = {
  roles: {
    label: 'Roles',
    value: [],
  },
  users: {
    label: 'Users',
    value: [],
  },
  portal_users: {
    label: 'Portal Users',
    value: [],
  },
  author: {
    label: 'Authors',
    value: [],
  },
  books: {
    label: 'Books',
    value: [],
  },
  top_selling: {
    label: 'Top Selling',
    value: [],
  },
  recommended: {
    label: 'Recommended',
    value: [],
  },
  categories: {
    label: 'Categories',
    value: [],
  },
  subcategories: {
    label: 'Sub Category',
    value: [],
  },
  chapters: {
    label: 'Summary',
    value: [],
  },
  mobile_slider: {
    label: 'Mobile Slider',
    value: [],
  },
  social_media: {
    label: 'Social Media',
    value: [],
  },
  publisher: {
    label: 'Publisher',
    value: [],
  },
  genre: {
    label: 'Genre',
    value: [],
  },
  feedback: {
    label: 'Feedback',
    value: [],
  },
  feed: {
    label: 'Feed',
    value: [],
  },
  author_review: {
    label: 'Author Review',
    value: []
  },
  book_review: {
    label: 'Book Review',
    value: []
  },
  coupons: {
    label: 'Coupons',
    value: []
  },
  email_templates: {
    label: 'Email Templates',
    value: []
  },
  mobileusers: {
    label: 'Mobileusers',
    value: []
  },
  admin_notification: {
    label: 'Notification',
    value: []
  },
  coach: {
    label: 'Coach',
    value: []
  },
  coach_review:{
    label: 'Coach Review',
    value: []
  },
  audio:{
    label: 'Audio',
    value: []
  },
  audio_review: {
    label: 'Audio Review',
    value: []
  },
  video: {
    label: 'Video',
    value: []
  },
  video_review: {
    label: 'Video Review',
    value: []
  },
  log: {
    label: 'Log',
    value: []
  },
} as any;

const ViewRoles = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [roleName, setRoleName] = useState<any>('');
  const [roleDescription, setRoleDescription] = useState<any>('');
  const [permissions, setPermission] = useState<any>({ ...initialState });

  useEffect(() => {
    fetchRole();
  }, []);

  const fetchRole = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/roles/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });

        const data = {} as any;

        Object.keys(initialState).forEach((key: any) => {
          data[key] = {
            label: initialState[key].label,
            value: res.data[key],
          };
        });

        setPermission(data);
        setRoleName(res.data.name);
        setRoleDescription(res.data.description);
      } catch (e) {
      }
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">ROLE INFO</h4>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE NAME</label>
                          <p>{roleName}</p>

                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ROLE'S DESCRIPTION</label>
                          <p>{roleDescription}</p>
                        </div>
                      </Col>

                      <Col xs={12}>
                        <Table component={Paper}>
                          <TableHead>
                            <TableRow>
                              <TableCell>SR NO.</TableCell>
                              <TableCell>MODULE</TableCell>
                              <TableCell>READ</TableCell>
                              <TableCell>WRITE</TableCell>
                              <TableCell>UPDATE</TableCell>
                              <TableCell>DELETE</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(permissions).map((key, i) => (
                              <TableRow key={i}>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>{permissions[key].label}</TableCell>
                                <TableCell>
                                  <Switch
                                    checked={permissions[key].value.includes('READ')}
                                    color="primary"
                                    name="top_selling"
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                  />
                                </TableCell>
                                {(permissions[key].label !== "Log") && (permissions[key]?.label !== "Feedback") 
                                 && (permissions[key]?.label !== "Audio")  && (permissions[key]?.label !== "Video")
                                 && (permissions[key]?.label !== "Audio Review")  && (permissions[key]?.label !== "Video Review")
                                 && (permissions[key]?.label !== "Recommended") &&
                                 (permissions[key]?.label !== "Top Selling")?
                                  <TableCell>
                                    <Switch
                                      checked={permissions[key].value.includes('WRITE')}
                                      color="primary"
                                      name="top_selling"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  : <TableCell></TableCell>}
                                 {(permissions[key].label !== "Log") && (permissions[key]?.label !== "Feedback") 
                                 && (permissions[key]?.label !== "Recommended") &&
                                 (permissions[key]?.label !== "Top Selling")?
                                  <TableCell>
                                    <Switch
                                      checked={permissions[key].value.includes('UPDATE')}
                                      color="primary"
                                      name="top_selling"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  : <TableCell></TableCell>}
                                {(permissions[key]?.label !== "Log") && (permissions[key]?.label !== "Recommended") &&
                                  (permissions[key]?.label !== "Top Selling") && (permissions[key]?.label !== "Audio")
                                  && (permissions[key]?.label !== "Video") && (permissions[key]?.label !== "Feedback")
                                  && (permissions[key]?.label !== "Mobileusers") && (permissions[key]?.label !== "Users") &&
                                  (permissions[key]?.label !== "Portal Users") ?
                                  <TableCell>
                                    <Switch
                                      checked={permissions[key].value.includes('DELETE')}
                                      color="primary"
                                      name="top_selling"
                                      inputProps={{
                                        "aria-label": "primary checkbox",
                                      }}
                                    />
                                  </TableCell>
                                  : <TableCell></TableCell>}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Col>
                    </Row>
                    <div
                      style={{
                        margin: "5px",
                        padding: "10px",
                      }}
                    >
                      {" "}
                      <Link
                        to="/ebookflutter_adminpanel/roles"
                        style={{ color: "white" }}
                      >
                        <button type="button" className="btn btn-primary">
                          Back
                        </button>
                      </Link>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ViewRoles);
