import React, { useEffect, useState,useRef } from "react";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import { connect } from 'react-redux';
import Loader from "../../components/ui/loader/Loader";
import { useFetchHook } from "../../utils/fetchHook";
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { toast } from 'react-toastify';
import '../eBook/style.css';

interface Props {
  token: string;
  user: UserRoles;
}

type FeedbackType = {
  id: number;
  description: string;
  name: string;
  email: string;
  rating: number;
  created_at: string;
  status: any;
  total: number;
};

const Feedback = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States for SetData
  const [dates, setDates] = useState<any>([null, null]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [isExpanded, setIsExpanded] = useState([] as any);
  const limit = 50;

  // Custom Hook
  const [{ isLoading, isError, data }, { setOptions }] = useFetchHook
    <{ data: FeedbackType[]; numberOfPage: number; total: number | null, }>
    (`${process.env.REACT_APP_API_URL}/feedback`,
      { data: [], numberOfPage: 0, total: null },
      {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

  // UseEffect
  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          name: name,
          email: email,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      setPagedata(true);
    }

    setCurrentPage(page + 1);

  }, [page]);

  if (isError) {
    return (
      <p>Something went wrong please try again later</p>
    );
  }

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (name.length > 0 || email.length > 0 || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setPage(1);
        setShowError(false);
        setOptions({
          params: {
            page,
            name: name,
            email: email,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }}

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (name.length > 0 || email.length > 0 || (dates[0] != null && dates[1] != null)) {
      e.preventDefault();
      setPage(1);
      setShowError(false);
      setOptions({
        params: {
          page,
          name: name,
          email: email,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setShowError(true);
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setName('');
    setEmail('');
    setDates([null, null]);
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };
  const removeElement = (id: any) => {
    const temp = [...isExpanded];
    temp.splice(temp.indexOf(id), 1);
    setIsExpanded(temp);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">APP FEEDBACK LIST ({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Name" name="name" value={name}  onKeyPress ={handleKeyPress} onChange={setter(setName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Email" name="email" value={email}  onKeyPress ={handleKeyPress} onChange={setter(setEmail)} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range"  format="MM/dd/yyyy"  onKeyPress ={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>NAME</TableCell>
                          <TableCell>EMAIL</TableCell>
                          <TableCell>DESCRIPTION</TableCell>
                          <TableCell>DATE</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((t, index) => (
                          <TableRow key={t.id}>
                            <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                            <TableCell>{t.name}</TableCell>
                            <TableCell>{t.email}</TableCell>
                            {/* <TableCell>{t.description}</TableCell> */}
                            <TableCell>{t.description.length < 50 ? t.description :
                              <>
                                <div style={{ width: "500px" }}>
                                  {isExpanded.includes(t.id) ? t.description : t.description.slice(0, limit) + '...'}
                                  {t.description.length > limit && (
                                    <button className="desc-btn" onClick={() => {
                                      isExpanded.includes(t.id) ? removeElement(t.id) : setIsExpanded([...isExpanded, t.id])
                                    }}>
                                      {isExpanded.includes(t.id) ? 'Read Less' : 'Read More'}
                                    </button>

                                  )}</div>
                              </>}
                            </TableCell>
                            <TableCell>{`${new Date(t.created_at).getMonth() + 1} -${new Date(t.created_at).getDate() + "  "} - ${new Date(t.created_at).getFullYear()}`}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage - 1}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Feedback);
