import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import swal from "sweetalert";
import Compress from "browser-image-compression";
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import AsyncSelect from 'react-select/async';
import { getLanguage } from "./Apiget";
import "../datatables.scss";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import Image from "../../../components/Common/Image";
import Chapter from "../Chapter";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from "@mui/material/TextField";
import { AxiosError } from "axios";
// import isURL from 'validator/lib/isURL';
import '../style.css';
import { makeStyles } from "@material-ui/core";
import { TextareaAutosize } from "@mui/material";
import UnSavedChanges from "./UnSavedChanges";
import EditBookLog from "../Edit/EditBookLog";

const useStyles = makeStyles(() => ({
  removeBorder: {
    borderColor: "#ccc",
  }
}));

interface Props {
  token: string;
  user: UserRoles;
}

const AddBook = (props: Props) => {
  const classes = useStyles();

  const { id } = useParams() as {
    id?: number | undefined;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [likes, setLikes] = useState(null);
  const [author, setAuthor] = useState([] as any);
  const [language, setLanguage] = useState([] as any);
  const [links, setLinks] = useState([] as any);
  const [Author_data, setAuthor_data] = useState([] as any);
  const [language_data, setLanguage_data] = useState([] as any);
  const [frontCover, setFrontCover] = useState([] as any);
  const [coverType, setCoverType] = useState<{ value: string; label: string } | null>(null);
  const [backCover, setBackCover] = useState([] as any);
  const [primaryFront, setPrimaryFront] = useState<any>(null);
  const [primaryBack, setPrimaryBack] = useState<any>(null);
  const [nFrontCover, setNFrontCover] = useState<any>(null);
  const [nBackCover, setNBackCover] = useState<any>(null);
  const [nPrimaryFront, setNPrimaryFront] = useState<any>(null);
  const [nPrimaryBack, setNPrimaryBack] = useState<any>(null);
  const [frontModal, setFrontModal] = useState(false);
  const [backModal, setBackModal] = useState(false);
  const [subcategories, setSubcategories] = useState([] as any);
  const [subcategory, setSubcategory] = useState<{ value: number; label: string } | any>(null);
  const [category, setCategory] = useState([] as any);
  const primaryFrontRef = React.useRef<HTMLInputElement>(null);
  const [publisher, setPublisher] = useState<{ value: number; label: string } | null>(null);
  const primaryBackRef = React.useRef<HTMLInputElement>(null);
  const [imgcompressor, setImgcompressor] = useState<any>({ imageUrl: null });
  const [error, setError] = useState<any>(null);
  const [user, setUser] = useState({
    category_id: 0,
    subcategory_id: "" as any,
    author_id: "" as any,
    category_id_Error: [] as any,
    subcategory_id_Error: [] as any,
    author_id_Error: [] as any,
    top_selling: false,
    recommended: false,
    book_name: "",
    book_title: "",
    publisher: "",
    price: "",
    dop: "",
    language: "",
    language_Error: [] as any,
    discount: "",
    description: "",
    asin_number: "",
    book_name_Error: [] as any,
    book_title_Error: [] as any,
    dop_Error: [] as any,
    description_Error: [] as any,
    asin_number_Error: [] as any,
    cover_type: "" as any,
    isbn: "",
    pages: "",
    book_id: "" as any,
    links_Error: [] as any,
  });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;

  const toggleFront = () => setFrontModal(!frontModal);
  const toggleBack = () => setBackModal(!backModal);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;
    const selectedValue2 = document.getElementById('exampleFormControlInput2') as HTMLInputElement;


    e.persist();
    var temp = { ...user, [e.target.name]: e.target.value };
    setIsSaved() as any;

    if ((selectedValue.value.length)) {
      temp = { ...temp, book_name_Error: "" };
    } else {
      temp = { ...temp, book_name_Error: "*Enter Book Name" };

    }

    if ((selectedValue2.value.length)) {
      temp = { ...temp, book_title_Error: "" };
    } else {
      temp = { ...temp, book_title_Error: "*Enter Book Title" };

    }
    setUser(temp);
  };


  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    var temp = { ...user, [e.target.name]: e.target.value };
    setIsSaved() as any;
    if (!e.target.name || !e.target.value || !user["description"]) {
      temp = { ...temp, description_Error: "*Enter Description" };
    } else {
      temp = { ...temp, description_Error: "" };
    }

    setUser(temp);
  };

  // Category DropDown API
  const loadCategory = async (category_name: string) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });
      return category.data;
    }
    catch (e) {
    }
  };

  // Subcategory DropDown API
  const loadSubCategory = async (category_id: any) => {
    try {
      const subcategory = await axiosInstance.get('/sub_categories/drop-sub', {
        params: {
          category_id: category_id,
        }
      });

      setSubcategories(subcategory.data);
    }
    catch (e) {
    }
  };

  // Publisher DropDown API
  const loadPublisher = async (publisher: string) => {
    try {
      const pubs = await axiosInstance.get('/publisher/drop-publisher', {
        params: {
          publisher,
        }
      });
      return pubs.data;

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  const options = [
    { value: 'Hard', label: 'All Hard' },
    { value: 'Soft', label: 'Soft' },
    { value: 'Paper Back', label: 'Paperback' }
  ]

  // Post API
  const postBook = async () => {
    const category_id_Error = [] as any;
    const subcategory_id_Error = [] as any;
    const language_Error = [] as any;
    const book_name_Error = [] as any;
    const book_title_Error = [] as any;
    const dop_Error = [] as any;
    const description_Error = [] as any;
    const author_id_Error = [] as any;
    const links_Error = [] as any;

    if (category.length === 0) {
      category_id_Error.push("*Enter Category Name");
    } else {
      setUser({ ...user, category_id_Error: "" })
    }

    if (!subcategory) {
      subcategory_id_Error.push("*Enter Sub Category Name");
    } else {
      setUser({ ...user, subcategory_id_Error: "" })
    }

    if (!author.length) {
      author_id_Error.push("*Enter Author");
    } else {
      setUser({ ...user, author_id_Error: "" })
    }
    if (!language.length) {
      language_Error.push("*Enter Language");
    } else {
      setUser({ ...user, language_Error: "" })
    }
    if (!user.book_name) {
      book_name_Error.push("*Enter Book Name");
    } else {
      setUser({ ...user, book_name_Error: "" })
    }
    if (!user.book_title) {
      book_title_Error.push("*Enter Book Title");
    } else {
      setUser({ ...user, book_title_Error: "" })
    }

    if (!user.dop) {
      setError("*Enter Date Of Publication");
    } else {
      setError(null);
    }

    if (!user.description) {
      description_Error.push("*Enter Description");
    } else {
      setUser({ ...user, description_Error: "" })
    }

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    let foundInvalidUrl = false;

    links.forEach((s:any) => {
      if (!s) {
        foundInvalidUrl = true;
      } else if (!urlRegex.test(s)) {
        foundInvalidUrl = true;
      }
    });
    if (foundInvalidUrl) {
      links_Error.push("Please Enter A Valid URL For All Social Links");
    }

    setUser({
      ...user,
      category_id_Error,
      subcategory_id_Error,
      language_Error,
      book_name_Error,
      book_title_Error,
      author_id_Error,
      dop_Error,
      description_Error,
      links_Error,
    });

    if (
      category_id_Error.length ||
      subcategory_id_Error.length ||
      language_Error.length ||
      book_name_Error.length ||
      book_title_Error.length ||
      dop_Error.length ||
      description_Error.length ||
      author_id_Error.length ||
      links_Error.length
    )
      return;

    const data = {
      category_id: category?.value,
      subcategory_id: subcategory?.value,
      author_id: author.map((a: { value: any; }) => a.value) as any,
      book_name: user.book_name,
      book_title: user.book_title,
      publisher: publisher?.value,
      price: parseFloat(user.price),
      dop: user.dop,
      discount: parseFloat(user.discount),
      description: user.description,
      asin_number: user.asin_number,
      isbn: user.isbn,
      language: language.map((a: any) => a.value),
      pages: parseInt(user.pages),
      cover_type: coverType?.value,
      links,
      // links: links?.map((s: { value: any; url: any; }) => ({ url: s.url })),

    };
    const form = new FormData();

    form.append("info", JSON.stringify(data));

    if (frontCover.length !== 0) {
      Array.from(frontCover).forEach((f: any) => {
        form.append("Fimage", f);
      });
    }

    if (backCover.length !== 0) {
      Array.from(backCover).forEach((f: any) => {
        form.append("Bimage", f);
      });
    }

    if (primaryBack) {
      form.append("primary_back", primaryBack);
    }

    if (primaryFront) {
      form.append("primary_front", primaryFront);
    }

    try {
      setIsLoading(true);
      await axiosInstance.post(
        `${process.env.REACT_APP_API_URL}/books/book`,
        form,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }
      );

      swal({
        title: "Book Added Successfully!",
        icon: "success",
      });

      setUser({
        ...user,
        category_id: 0,
        subcategory_id: "" as any,
        author_id: "" as any,
        category_id_Error: [] as any,
        subcategory_id_Error: [] as any,
        author_id_Error: [] as any,
        top_selling: false,
        recommended: false,
        book_name: "",
        book_title: "",
        publisher: "",
        price: "",
        dop: "",
        language: "",
        language_Error: [] as any,
        discount: "",
        description: "",
        asin_number: "",
        book_name_Error: [] as any,
        book_title_Error: [] as any,
        dop_Error: [] as any,
        description_Error: [] as any,
        asin_number_Error: [] as any,
        cover_type: "" as any,
        isbn: "",
        pages: "",
        book_id: "" as any,
        links_Error: [] as any,

      });
      setCategory([]);
      setSubcategory(null);
      setFrontCover([]);
      setBackCover([]);
      setLinks([]);
      // set_val(null);
      setPrimaryBack(null);
      setPrimaryFront(null);
      setAuthor([]);
      setLanguage([]);
      setCoverType(null);
      setPublisher(null);
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
    setIsLoading(false);
  };

  // Put API
  const updateBook = async () => {
    const category_id_Error = [];
    const subcategory_id_Error = [];
    const language_Error = [];
    const book_name_Error = [];
    const book_title_Error = [];
    const description_Error = [];
    const author_id_Error = [];
    const links_Error = [];

    if (category.length === 0) {
      category_id_Error.push("*Enter Category Name");
    } else {
      setUser({ ...user, category_id_Error: "" })
    }

    if (!subcategory) {
      subcategory_id_Error.push("*Enter Sub Category Name");
    } else {
      setUser({ ...user, subcategory_id_Error: "" })
    }

    if (!author.length) {
      author_id_Error.push("*Enter Author");
    } else {
      setUser({ ...user, author_id_Error: "" })
    }
    if (!language.length) {
      language_Error.push("*Enter Language");
    } else {
      setUser({ ...user, language_Error: "" })
    }
    if (!user.book_name) {
      book_name_Error.push("*Enter Book Name");
    } else {
      setUser({ ...user, book_name_Error: "" })
    }
    if (!user.book_title) {
      book_title_Error.push("*Enter Book Title");
    } else {
      setUser({ ...user, book_title_Error: "" })
    }

    if (!user.dop) {
      setError("*Enter Date Of Publication");
    } else {
      setError(null);
    }
    if (!user.description) {
      description_Error.push("*Enter Description");
    } else {
      setUser({ ...user, description_Error: "" })
    }
    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    let foundInvalidUrl = false;

    links.forEach((s:any) => {
      if (!s) {
        foundInvalidUrl = true;
      } else if (!urlRegex.test(s)) {
        foundInvalidUrl = true;
      }
    });
    if (foundInvalidUrl) {
      links_Error.push("Please Enter A Valid URL For All Social Links");
    }

    setUser({
      ...user,
      category_id_Error,//: [] as any,
      subcategory_id_Error,//: [] as any,
      language_Error,//: [] as any,
      book_name_Error,//: [] as any,
      book_title_Error,//: [] as any,
      author_id_Error,//: [] as any,
      description_Error,//: [] as any,
      links_Error,
    });

    if (
      category_id_Error.length ||
      subcategory_id_Error.length ||
      language_Error.length ||
      book_name_Error.length ||
      book_title_Error.length ||
      description_Error.length ||
      author_id_Error.length ||
      links_Error.length

    ) {
      return;
    }

    const data = {
      category_id: category?.value,
      subcategory_id: subcategory?.value,
      author_id: author.map((a: { value: any; }) => a.value) as any,
      book_name: user.book_name,
      book_title: user.book_title,
      publisher: publisher?.value,
      price: parseFloat(user.price),
      dop: user.dop,
      discount: parseFloat(user.discount),
      description: user.description,
      language: language ? language.map((a: any) => a.value) : [],
      pages: parseInt(user.pages),
      asin_number: user.asin_number ? parseInt(user.asin_number, 10) : null,
      isbn: user.isbn ? parseInt(user.isbn, 10) : null,
      cover_type: coverType?.value,
      links,
      to_keep_front_image: nFrontCover,
      to_keep_back_image: nBackCover,
      to_keep_primary_front: nPrimaryFront,
      to_keep_primary_back: nPrimaryBack,
    };

    const form = new FormData();

    form.append("info", JSON.stringify(data));

    if (frontCover.length !== 0) {
      Array.from(frontCover).forEach((f: any) => {
        form.append("Fimage", f);
      });
    }

    if (backCover.length !== 0) {
      Array.from(backCover).forEach((f: any) => {
        form.append("Bimage", f);
      });
    }

    if (primaryBack) {
      form.append("primary_back", primaryBack);
    }

    if (primaryFront) {
      form.append("primary_front", primaryFront);
    }

    try {
      setIsLoading(true);
      await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/books/${id}`,
        form,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        }
      );

      swal({
        title: "Book updated Successfully!",
        icon: "success",
      });
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
    setIsLoading(false);
  };

  // Post and Put API Logic
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (id) {
      updateBook();
    } else {
      postBook();
    }
  };

  useEffect(() => {
    getdatas();
    getBook();
  }, []);

  // API
  async function getdatas() {
    try {
      const data = await axiosInstance.get(`/v2_authors/get-author`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      setAuthor_data(data.data);
      const languageData = await getLanguage();
      if (languageData?.data && Array.isArray(languageData?.data)) {
        const temp = languageData.data.map((lang: { language_name: any; lang_id: any; }) => {
          return {
            label: lang.language_name,
            value: lang.lang_id,
          };
        });

        setLanguage_data(temp);
        toast.info("Data loaded successfully");
      }
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  }

  // Fetch API
  const getBook = async () => {
    if (id) {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_URL}/books/admin-findone/${id}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            },
          }
        );
        const data = await res.json();
        setPublisher({
          ...data,
          value: data.publisher,
          label: data.publisher_name,
        });

        setUser({
          ...data,
          isbn: data.isbn,
          subcategory_id: {
            value: data.subcategory_id,
            label: data.subcategory_name,
          },
        }); setLikes(data.likes)
        setAuthor(data.author);
        setLanguage(data.language);
        setCoverType({ ...data, value: data.cover_type, label: data.cover_type });
        setLinks(data.links);
        setNPrimaryFront(data.primary_front);
        setNPrimaryBack(data.primary_back);
        setNFrontCover(data.front_image);
        setNBackCover(data.back_image);
        setCategory({

          value: data.category_id,
          label: data.category_name
        });
        setSubcategory({

          value: data.subcategory_id,
          label: data.subcategory_name
        });

      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');

        }
      }
    }
  };

  const renderPrimaryFront = () => {
    if (nPrimaryFront) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setNPrimaryFront(null)}
              src={`${process.env.REACT_APP_S3_APP_API}/books/${nPrimaryFront}`}
              height="auto"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      );
    }

    if (primaryFront) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setPrimaryFront(null)}
              src={URL.createObjectURL(primaryFront)}
              height="auto"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      );
    }

    return (
      <button
        className="btn btn-primary"
        onClick={(e) => {
          e.preventDefault();
          primaryFrontRef?.current?.click();
        }}
      >
        Upload front cover
      </button>
    );
  };

  const renderPrimaryBack = () => {
    if (nPrimaryBack) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setNPrimaryBack(null)}
              src={`${process.env.REACT_APP_S3_APP_API}/books/${nPrimaryBack}`}
              height="auto"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      );
    }

    if (primaryBack) {
      return (
        <Row>
          <Col xs="auto">
            <Image
              onPress={() => setPrimaryBack(null)}
              src={URL.createObjectURL(primaryBack)}
              height="auto"
              width="100px"
              alt="front"
            />
          </Col>
        </Row>
      );
    }

    return (
      <button
        className="btn btn-primary"
        onClick={(e) => {
          e.preventDefault();

          primaryBackRef?.current?.click();
        }}
      >
        Upload back cover
      </button>
    );
  };

  const renderFront = () => {
    if (nFrontCover) {
      return (
        <Row>
          {nFrontCover?.map((img: any, i: any) => (
            <Col xs="auto" key={i}>
              <Image
                src={`${process.env.REACT_APP_S3_APP_API}/books/${img}`}
                height="100px"
                width="100px"
                alt="cover"
                key={i}
                onPress={() => {
                  const temp = nFrontCover.filter((t: any) => t !== img);
                  setNFrontCover(temp);
                }}
              />
            </Col>
          ))}
          {frontCover.map((file: any, i: React.Key | null | undefined) => (
            <Col xs="auto" key={i}>
              <Image
                src={URL.createObjectURL(file)}
                height="100px"
                width="80px"
                onPress={() => {
                  const newFront = frontCover.filter(
                    (f: { name: ""; lastModified: ""; }) =>
                      f.name !== file.name && f.lastModified !== file.lastModified
                  );
                  setFrontCover(newFront);
                }}
                alt="front"
                key={i}
              />
            </Col>
          ))}
        </Row>
      );
    }

  };

  const renderBack = () => {
    if (nBackCover) {
      return (
        <Row>
          {nBackCover.map((img: any, i: any) => (
            <Col xs="auto">
              <Image
                src={`${process.env.REACT_APP_S3_APP_API}/books/${img}`}
                height="100px"
                width="100px"
                alt="cover"
                key={i}
                onPress={() => {
                  const temp = nBackCover.filter((t: any) => t !== img);
                  setNBackCover(temp);
                }}
              />
            </Col>
          ))}
          {backCover.map((file: any, i: React.Key | null | undefined) => (
            <Col xs="auto">
              <Image
                src={URL.createObjectURL(file)}
                height="100px"
                width="80px"
                onPress={() => {
                  const newBack = backCover.filter(
                    (f: any) =>
                      f.name !== file.name && f.lastModified !== file.lastModified
                  );
                  setBackCover(newBack);
                }}
                alt="front"
                key={i}
              />
            </Col>
          ))}
        </Row>
      );
    }

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id ? `UPDATE BOOK (❤️: ${likes ? likes : ''})` : 'ADD BOOK'}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>BOOK NAME*</label>
                          <input
                            type="text"
                            maxLength={150}
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Book Name"
                            name="book_name"
                            value={user.book_name}
                            onChange={onInputChange}
                          />
                          {user.book_name_Error && <div style={{ color: 'red' }}>{user.book_name_Error}</div>}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group publication-input">
                          <label>DATE OF PUBLICATION*</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              label="Date of Publication"
                              value={user.dop}
                              onChange={date => {
                                if (date) {
                                  setUser({ ...user, dop: date! });
                                  setError(null);
                                } else {
                                  setError("Enter Date of Publication");
                                }

                              }}

                              renderInput={(params) => <TextField className={classes.removeBorder} {...params} error={false} />}
                            />
                          </LocalizationProvider>
                          {error && <div style={{ color: 'red' }}>{error}</div>}

                        </div>
                      </Col>


                      <Col xs={6}>
                        <div className="form-group">
                          <label>BOOK TITLE*</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput2"
                            placeholder="Enter Book Title"
                            name="book_title"
                            value={user.book_title}
                            onChange={onInputChange}
                          />
                          {user.book_title_Error && <div style={{ color: 'red' }}>{user.book_title_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SELECT AUTHOR*</label>

                          <Select
                            className="select"
                            name="author_id"
                            placeholder="Select Author"
                            isMulti
                            value={author}
                            onChange={(e) => {
                              setAuthor(e)
                              setIsSaved() as any;
                              setUser({ ...user, author_id_Error: [] })

                            }}
                            options={Author_data}
                          />
                          {user?.author_id_Error && <div style={{ color: 'red' }}>{user?.author_id_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SELECT CATEGORY*</label>
                          <AsyncSelect placeholder="Category"
                            value={category} cacheOptions defaultOptions loadOptions={loadCategory}
                            onChange={(e: any) => {
                              setCategory(e);
                              setSubcategory(null);
                              setSubcategories([]);
                              loadSubCategory(e.value);
                              setUser({ ...user, ['category_id']: e?.value as any })
                              setUser({ ...user, category_id_Error: [] })

                            }}
                          />
                          {user?.category_id_Error && <div style={{ color: 'red' }}>{user?.category_id_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SELECT SUB CATEGORY*</label>
                          <Select placeholder="Sub Category" value={subcategory}
                            options={subcategories}
                            onChange={(e) => {
                              setSubcategory(e)
                              setIsSaved() as any;
                              setUser({ ...user, subcategory_id_Error: [] })
                            }}
                          />
                          {user?.subcategory_id_Error && <div style={{ color: 'red' }}>{user?.subcategory_id_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <label>SELECT BOOK PUBLISHER</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={publisher}
                          loadOptions={loadPublisher}
                          placeholder="Publisher"
                          onChange={setPublisher}
                        />
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>PRICE</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Price"
                            name="price"
                            value={user.price}
                            onChange={onInputChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SELECT LANGUAGE*</label>
                          <Select
                            className="select"
                            name="language_id"
                            placeholder="Select Language"
                            isMulti
                            value={language}
                            onChange={(e) => {
                              setLanguage(e)
                              setIsSaved() as any;
                              setUser({ ...user, language_Error: [] })
                              if (e.length === 0) {
                                setUser({ ...user, language_Error: "*Enter Language" })
                              }
                            }}
                            options={language_data}
                          />
                          {user?.language_Error && <div style={{ color: 'red' }}>{user?.language_Error}</div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SELECT COVER TYPE</label>
                          <Select placeholder="Cover Type" options={options} value={coverType} onChange={setCoverType} />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>PRIMARY FRONT COVER</label>
                          <input
                            type="file"
                            ref={primaryFrontRef}
                            className="form-control"
                            id="exampleFormControlInput1"
                            style={{ display: "none" }}
                            name="primary_front"
                            onChange={(e: any) => {
                              setIsSaved() as any;
                              const file = e.target.files[0];
                              if (e.target.files && e.target.files[0]) {
                                if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                  toast.error("only PNG/JPEG/JPG are supported");
                                  return;
                                }
                                if (e.target.files[0].size > 5000_000) {
                                  toast.error('File too large. Please select file less than 5MB');
                                  return;
                                }
                                const options = {
                                  maxSizeMB: 1,
                                  useWebWorker: true
                                }
                                Compress(file, options)
                                  .then((compressedBlob) => {
                                    setImgcompressor({
                                      imageUrl: URL.createObjectURL(compressedBlob)
                                    })
                                    console.log(imgcompressor)
                                    const convertedBlobFile = new File([compressedBlob], file.name, {
                                      type: file.type,
                                      lastModified: Date.now(),
                                    });
                                    setPrimaryFront(convertedBlobFile)
                                  })
                                  .catch((e) => {
                                  });
                              }
                            }}
                            accept="image/*"
                          />
                          <br />
                          {renderPrimaryFront()}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>PRIMARY BACK COVER</label>
                          <input
                            type="file"
                            ref={primaryBackRef}
                            className="form-control"
                            id="exampleFormControlInput1"
                            style={{ display: "none" }}
                            name="primary_front"
                            onChange={(e: any) => {
                              setIsSaved() as any;
                              const file = e.target.files[0];
                              if (e.target.files && e.target.files[0]) {
                                if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                  toast.error("only PNG/JPEG/JPG are supported");
                                  return;
                                }
                                if (e.target.files[0].size > 5000_000) {
                                  toast.error('File too large. Please select file less than 5MB');
                                  return;
                                }
                                const options = {
                                  maxSizeMB: 1,
                                  useWebWorker: true
                                }
                                Compress(file, options)
                                  .then((compressedBlob) => {
                                    setImgcompressor({
                                      imageUrl: URL.createObjectURL(compressedBlob)
                                    })
                                    console.log(imgcompressor)
                                    const convertedBlobFile = new File([compressedBlob], file.name, {
                                      type: file.type,
                                      lastModified: Date.now(),
                                    });
                                    setPrimaryBack(convertedBlobFile)
                                  })
                                  .catch((e) => {
                                  });
                              }
                            }}
                            accept="image/*"
                          />
                          <br />
                          {renderPrimaryBack()}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>NUMBER OF PAGES</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Number Of Pages"
                            name="pages"
                            value={user.pages}
                            onChange={onInputChange}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}

                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ISBN NUMBER</label>
                          <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter ISBN Number"
                            name="isbn"
                            value={user.isbn}
                            onChange={onInputChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>BOOK DESCRIPTION*...(3000 Characters)</label>
                          <TextareaAutosize
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            minRows={4}
                            maxLength={3000}
                            id="exampleFormControlInput1"
                            placeholder="Enter Book Description"
                            name="description"
                            value={user.description || ""}
                            onChange={onTextareaChange}
                          />
                          {user?.description_Error && <div style={{ color: 'red' }}>{user?.description_Error}</div>}
                          <label style={{ color: "#0069d9" }}>{user.description.length} characters</label>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>ASIN NUMBER</label>
                          <input
                            type="number"
                            className="form-control"
                            name="asin_number"
                            id="exampleFormControlInput1"
                            placeholder="Enter ASIN Number"
                            value={user.asin_number}
                            onChange={onInputChange}
                          />
                          {user?.asin_number_Error?.length
                            ? user?.asin_number_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>LINKS</label>
                          {links.map((item: string | number | readonly string[] | undefined, i: any) => {
                            return (
                              <input
                                type="text"
                                className="form-control"
                                name="links"
                                id="exampleFormControlInput1"
                                placeholder="Place Your Link Here"
                                value={item}
                                style={{ marginTop: 5 }}
                                onChange={(e) => {
                                  setIsSaved() as any;
                                  const temp = [...links];

                                  temp[i] = e.target.value;
                                  setLinks(temp);
                                }}
                              />
                            );
                          })}
                          {user?.links_Error?.length
                            ? user?.links_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null}
                          {/* {user?.links_Error?.length
                            ? user?.links_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                          <br />

                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setLinks((prev: any) => [...prev, ""]);
                            }}
                            className="btn btn-primary"
                          >
                            ADD LINK
                          </button>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div
                          style={{
                            display: "flex",
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <div
                            style={{
                              margin: "5px",
                              padding: "10px",
                            }}
                          >
                            <input
                              type="submit"
                              value="Submit"
                              className="btn btn-primary"
                              onClick={() => {
                                setPristine()
                              }}
                            />
                          </div>
                          <div
                            style={{
                              margin: "5px",
                              padding: "10px",
                            }}
                          >
                            <Link to="/ebookflutter_adminpanel/book" style={{ color: "white" }}>
                              <button
                                type="button"
                                className="btn btn-primary"
                              >
                                Back
                              </button>
                            </Link>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12}>
                        {id ?
                          <Chapter currentBook={{ value: user.book_id, label: user.book_title }} />
                          : ''}
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={frontModal} toggle={toggleFront}>
            <ModalHeader toggle={toggleFront}>Front Cover images</ModalHeader>
            <ModalBody>{renderFront()}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleFront}>
                Close
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={backModal} toggle={toggleBack}>
            <ModalHeader toggle={toggleBack}>Back Cover images</ModalHeader>
            <ModalBody>{renderBack()}</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleBack}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
        {id !== undefined ?
          <EditBookLog /> : <></>}
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(AddBook);
