import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import Switch from "@material-ui/core/Switch";
import VisibilityIcon from "@material-ui/icons/Visibility";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import './style.css';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { AxiosError } from "axios";
import Select from 'react-select';
import '../eBook/style.css'

interface Props {
  user: UserRoles;
  token: string | null;
}
// API data format
type CategoryType = {
  video_id: number,
  video_name: string,
  created_at: string,
  updated_at: null,
  approval_status: boolean,
  video_cover_image: string,
  likes: number,
  video_description: string,
  coach_first_name: string,
  coach_last_name: string,

}

const Video = (props: Props) => {
  const location = useLocation();
  const tableRef = useRef<HTMLTableElement>(null);
  // States for SetData
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedata, setPagedata] = useState(false);
  const [videoName, setVideoName] = useState('');
  const [showError, setShowError] = useState(false);
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const [isExpanded, setIsExpanded] = useState([] as any);
  const [pending, setPending] = useState<{ value: boolean; label: string } | null>(null);
  const [users, setUsers] = useState({
    approve: null as any,
    pending: null as any
  })
  const limit = 50;
  // Custom Hook
  const [
    { isLoading, data },
    { setOptions, setData }
  ] = useFetchHook<{
    data: CategoryType[]; numberOfPage: number; total: number | null
  }>(`${process.env.REACT_APP_API_URL}/coach/admin-video`,
    { data: [], numberOfPage: 1, total: null },
    {
      params: { page },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const approveValue = searchParams.get('approve');
    if (approveValue) {
      const value = approveValue === 'true' ? true : false;
      const option = approveOptions.find((o) => o.value === value.toString());
      if (option) {
        setApprove(option);
        setOptions({
          params: {
            approve: option?.value
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          video_name: videoName,
          approve: approve?.value,
          pending: pending?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page,approve?.value]);

  useEffect(() => {
    onFilterApply(null as any, false)
  }, [users])

  // Select Options
  const approveOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];

  const pendingOption = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };
  const redirect = (id: number) => {
    if (!props.user[modules.video_review].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`/ebookflutter_adminpanel/view/video_view/${id}`);
  }

  // Toggle API
  const updateToggle = async (video_id: number) => {
    if (!props.user[modules.video].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      // setIsLoading(true);
      const res = await axiosInstance.put(`/coach/video-approval`, { video_id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      // setIsLoading(false);
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.video_id === video_id) {
          return { ...t, approval_status: res.data.status, approved_by: res.data.approved_by };
        }

        return t;
      });
      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);

      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (videoName.length > 0 || pending != null || approve != null || (dates[0] != null && dates[1] != null)) {
        // setShowError(false);
        e.preventDefault();
        setPage(0);
        setOptions({
          params: {
            page,
            video_name: videoName,
            approve: approve?.value,
            pending: pending?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }
  }

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {

    if (videoName.length > 0 || pending != null || users.approve != null || (dates[0] != null && dates[1] != null)) {
      setShowError(false);
      if (e !== null) {
        e.preventDefault();
      }
      setPage(0);
      setOptions({
        params: {
          page,
          video_name: videoName,
          approve: users.approve?.value,
          pending: users.pending?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setVideoName('');
    setApprove(null);
    setDates(null);
    setPending(null);
    setShowError(false);
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  const removeElement = (id: any) => {
    const temp = [isExpanded];
    temp.splice(temp.indexOf(id), 1);
    setIsExpanded(temp);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">VIDEO LIST({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Video Name" name="name" value={videoName} onKeyPress={handleKeyPress} onChange={setter(setVideoName)} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approved" options={approveOptions} value={approve}
                        onChange={(value) => {
                          setApprove(value);
                          setUsers({ ...users, approve: value })
                        }} />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>

                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Pending Profile" options={pendingOption} value={pending}
                        onChange={(value) => {
                          setPending(value);
                          setUsers({ ...users, pending: value })
                        }}
                      />
                    </Col>
                  </Row><br />
                  <Row>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col></Row>


                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>COACH NAME</TableCell>
                          <TableCell>VIDEO TITLE</TableCell>
                          <TableCell>DESCRIPTION</TableCell>
                          <TableCell>CREATED DATE</TableCell>
                          <TableCell>APPROVAL STATUS</TableCell>
                          <TableCell>LIKES COUNT</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((t, index) => (
                          <TableRow key={t.video_id}>

                            <TableCell>{(page * 25) + index + 1}</TableCell>
                            <TableCell>
                              {t.coach_first_name}  {t.coach_last_name}
                            </TableCell>
                            <TableCell>{t.video_name}</TableCell>
                            <TableCell>{t.video_description.length < 50 ? t.video_description :
                              <>
                                <div style={{ width: "500px" }}>
                                  {isExpanded.includes(t.video_id) ? t.video_description : t.video_description.slice(0, limit) + '...'}
                                  {t.video_description.length > limit && (
                                    <button  className="desc-btn" onClick={() => {
                                      isExpanded.includes(t?.video_id) ? removeElement(t?.video_id) : setIsExpanded([...isExpanded, t.video_id])
                                    }}>
                                      {isExpanded.includes(t?.video_id) ? 'Read Less' : 'Read More'}
                                    </button>

                                  )}</div>
                              </>}
                            </TableCell>
                            <TableCell>{`${new Date(t.created_at).getDate() + "  "} - ${new Date(t.created_at).getMonth() + 1} - ${new Date(t.created_at).getFullYear()}`}</TableCell>
                            <TableCell>
                              <Switch checked={t.approval_status} color="primary" onChange={() => updateToggle(t.video_id)} />
                            </TableCell>
                            <TableCell>{t.likes}
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton color="primary" onClick={() => redirect(t.video_id)}>
                                  <VisibilityIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => {
                        setPage(value - 1)
                        removeElement(_e)
                      }}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Video);
