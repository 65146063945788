import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import { useSelector } from 'react-redux';
import swal from "sweetalert";
import "../datatables.scss";
import Select from 'react-select';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import TextField from '@mui/material/TextField';
import MobileDatePicker from '@mui/lab/MobileDatePicker';


const EditCoupon = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user_id = useSelector((state:any) => state.user.id);
  const [state, setState] = useState({
    coupon_code: '',
    discount_type: '',
    subscription_plan_type: '',
    description: '',
    usage: '',
    discount: '',
    expiry: new Date(),
  });
  const [valid, setValid] = useState({
    coupon_code_Error: [],
    discount_type_Error: [],
    subscription_plan_type_Error: [],
    description_Error: [],
    usage_Error: [],
    discount_Error: [],
    expiry_Error: [],
    msg_Error: [],
  });
  const { id } = useParams() as {
    id?: number | undefined;
  }
  useEffect(() => {
    getCoupon();
  }, [user_id]);
  const getCoupon = async () => {
    const res = await axiosInstance.get(
        `${process.env.REACT_APP_API_URL}/coupons/${id}`
      );
      const data = res.data.data[0];
      setState({
        coupon_code: data.coupon_code,
        discount_type: data.discount_type,
        subscription_plan_type: data.subscription_plan_type,
        description: data.description,
        usage: data.usage,
        discount: data.discount,
        expiry: new Date(data.expiry),
      })
  }
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const coupon_code_Error = [] as any,
         discount_type_Error = [] as any,
         subscription_plan_type_Error = [] as any,
        //  description_Error = [] as any,
         usage_Error = [] as any,
         discount_Error = [] as any,
         msg_Error = [] as any,
         expiry_Error = []as any;
    const alphaNum = new RegExp("^[a-zA-Z0-9]*$");

    if (!state.coupon_code) coupon_code_Error.push("Enter coupon code");
    if(!alphaNum.test(state.coupon_code)) coupon_code_Error.push("Coupon Code should be alpha Numeric")
    setValid({ ...valid, coupon_code_Error });
    if (!state.discount_type) discount_type_Error.push("Enter Discount Type");
    setValid({ ...valid, discount_type_Error });
    if (!state.subscription_plan_type) subscription_plan_type_Error.push("Enter Subscription Plan Type");
    setValid({ ...valid, subscription_plan_type_Error });
    if (!state.usage) usage_Error.push("Enter Usage");
    setValid({ ...valid, usage_Error });
    if (!state.expiry) expiry_Error.push("Enter Expiry Date");
    setValid({ ...valid, expiry_Error });
    if (!state.discount) discount_Error.push("Enter Discount");
    setValid({ ...valid, discount_Error });

    // if (coupon_code_Error.length || discount_Error || subscription_plan_type_Error.length || expiry_Error.length || usage_Error.length || discount_type_Error.length) return;

    if (isLoading === false) {
      setIsLoading(true);
      const res1 = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/coupons/${id}`,
        {
            ...state,
            updated_by: user_id,
        }
      );
      console.log(res1);

      if (res1?.data?.success === 0) {
        setIsLoading(false);
        msg_Error.push(res1?.data?.message);
        setValid({ ...valid, msg_Error });
        if (msg_Error.length) return;
      }

      setIsLoading(false);
      swal({
        title: "Updated Successfully!",
        icon: "success",
        // button: "OK!",
      });
    }

    setValid({
      ...valid,
      msg_Error: [] as any,
    });
  };

  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xs={12}>
            <Card>
              <CardBody>
                <h4 className="card-title">EDIT COUPON</h4>
                <form onSubmit={(e:any) => onSubmit(e)}>
                  <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>COUPON CODE*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="coupon_code"
                            value={state.coupon_code}
                            id="exampleFormControlInput1"
                            placeholder="Enter Coupon Code"
                            onChange={(e) => onInputChange(e)}
                          />

                          {valid?.coupon_code_Error.length
                            ? valid?.coupon_code_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>DISCOUNT TYPE*</label>
                          <Select
                            options={[
                                { value: 'percentage', label: 'Percentage' },
                                { value: 'value', label: 'Value' },
                            ]}
                            placeholder="Select Type"
                            isSearchable={false}
                            value={[
                                { value: 'percentage', label: 'Percentage' },
                                { value: 'value', label: 'Value' },
                            ].filter(i => i.value == state.discount_type)[0]}
                            onChange={(e:any) => (setState({...state, discount_type: e.value}))}
                          />

                          {valid?.discount_type_Error.length
                            ? valid?.discount_type_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>DISCOUNT*</label>
                          <input
                            type="number"
                            className="form-control"
                            name="discount"
                            value={state.discount}
                            id="exampleFormControlInput1"
                            placeholder="Enter Discount"
                            onChange={(e) => onInputChange(e)}
                          />

                          {valid?.discount_Error.length
                            ? valid?.discount_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>DESCRIPTION</label>
                          <input
                            type="text"
                            className="form-control"
                            name="description"
                            value={state.description}
                            id="exampleFormControlInput1"
                            placeholder="Enter Coupon Description"
                            onChange={(e) => onInputChange(e)}
                          />

                          {valid?.description_Error.length
                            ? valid?.description_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>USAGE*</label>
                          <input
                            type="number"
                            className="form-control"
                            name="usage"
                            value={state.usage}
                            id="exampleFormControlInput1"
                            placeholder="How many times can the code be used?"
                            onChange={(e) => onInputChange(e)}
                          />

                          {valid?.usage_Error.length
                            ? valid?.usage_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group" style={{display: 'flex', flexDirection: 'column'}}>
                          <label>EXPIRY*</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                                label="Expiry Date"
                                value={state.expiry}
                                //  style={{padding: "5px"}}
                                onChange={(newValue:any) => {
                                  setState({ ...state, expiry: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                          {valid?.expiry_Error.length
                            ? valid?.expiry_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>Subscription Plan Type*</label>
                          <Select
                            options={[
                            { value: 'free', label: 'Free Plan' },
                            { value: 'monthly', label: 'Monthly Subscription' },
                            { value: 'yearly', label: 'Yearly Subscription' },
                            ]}
                            placeholder="Select Type"
                            isSearchable={false}
                            value={[
                                { value: 'free', label: 'Free Plan' },
                                { value: 'monthly', label: 'Monthly Subscription' },
                                { value: 'yearly', label: 'Yearly Subscription' },
                            ].filter(i => i.value === state.subscription_plan_type)[0]}
                            onChange={(e:any) => (setState({...state, subscription_plan_type: e.value}))}
                          />

                          {valid?.subscription_plan_type_Error.length
                            ? valid?.subscription_plan_type_Error.map((error, i) => (
                                <ErrorSpan key={i} error={error} />
                              ))
                            : null}
                        </div>
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/coupons"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

export default EditCoupon;
