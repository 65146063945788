// THIS IS ENTRY POINT OF THE PROJECT WHERE APP COMPONENT IS CALLED AND IN THE APP COMPONENT
// REACT VIEW PAGES ARE SHOWN
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import {store} from "./store/index";

const app = (
  // PROVIDER IS USED TO CONNECT THE PROJECT TO REACT -REDUX STORE
  <Provider store={store}>
    <ToastContainer position="bottom-center" />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
