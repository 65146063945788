import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
// import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import axiosInstance from "../../../helpers/axios";
import swal from "sweetalert";
import "../datatables.scss";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import { connect } from 'react-redux';
import UnSavedChanges from "./UnSavedChanges";

interface Props {
  token: string;
  user: UserRoles;

}
const AddGenre = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState({
    geners_name: "",
  });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;

  const [valid, setValid] = useState({
    geners_name_Error: "",
    msg_Error: [],
  });

  useEffect(() => {
  }, [users]);


  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = document.getElementById('exampleFormControlInput1') as HTMLInputElement;

    e.persist();
    setUsers({ ...users, [e.target.name]: e.target.value });
    setIsSaved() as any;

    if ((selectedValue.value.length)) {
      setValid({ ...valid, geners_name_Error: "" })
    } else {
      setValid({ ...valid, geners_name_Error: "*Enter genre name" });

    }
    // if (!e.target.name || !e.target.value || !users.geners_name) {
    //   setValid({ ...valid, geners_name_Error: "*Enter genre name" });
    // } else {
    //   setValid({ ...valid, geners_name_Error: "" })
    // }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const msg_Error = [] as any;
      const geners_name_Error = [] as any;
      // const msg_Error = [];

      if (!users.geners_name) {
        geners_name_Error.push("*Enter genre name");
      } else {
        setValid({ ...valid, geners_name_Error: "" })
      }

      // if (users.geners_name.trim().length === 0) geners_name_Error.push("Enter genre name");
      setValid({ ...valid, geners_name_Error });

      if (geners_name_Error.length) return;

      if (isLoading === false) {
        setIsLoading(true);
        const res1 = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/genre/genre`,
          users,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );

        if (res1?.data?.success === 0) {
          setIsLoading(false);
          msg_Error.push(res1?.data?.message);
          setValid({ ...valid, msg_Error });
          if (msg_Error.length) return;
        }

        setIsLoading(false);
        swal({
          title: "Added Successfully!",
          text: users.geners_name,
          icon: "success",
          // button: "OK!",
        });
      }

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      } else {
        toast.error('Something went wrong. Please try again later');

      }

    }
    setIsLoading(false);
    setValid({
      ...valid,
      msg_Error: [],
    });

    setUsers({
      ...users,
      geners_name: "",
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>

                  <h4 className="card-title"> {id !== undefined ? "UPDATE GENRE" : "ADD GENRE"}</h4>
                  <form onSubmit={(e) => onSubmit(e)}>


                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>GENRE NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="geners_name"
                            value={users.geners_name}
                            id="exampleFormControlInput1"
                            placeholder="Enter genre name"
                            onChange={onInputChange}

                          />
                          {valid?.geners_name_Error && <div style={{ color: 'red' }}>{valid?.geners_name_Error} </div>}

                          {/* {valid?.geners_name_Error.length
                            ? valid?.geners_name_Error.map((error, i) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}
                          />
                          {/* <button type="submit" className="btn btn-primary">
                            Submit
                          </button> */}
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/genre"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AddGenre);


