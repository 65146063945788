import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
	Paper,
	TableContainer,
	Table,
	TableBody,
	TableHead,
	TableRow,
	TableCell,
} from "@material-ui/core";
import Select from 'react-select';
import './style.css';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";
import IconButton from "@material-ui/core/IconButton";
import swal from "sweetalert";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { TableSortLabel } from "@mui/material";

type SortOrder = "asc" | 'desc' | undefined;

interface Props {
	user: UserRoles;
	token: string | null;
}

// interface MyType {
// 	[key: string]: any;
// }

// API data format
type CategoryType = {
	category_id: number;
	category_name: string;
	noOfBooks: number;
	image: string;
	top: boolean;
	headerId: string | null,
}

// States Of SetData
const Category = (props: Props) => {
	const tableRef = useRef<HTMLTableElement>(null);
	const history = useHistory();
	const [dates, setDates] = useState<any>([null, null]);
	const [page, setPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [name, setName] = useState('');
	const [top, setTop] = useState<{ value: boolean; label: string } | null>(null);
	const [sortOrder, setSortOrder] = useState<SortOrder>('asc');
	const [showError, setShowError] = useState(false);
	const [pagedata, setPagedata] = useState(false);
	const [filter, setFilter] = useState("");
	const [selectedOrder, setSelectedOrder] = useState<SortOrder>("asc");

	// Custom Hook For API
	const [
		{ isLoading, data },
		{ setOptions, setData, fetchData }
	] = useFetchHook<{
		data: CategoryType[]; numberOfPage: number; total: number | null
	}>(`${process.env.REACT_APP_API_URL}/categories/get-category/`,
		{ data: [], numberOfPage: 1, total: null },
		{
			params: { page },
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		});


	const TopCategoryOption = [
		{ value: true, label: "Active" },
		{ value: false, label: "InActive" }
	]

	const tableHeaders = [
		{ id: "image", label: "IMAGE" },
		{ id: "category_name", label: "CATEGORY NAME" },
		{ id: "top", label: "TOP" },
	];

	tableHeaders.forEach((item, index) => {
		otherFunction(item.id, index);
	});

	function otherFunction(id: string, index: number) {
	}


	const handleSort = async (id: string) => {
		setFilter(id);
		setSelectedOrder(sortOrder);
		const columnType = id === 'top' ? "null" : 'string';
		setOptions({
			params: {
				page,
				order: sortOrder,
				column_name: id,
				column_type: columnType,
			},
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		});

		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
	};

	const copyTable = () => {
		const table = tableRef.current;
		if (table) {
			const range = document.createRange();
			range.selectNode(table);
			window.getSelection()?.removeAllRanges();
			window.getSelection()?.addRange(range);
			document.execCommand('copy');
			window.getSelection()?.removeAllRanges();
			toast.info("Copied To Clipboard")
		}
	}

	const exportToExcel = () => {
		const table = document.querySelector('table');
		const html = table?.outerHTML;
		if (html) {
			const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
			const dataUrl = URL.createObjectURL(blob);
			const downloadLink = document.createElement('a');
			downloadLink.href = dataUrl;
			downloadLink.download = 'table.xls';
			document.body.appendChild(downloadLink);
			downloadLink.click();
			document.body.removeChild(downloadLink);
			URL.revokeObjectURL(dataUrl);
		}
	}

	const convertTableToCSV = (table: any) => {
		let csv = '';
		const rows = table.querySelectorAll('tr');
		for (let i = 0; i < rows.length; i++) {
			const cells = rows[i].querySelectorAll('th, td');
			for (let j = 0; j < cells.length; j++) {
				csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
			}
		}
		return csv;
	};

	const handleDownloadCSV = () => {
		const tableNode = document.getElementById('table-data');
		const csvData = convertTableToCSV(tableNode);
		const downloadLink = document.createElement('a');
		downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
		downloadLink.download = 'table-data.csv';
		downloadLink.click();
	};

	useEffect(() => {
		if (pagedata) {
			var payload = {
				page,
				name: name,
				top: top?.value,
				dates,
			} as any;

			if (filter != "") {
				payload["order"] = selectedOrder;
				payload["column_name"] = filter;
				// payload["column_type"] = type === 'string' ? "string" : null;
				payload["column_type"] = filter === 'top' ? null : 'string';

			}
			setOptions({
				params: payload,
				headers: {
					Authorization: `Bearer ${props.token}`,
				}
			});
		}
		else {
			setPagedata(true);
		}
		setCurrentPage(page + 1);
	}, [page]);


	// debugger;
	const deleteUser = async (category_id: number) => {
		if (!props.user[modules.categories].includes(permissions.DELETE)) {
			toast.error(errorMsg);
			return;
		}

		try {
			const req = await swal({
				buttons: ['No', 'Yes'],
				title: "Are you sure?",
				text: "Once deleted, you will not be able to recover this Category Name!",
				icon: "error",
				dangerMode: true,

			});

			if (!req) {
				return;
			}
			// setIsLoading(true);
			const res = await axiosInstance.delete(
				`${process.env.REACT_APP_API_URL}/categories/${category_id}`,
				{
					headers: {
						Authorization: `Bearer ${props.token}`,
					}

				});
			if (res.data.error) {
				toast.error(res.data.error, { autoClose: false, style: { width: '500px' } });
				return;
			}
			fetchData();
			swal("Your Category Name has been deleted!", {
				icon: "success",
			});
		} catch (e) {
			toast.error('Something went wrong. Please try again later');
		}
	};

	const redirectEdit = (id: number) => {
		if (!props.user[modules.categories].includes(permissions.UPDATE)) {
			toast.error(errorMsg);
			return;
		}
		window.open(`/ebookflutter_adminpanel/edit-category/${id}`);
	};

	const redirectAdd = () => {
		if (!props.user[modules.categories].includes(permissions.WRITE)) {
			toast.error(errorMsg);
			return;
		}
		history.push(`add-category`);
	};


	//  Top Category Toggle API
	const topSwitchChange = async (id: number) => {
		try {
			const res = await axiosInstance.put(`categories/top/${id}`, {}, {
				headers: {
					Authorization: `Bearer ${props.token}`,
				}
			});

			const newState = data.data.map((u) => {
				if (u.category_id === res.data.category_id) {
					return { ...u, top: res.data.top };

				}
				return u;
			});
			setData((t) => ({ ...t, data: newState }));
		} catch (e) {
			toast.error('Something went wrong! please try again later');
		}
	};

	const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
		action(e.target.value);
	};

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			if (name.length > 0 || top != null || (dates[0] != null && dates[1] != null)) {
				setShowError(false);
				e.preventDefault();
				setPage(1);
				setOptions({
					params: {
						page: 1,
						name: name,
						top: top?.value,
						dates,
					},
					headers: {
						Authorization: `Bearer ${props.token}`,
					}
				});
			}
			else {
				setShowError(true);
			}
		}
	};
	// Filter
	const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, b: any = null) => {
		var i;
		if (b === null) {
			i = (top?.value)
		} else {
			i = (b['value'])
		}
		if (name.length > 0 || i != null || (dates[0] != null && dates[1] != null)) {
			setShowError(false);
			if (e !== null) {
				e.preventDefault();
			}
			setPage(1);
			setOptions({
				params: {
					page: 1,
					name: name,
					top: i,
					dates,
				},
				headers: {
					Authorization: `Bearer ${props.token}`,
				}
			});
		}
		else {
			setShowError(true);
		}
	};

	// Reset
	const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setName('');
		setTop(null);
		setShowError(false);
		setDates([null, null])
		setOptions({
			params: {
				page,
			},
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		});
	}


	return (
		<React.Fragment>
			<div className="page-content">
				<Container fluid>
					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<Row>
										<Col xs={6}>
											<h4 className="card-title">CATEGORY LIST ({data.total})</h4>

										</Col>
										<Col xs={6}
											style={{ display: "flex", justifyContent: "flex-end" }} >
											<Button
												variant="contained"
												style={{ color: "blue", }}
												onClick={redirectAdd}
											>
												Add Category
											</Button>
										</Col>
									</Row>
									<hr />
									<Row>
										<Col xs={3}>
											<Input placeholder="Category Name" value={name} onKeyPress={handleKeyPress} onChange={setter(setName)} />
										</Col>
										<Col xs={3}>
											<Select placeholder="Top Category" value={top}
												options={TopCategoryOption} onChange={(value) => {
													setTop(value);
													onFilterApply(null as any, value);
												}}
											/>
										</Col>
										<Col xs={3}>
											<div>
												<DateRangePicker placeholder="Select Date Range" onKeyPress={handleKeyPress} format="MM/dd/yyyy" onChange={setDates} value={dates} />

											</div>
										</Col>
										<Col xs="auto">
											<button className="btn btn-primary"
												onClick={onFilterApply}>Apply</button>
										</Col>
										<Col xs="auto">
											<button className="btn btn-primary" onClick={onResetApply}>Reset</button>
										</Col>

									</Row>
									<div className="msg-box">
										{showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
									</div>

									<br />
									<button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
									<button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
									<button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>

									<TableContainer component={Paper}>
										<Table id="table-data" ref={tableRef}>
											<TableHead>
												<TableRow>
													<TableCell>SR NO</TableCell>
													{tableHeaders.map((header) => (
														<TableCell key={header.id}>
															<TableSortLabel
																active={header.id === "image"}
																direction={sortOrder}
																// {sortOrder === 'asc' ? 'Sort Descending' : 'Sort Ascending'}
																onClick={() => handleSort(header.id)}
															>
																{header.label}
															</TableSortLabel>
														</TableCell>
													))}
													<TableCell>ACTION</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>

												{data.data
													.map((user, index) => (
														<TableRow key={user.category_id}>
															<TableCell >  {((page - 1) * 25) + index + 1}</TableCell>
															<TableCell>
																<img
																	className="rounded-circle avatar-sm"
																	src={user.image ? `${process.env.REACT_APP_S3_APP_API}/category/${user.image}`
																		: `${process.env.REACT_APP_S3_APP_API}/demobook`
																	}
																	alt="profile"

																/>
															</TableCell>
															<TableCell>{user.category_name}</TableCell>

															{/* {user.category_name} */}

															<TableCell>
																<Switch checked={user.top} color="primary" onChange={() => topSwitchChange(user.category_id)} />
															</TableCell>
															<TableCell>
																<div className="innercell">
																	<IconButton color="primary" onClick={() => redirectEdit(user.category_id)}>
																		<EditIcon />
																	</IconButton>
																	<Link
																		to="#"
																		onClick={() => {
																			deleteUser(user.category_id);
																		}}
																	>
																		<DeleteIcon />
																	</Link>
																</div>
															</TableCell>
														</TableRow>
													))}
											</TableBody>
										</Table>
										<Pagination
											count={data.numberOfPage}
											color="primary"
											variant="outlined"
											showFirstButton={true}
											showLastButton={true}
											page={currentPage - 1}
											onChange={(_e, value) => setPage(value)}
										/>
									</TableContainer>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
				{isLoading ? <Loader /> : null}
			</div>
		</React.Fragment>
	);
};

// Redux
const mapStateToProps = (state: StoreState) => {
	return {
		token: state.token!,
		user: state.user,
	};
};

export default connect(mapStateToProps)(Category);
