import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import Select from "react-select";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import axiosInstance from "../../helpers/axios";
import swal from "sweetalert";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import validator from 'validator';
import Modal from 'react-modal';
import '../eBook/style.css';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";


const EditMobileUsers = (props) => {
  // States for setData
  const { id } = useParams();
  // const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [mobile, setMobile] = useState('');
  // const [oldMobile, setOldMobile] = useState("");
  // const [errorMessage, setErrorMessage] = useState('');
  // const [isLoading, setIsLoading] = useState(false);
  const [signDate, setSignDate] = useState("")
  const [modifiedDate, setModifiedDate] = useState("")
  const [state, setState] = useState("");
  // const [password, setPassword] = useState("");
  const [valid, setValid] = useState({

    fname_error: "",
  });

  const [transactions, setTrs] = useState([]);
  const [userPlan, setUserPlan] = useState(null);
  // const [userPlan, setUserPlan] = useState({ value: number, label: string } )

  const [errors, setErrors] = useState({
    nameError: null,
    emailError: null,
    cityError: null,
    countryError: null,
    mobileError: null,
    stateError: null,
    imageError: null,
  });
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [input, setInput] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  });

  const [error, setError] = useState({
    username: '',
    password: '',
    confirmPassword: ''
  })

  // Static Dropdown
  const plans = [
    { label: 'Free', value: 5 },
    { label: 'Premium Lifetime', value: 4 },
    { label: 'Premium for one year', value: 6 },
  ];

  useEffect(() => {
    fetchUser();
    fetchTrs();
  }, []);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '540px',
      padding: '60px',
    },
  };

  // Email API
  const checkEmail = (email) => {
    if (email.length > 0) {
      if (oldEmail !== email) {
        if (String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )) {

          if (id) {
            axiosInstance.post(`${process.env.REACT_APP_API_URL}/register/email-exists`,
              {
                "email": email
              }
            ).then((res) => {

              if (res?.data?.exists) {
                setErrors({ ...errors, emailError: 'Email Already Exists' })

              } else {
                setErrors({ ...errors, emailError: null })
              }
            }).catch((e) => console.log(e))
          }
        } else {
          setErrors({ ...errors, emailError: 'Please Enter Valid Email' })
        }
      } else {
        setErrors({ ...errors, emailError: null })

      }
    }
    else {
      setErrors({ ...errors, emailError: "Please Enter Email" })
    }
  }

  // Mobile Number API
  // const checkMobile = (mobile) => {
  //   if (mobile.length > 0) {
  //     if (oldMobile !== mobile) {
  //       if (id) {
  //         axiosInstance.post(`${process.env.REACT_APP_API_URL}/register/phone-exists`,
  //           {
  //             "phone": mobile
  //           }
  //         ).then((res) => {

  //           if (res.data.exists) {
  //             setErrors({ ...errors, mobileError: "Mobile Number Already Exists" })
  //           }
  //         }).catch((e) => console.log(e))
  //       }
  //     } else {
  //       setErrors({ ...errors, mobileError: "Same Phone is already exist. Please contact website Administrator" })

  //     }
  //   }
  //   else {
  //     setErrors({ ...errors, mobileError: "Please Enter Mobile Number" })
  //   }

  // }

  // Fetch API
  const fetchUser = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/register/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });

        setName(res.data.fname + ' ' + res.data.lname);
        setEmail(res.data.email);
        setOldEmail(res.data.email);
        setCity(res.data.city)
        setCountry(res.data.country);
        setMobile(res.data.mobileno);
        setState(res.data.state);
        setUserPlan(res.data.plan_id);
        setSignDate(res?.data?.created_at)
        setModifiedDate(res?.data?.updated_at)
      } catch (e) {
        toast.error('Unable to load user, please try again later');
      }
    }
  };

  // Fetch API
  const fetchTrs = async () => {
    try {
      const res = await axiosInstance.get(`/pay/transactions/${id}`, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });
      // console.log(res.data[0].plan)
      // setUserPlan({
      //   value: res.data[0].plan.id,
      //   label: res.data[0].plan.name,
      // });
      // setUserPlan()
      setTrs(res.data);

    } catch (e) {
      toast.error('Unable to load transactions, please try again later');
    }
  };

  // Put API
  const update = async () => {
    let fname = name.split(' ')[0];
    let lname = name.split(' ')[1]
    const fname_error = [];

    if (!fname || !lname) {
      fname_error.push('*First and last Name is required');
    } else {
      setValid({ ...valid, fname_error: "" })
    }

    // if (fname.trim().length < 1 && lname.trim().length < 1) {
    //   toast.error('Name is required');
    //   return;
    // }
    if (errors.emailError != null && errors.mobileError != null) {
      toast.error('Email is required');
      return;
    }


    setValid({ ...valid, fname_error, });

    if (fname_error.length) return;
    console.log("jh", mobile)
    try {
      await axiosInstance.put(`/register/admin/${id}`, {
        fname,
        lname,
        country,
        city,
        state,
        email,
        mobileno: mobile === null ? "" : mobile,
      }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });

      if ([4, 5, 6].includes(userPlan)) {
        await axiosInstance.post('/pay/premium-lifetime', {
          user_id: parseInt(id, 10),
          plan_id: userPlan,
        }, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
      }
      swal({
        title: "User Updated Successfully!",
        icon: "success",
        button: "OK!",
      });
    } catch (e) {
      const error = e.response.data.error;
      if (error) {
        toast.error(error)
      } else {
        toast.error("Unable to update user. Please try again later");
      }
    }
  };


  const onInputChange = async (e) => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value
    }));
    validateInput(e);
  }

  // Strong validation Logic
  const validateInput = e => {

    let { name, value } = e.target;
    setError(prev => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "username":
          if (!value) {
            stateObj[name] = "Please enter Username.";
          }
          break;

        case "password":
          if (!validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {
            stateObj[name] = "please Enter Strong Password"
          } else if (!value) {
            stateObj[name] = "Please enter Password.";
          } else if (input.confirmPassword && value !== input.confirmPassword) {
            stateObj["confirmPassword"] = "Password and Confirm Password does not match.";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword ? "" : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter Confirm Password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] = "Password and Confirm Password does not match.";
          }
          break;

        default:
          break;
      }

      return stateObj;
    });
  }
  // submit button
  const onSubmit = (e) => {
    e.preventDefault();

    if (id) {
      update();

    } else {
      console.log("No ID")
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  const passwordSubmit = async (e) => {
    try {
      const res = await axiosInstance.put(
        `${process.env.REACT_APP_API_URL}/register/changepassword/${id}`,
        {

          change_password: input.password,
          confirm_password: input.confirmPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });


      if (res?.data?.success === 0) {
        setInput({ ...input });
        return;
      }
      // setIsLoading(false);
      swal({
        title: "Password Successfully Change!",
        icon: "success",
      });
    } catch (e) {
      // setIsLoading(false);
      const error = e;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      } else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">EDIT USER</h4>
                  <form onSubmit={(e) => onSubmit(e)}>
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            name="category_name"
                            value={name}
                            id="exampleFormControlInput1"
                            placeholder="Enter name"
                            onChange={(e) => {
                              const selectedValue2 = document.getElementById('exampleFormControlInput1').value;

                              setName(e.target.value)

                              if ((selectedValue2.length)) {
                                setValid({ ...valid, fname_error: "" })
                              } else {
                                setValid({ ...valid, fname_error: "*First and last Name is required" });

                              }
                              // if (!e.target.value || !name) {
                              //   setValid({ ...valid, fname_error: "*First and last Name is required" });
                              // } else {
                              //   setValid({ ...valid, fname_error: "" })
                              // }
                            }
                            }
                          />
                          {valid?.fname_error && <div style={{ color: 'red' }}>{valid?.fname_error} </div>}

                        </div>
                      </Col>
                      <Col xs={6}>
                        <label>Mobile</label>
                        <PhoneInput
                          defaultCountry="US"

                          placeholder="Enter your mobile no"
                          name="mobileno"
                          value={mobile}
                          onChange={(e) => {
                            setMobile(e);
                            // try {
                            //   if ((e).length > 0) {
                            //     if (!isValidPhoneNumber(e)) {
                            //       setValid({ ...valid, authors_phone_number: ["Invalid Phone Number"] });
                            //     } else {
                            //       setValid({ ...valid, authors_phone_number: [] });
                            //     }
                            //   }


                            // } catch (error) {
                            //   setValid({ ...valid, authors_phone_number: [] });
                            // }
                          }
                          }
                        />

                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>E-mail*</label>
                          <Input
                            type="text"
                            className="form-control"
                            name="authors_bio"
                            id="exampleFormControlInput1"
                            placeholder="Enter E-mail"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                              checkEmail(e.target.value);
                            }}
                          />
                          {errors.emailError ? (
                            <ErrorSpan error={errors.emailError} />
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            id="exampleFormControlInput1"
                            placeholder="Enter Country"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          />
                          {errors.countryError ? (
                            <ErrorSpan error={errors.countryError} />
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            id="exampleFormControlInput1"
                            placeholder="Enter City"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          />
                          {errors.cityError ? (
                            <ErrorSpan error={errors.cityError} />
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>State</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            id="exampleFormControlInput1"
                            placeholder="Enter State"
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                          />
                          {errors.stateError ? (
                            <ErrorSpan error={errors.stateError} />
                          ) : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <label>User Plan</label>
                        <Select
                          options={plans}
                          // value={userPlan}
                          value={plans.find((val) => val.value === userPlan)}
                          onChange={(e) => setUserPlan(e.value)}
                        />

                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SIGNUP</label>
                          <input
                            type="text"
                            className="form-control"
                            name="created_at"
                            id="exampleFormControlInput1"
                            placeholder="Sign Up"
                            readOnly
                            value={`${new Date(signDate).getDate()} - ${new Date(signDate).getMonth() + 1} - ${new Date(signDate).getFullYear()}`}
                            onChange={(e) => setSignDate(e.target.value)}
                          />

                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>LAST MODIFIED DATE</label>
                          <input
                            type="text"
                            className="form-control"
                            name="created_at"
                            id="exampleFormControlInput1"
                            placeholder="Sign Up"
                            readOnly
                            value={modifiedDate === null ? '-' : `${new Date(modifiedDate).getDate()} - ${new Date(modifiedDate).getMonth() + 1} - ${new Date(modifiedDate).getFullYear()}`}
                            onChange={(e) => setModifiedDate(e.target.value)}
                          />

                        </div>
                      </Col>


                      <Col xs={6}>
                        <div >
                          <br />
                          <button type="button"
                            className="password-btn"
                            onClick={openModal}>Change Password</button>
                        </div>
                      </Col>

                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {/* {" "} */}
                          <Link
                            to="/ebookflutter_adminpanel/mobileUsers"
                            style={{ color: "white" }}
                          >
                            <button
                              type="text"
                              className="btn btn-primary"
                            >
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                  <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <form className="modal-passwd"
                      onClick={(e) => passwordSubmit(e)}
                    >
                      <h3 ref={(_subtitle) => (subtitle = _subtitle)}>PassWord</h3>
                      <input
                        className="modal-input"
                        type="password"
                        name="password"
                        placeholder='Enter Password'
                        value={input.password}
                        onChange={onInputChange}
                        onBlur={validateInput}

                      />
                      {error.password ? (
                        <ErrorSpan error={error.password} />
                      ) : null}
                      <br /><br />
                      <h3 >Confirm PassWord</h3>

                      <input
                        className="modal-input"
                        type="password"
                        name="confirmPassword"
                        placeholder='Enter Confirm Password'
                        value={input.confirmPassword}
                        onChange={onInputChange}
                        onBlur={validateInput} />

                      {error.confirmPassword ? (
                        <ErrorSpan error={error.confirmPassword} />
                      ) : null}

                      <div className="submit-section text-center mt-3">
                        <button className="modal-submit" onClick={closeModal}>Update</button></div>

                    </form>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {transactions.length ?
                <Card>
                  <CardBody>
                    <h4 className="card-title">TRANSACTIONS</h4>
                    <TableContainer component={Paper}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>SR NO</TableCell>
                            <TableCell>DATE OF TRANSACTION </TableCell>
                            <TableCell> PLAN NAME</TableCell>
                            <TableCell> GATEWAY</TableCell>
                            <TableCell>GATEWAY TRANSACTION ID</TableCell>
                            <TableCell>TRANSACTION ID</TableCell>
                            <TableCell>START DATE</TableCell>
                            <TableCell>END DATE</TableCell>
                            <TableCell>AMOUNT </TableCell>
                            <TableCell>PAYMENT STATUS</TableCell>
                            <TableCell>TRANSACTION TYPE</TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {transactions.map((trs, i) => {
                            return (
                              <>

                                <TableRow key={trs.id} style={{ backgroundColor: (i) ? '#B8E2F2' : '#ECFFDC' }}>

                                  <TableCell>  {i + 1}</TableCell>
                                  <TableCell>{trs.transaction_date}</TableCell>
                                  <TableCell> {trs.plan.name}</TableCell>
                                  <TableCell> {trs.gateway.name}</TableCell>
                                  <TableCell> {trs.transaction_gateway_id}</TableCell>
                                  <TableCell> {trs.transaction_public_id}</TableCell>
                                  <TableCell>{(trs.start_at === null) ? "-" : `${new Date(trs.start_at).getMonth() + 1}  - ${new Date(trs.start_at).getDate() + "  "} - ${new Date(trs.start_at).getFullYear()}`}
                                  </TableCell>
                                  <TableCell>{(trs.end_at === null) ? "-" : `${new Date(trs.end_at).getMonth() + 1}  - ${new Date(trs.end_at).getDate() + "  "} - ${new Date(trs.end_at).getFullYear()}`}
                                  </TableCell>
                                  <TableCell> ${trs.amount / 1000}</TableCell>
                                  <TableCell>{trs.payment_status} </TableCell>

                                  <TableCell>{(trs.ip_address === null) ? "Renewed" : "Original"}</TableCell>
                                </TableRow>
                              </>
                            )
                          })}

                        </TableBody>
                      </Table>
                    </TableContainer>   </CardBody>

                </Card>
                : null}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps)(EditMobileUsers);
