import React, { useEffect, useState, useRef } from "react";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { toast } from "react-toastify";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import axiosInstance from "../../helpers/axios";
import Switch from "@material-ui/core/Switch";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user'
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import '../eBook/style.css';

interface Props {
  token: string;
  user: UserRoles;
}


type RecommendedRes = {
  book_id: number;
  book_name: string;
  recommended: boolean;
  top_selling: boolean;
  publisher: string;
  category_name: string;
  primary_front: string;
  likes: number;
};

const Recommended = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States for SetData
  const [page, setPage] = useState(0);
  const [dates, setDates] = useState<any>([null, null]);
  const [bookName, setBookName] = useState('');
  const [bookTitle, setBookTitle] = useState('');
  const [publisher, setPublisher] = useState<{ value: number; label: string } | null>(null);
  const [author, setAuthor] = useState<{ value: number; label: string } | null>(null);
  const [category, setCategory] = useState<{ value: number; label: string } | null>(null);
  const [subcategories, setSubcategories] = useState([] as any);
  const [subcategory, setSubcategory] = useState<{ value: number; label: string } | any>(null);
  const [language, setLanguage] = useState<{ value: number; label: string } | null>(null);
  const [coverType, setCoverType] = useState<{ value: string; label: string } | null>(null);
  const [topSelling, setTopSelling] = useState<{ value: boolean; label: string } | null>(null);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [newUsers, setNewusers] = useState({
    publisher: null as any,
    category: null as any,
    subcategory: null as any,
    language: null as any,
    author: null as any,
    coverType: null as any,
  })

  // Custom Hook
  const [{ isLoading, data }, { setOptions }] =
    useFetchHook<{
      data: RecommendedRes[]; numberOfPage: number; total: number | null,
    }>(`${process.env.REACT_APP_API_URL}/books/admin-books`,
      { data: [], numberOfPage: 1, total: null },
      {
        params: { page, recommended: true, },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

  useEffect(() => {
    onFilterApply(null as any, false)
  }, [newUsers])

  // useEffect
  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          book_name: bookName,
          book_title: bookTitle,
          publisher: publisher?.value,
          author: author?.value,
          category: category?.value,
          subcategory: subcategory?.value,
          language: language?.value,
          coverType: coverType,
          topSelling: topSelling?.value,
          recommended: true,
          cover_type: coverType?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    } else {
      setPagedata(true);
    }

    setCurrentPage(page + 1);
  }, [page]);

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };


  // Select Options
  const coverTypeOptions = [
    { value: 'Hard', label: 'Hard' },
    { value: 'Soft', label: 'Soft' },
    { value: 'Paper Back', label: 'Paperback' }
  ];

  // Publisher DropDown API
  const loadPublisher = async (publisher: string) => {
    try {
      const pubs = await axiosInstance.get('/publisher/drop-publisher', {
        params: {
          publisher,
        }
      });

      return pubs.data;
    } catch (e) { }
  };

  // Author DropDown API
  const loadAuthor = async (author: string) => {
    try {
      const authors = await axiosInstance.get('/v2_authors/get-author', {
        params: {
          name: author,
        }
      });

      return authors.data;
    } catch (e) { }
  };

  // Category DropDown API
  const loadCategory = async (category_name: string) => {
    try {
      const category = await axiosInstance.get('/categories/drop-category', {
        params: {
          category: category_name,
        }
      });

      return category.data;
    }
    catch (e) {
    }
  };

  // SubCategory DropDown API
  const loadSubCategory = async (category_id: any) => {
    try {
      const subcategory = await axiosInstance.get('/sub_categories/drop-sub', {
        params: {
          category_id: category_id,
        }
      });
      setSubcategories(subcategory.data);
    }
    catch (e) {
    }
  };

  // Language DropDown API
  const loadLang = async (lang: string) => {
    try {
      const language = await axiosInstance.get('/language/drop-lang', {
        params: {
          lang,
        }
      });

      return language.data;
    } catch (e) { }
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (bookName.length > 0 || bookTitle.length > 0 || publisher != null || author != null || category != null || subcategory != null || language != null || topSelling != null || coverType != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(0);
        setOptions({
          params: {
            page,
            book_name: bookName,
            book_title: bookTitle,
            publisher: publisher?.value,
            author: author?.value,
            category: category?.value,
            subcategory: subcategory?.value,
            language: language?.value,
            topSelling: topSelling?.value,
            recommended: true,
            cover_type: coverType?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }
  }

  // Filter API
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, isFilterapply: boolean) => {
    if (bookName.length > 0 || bookTitle.length > 0 || publisher != null || author != null || category != null || subcategory != null || language != null || topSelling != null || coverType != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setPage(0);
      setOptions({
        params: {
          page,
          book_name: bookName,
          book_title: bookTitle,
          publisher: publisher?.value,
          author: author?.value,
          category: category?.value,
          subcategory: subcategory?.value,
          language: language?.value,
          topSelling: topSelling?.value,
          recommended: true,
          cover_type: coverType?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setBookName('');
    setBookTitle('');
    setPublisher(null);
    setAuthor(null);
    setCategory(null);
    setSubcategory(null);
    setLanguage(null);
    setCoverType(null);
    setTopSelling(null);
    setDates([null, null])
    setShowError(false);
    setOptions({
      params: {
        page,
        recommended: true,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">RECOMMENDED LIST ({data?.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Book Name" value={bookName} onKeyPress={handleKeyPress} onChange={setter(setBookName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Book Title" value={bookTitle} onKeyPress={handleKeyPress} onChange={setter(setBookTitle)} />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={publisher}
                        loadOptions={loadPublisher}
                        placeholder="Publisher"
                        onChange={(value) => {
                          setPublisher(value);
                          setNewusers({ ...newUsers, publisher: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Author" value={author} cacheOptions defaultOptions loadOptions={loadAuthor}
                        onChange={(value) => {
                          setAuthor(value);
                          setNewusers({ ...newUsers, author: value })
                        }} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Category" value={category} cacheOptions defaultOptions loadOptions={loadCategory} onChange={(e: any) => {
                        setCategory(e);
                        setSubcategory(null);
                        setSubcategories([]);
                        loadSubCategory(e.value);
                        setNewusers({ ...newUsers, category: e })
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Sub Category" value={subcategory} options={subcategories}
                        onChange={(value) => {
                          setSubcategory(value);
                          setNewusers({ ...newUsers, subcategory: value })
                        }} />
                    </Col>
                    <Col xs={3}>
                      <AsyncSelect placeholder="Language" value={language} cacheOptions defaultOptions loadOptions={loadLang}
                        onChange={(value) => {
                          setLanguage(value);
                          setNewusers({ ...newUsers, language: value })
                        }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Cover Type" options={coverTypeOptions} value={coverType} onChange={(value) => {
                        setCoverType(value);
                        setNewusers({ ...newUsers, coverType: value })
                      }} />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>FRONT IMAGE</TableCell>
                          <TableCell>BOOK NAME</TableCell>
                          <TableCell>PUBLISHER</TableCell>
                          <TableCell>CATEGORY</TableCell>
                          <TableCell>RECOMMENDED</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.book_id}>
                              <TableCell>{(page * 25) + index + 1}</TableCell>
                              <TableCell>
                                <img
                                  style={{ height: "50px", width: "80px" }}
                                  src={user.primary_front ? `${process.env.REACT_APP_S3_APP_API}/books/${user.primary_front}`
                                    : `${process.env.REACT_APP_S3_APP_API}/books/books.png`}
                                  alt="books"
                                />
                              </TableCell>
                              <TableCell style={{ width: "300px" }}>{user.book_name}</TableCell>
                              <TableCell>{user.publisher}</TableCell>
                              <TableCell>{user.category_name}</TableCell>
                              <TableCell>
                                <Switch checked={user?.recommended} color="primary" disabled />
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value - 1)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Recommended);
