import { logoutAction, registerUserAction, ActionTypes, UserRoles } from './user';

const initialState: UserRoles = {
  name: '',
  email: '',
  role_id: 0,
  active: true,
  roles: [],
  users: [],
  author: [],
  books: [],
  top_selling: [],
  recommended: [],
  categories: [],
  chapters: [],
  mobile_slider: [],
  subcategories: [],
  social_media: [],
  publisher: [],
  genre: [],
  feedback: [],
  mobileUsers: [],
  feed: [],
  author_review: [],
  book_review: [],
  log: [],
  email_templates: [],
  coupons: [],
  mobileusers: [],
  coach: [],
  coach_review: [],
  audio_review: [],
  video_review: [],
  audio: [],
  video: [],
  audio_view: [],
  notification: [],
  admin_notification:[],
  notification_template: [],
  newUser:[],
  portal_users:[],
};

export const userReducer = (state: UserRoles = initialState, action: logoutAction | registerUserAction): UserRoles => {
  switch (action.type) {
    case ActionTypes.REGISTER_USER:
      return { ...action.payload, mobileUsers: ["READ"] };

    case ActionTypes.LOGOUT_USER:
      return {
        name: '',
        email: '',
        role_id: 0,
        active: true,
        roles: [],
        users: [],
        author: [],
        books: [],
        top_selling: [],
        recommended: [],
        categories: [],
        chapters: [],
        mobileUsers: [],
        mobile_slider: [],
        subcategories: [],
        social_media: [],
        publisher: [],
        genre: [],
        feed: [],
        feedback: [],
        author_review: [],
        book_review: [],
        log: [],
        email_templates: [],
        coupons: [],
        mobileusers: [],
        coach: [],
        coach_review: [],
        audio_review: [],
        video_review: [],
        audio: [],
        video: [],
        audio_view: [],
        notification: [],
        admin_notification:[],
        notification_template: [],
        newUser:[],
        portal_users:[],
      };
    default:
      return state;
  }
}
