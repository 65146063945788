import React, { useState, useEffect,useRef } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../components/ui/loader/Loader";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { useFetchHook } from "../../utils/fetchHook";
import { connect } from "react-redux";
import axiosInstance from "../../helpers/axios";
import { toast } from "react-toastify";
import { errorMsg, modules, permissions } from "../../utils/constants";
import Select from 'react-select';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import AsyncSelect from 'react-select/async';
import images from '../../assets/images';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'

interface Props {
  token: string;
  user: UserRoles;
}

//  API Parameter
type MobileUsersType = {
  user_id: number,
  loginstatus: boolean,
  fname: string,
  lname: string,
  email: string,

  mobileno: number,
  referal_code: string,
  user_platform: string,
  user_platform_version: string,
  userplatform: string,
  plantype: string,
  created_at: string,
  sub_name:string,
  gateway_name:string,
  payment_date:string,
  payment_status:string,
  transaction_id:number
}

const MobileUsers = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States for setData
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [fname, setFname] = useState('');
  const [transaction, setTransaction] = useState('');
  const [lname, setlname] = useState('');
  const [email, setEmail] = useState('');
  const [mobileno, setMobileno] = useState('');
  const [referal_code, setReferal_code] = useState('')
 const [gateway, setGateway] = useState<{ value: string; label: string } | null>(null);
  const [payment_status, setPayment_status] = useState<{ value: string; label: string } | null>(null);
  const [planType, setPlanType] = useState<{ value: number; label: string } | null>(null);
  const [userplatform, setLoginPlatform] = useState<{ value: string; label: string } | null>(null);
  const [showError, setShowError] = useState(false);
  const [pagedata, setPagedata] = useState(false);
  const [dates, setDates] = useState<any>([null, null]);
  const [users, setUsers] = useState({
    planType: null as any,
    gateway: null as any,
    userplatform: null as any,
    payment_status: null as any,
  })
  // Fetch Hook
  const [{ isLoading, data }, { setIsLoading, setOptions, setData }] = useFetchHook
    <{ data: MobileUsersType[]; numberOfPage: number; total: number | null, }>
    (`${process.env.REACT_APP_API_URL}/register/get-user`,
      { data: [], numberOfPage: 1, total: null },
      {
        params: { page, loginStatus: true, },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      useEffect(() => {
        onFilterApply(null as any, false)
      }, [users])

  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          fname: fname,
          lname:lname,
          email: email,
          mobileno: mobileno,
          transaction_id :transaction,
          planType: planType?.value,
          payment_status:payment_status?.value,
          gateway:gateway?.value,
          referal_code: referal_code,
          userplatform: userplatform?.value,
          loginStatus: true,
          dates,

        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        },
      });
    } else {
      setPagedata(true);
    }

    setCurrentPage(page + 1);
  }, [page]);

  // Switch API
  const onSwitch = async (id: number, loginStatus: boolean) => {
    if (!props.user[modules.users].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      setIsLoading(true);
      const res = await axiosInstance.put(
        `/register/loginStatus/${id}`,
        { loginstatus: !loginStatus },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      );

      const newData = data.data.map((t) => {
        const temp = { ...t };

        if (temp.user_id === id) {
          temp.loginstatus = !loginStatus;
          console.log(res);
        }

        return temp;
      });

      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      console.log(e);
      toast.error("Unable to switch. please try again later");
    }
    setIsLoading(false);
  };


  const redirectEdit = (user_id: number) => {
    if (!props.user[modules.users].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`edit-mobile-user/${user_id}`);
  };

  const redirectView = (user_id: number) => {
    if (!props.user[modules.users].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`view-mobile-user/${user_id}`);
  };


  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  // DropDown API
  const loadPlanType = async (planType: String) => {
    try {
      const pubs = await axiosInstance.get('users/drop-userplan/', {
        params: {
          planType,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      return pubs.data;
    } catch (e) { }
  };

// Static DropDown
  const options2 = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "IOS",
      value: "IOS",
    },
    {
      label: "ANDROID",
      value: "ANDROID",
    },

  ];
  const paymentoption = [
    {
      label: "Funding",
      value: "Funding",
    },
    {
      label: "Refund",
      value: "Refund",
    },
    {
      label: "Cancel",
      value: "Cancel",
    },
    {
      label: "Success",
      value: "Success",
    },
    {
      label: "Failed",
      value: "Failed",
    },

  ];
  const gatewayoption = [
    {
      label: "Stripe",
      value: "Funding",
    },
    {
      label: "in app purchase",
      value: "Inapppurchase",
    },
  ];

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (fname.length > 0  || transaction.length > 0 || lname.length > 0 || email.length > 0 || mobileno.length > 0 || planType != null || referal_code.length > 0 || payment_status != null || gateway != null || userplatform != null || (dates[0] != null && dates[1] != null)) {
        e.preventDefault();
        setShowError(false);
        setPage(1);
        setOptions({
          params: {
            page,
            fname: fname,
            lname:lname,
            email: email,
            mobileno: mobileno,
            transaction_id :transaction,
            planType: planType?.value,
            payment_status:payment_status?.value,
            gateway:gateway?.value,
            referal_code: referal_code,
            userplatform: userplatform?.value,
            loginStatus: true,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);

      }
    }}

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement> ,isFilterapply: boolean) => {

    if (fname.length > 0  || transaction.length > 0 || lname.length > 0 || email.length > 0 || mobileno.length > 0 || planType != null || referal_code.length > 0 || payment_status != null || gateway != null || userplatform != null || (dates[0] != null && dates[1] != null)) {
      if (e !== null) {
        e.preventDefault();
      }
      setShowError(false);
      setPage(1);
      setOptions({
        params: {
          page,
          fname: fname,
          lname:lname,
          email: email,
          mobileno: mobileno,
          transaction_id :transaction,
          planType: planType?.value,
          payment_status:payment_status?.value,
          gateway:gateway?.value,
          referal_code: referal_code,
          userplatform: userplatform?.value,
          loginStatus: true,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }


    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFname('');
    setlname('');
    setEmail('');
    setTransaction('');
    setMobileno('');
    setPlanType(null);
    setShowError(false);
    setPayment_status(null);
    setGateway(null);
    setReferal_code('');
    setLoginPlatform(null);
    setOptions({
      params: {
        page,
        loginStatus: true,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">MOBILE USER LIST({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="First Name" value={fname} onKeyPress ={handleKeyPress}  onChange={setter(setFname)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Last Name" value={lname} onKeyPress ={handleKeyPress}  onChange={setter(setlname)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Email" value={email} onKeyPress ={handleKeyPress}  onChange={setter(setEmail)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Phone" value={mobileno}
                        name="mobileno"
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        // maxLength={10}
                        onChange={setter(setMobileno)} />
                    </Col>

                  </Row>
                  <br />
                  <Row>
                  <Col xs={3}>
                      <AsyncSelect
                        cacheOptions
                        defaultOptions
                        value={planType}
                        loadOptions={loadPlanType}
                        placeholder="Plan Type"
                        // onChange={setPlanType}
                        onChange={(value) => {
                          setPlanType(value);
                          setUsers({ ...users, planType: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="Referral code " value={referal_code} onKeyPress ={handleKeyPress}  onChange={setter(setReferal_code)} />
                    </Col>

                    <Col xs={3}>
                      <Select placeholder="Login Platform" name="userplatform" options={options2} value={userplatform}
                      // onChange={setLoginPlatform}
                      onChange={(value) => {
                        setLoginPlatform(value);
                        setUsers({ ...users, userplatform: value })
                      }} />
                    </Col>

                    </Row><br/>
                    <Row>
                    <Col xs={3}>
                      <Input placeholder="Transaction Id" value={transaction}  onKeyPress ={handleKeyPress}  onChange={setter(setTransaction)} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Payment Status" name="userplatform" options={paymentoption} value={payment_status}
                      // onChange={setPayment_status}
                      onChange={(value) => {
                        setPayment_status(value);
                        setUsers({ ...users, payment_status: value })
                      }} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Gateway" name="gateway" options={gatewayoption} value={gateway}
                      // onChange={setGateway}
                      onChange={(value) => {
                        setGateway(value);
                        setUsers({ ...users, gateway: value })
                      }} />
                    </Col>

                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                    </Row>

                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>NAME</TableCell>
                          <TableCell>E-MAIL</TableCell>
                          <TableCell>PHONE</TableCell>
                           <TableCell>REFERAL CODE</TableCell>
                           <TableCell>GATEWAY</TableCell>
                           <TableCell>LAST TRANSACTION DATE</TableCell>
                           <TableCell>PLAN TYPE</TableCell>
                          <TableCell>SIGNUP DATE</TableCell>
                          <TableCell>LOGIN WITH PLATFORM</TableCell>
                          <TableCell>ACTIVE</TableCell>
                          <TableCell>ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((temp, i) => (
                          <TableRow key={temp.user_id}>
                            <TableCell>
                               {((page - 1) * 25) + i + 1}
                            </TableCell>
                            <TableCell>
                              {temp.fname + " " + temp.lname}
                            </TableCell>
                            <TableCell>{temp.email}</TableCell>
                            <TableCell>{temp.mobileno}</TableCell>
                            <TableCell>{temp.referal_code}</TableCell>
                            <TableCell>{temp.gateway_name}</TableCell>
                            {/* <TableCell>{ (temp.payment_date === null) ? "-" :`${new Date(temp.payment_date).getDate() + "  "} - ${new Date(temp.payment_date).getMonth() + 1} - ${new Date(temp.payment_date).getFullYear()}`}</TableCell> */}
                            <TableCell>{ (temp.payment_date === null) ? "-" :temp.payment_date}</TableCell>
                            <TableCell>{temp.sub_name}</TableCell>
                            <TableCell>{`${new Date(temp.created_at).getMonth() + 1} - ${new Date(temp.created_at).getDate() + "  "} - ${new Date(temp.created_at).getFullYear()}`}</TableCell>
                            <TableCell>{temp.user_platform === "android" ? <img src={images.android} /> : <img src={images.apple} />}</TableCell>
                            <TableCell>
                              <Switch
                                checked={temp.loginstatus}
                                color="primary"
                                name="top_selling"
                                onChange={() => onSwitch(temp.user_id, temp.loginstatus)}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton
                                  color="primary"
                                  onClick={() => redirectView(temp.user_id)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton
                                  color="primary"
                                  onClick={() => redirectEdit(temp.user_id)}
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage - 1}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(MobileUsers);
