import React, { createContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, CardBody } from "reactstrap";
import "./datatables.scss";
import DeleteIcon from "@material-ui/icons/Delete";
import axiosInstance from "../../helpers/axios";
import EditIcon from "@material-ui/icons/Edit";
import swal from "sweetalert";
import Button from "@material-ui/core/Button";
import Loader from "../../components/ui/loader/Loader";
import { connect } from "react-redux";
import { errorMsg, modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";

export const newContext = createContext(0);

const Chapter = (props: any) => {
  // States for SetData
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [chapters, setChapters] = useState([] as any);
  const [topFifty, setTopFifty] = useState([] as any);

  useEffect(() => {
    if (props?.currentBook && props?.currentBook.value > 0) {
      getChapters();
      getTopFifty();
    }
  }, [props?.currentBook.value]);

  // Get API
  const getChapters = async () => {
    try {
      const res = await axiosInstance.get(
        `/chapters/admin-chapter/${props.currentBook.value}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      setChapters(res.data);
    } catch (e) {
      console.log(e);
      toast.error("Unable to load Summary");
    }
  };

  const getTopFifty = async () => {
    try {
      const res = await axiosInstance.get(
       
        `${process.env.REACT_APP_API_URL}/booktoptopics/${props.currentBook.value}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      );
      setTopFifty(res.data);
    } catch (e) {
      console.log(e);
      toast.error("Unable to load Summary");
    }
  };

  const deletetopuser = async (booktop_id: any) => {
    if (!props.user[modules.chapters].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Book!",
      icon: "error",
      dangerMode: true,
    }).then(async (deletetopuser) => {
      if (deletetopuser) {
        if (isLoading == false) {
          setIsLoading(true);
          const res1 = await axiosInstance.delete(
            `${process.env.REACT_APP_API_URL}/booktoptopics/delbooktopics/${props.currentBook.value}/${booktop_id}`,
            {
              headers: {
                Authorization: `Bearer ${props.token}`,
              },
            }
          );
          setIsLoading(false);
          getChapters();
          getTopFifty();
          swal(res1.data.message, {
            icon: "success",
          });
        }
      } else {
      }
    });
  };

  const deleteUser = async (chapter_id: any) => {
    if (!props.user[modules.chapters].includes(permissions.DELETE)) {
      toast.error(errorMsg);
      return;
    }
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Book!",
      icon: "error",
      dangerMode: true,
    }).then(async (deleteUser) => {
      if (deleteUser) {
        if (isLoading == false) {
          setIsLoading(true);
          const res1 = await axiosInstance.delete(
            `${process.env.REACT_APP_API_URL}/chapters/${chapter_id}`
          );
          setIsLoading(false);
          getChapters();
          swal(res1.data.message, {
            icon: "success",
          });
        }
      } else {
      }
    });
  };

  const redirectAdd = () => {
    if (!props.user[modules.chapters].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    if (!props.currentBook) {
      toast.error("Please select book to add summary");
      return;
    }
    history.push(
      `add-chapter/${props.currentBook.value}`,
      props.currentBook.value
    );
  };
  const redirectTopTen = (topid:string) => {
    if (!props.user[modules.chapters].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }

    if (!props.currentBook) {
      toast.error("Please select book to add summary");
      return;
    }
    history.push(
      `/ebookflutter_adminpanel/add-top-ten/${props.currentBook.value}/${topid}`,
      props.currentBook.value
    );
  };

  localStorage.setItem("bookId", props.currentBook.value);
  const redirectEdit = (id: any) => {
    if (!props.user[modules.chapters].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    if (!props.currentBook) {
      toast.error("Please select book to add summary");
      return;
    }
    history.push({
      pathname: `/ebookflutter_adminpanel/edit-chapter/${props.currentBook.value}/${id}`,
      state: props.currentBook,
    });
  };
  const redirectTopTenEdit = (booktopic_id: any) => {
    let editId = 1
    if (!props.user[modules.chapters].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    if (!props.currentBook) {
      toast.error("Please select book to add summary");
      return;
    }
    history.push({
      pathname: `/ebookflutter_adminpanel/edit-top/${props.currentBook.value}/${booktopic_id}/${editId}`,
      state: props.currentBook,
    });
  };

  return (
    <React.Fragment>
      <div className="page-content" style={{ padding: "40px 0px 0px 0px" }}>
        <div>
          <Row>
            <Col xs={12}>
              <div className="mb-4">
                <div>
                  <Row>
                    <Col xs={6}>
                      <label>MANAGE SUMMARY</label>
                    </Col>
                    <Col xs={6}>
                      <div className="text-right">
                        
                        <Button
                          variant="contained"
                          style={{ color: "blue" }}
                          // onClick={redirectTopTen}
                          onClick={() =>
                            redirectTopTen('top10')
                          }
                          
                        >
                          Top 10
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          variant="contained"
                          style={{ color: "blue" }}
                          // onClick={redirectTopTen}
                          onClick={() =>
                            redirectTopTen('top30')
                          }
                        >
                          Top 30
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          variant="contained"
                          style={{ color: "blue" }}
                          // onClick={redirectTopTen}
                          onClick={() =>
                            redirectTopTen('top50')
                          }
                        >
                          Top 50
                        </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button
                          variant="contained"
                          style={{ color: "blue" }}
                          onClick={redirectAdd}
                        >
                          Add Summary
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>

              <label>TOP LINES</label>
              <div className="mb-4">
                <Row>
                  <Col xs={12}>
                    <div className="row">
                      {topFifty?.data?.map((item: any) => (
                        <div className="col-4">
                          <div className=" card m-1">
                            <CardBody>
                              <Row>
                                <Col xs={6}>
                                  {/* <h4>{item.booktopic_id.toUpperCase()}</h4> */}
                                  <h5>{item.booktop_title}</h5>
                                </Col>
                                <Col xs={6}>
                                  <IconButton
                                    color="primary"
                                    onClick={() =>
                                      redirectTopTenEdit(item.booktopic_id)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      deletetopuser(item.booktopic_id)
                                    }
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>

              <label>SUMMARY</label>
              <div>
                <Row>
                  <Col xs={12}>
                    <div className="row">
                      {chapters?.map((item: any) => (
                        <div className="col-3">
                          <div className="card m-1">
                            <CardBody>
                              <Row>
                                <Col xs={6}>
                                  <h5>{item.chapter_name}</h5>
                                </Col>
                                <Col xs={6}>
                                  <IconButton
                                    color="primary"
                                    onClick={() =>
                                      redirectEdit(item.chapter_id)
                                    }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <Link
                                    to="#"
                                    onClick={() => deleteUser(item.chapter_id)}
                                  >
                                    <DeleteIcon />
                                  </Link>
                                </Col>
                              </Row>
                            </CardBody>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Chapter);
