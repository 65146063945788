import React, { useState,useRef,useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import Button from "@material-ui/core/Button";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import EditIcon from "@material-ui/icons/Edit";
// import { useFetchHook } from "../../utils/fetchHook";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import { errorMsg,modules, permissions } from "../../utils/constants";
import { toast } from "react-toastify";
import axiosInstance from "../../helpers/axios";
// import { errorMsg, modules, permissions } from "../../utils/constants";

interface Props {
	user: UserRoles;
	token: string | null;
}

// type EmailTemplatesType = {
//   id: number,
//   template_name: String,
// }

const EmailTemplates = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>({ data: [] }) as any;
  const [allPageCount, setAllPageCount] = useState(0);


      const loadUser = async () => {
        setIsLoading(true);
        const res = await axiosInstance.get(
          `${process.env.REACT_APP_API_URL}/email_templates/get-templates/?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${props.token}`,
            }
          }
        );
        setIsLoading(false);
        setData(res?.data);
        setAllPageCount(res?.data?.numberOfPages);
      };


      useEffect(() => {
        loadUser();
      }, []);

      useEffect(() => {
        loadUser();
      }, [page]);


  const redirectEdit = (id: number) => {
    history.push({ pathname: "/ebookflutter_adminpanel/edit_template", state: { id: id } });
  };

  const redirectAdd = () => {
		if (!props.user[modules.email_templates].includes(permissions.WRITE)) {
			toast.error(errorMsg);
			return;
		}
		history.push(`add-emailtemplate`);
	};

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };

  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">EMAIL TEMPLATES({data.total})</h4>
                    </Col>
                    <Col xs={6}
											style={{ display: "flex", justifyContent: "flex-end" }} >
											<Button
												variant="contained"
												style={{ color: "blue", }}
												onClick={redirectAdd}
											>
												Add Emailtemplate
											</Button>
										</Col>
                  </Row>
                  <hr />
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>TEMPLATE NAME</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((template:any, index:any) => (
                            <TableRow key={template.id}>
                              <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                              <TableCell>{template.template_name}</TableCell>
                              <TableCell>
                                <IconButton color="primary" onClick={() => redirectEdit(template.id)}>
                                  <EditIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={allPageCount}
                      // page={allPageCount}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading && <Loader />}
        {/* {isLoading ? <Loader /> : null} */}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(EmailTemplates);
