// IMPORT ALL DEPENDENCY
import React from 'react'
import { Link, useHistory } from 'react-router-dom';
import { CardBody, Col, Row } from "reactstrap";
import { useState } from 'react';
import { Card } from '@mui/material';
import { toast } from "react-toastify";
import axiosInstance from '../../helpers/axios';
import ErrorSpan from '../../components/ui/errorSpan/ErrorSpan';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import '../eBook/style.css'
import 'react-phone-number-input/style.css'
import logodark from "../../assets/images/login_logo.png";

const ForgetMobileNumber = () => {
  // STATES
  const history = useHistory();
  const [mobile, setMobile] = useState('');
  const [valid, setValid] = useState({
    forget_phone_number: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  // THIS EVENT IS USED TO SUBMIT FROM WITH API IMPLEMENTATION WITH ERROR HANDLING
  const onSubmit = async (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (valid.forget_phone_number.length > 0) {
      return;
    }
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/admin/verifying-identity`,
          {
            "verify_field": mobile
          }
        );
        if (res.status === 200) {
          toast.success(res.data.message)
        }
        const id = res.data.user.id;
        history.push(`/ebookflutter_adminpanel/send-otp/${id}`);
      } catch (e:any) {
        setIsLoading(false);
        const error = e;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
  };

  // CUSTOM CSS
  const testForget = {
    width: "500px", margin: "0 auto"
  };

  // THIS IS JSX ALL THE INPUT,DRO-DOWN ,TEXTAREA VALUE IS SET WITH THE USE OF STATE
  return (
    <React.Fragment>
      <div className="page-content container">
        <Card sx={{ p: 3, }}>
          <CardBody>
            <span className="logo-lg">
              <img style={{ width: "20%" }} src={logodark} alt="" />
            </span>
            <form
              style={testForget}
              onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0", textAlign: "center" }} className='heading'>
                Enter Your Mobile Number</h4>
              <hr /><br />
              <Row>
                <Col >
                  <div className="form-group">
                    <PhoneInput
                      defaultCountry="US"

                      className='phone-input-new'
                      placeholder="Enter your mobile no"
                      name="verify_field"
                      value={mobile}
                      onChange={(e:any) => {

                        try {
                          if ((e).length > 0) {
                            if (!isValidPhoneNumber(e)) {
                              setValid({ ...valid, forget_phone_number: ["Invalid Phone Number"] as any});
                            } else {
                              setValid({ ...valid, forget_phone_number: [] });
                            }
                          } setMobile(e);

                        } catch (error) {
                          setValid({ ...valid, forget_phone_number: [] });
                        }
                      }
                      }
                    />
                    {valid.forget_phone_number.length > 0 ? (
                      <ErrorSpan error={valid.forget_phone_number} />
                    ) : null}
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  display: "flex",
                  margin: "5px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                    textAlign: "center"
                  }}
                >
                  <input
                    type="submit"
                    value="Submit"
                    className="btn btn-primary"
                  />
                </div>
                <div
                  style={{
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  {" "}
                  <Link
                    to="/ebookflutter_adminpanel/login"
                    style={{ color: "white" }}
                  >
                    <button type="button" className="btn btn-primary">
                      Back
                    </button>
                  </Link>
                </div>
              </div>
              <div className="mt-4 text-center">
                <Link
                  to="/ebookflutter_adminpanel/forgot-password"
                  className="text-muted">
                  <i className="mdi mdi-lock mr-1"></i> Or reset by Email Address
                </Link>
              </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>

  )
}

export default ForgetMobileNumber;
