import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useHistory, useLocation } from "react-router-dom";
import Select from 'react-select';
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import './style.css';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user';
import Switch from "@material-ui/core/Switch";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import { errorMsg, modules, permissions } from "../../utils/constants";
import axiosInstance from "../../helpers/axios";
import { toast } from "react-toastify";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { AxiosError } from "axios";
import '../eBook/style.css'

interface Props {
  user: UserRoles;
  token: string | null;
}
// API data format
type AudioType = {
  id: number,
  audio_id: number,
  audio_name: string,
  created_at: string,
  updated_at: null,
  approval_status: boolean,
  audio_cover_image: string,
  likes: number,
  audio_description: string,
  coach_first_name: string,
  coach_last_name: string,
}

const Audio = (props: Props) => {
  const location = useLocation();
  const tableRef = useRef<HTMLTableElement>(null);
  // states for setData
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedata, setPagedata] = useState(false);
  const [audioName, setAudioName] = useState('');
  const [showError, setShowError] = useState(false);
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const [pending, setPending] = useState<{ value: boolean; label: string } | null>(null);
  const [isExpanded, setIsExpanded] = useState([] as any);
  const [users, setUsers] = useState({
    approve: null as any,
    pending: null as any
  })
  const limit = 50;
  // Custom Hook
  const [{ isLoading, data }, { setOptions, setData }] = useFetchHook
    <{ data: AudioType[]; numberOfPage: number; total: number | null, }>
    (
      `${process.env.REACT_APP_API_URL}/coach/admin-audio`,
      { data: [], numberOfPage: 1, total: null },
      {
        params: { page },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const approveValue = searchParams.get('approve');
    if (approveValue) {
      const value = approveValue === 'true' ? true : false;
      const option = approvedOptions.find((o) => o.value === value.toString());
      if (option) {
        setApprove(option);
        setOptions({
          params: {
            approve: option.value
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
      }
    }
  }, [location]);

  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          audio_name: audioName,
          approve: approve?.value,
          pending: pending?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page, approve?.value]);

  useEffect(() => {
    onFilterApply(null as any, false)
  }, [users])

  const approvedOptions = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];

  const pendingOption = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' }
  ]

  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  const redirect = (id: number) => {
    if (!props.user[modules.audio].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`/ebookflutter_adminpanel/view/audio_view/${id}`);
  }

  // Toggle API
  const updateToggle = async (audio_id: number) => {
    if (!props.user[modules.audio].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }

    try {
      const res = await axiosInstance.put(`/coach/audio-approval`, { audio_id },
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.audio_id === audio_id) {
          return { ...t, approval_status: res.data.status, approved_by: res.data.approved_by };
        }

        return t;
      });

      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');

      }
    }
  };


  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (audioName.length > 0 || pending != null || approve != null || (dates[0] != null && dates[1] != null)) {

        setShowError(false);
        e.preventDefault();
        setPage(0);
        setOptions({
          params: {
            page,
            audio_name: audioName,
            approve: approve?.value,
            pending: pending?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);

      }
    }
  }

  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {
    if (audioName.length > 0 || pending != null || users.approve != null || (dates[0] != null && dates[1] != null)) {

      setShowError(false);
      if (e !== null) {
        e.preventDefault();
      }
      setPage(0);
      setOptions({
        params: {
          page,
          audio_name: audioName,
          approve: users.approve?.value,
          pending: users.pending?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  };

  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAudioName('');
    setApprove(null);
    setPending(null);
    setDates([null, null]);
    setShowError(false);
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  const removeElement = (id: any) => {
    const temp = [isExpanded];
    temp.splice(temp.indexOf(id), 1);
    setIsExpanded(temp);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">AUDIO LIST ({data.total})</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }} >
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Audio Name" name="name" value={audioName} onKeyPress={handleKeyPress} onChange={setter(setAudioName)} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approved" options={approvedOptions} value={approve}

                        onChange={(value) => {
                          setApprove(value);
                          setUsers({ ...users, approve: value })
                        }}
                      />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>             </Col>

                    <Col xs={3}>
                      <Select placeholder="Pending Profile" options={pendingOption} value={pending}
                        onChange={(value) => {
                          setPending(value);
                          setUsers({ ...users, pending: value })
                        }}
                      />
                    </Col>
                  </Row><br />
                  <Row>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>

                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>COACH NAME</TableCell>
                          <TableCell>AUDIO NAME</TableCell>
                          <TableCell>DESCRIPTION</TableCell>
                          <TableCell>CREATED DATE</TableCell>
                          <TableCell>APPROVAL STATUS</TableCell>
                          <TableCell>LIKES COUNT</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data
                          .map((user, index) => (
                            <TableRow key={user.audio_id}>
                              <TableCell>{(page * 25) + index + 1}</TableCell>
                              <TableCell>{user.coach_first_name} {user.coach_last_name}</TableCell>
                              <TableCell>{user.audio_name}</TableCell>
                              <TableCell>{user.audio_description.length < 50 ? user.audio_description : <>
                                <div style={{ width: "500px" }}>
                                  {isExpanded.includes(user.audio_id) ? user.audio_description : user.audio_description.slice(0, limit) + '...'}
                                  {user.audio_description.length > limit && (
                                    <button className="desc-btn" onClick={() => {
                                      isExpanded.includes(user?.audio_id) ? removeElement(user?.audio_id) : setIsExpanded([...isExpanded, user?.audio_id])
                                    }}>
                                      {isExpanded.includes(user?.audio_id) ? 'Read Less' : 'Read More'}
                                    </button>

                                  )}</div>

                              </>}

                              </TableCell>
                              <TableCell>{`${new Date(user.created_at).getMonth() + 1} - ${new Date(user.created_at).getDate() + "  "} -  ${new Date(user.created_at).getFullYear()}`}</TableCell>
                              <TableCell>
                                <Switch checked={user.approval_status} color="primary" onChange={() => updateToggle(user.audio_id)} />
                              </TableCell>
                              <TableCell>{user.likes}</TableCell>
                              <TableCell>
                                <div className="innercell">
                                  <IconButton color="primary" onClick={() => redirect(user.audio_id)}>
                                    <VisibilityIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}

                      onChange={(_e, value) => {
                        setPage(value - 1)
                        removeElement(_e)
                      }}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Audio);
