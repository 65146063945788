// IMPORT REACT
import React from "react";
// IMPORT REACT- ROUTER FOR NAVIGATION
import { Route, Redirect,RouteComponentProps } from "react-router-dom";
// IMPORT CONNECT FROM REACT-REDUX TO CONNECT WITH REACT STORE
import { connect } from 'react-redux';
//import { AnyARecord } from "dns";



interface AppRouteProps extends RouteComponentProps {
  component: React.ComponentType<any>;
  layout: React.ComponentType<any>;
  isAuthProtected: boolean;
}

const AppRoute:React.FC<AppRouteProps> = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  
  ...rest 
}) => {
  return (
    <Route
    {...rest}
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem('token')) {
        return (
          <Redirect
            to={{
              pathname: "/ebookflutter_adminpanel/login",
              state: { from: props.location },
            }}
          />
        );
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
  )
};

const mapStateToProps = (state:any) => {
  return {
    token: state.token,
    user: state.user,
  };
}

export default connect(mapStateToProps)(AppRoute);
