import {combineReducers, createStore} from 'redux';

import {UserRoles} from './auth/admins/user';

import Layout from './layout/reducer';

import {tokenReducer} from './auth/admins/tokenReducer';
import {userReducer} from './auth/admins/userReducer';

export interface StoreState {
  token: string | null;
  user: UserRoles;
  Layout: any; // TODO: fix types
}

const reducers = combineReducers<StoreState>({
  token: tokenReducer,
  user: userReducer,
  Layout,
});

export const store = createStore(reducers);
