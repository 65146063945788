import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
// import swal from "sweetalert";
import { Container, Row, Col, Card, CardBody, Input } from 'reactstrap';
import IconButton from "@material-ui/core/IconButton";
import { Pagination } from "@material-ui/lab";
import Button from "@material-ui/core/Button";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useFetchHook } from '../../utils/fetchHook';
import { connect } from 'react-redux';
import axiosInstance from '../../helpers/axios';
import { toast } from 'react-toastify';
import { errorMsg, modules, permissions } from '../../utils/constants';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import Loader from '../../components/ui/loader/Loader';

// Props
interface Props {
  token: string;
  user: UserRoles;
}

type UserType = {
  id: number,
  active: true,
  name: String,
  email: String,
  role_name: String,
}

const Users = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States of setData
  const history = useHistory();
  const [role, setRole] = useState<any>('');
  const [page, setPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [name, setName] = useState('');
  const [showError, setShowError] = useState(false);
  const [dates, setDates] = useState<any>([null, null]);
  const [roleData, setRoleData] = useState([]);

  // Custom Hook
  const [{ isLoading, data }, { setOptions, setData }] = useFetchHook
    <{ data: UserType[]; numberOfPages: number; total: number | null, }>
    ('/admin',
      { data: [], numberOfPages: 1, total: null }, {
      headers: {
        Authorization: `Bearer ${props.token}`,
      },
      params: {
        page
      }
    });

  // UserEffect of pagination
  useEffect(() => {
    setOptions({
      params: {
        page,
        name: name,
        role: role.value,
        dates,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
    setCurrentPage(page + 1);
  }, [page]);

  // API For Active Status
  const onSwitch = async (id: number) => {
    if (!props.user[modules.users].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    try {
      const res = await axiosInstance.put(`/admin/active/${id}`, {}, {
        headers: {
          Authorization: `Bearer ${props.token}`
        }
      });

      const newData = data.data.map((t) => {
        const temp = { ...t };
        if (temp.id === res.data.id) {
          temp.active = res.data.active;
        }
        return temp;
      });

      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPages }));
    } catch (e) {
      toast.error('Unable to switch. please try again later');
    }
  }
  // DElete API
  // const onDelete = async (id: number) => {
  //   if (!props.user[modules.users].includes(permissions.DELETE)) {
  //     toast.error(errorMsg);
  //     return;
  //   }

  //   try {
  //     const confirm = await swal({
  //       buttons: ['No', 'Yes'],
  //       title: "Are you sure?",
  //       text: "Once deleted, you will not be able to recover this User!",
  //       icon: "error",
  //       dangerMode: true,

  //     });

  //     if (!confirm) {
  //       return;
  //     }

  //     const res = await axiosInstance.delete(`/admin/${id}`, {
  //       headers: {
  //         Authorization: `Bearer ${props.token}`,
  //       }
  //     });

  //     const newData = data.data.filter((t) => t.id !== res.data.id);
  //     setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPages }));
  //   } catch (e) {
  //     toast.error('Unable to switch. please try again later');
  //   }
  // };

  // OnClick Redirect To Another File(Add)
  const redirectEdit = (user_id: number) => {
    if (!props.user[modules.users].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    window.open(`edit-adminuser/${user_id}`);
  };

  // Redirect To Another File
  const redirectAdd = () => {
    if (!props.user[modules.users].includes(permissions.WRITE)) {
      toast.error(errorMsg);
      return;
    }
    history.push('add-adminuser');
  };

  // Redirect To Another File (View)
  const redirectView = (user_id: number) => {
    if (!props.user[modules.users].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }

    history.push(`view-user/${user_id}`);
  };
  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (name.length > 0 || role != null || (dates[0] != null && dates[1] != null)) {
        setShowError(false);
        e.preventDefault();
        setPage(0);
        setOptions({
          params: {
            page,
            name: name,
            role: role.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }
  }

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, b: any = null) => {
    var i;
    if (b === null) {
      i = (role.value)
    } else {
      i = (b['value'])
    }
    if (name.length > 0 || i != null || (dates[0] != null && dates[1] != null)) {
      setShowError(false);
      if (e !== null) {
        e.preventDefault();
      }
      setPage(0);
      setOptions({
        params: {
          page,
          name: name,
          role: i,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setShowError(true);
    }
  };

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();
		setName('');
    setDates([null, null]) as any;
    setRole('');
    setShowError(false);
		setOptions({
			params: {
				page,
			},
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		});
	}
  // const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
  //   e.preventDefault();
  //   setName('');
  //   setDates([null, null]) as any;
  //   setRole('');
  //   setShowError(false);
  //   setOptions({
  //     params: {
  //       page,
  //     },
  //     headers: {
  //       Authorization: `Bearer ${props.token}`,
  //     }
  //   });
  // }

  // Fetch Role API
  const fetchRoles = async () => {
    try {
      const res = await axiosInstance.get('/roles', {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });

      const data = res.data.data.map((t: any) => ({ value: t.role_id, label: t.name }));
      setRoleData(data);
    } catch (e) {
    }
  };

  // useEffect for fetch Roles
  useEffect(() => {
    fetchRoles();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">ADMIN USERS LIST</h4>
                    </Col>
                    <Col xs={6}
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <Button
                        variant="contained"
                        style={{ color: "blue", height: "40px" }}
                        onClick={redirectAdd}
                      >
                        Add User
                      </Button>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Name" value={name} onKeyPress={handleKeyPress} onChange={setter(setName)} />
                    </Col>
                    <Col xs={3}>
                      <Select
                        className="select"
                        name="publisher_id"
                        placeholder="Select Role"
                        value={role}
                        // onChange={(e) => {
                        //   setRole(e)
                        // }}
                        onChange={(value) => {
                          setRole(value);
                          onFilterApply(null as any, value);
                        }}
                        options={roleData}
                      />
                    </Col>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onFilterApply}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <br />
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO.</TableCell>
                          <TableCell>NAME</TableCell>
                          <TableCell>E-MAIL</TableCell>
                          <TableCell>ROLE</TableCell>
                          <TableCell>ACTIVE</TableCell>
                          <TableCell>ACTIONS</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((temp, i) => (
                          <TableRow key={temp.id}>
                            {/* {(index + 1) + (25 * (page - 1))} */}
                            <TableCell> {(page * 25) + i + 1}</TableCell>
                            <TableCell>{temp.name}</TableCell>
                            <TableCell>{temp.email}</TableCell>
                            <TableCell>{temp.role_name}</TableCell>
                            <TableCell>
                              <Switch
                                checked={temp.active}
                                color="primary"
                                name="user"
                                onChange={() => onSwitch(temp.id)}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton
                                  color="primary"
                                  onClick={() => redirectView(temp.id)}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                <IconButton color="primary" onClick={() => redirectEdit(temp.id)}>
                                  <EditIcon />
                                </IconButton>

                                {/* <Link
                                  to="#"
                                  onClick={() => onDelete(temp.id)}
                                >
                                  <DeleteIcon />
                                </Link> */}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPages}
                      page={currentPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      onChange={(_e, value) => setPage(value - 1)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}

      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(Users);
