// THIS COMPONENT IS USED IN ADD COUPON FOR IN-APP
// IMPORT REACT
import React, { useState } from "react";
// IMPORT REACT ROUTER DOM FOR ROUTING
import { Link } from "react-router-dom";
// REACT-STRAP IS THIRD PARTY PACKAGE THAT IS USED FOR ROW, COL,CARD ETC
import { Row, Col } from "reactstrap";
// IMPORT AXIOS FOR API IMPLEMENTATION
import axiosInstance from "../../../helpers/axios";
// IMPORT CSS
import "../datatables.scss";
// IMPORT SELECT FROM DROP-DOWN
import Select from 'react-select';
// IMPORT AXIOS-ERROR FOR ERROR MESSAGE
import { AxiosError } from "axios";
// IMPORT TOAST MESSAGE
import { toast } from "react-toastify";
// IMPORT DATE-PICKER FOR SELECT DATE
import DatePicker from 'react-datetime';
// IMPORT DATE-PICKER CSS
import 'react-datetime/css/react-datetime.css';
// IMPORT ASYNC-SELECT FROM DROP-DOWN
import AsyncSelect from 'react-select/async';
// IMPORT USE-SELECTOR
import { useSelector } from 'react-redux';
import moment from 'moment';
// IMPORT CSS
import '../../eBook/style.css'
import Loader from "../../../components/ui/loader/Loader";
import swal from "sweetalert";

export default function AddInapp() {
  // STATES FOR UPDATING DATA
  const token = useSelector((state: any) => state.token);
  const [subscription, setSubcription] = useState([] as any)
  const [offers, setOffers] = useState<{ value: number, label: string } | null>(null);
  const [offersub, setOffersub] = useState([] as any);
  const [inappUsage, setInappUsage] = useState<{ value: number, label: string } | null>(null);
  const [coupon, setCoupon] = useState("") as any;
  const [expiry, setExpiry] = useState("") as any;
  const [description, setDescription] = useState("") as any;
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [price, setPrice] = useState<{ value: number; label: string } | null>(null);
  const [priceyear, setPriceyear] = useState<{ value: number; label: string } | null>(null);
  const [months, setMonths] = useState<{ value: number; label: string } | null>(null);
  const [industoryOffers, setIndustoryOffers] = useState("");
  const [selectedUse, setSelectedUse] = useState("PAY_AS_YOU_GO");
  const [offerReference, setOfferRefernce] = useState("");
  const [payofOtions1, setPayofOtions1] = useState<{ value: number; label: string } | null>(null);
  const [payofOtions2, setPayofOtions2] = useState<{ value: string; label: number } | null>(null);
  const [free, setFree] = useState<{ value: number; label: string } | null>(null);
  const [state, setState] = useState({
    sub_id: 0,
  });
  const [checkbox1, setCheckbox1] = useState(false);
  const [valid, setValid] = useState({
    coupon_error: [] as any,
    offer_error: [] as any,
    subscription_error: [] as any,
    inapp_usage_error: [] as any,
    inapp_expiry_error: [] as any,
    inapp_description_error: [] as any,
  });

  const [userinfo, setUserInfo] = useState({
    subscribers: [] as any,
  });

  // THIS FUNCTION IS USED TO SET THE DATA OF RADIO BUTTON FOR INTRODUCTORY OFFERS
  const handleIndustoryOffers = (event: any) => {
    setIndustoryOffers(event.target.value);

  };

  // THIS FUNCTION IS USED TO SET THE DATA OF RADIO BUTTON TYPE OF OFFERS
  const handleUseChange = (event: any) => {
    setSelectedUse(event.target.value);
  };

  // THIS IS OPTIONS OF THE NUMBER OF MONTHS(PAY_US_YOU_GO)
  const options = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
  ]

  // THIS IS OPTIONS OF THE NUMBER OF MONTHS(PAY_UP_FRONT)
  const payofOtions = [
    { value: 1, label: '1 Month' },
    { value: 2, label: '2 Month' },
    { value: 3, label: '3 Month' },
    { value: 4, label: '6 Month' },
    { value: 5, label: '1 Year' },
  ]

  // THIS IS OPTIONS OF THE NUMBER OF YEARS
  const payofOtions3 = [
    { value: "ONE_YEAR", label: 1 },
  ]

  // THIS IS OPTIONS OF THE NUMBER OF MONTHS
  const freeOptions = [
    { value: 1, label: '3 Days' },
    { value: 2, label: '1 Week' },
    { value: 3, label: '2 Week' },
    { value: 4, label: '1 Month' },
    { value: 5, label: '2 Months' },
    { value: 5, label: '3 Months' },
    { value: 5, label: '6 Months' },
    { value: 5, label: '1 Year' },
  ]

  // THIS IS API FOR SUBSCRIPTION DROPDOWN
  const loadSubscription = async (subscription: string) => {
    try {
      const sub = await axiosInstance.get('pay/ios_subscription',
        {
          params: {
            subscription
          }
        });
      return sub.data;
    } catch (e) {
      const error = e as AxiosError;
      console.log(error)
    }
  }
  // THIS IS API FOR OFFERS DROPDOWN
  const loadOffers = async (sub_id: string) => {
    try {
      const off = await axiosInstance.get('/pay/offers', {
        params: {
          sub_id: sub_id
        }
      });
      setOffersub(off.data)

    } catch (e) {
      const error = e as AxiosError;
      console.log(error)
    }
  }

  // THIS IS API FOR USAGES DROPDOWN
  const inappUsageoption = async (usage: string) => {
    try {
      const inn = await axiosInstance.get("pay/coupon_usage", {
        params: {
          usage
        }
      })
      return inn.data
    } catch (e) {
      console.log(e)
    }
  }

  // THIS IS API FOR PRICES DROPDOWN PREMIUM MONTHLY
  const loadPrice = async (price: string) => {
    try {
      const pubs = await axiosInstance.get('pay/prices', {
        params: {
          price,
        }
      });
      return pubs.data;

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };
  // THIS IS API FOR PRICES DROPDOWN FOR PREMIUM YEARLY
  const loadPriceyearly = async (price: string) => {
    try {
      const pubs = await axiosInstance.get('/pay/prices2', {
        params: {
          price,
        }
      });
      return pubs.data;

    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  // THIS FUNCTION IS USED FOR THE IN-APP SUBMIT HAVE API IMPLEMENTATION ALSO HAVE VALIDATION
  const inAppSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const coupon_error = [] as any
    const offer_error = [] as any
    const inapp_usage_error = [] as any
    const subscription_error = [] as any
    const inapp_description_error = [] as any

    if (!coupon) {
      coupon_error.push("*Enter Coupon Code");
    } else if (coupon.length < 4) {
      coupon_error.push("*Coupon Code Is Too Short ");
    }
    else {
      setValid({ ...valid, coupon_error: "" });
    }
    if (subscription.length === 0) {
      subscription_error.push("*Choose Subscription Plan")
    }

    if (!offers) {
      offer_error.push("*Enter Offer Type");
    }
    if (!inappUsage) {
      inapp_usage_error.push("*Enter Number Of Usage")
    }

    if (!expiry) {
      setError("*Enter Expiry Date");
    } else {
      setError(null);
    }

    if (!description) {
      inapp_description_error.push("*Enter Description");
    } else {
      setValid({ ...valid, inapp_description_error: "" });
    }

    setValid({ ...valid, coupon_error, offer_error, inapp_usage_error, subscription_error, inapp_description_error });
    if (coupon_error.length || offer_error.length || inapp_usage_error.length || subscription_error.length || inapp_description_error.length) return;
    const changemonth = `${(new Date(expiry).getMonth() + 1)}`
    const addzero = ("0" + changemonth).slice(-2);
    const expiryDate = `${new Date(expiry).getFullYear()}-${addzero}-${new Date(expiry).getDate()}`
    const data = {
      coupon_code: coupon,
      description: description,
      expiry: expiryDate,
      usage: JSON.stringify(inappUsage?.label),
      offer_id: offers?.value,
    }

    if (isLoading === false) {
      setIsLoading(true);
      try {

        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/pay/coupon/`,
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        if (res?.data?.success === 0) {
          setIsLoading(false);
        }
        setIsLoading(false);
        swal({
          title: "Added Successfully!",
          icon: "success",
        });

      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {
          toast.error('Something went wrong. Please try again later');
        }
      }
    }
    setCoupon("") as any;
    setDescription("") as any;
    setExpiry("") as any;
    setSubcription([]) as any;
    setOffers(null) as any;
    setInappUsage(null) as any;

  }

  // THIS FUNCTION IS USED FOR CALENDER TO HIDE THE PAST DATE FROM THE CALENDAR
  const yesterday = moment().subtract(0, 'day');
  const disablePastDt = (current: { isAfter: (arg0: moment.Moment) => any; }) => {
    return current.isAfter(yesterday);
  };

  // THIS FUNCTION IS USED TO SET THE DATA OF CHECKBOX BUTTON CUSTOMER ELIGIBILITY
  const handleChange = (e: any) => {
    const { value, checked } = e.target;
    const { subscribers } = userinfo;


    userinfo.subscribers.map((a: any) => {
      if (a === 'NEW') {
        setCheckbox1(!checkbox1)
      } else if (a === "EXISTING") {
        setCheckbox1(!checkbox1)
      } else if (a === 'EXPIRED') {
        setCheckbox1(checkbox1)
      }
    })
    if (checked) {
      setUserInfo({
        subscribers: [...subscribers, value],
      });
    }
    else {
      setUserInfo({
        subscribers: subscribers.filter((e: any) => e !== value),
      });
    }
  };

  // THIS FUNCTION IS USED TO SUBMIT POPUP FOR CREATE OFFERS AND HAVE POPUP API IMPLEMENTATION
  const onSubmitInppOffer: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const nameData = {
      name: offerReference
    }
    if (isLoading === false) {
      setIsLoading(true);
      try {
        const sub_id = subscription.value;
        const res = await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/pay/check_offer_name?sub_id=${sub_id}`,
          nameData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );
        if (res.status === 200) {
          const data = {
            name: offerReference,
            customerEligibilities: userinfo?.subscribers,
            sub_id: subscription.value,
            price_id: (subscription.label === "Premium Monthly") ? price?.value : priceyear?.value,
            offerMode: selectedUse,
            offerEligibility: industoryOffers,
            duration: (subscription.label === "Premium Monthly" && selectedUse === "PAY_AS_YOU_GO") ? "ONE_MONTH" : (selectedUse === "PAY_UP_FRONT") ? "THREE_MONTHS" : (selectedUse === "FREE_TRIAL") ? "TWO_WEEKS" : (subscription.label === "Premium Yearly" && selectedUse === "PAY_AS_YOU_GO") ? "ONE_YEAR" : "",
            numberOfPeriods: (selectedUse === "PAY_UP_FRONT") ? 1 : (selectedUse === "FREE_TRIAL") ? 1 : (subscription.label === "Premium Yearly" && selectedUse === "PAY_AS_YOU_GO") ? 1 : months?.value,
          }
            try {
              const res = await axiosInstance.post(
                `${process.env.REACT_APP_API_URL}/pay/offer`,
                data,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  }
                }
              );
              if (res?.data?.success === 0) {
                setIsLoading(false);
              }
              setIsLoading(false);
              toast.success("Added Successfully!")

            } catch (e) {
              setIsLoading(false);
              const error = e as AxiosError;
              if (error.response?.status === 400)
                toast.error(error.response?.data.error);
              else if (error.response?.data.error.errors[0].detail) {
                toast.error(error.response?.data.error.errors[0].detail)
              }
              else {
                toast.error('Something went wrong. Please try again later');
              }

          }
        }
        setIsLoading(false);
        setOfferRefernce("")as any;
        setUserInfo({...userinfo, subscribers:[]}) as any;
        setIndustoryOffers("") as any;
        setMonths(null);
        setPrice(null)
        setPriceyear(null);
        setPayofOtions1(null)
        setPayofOtions2(null)
      } catch (e) {
        setIsLoading(false);
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        } else {

          toast.error('Something went wrong. Please try again later');
        }
      }
      setIsLoading(false);
    }
    setIsLoading(false);
  };


  userinfo.subscribers.forEach((item: any, index: number) => {
    otherFunction(item.id, index);
  });

  function otherFunction(id: string, index: number) {
  }

  // FROM THERE JSX PART IS STARTED ALL THE INPUT, DROPDOWN ,CALENDER , TEXTAREA,POPUP ARE SHOWN FROM THERE
  // THIS PART IS SHOW IN OUR REACT APPLICATION
  return (
    <>{offersub.length > 10 }
      {(subscription.length === 0) || (offersub.length > 10 ) ? "" : <> <p style={{ color: "blue" }} data-toggle="modal" data-target="#exampleModalLong">
        Create Offers
      </p></>}
      <Row>
        <Col xs={6} >
          <div className="form-group">
            <label>CHOOSE SUBSCRIPTION*</label>
            <AsyncSelect cacheOptions defaultOptions
              placeholder="Choose Subscription"
              loadOptions={loadSubscription} value={subscription} onChange={(e: any) => {
                setSubcription(e)
                // setIsSaved() as any;
                setOffers(null);
                setOffersub([])
                loadOffers(e?.value)
                setState({ ...state, ['sub_id']: e?.value as any })
                setValid({ ...valid, subscription_error: [] })

              }} />
            {valid?.subscription_error && <div style={{ color: 'red' }}>{valid?.subscription_error} </div>}

          </div>
        </Col>
        {(subscription.length === 0) ? "" :
          <>

            <Col xs={6} >
              <div className="form-group">
                <label>CHOOSE OFFERS*</label>
                <Select options={offersub} placeholder="Choose Offers"
                  value={offers} onChange={(e) => {
                    setOffers(e) as any
                    setValid({ ...valid, offer_error: [] })
                  }}
                />
                {valid?.offer_error && <div style={{ color: 'red' }}>{valid?.offer_error} </div>}

              </div>
            </Col>
          </>}

      </Row>
      {(subscription.length === 0) || (offers === null) ? "" : <>
        <Row>
          <Col xs={6}>
            <div className="form-group">
              <label>COUPON CODE*</label>
              <input
                type="text"
                className="form-control"
                name="coupon_code"
                value={coupon}
                id="exampleFormControlInput1"
                placeholder="Enter Coupon Code "
                onChange={(e) => {
                  // setIsSaved() as any;
                  setCoupon(e.target.value.toUpperCase())
                  if (!e.target.value || coupon?.length === 0) {
                    setValid({ ...valid, coupon_error: "*Enter Coupon Code" });
                  } else if (coupon?.length < 4) {
                    setValid({ ...valid, coupon_error: "*Coupon Code Is Too Short" });
                  }
                  else {
                    setValid({ ...valid, coupon_error: "" })
                  }
                }}
              />
              {valid?.coupon_error && <div style={{ color: 'red' }}>{valid?.coupon_error} </div>}
            </div>
          </Col>
          <Col xs={6}>
            <div className="form-group">
              <label>NUMBER OF USAGE*</label>
              <AsyncSelect cacheOptions defaultOptions placeholder="Number Of Usage" value={inappUsage} loadOptions={inappUsageoption}
                onChange={(e) => {
                  // setIsSaved() as any;
                  setInappUsage(e)
                  setValid({ ...valid, inapp_usage_error: [] })
                }} />
              {valid?.inapp_usage_error && <div style={{ color: 'red' }}>{valid?.inapp_usage_error} </div>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={6} >
            <div className="form-group" style={{ display: 'flex', flexDirection: 'column' }}>
              <label>EXPIRY*</label>
              <DatePicker
                value={expiry}
                timeFormat={false}
                isValidDate={disablePastDt}
                onChange={(e: any) => {
                  if (e) {
                    // setIsSaved() as any;
                    setExpiry(e);
                    setError(null);
                  } else {
                    setError("*Expiry Date Can Not Be Empty");
                  }
                }}
              />
              {error && <div style={{ color: 'red' }}>{error}</div>}
            </div>
          </Col>
          <Col xs={6}>
            <div className="form-group">
              <label>DESCRIPTION*</label>
              <input
                type="text"
                className="form-control"
                name="coupon_code"
                id="exampleFormControlInput1"
                placeholder="Enter Description "
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value)
                  // setIsSaved() as any;
                  if (!e.target.value || description?.length === 0) {
                    setValid({ ...valid, inapp_description_error: "*Enter Description" });
                  } else {
                    setValid({ ...valid, inapp_description_error: "" })
                  }
                }}
              />
              {valid?.inapp_description_error && <div style={{ color: 'red' }}>{valid?.inapp_description_error} </div>}

            </div>
          </Col>
        </Row>
      </>}
      <div
        style={{
          display: "flex",
          margin: "5px",
          padding: "10px",
        }}
      >
        <div
          style={{
            margin: "5px",
            padding: "10px",
          }}
        >
          <input
            name="submit"
            type="submit"
            value="Submit"
            className="btn btn-primary"
            // onSubmit={(e:any) => inAppSubmit(e)}
            onClick={(e: any) => {
              // setPristine()
              inAppSubmit(e)
            }}
          />
        </div>
        <div
          style={{
            margin: "5px",
            padding: "10px",
          }}
        >
          {" "}
          <Link
            to="/ebookflutter_adminpanel/coupons"
            style={{ color: "white" }}
          >
            <button type="button" className="btn btn-primary">
              Back
            </button>
          </Link>
        </div>
      </div>
      {/* </form> */}
      {/* {Prompt} */}

      {/* THIS CODE IS FOR THE POPUP FOR CREATE OFFERS */}
      <div className="modal fade" id="exampleModalLong" role="dialog" aria-labelledby="exampleModalLongTitle" data-backdrop="static" aria-hidden="true" data-keyboard='false'>
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">Create Offer</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body popup-offer" >
              <div>
                <div className="form-group">
                  <label htmlFor="recipient-name" className="col-form-label">Offer Reference Name:</label>
                  <input type="text" className="form-control" id="recipient-name" value={offerReference} onChange={(e) => { setOfferRefernce(e.target.value) }} />
                </div>
                <h6><b>Customer Eligibility</b></h6>
                <p>Select who is eligible for this offer:</p>
                <div className="form-check my-2">
                  <input className="form-check-input" type="checkbox" name="subscribers" value="NEW" id="flexCheckDefault" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    New Subscribers
                  </label>
                </div>
                <div className="form-check my-2">
                  {/* && flag === 0(new and expired doesnt contained) */}
                  <input className="form-check-input" type="checkbox" disabled={industoryOffers !== "REPLACE_INTRO_OFFERS" && !(userinfo?.subscribers.includes("NEW") || userinfo?.subscribers.includes("EXPIRED"))}
                    name="subscribers" value="EXISTING" id="flexCheckDefault" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="flexCheckDefault" >
                    Existing Subscribers
                  </label>
                </div>
                <div className="form-check my-2">
                  <input className="form-check-input" type="checkbox" name="subscribers" value="EXPIRED" id="flexCheckDefault" onChange={handleChange} />
                  <label className="form-check-label" htmlFor="flexCheckDefault">
                    Expired Subscribers
                  </label>
                </div><br />

                <h6><b>Introductory Offers</b></h6>
                <p>Can eligible customers still get an introductory offer when redeeming this offer code?</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="genderRadio"
                    value="STACK_WITH_INTRO_OFFERS"
                    checked={industoryOffers === "STACK_WITH_INTRO_OFFERS"}
                    onChange={handleIndustoryOffers}
                  />
                  <label className="form-check-label" htmlFor="genderRadio1">
                    Yes, customers can redeem both an introductory offer and this code
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="genderRadio"
                    value="REPLACE_INTRO_OFFERS"
                    checked={industoryOffers === "REPLACE_INTRO_OFFERS"}
                    onChange={handleIndustoryOffers}
                  />
                  <label className="form-check-label" htmlFor="genderRadio2">
                    No, this code will replace the introductory offer
                  </label>
                </div><br />
                <h6><b>Type of offer</b></h6>
                <p>Choose a paid or free offer for your subscribers. They will automatically be changed the full subscription price when the offer ends, unless they have turned off automatic renewal.</p>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="useRadio"
                    value="PAY_AS_YOU_GO"
                    checked={selectedUse === "PAY_AS_YOU_GO"}
                    onChange={handleUseChange}
                  />
                  <label className="form-check-label" htmlFor="useRadio1">
                    Pay as you go
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="useRadio"
                    value="PAY_UP_FRONT"
                    checked={selectedUse === "PAY_UP_FRONT"}
                    onChange={handleUseChange}
                  />
                  <label className="form-check-label" htmlFor="useRadio2">
                    Pay up front
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="useRadio"
                    value="FREE_TRIAL"
                    checked={selectedUse === "FREE_TRIAL"}
                    onChange={handleUseChange}
                  />
                  <label className="form-check-label" htmlFor="useRadio2">
                    Free
                  </label>
                </div>
                <br />
                {(subscription?.label === 'Premium Yearly') || (selectedUse === "PAY_UP_FRONT") || (selectedUse === "FREE_TRIAL") ? "" : <>
                  <h6><b>Duration</b></h6>
                  <Row><Col xs={6}>
                    <label>Number of months</label>
                    <Select placeholder="Number Of Months" options={options} value={months} onChange={setMonths} />
                  </Col></Row><br /></>}


                {(selectedUse === "PAY_AS_YOU_GO") || (selectedUse === "FREE_TRIAL") ? " " : <>
                  <h6><b>Duration</b></h6>
                  <Row><Col xs={6}>
                    <label>Number of months</label>
                    <Select placeholder="Number of Months" options={payofOtions} value={payofOtions1} onChange={setPayofOtions1} />
                  </Col></Row><br />
                </>}
                {(subscription?.label === 'Premium Monthly') || (selectedUse === "PAY_UP_FRONT") || (selectedUse === "FREE_TRIAL") ? "" : <>
                  <h6><b>Duration</b></h6>
                  <Row><Col xs={6}>
                    <label>Number of Years</label>
                    <Select placeholder="Number of Years" options={payofOtions3} value={payofOtions2} onChange={setPayofOtions2} />
                  </Col></Row><br />
                </>}
                {(selectedUse === "PAY_AS_YOU_GO") || (selectedUse === "PAY_UP_FRONT") ? " " : <>
                  <h6><b>Duration</b></h6>
                  <Row><Col xs={6}>
                    <label>Number of months</label>
                    <Select placeholder="Number of Months" options={freeOptions} value={free} onChange={setFree} />
                  </Col></Row><br />
                </>}
                {(selectedUse === "FREE_TRIAL") ? "" : <>
                  <h6><b>Price</b></h6>
                  <p>Choose a price ,and we'll automatically calculate the prices for all 175 countries or regions based on the most recent foreign exchanges rates. You can edit prices for individual countries or regions in the next step. </p>
                  <br />
                  <Row>
                    {(subscription?.label === 'Premium Monthly') ? <>
                      <Col xs={6}>
                        <label>Price</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={price}
                          loadOptions={loadPrice}
                          placeholder="Price For Monthly Subscription"
                          onChange={setPrice}
                        />
                      </Col> </> : (subscription?.label === 'Premium Yearly') ?
                      <Col xs={6}>
                        <label>Price</label>
                        <AsyncSelect
                          cacheOptions
                          defaultOptions
                          value={priceyear}
                          loadOptions={loadPriceyearly}
                          placeholder="Price For Yearly Subscription"
                          onChange={setPriceyear}
                        />
                      </Col> : <></>}
                  </Row></>}
              </div>
            </div>
            <div className="modal-footer offer-ftxt">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" className="btn btn-primary" onClick={(e: any) => onSubmitInppOffer(e)} >Submit</button>
            </div>
          </div>
        </div>
        {isLoading ? <Loader /> : null}
      </div>
    </>
  )
}
