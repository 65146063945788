import React, { useEffect, useState } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import SettingsBackupRestoreTwoToneIcon from '@mui/icons-material/SettingsBackupRestoreTwoTone';
import "../../eBook/style.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Switch,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
const EditAuthorLogs = () => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const token = useSelector((state: any) => state.token);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [data, setData] = useState([]) as any;
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/log/admin-logs/${id}?page=1&modulename=v_authors&action=update&author_name=&dates[]=null&dates[]=null`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => toast.error(error));
  }, []);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <div className='container-fluid'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '33%', flexShrink: 0 }}>
            LOGS ACTIVITY
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table>
              <TableHead>
                {data?.data?.map((t: any, index: any) => (
                  <>
                    <TableRow className='row-sec-author' key={index}>
                      <TableRow style={{ width: '396px' }}>
                        <TableCell width={200}><span>{new Date(t.createdAt).toLocaleString("en-US", { timeZone: "America/New_York", hour12: true })} </span> </TableCell>
                        <TableCell width={200}><div className="border-section-with-switch-author"> </div> </TableCell>
                      </TableRow>
                      <TableRow className='row-sec-author bg-new' key={index}>
                        <TableCell width={400}>Author Name:{t.values["Author First Name"]} {t.values["Author Last Name"]}</TableCell>
                        <TableCell width={400}>Author Email:{t.values["Author Email"]} </TableCell>
                        <TableCell width={400}>Coauthor Name:{t.values["Coauthor First Name"]} {t.values["Coauthor Last Name"]} </TableCell>
                        <TableCell width={400}>Author Email:{t.values["Author Email"]} </TableCell>
                        <TableCell width={400}>Date of Birth:{`${new Date(t.values["Date of Birth"]).getDate() + "  "} - ${new Date(t.values["Date of Birth"]).getMonth() + 1} - ${new Date(t.values["Date of Birth"]).getFullYear()}`} </TableCell>
                        <TableCell width={400}>Member Since:{t.values["Member Since"]} </TableCell>
                        <TableCell width={400}>Private Phone:{t.values["Private Phone"]} </TableCell>
                        <TableCell width={200}>Image:
                          <img src={t.values["Cover image"] ? `${process.env.REACT_APP_S3_APP_API}/author/${t.values["Cover image"]}`
                            : `${process.env.REACT_APP_S3_APP_API}/author/author.png`
                          }
                            alt="social" height="30px" width="30px" />
                        </TableCell>
                        <TableCell width={400}>Public Phone:{t.values["Public Phone"]} </TableCell>
                        <TableCell width={400}>Top Author :
                          <Switch checked={t.values["Top Author"]} disabled />
                        </TableCell>
                        <TableCell width={400}>Updated By:{t.action_performed_by.name}</TableCell>
                        <TableCell width={400}><SettingsBackupRestoreTwoToneIcon /></TableCell>
                      </TableRow>
                    </TableRow></>
                ))}
              </TableHead>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
export default EditAuthorLogs;
