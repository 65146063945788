import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, InputGroup, InputGroupAddon, Input } from "reactstrap";
import "../datatables.scss";
import Select from "react-select";
import Image from "../../../components/Common/Image";
import { connect } from 'react-redux';
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from "@mui/material/TextField";
import { TextareaAutosize } from "@mui/material";

interface Props {
  token: string;
  user: UserRoles;
}
const ViewAuthor = (props: Props) => {
  const { id } = useParams() as {
    id?: number | undefined;
  }
  const [genre, setGenre] = useState([] as any);
  const [language, setLanguage] = useState([] as any);
  const [location, setLocation] = useState([] as any);
  const [social, setSocial] = useState([] as any);
  const [likes, setLikes] = useState(null);

  const [user, setUser] = useState({
    authors_image: "",
    author_first_name: "",
    author_last_name: "",
    coauthor_fname: "",
    coauthor_lname: "",
    public_phone: "",
    private_phone: "",
    author_email: "",
    goodread_url: "",
    authors_dob: "",
    member_since: "",
    authors_bio: "",
  });

  const [networkShowing, setNetworkingShow] = useState([]);

  const [networkCover, setNetworkImage] = useState(null);

  useEffect(() => {
    getAuthor();
  }, []);

  const getAuthor = async () => {
    if (id) {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/v2_authors/admin-authors/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        })
        const data = await res.json();

        setUser({ ...data });
        setNetworkImage(data.cover_image);
        setNetworkingShow(data.images);
        setLanguage(data.lang);
        setLikes(data.likes);
        setSocial(data.social);
        setLocation(data.loc);
        setGenre(data.genre);
      } catch (e) {
        console.log(e);
      }
    }
  }

  const renderCover = () => {
    if (networkCover) {
      return (
        <Row>
          <Col xs="auto">
            <Image src={`${process.env.REACT_APP_S3_APP_API}/author/${networkCover}`} height="auto" width="100px" alt="cover" showClose={false} onPress="" />
          </Col>
        </Row>
      )
    }
  };

  const renderShowing = () => {
    return (
      <Row>
        {networkShowing.map((img) => (
          <Col xs="auto">
            <Image src={`${process.env.REACT_APP_S3_APP_API}/author/${img}`} height="auto" width="100px" alt="cover" showClose={false} onPress="" />
          </Col>
        ))}
      </Row>
    )
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">AUTHOR (❤️: {likes})</h4>
                  <Row>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>AUTHOR FIRST NAME*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Author First Name"
                          name="author_first_name"
                          value={user.author_first_name}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>AUTHOR LAST NAME*</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Author Last Name"
                          name="author_last_name"
                          value={user.author_last_name}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>CO AUTHOR FIRST NAME</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Author First Name"
                          name="coauthor_fname"
                          value={user.coauthor_fname}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>CO AUTHOR LAST NAME</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Author Last Name"
                          name="coauthor_lname"
                          value={user.coauthor_lname}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>PUBLIC MOBILE NO</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter your Mobile No"
                          disabled
                          name="public_phone"
                          value={user.public_phone}
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>PRIVATE MOBILE NO</label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter your Mobile No"
                          name="private_phone"
                          value={user.private_phone}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>EMAIL</label>
                        <input
                          type="email"
                          className="form-control"
                          id="exampleFormControlInput1"
                          placeholder="Enter Email"
                          name="author_email"
                          value={user.author_email}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>GENRE</label>
                        <Select
                          // className="form-control"
                          className="select"
                          name="genre_id"
                          placeholder="Select Genre"
                          isMulti
                          value={genre}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>LOCATION</label>
                        <Select
                          // className="form-control"
                          className="select"
                          name="location_id"
                          placeholder="Select Location"
                          isMulti
                          value={location}
                          onChange={setLocation}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>LANGUAGE</label>
                        <Select
                          className="select"
                          name="lang_id"
                          placeholder="Select Language"
                          isMulti
                          value={language}
                          onChange={setLanguage}
                          isDisabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>GOODREADS URL</label>
                        <input
                          type="text"
                          className="form-control"
                          name="goodread_url"
                          id="exampleFormControlInput1"
                          placeholder="Enter Goodreads Url"
                          value={user.goodread_url}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>AUTHOR's DOB*</label>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <MobileDatePicker
                            disabled
                            label="Author Dob"
                            value={user.authors_dob}
                            onChange={date => {
                              setUser({ ...user, authors_dob: date! });
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          />
                        </LocalizationProvider>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        <label>MEMBER SINCE</label>
                        <input
                          type="text"
                          className="form-control"
                          name="member_since"
                          id="exampleFormControlInput1"
                          placeholder="Enter member since"
                          value={user.member_since}
                          disabled
                        />
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>AUTHOR's BIO *</label>
                        <TextareaAutosize
                          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                          minRows={4}
                          maxLength={500}
                          // className="form-control"
                          name="authors_bio"
                          id="exampleFormControlInput1"
                          placeholder="Enter bio"
                          value={user.authors_bio}
                          disabled
                        />
                        <label style={{ color: "#0069d9" }}>{user.authors_bio.length} characters</label>
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="form-group">
                        <label>SOCIAL LINKS</label>
                        <br />
                        <Select
                          className="select"
                          name="genre_id"
                          placeholder="Select Social links"
                          isMulti
                          value={social}
                          onChange={setSocial}
                          isDisabled
                        />
                        {social?.map((temp: any, i: number) => (
                          <InputGroup style={{ marginTop: '4px' }}>
                            <InputGroupAddon addonType="prepend">
                              <img src={`${process.env.REACT_APP_S3_APP_API}/social/${temp.iconpath}`} height="38px" width="30px" alt="sc" />
                            </InputGroupAddon>
                            <Input placeholder="place your link here" value={social[i].url} />
                          </InputGroup>
                        ))}
                      </div>
                    </Col>
                    <Col xs={6}>
                      <label>Primary Author Image</label>
                      <br />
                      {renderCover()}
                    </Col>
                    <Col xs={6}>
                      <div className="form-group">
                        {" "}
                        <label>AUTHOR IMAGES</label>
                        <br />
                        {renderShowing()}
                      </div>
                    </Col>
                    <div
                      style={{
                        display: "flex",
                        margin: "5px",
                        padding: "10px",
                      }}
                    >
                      <div
                        style={{
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        {" "}
                        <Link
                          to="/ebookflutter_adminpanel/author"
                          style={{ color: "white" }}
                        >
                          <button type="button" className="btn btn-primary">
                            Back
                          </button>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(ViewAuthor);
