import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import axiosInstance from "../../helpers/axios";
import { connect } from "react-redux";
import { StoreState } from "../../store";
import { UserRoles } from "../../store/auth/admins/user";


interface Props {
  token: string;
  user: UserRoles;
}
// type ViewMobileUsersTypes = {
//   fname: string,
//   email: string,
//   city: string,
//   country: string,
//   mobileno: number,
//   state: string
// };
const ViewMobileUsers = (props: Props) => {
  const { id } = useParams() as {
    id?: string | undefined;
  }
  const [user, setUser] = useState({
    fname: "",
    lname: "",
    email: "",
    city: "",
    country: null,
    mobileno: "",
    state: ""
  });
  useEffect(() => {
    fetchUser();
  }, []);



  const fetchUser = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/register/${id}`, {

          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        });

        setUser(res.data);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">MOBILE USER</h4>
                  <form onSubmit={(e) => e.preventDefault()}>

                    {user && user.fname && (

                      <Row>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>NAME</label>
                            <br />

                            {user.fname + " " + user.lname}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>E-mail</label>
                            <br />
                            {user.email}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>City</label>
                            <br />
                            {user.city}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>Country</label>
                            <br />
                            {user.country}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>State</label>
                            <br />
                            {user.state}
                          </div>
                        </Col>
                        <Col xs={6}>
                          <div className="form-group">
                            <label>Mobile</label>
                            <br />
                            {user.mobileno}
                          </div>
                        </Col>
                        <Col>
                        <Link
                            to="/ebookflutter_adminpanel/mobileusers"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                          </Col>
                      </Row>
                    )}
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,


  };
};

export default connect(mapStateToProps)(ViewMobileUsers);
