/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Row, Col, Card, CardBody, Container, InputGroup, InputGroupAddon, Input } from "reactstrap";
import axiosInstance from "../../../helpers/axios";
import "../datatables.scss";
import '../style.css';
import Select from "react-select";
import ErrorSpan from "../../../components/ui/errorSpan/ErrorSpan";
import Loader from "../../../components/ui/loader/Loader";
import Image from "../../../components/Common/Image";
import { getLanguage, getLocation } from "./Apiget";
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { StoreState } from "../../../store";
import { UserRoles } from "../../../store/auth/admins/user";
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from "@mui/material/TextField";
import { AxiosError } from "axios";
import isURL from 'validator/lib/isURL';
import { MultiValue } from 'react-select';
import AsyncSelect from 'react-select/async';
import {
  makeStyles,

} from "@material-ui/core";
import { TextareaAutosize } from "@mui/material";
import UnSavedChanges from "./UnSavedChanges";
import Compress from "browser-image-compression";
import EditAuthorLogs from "../Edit/EditAuthorLogs";


const useStyles = makeStyles(() => ({
  removeBorder: {
    borderColor: "#ccc",
  }
}));
interface Props {
  token: string;
  user: UserRoles;
}

const AddAuthor = (props: Props) => {
  const classes = useStyles();
  const { id } = useParams() as {
    id?: number | undefined;
  }

  // States of setData
  const [genre, setGenre] = useState<MultiValue<{ value: number; label: string }> | null>(null);
  const [options1, setOptions1] = useState([] as any);
  const [options2, setOptions2] = useState([] as any);
  const [language, setLanguage] = useState([] as any);
  const [location, setLocation] = useState([] as any);
  const [social, setSocial] = useState([] as any);
  const [likes, setLikes] = useState(null);
  const [Language_data, setLanguage_data] = useState([]);
  const [Location_data, setLocation_data] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [imgcompressor, setImgcompressor] = useState<any>({ imageUrl: null });
  const [isUpdateCalled, setIsUpdateCalled] = useState(false); // New state variable
  const [user, setUser] = useState({
    authors_image: "",
    author_first_name: "",
    author_last_name: "",
    coauthor_fname: "",
    coauthor_lname: "",
    public_phone: "",
    private_phone: "",
    author_email: "",
    goodread_url: "",
    authors_dob: "",
    member_since: "",
    authors_bio: "",
  });

  const [valid, setValid] = useState({
    author_first_name_Error: [] as any,
    author_last_name_Error: [] as any,
    authors_bio_Error: [] as any,
    authors_phone_number: [] as any,
    private_phone_number: [] as any,
    goodread_url_Error: [] as any,
    social_Error: [] as any,
  });
  const [Prompt, setIsSaved, setPristine] = UnSavedChanges() as any;
  const [showing, setimg] = useState([] as any);
  const [networkShowing, setNetworkingShow] = useState([]);
  const [coverImage, setCoverImage] = useState<any>(null);
  const [networkCover, setNetworkImage] = useState<any>(null);
  const imgRef = React.useRef<HTMLInputElement>(null);
  const coverRef = React.useRef<HTMLInputElement>(null);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    setUser({ ...user, [e.target.name]: e.target.value });
    setIsSaved() as any;

  };

  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.persist();
    setUser({ ...user, [e.target.name]: e.target.value });
    if (!e.target.name || !e.target.value || !user.authors_bio) {
      setValid({ ...valid, authors_bio_Error: "*Enter Bio" });
    } else {
      setValid({ ...valid, authors_bio_Error: "" })
    }
  };

  // Post API
  const postAuthor = async () => {
    const author_first_name_Error = [] as any;
    const author_last_name_Error = [] as any;
    const authors_bio_Error = [] as any;
    const goodread_url_Error = [] as any;
    const social_Error = [] as any;

    if (!user.author_first_name) {
      author_first_name_Error.push("*Enter Author First Name");
    } else {
      setValid({ ...valid, author_first_name_Error: "" })
    }
    if (!user.author_last_name) {
      author_last_name_Error.push("*Enter Author Last Name");
    } else {
      setValid({ ...valid, author_last_name_Error: "" })
    }
    if (!user.authors_bio) {
      authors_bio_Error.push("*Enter Author Bio");
    } else {
      setValid({ ...valid, authors_bio_Error: "" })
    }
    if (user?.goodread_url === "") {
      setUser({ ...user, goodread_url: "", })
    } else if (!isURL(user?.goodread_url)) {
      goodread_url_Error.push("Invalid URL")
    }

    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    let foundInvalidUrl = false;

    social.forEach((s: { value: any; url: any; }) => {
      if (!s.url) {
        foundInvalidUrl = true;
      } else if (!urlRegex.test(s.url)) {
        foundInvalidUrl = true;
      }
    });
    if (foundInvalidUrl) {
      social_Error.push("Please Enter A Valid URL For All Social Links");
    }
    setValid({
      ...valid,
      author_first_name_Error,
      author_last_name_Error,
      authors_bio_Error,
      goodread_url_Error,
      social_Error,
    });

    if
      (
      author_first_name_Error.length ||
      author_last_name_Error.length ||
      authors_bio_Error.length ||
      goodread_url_Error.length ||
      social_Error.length
    )
      return;

    const formData = new FormData();

    const data = {
      author_first_name: user.author_first_name,
      author_last_name: user.author_last_name,
      coauthor_fname: user.coauthor_fname,
      coauthor_lname: user.coauthor_lname,
      public_phone: user.public_phone,
      private_phone: user.private_phone,
      author_email: user.author_email,
      goodread_url: user.goodread_url,
      authors_dob: user.authors_dob,
      member_since: user.member_since,
      authors_bio: user.authors_bio,
      genre_id: genre ? genre.map((g: { value: any }) => g.value) : [] as any,
      location_id: location ? location.map((l: { value: any; }) => l.value) : [] as any,
      lang_id: language ? language.map((lang: { value: any; }) => lang.value) : [] as any,
      social: social?.map((s: { value: any; url: any; }) => ({ social_id: s.value, url: s.url })),
    };

    formData.append('info', JSON.stringify(data));
    if (showing.length !== 0) {
      Array.from(showing).forEach((f: any) => {
        formData.append('photos', f);
      });
    }

    if (coverImage) {
      formData.append('cover_image', coverImage);
    }

    if (isLoading === false) {
      setIsLoading(true);
      try {
        await axiosInstance.post(
          `${process.env.REACT_APP_API_URL}/v2_authors/authors`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${props.token}`
            }
          }
        );
        swal({
          title: "Author Added Successfully!",
          icon: "success",

        });
        setUser({
          ...user,
          author_first_name: "",
          author_last_name: "",
          coauthor_fname: "",
          coauthor_lname: "",
          public_phone: "",
          private_phone: "",
          author_email: "",
          goodread_url: "",
          authors_dob: "",
          member_since: "",
          authors_bio: "",
        });
        setimg([]);
        setLanguage([]);
        setGenre([]);
        setSocial([]);
        setCoverImage(null);
        setNetworkImage(null);
        setNetworkingShow([])
        setLocation([]);
      } catch (e) {
        const error = e as AxiosError;
        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }
      setIsLoading(false);
    }
  };

  // Put API
  const updateAuthor = async () => {
    const author_first_name_Error = [];
    const author_last_name_Error = [];
    const authors_bio_Error = [];
    const goodread_url_Error = [];
    const social_Error = [] as any;

    if (user.author_first_name === "") {
      author_first_name_Error.push("*Enter Author First name");
    }
    if (user.author_last_name === "") {
      author_last_name_Error.push("*Enter Author Last name");
    }
    if (user.authors_bio === "") {
      authors_bio_Error.push("*Enter Author Bio");
    }

    if (user.goodread_url === "") {
      setUser({ ...user, goodread_url: "", })
    } else if (!isURL(user?.goodread_url)) {
      goodread_url_Error.push("Invalid URL")
    }


    const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
    let foundInvalidUrl = false;

    social.forEach((s: { value: any; url: any; }) => {
      if (!s.url) {
        foundInvalidUrl = true;
      } else if (!urlRegex.test(s.url)) {
        foundInvalidUrl = true;
      }
    });
    if (foundInvalidUrl) {
      social_Error.push("Please Enter A Valid URL For All Social Links");
    }

    setValid({
      ...valid,
      author_first_name_Error,
      author_last_name_Error,
      authors_bio_Error,
      goodread_url_Error,
      social_Error,
    });

    if (
      author_first_name_Error.length ||
      author_last_name_Error.length ||
      authors_bio_Error.length ||
      goodread_url_Error.length ||
      social_Error.length
    )
      return;
    const formData = new FormData();

    const data = {
      author_first_name: user.author_first_name,
      author_last_name: user.author_last_name,
      coauthor_fname: user.coauthor_fname,
      coauthor_lname: user.coauthor_lname,
      public_phone: user.public_phone,
      private_phone: user.private_phone,
      author_email: user.author_email,
      goodread_url: user.goodread_url,
      authors_dob: user.authors_dob,
      member_since: user.member_since,
      authors_bio: user.authors_bio,
      genre_id: genre ? genre.map((g: { value: any }) => g.value) : [] as any,
      location_id: location ? location.map((l: { value: any }) => l.value) : [] as any,
      lang_id: language ? language.map((lang: { value: any; }) => lang.value) : [] as any,
      social: social?.map((s: { value: any; url: any; }) => ({ social_id: s.value, url: s.url })),
      cover_to_keep: networkCover,
      images_to_keep: networkShowing,
    };

    formData.append('info', JSON.stringify(data));

    if (showing.length !== 0) {
      Array.from(showing).forEach((f: any) => {
        formData.append('photos', f);
      });
    }
    if (coverImage) {
      formData.append('cover_image', coverImage);
    }
    if (isLoading === false) {
      setIsLoading(true);
      setIsUpdateCalled(true);

      try {
        await axiosInstance.put(
          `${process.env.REACT_APP_API_URL}/v2_authors/authors/${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${props.token}`
            }
          }
        );
        swal({
          title: "Author Updated Successfully!",
          icon: "success",
        });
      } catch (e) {
        const error = e as AxiosError;

        if (error.response?.data.error) {
          toast.error(error.response?.data.error);
        }
        else {
          toast.error('Something went wrong. Please try again later');
        }
      }

      setIsLoading(false);
      setIsUpdateCalled(false);

    }
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (e: React.FormEvent<HTMLFormElement>) => {

    e.preventDefault();

    if (id) {
      updateAuthor();
    } else {
      postAuthor();
    }
  };

  useEffect(() => {
    getLanguageData();
    getLocationData();
    getSocialData();
    getAuthor();
  }, []);

  // Fetch API
  const getAuthor = async () => {
    if (id) {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/v2_authors/admin-authors/${id}`, {
          headers: {
            Authorization: `Bearer ${props.token}`
          }
        })
        const data = await res.json();
        const datesss = data.authors_dob as any;
        var arr = datesss.split('-') as any;

        if (arr[0] === '0000' || arr[0] === '0001') {
          setUser({
            ...data,
            authors_dob: "",
          });
        } else {
          setUser({ ...data })

        }
        setNetworkImage(data.cover_image);
        setNetworkingShow(data.images);
        setLanguage(data.lang);
        setLikes(data.likes);
        setSocial(data.social);
        setLocation(data.loc);
        setGenre(data.genre);
      } catch (e) {
        console.log(e);
      }
    }
  }

  async function getLanguageData() {
    const dataLanguage = await getLanguage();
    setLanguage_data(dataLanguage.data);
  }

  async function getLocationData() {
    const dataLocation = await getLocation();
    setLocation_data(dataLocation.data);
  }

  // Social link API
  const getSocialData = async () => {
    try {
      const req = await fetch(`${process.env.REACT_APP_API_URL}/socialMedia/get-socialMedia`);
      const dataRaw = await req.json();

      const data = dataRaw.data;
      const mapData = data.map((s: { social_name: any; social_id: any; iconpath: any; }) => ({
        label: s.social_name,
        value: s.social_id,
        iconpath: s.iconpath,
        url: '',
      }));

      setSocialData(mapData);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const temp2 = [] as any;
    Language_data?.forEach((value: { language_name: any; lang_id: any; }) => {
      temp2.push({ label: value.language_name, value: value.lang_id });
    });
    setOptions2(temp2);
  }, [Language_data]);

  useEffect(() => {
    const temp = [] as any;
    Location_data?.forEach((value: { country_name: any; location_id: any; }) => {
      temp.push({ label: value.country_name, value: value.location_id });
    });
    setOptions1(temp);
  }, [Location_data]);

  const renderCover = () => {
    if (networkCover) {
      return (
        <Row>
          <Col xs="auto">
            <Image src={`${process.env.REACT_APP_S3_APP_API}/author/${networkCover}`} height="auto" width="100px" alt="cover" onPress={() => setNetworkImage(null)} />
          </Col>
        </Row>
      )
    }

    if (coverImage) {
      return (
        <Row>
          <Col xs="auto">
            <Image src={URL.createObjectURL(coverImage)} height="auto" width="100px" alt="cover" onPress={() => setCoverImage(null)} />
          </Col>
        </Row>
      )
    }

    return (
      <button className="btn btn-primary" onClick={(e) => {
        e.preventDefault();

        coverRef?.current?.click();
      }}>Add Image</button>
    );
  };

  const loadGenre = async (genre: String) => {
    try {
      const category = await axiosInstance.get('/genre/get-genre', {
        params: {
          genre: genre,
        }
      });

      return category.data;
    } catch (e) { }
  };

  const renderShowing = () => {
    return (
      <Row>
        {networkShowing.map((img) => (
          <Col xs="auto">
            <Image src={`${process.env.REACT_APP_S3_APP_API}/author/${img}`} height="auto" width="100px" alt="cover" onPress={() => {
              const newImg = networkShowing.filter((t) => t !== img);

              setNetworkingShow(newImg);
            }} />
          </Col>
        ))}


        {showing.map((img: any, i: any) => {
          return (
            <Col xs="auto" key={i}>
              <Image src={URL.createObjectURL(img)} height="auto" width="100px" alt="profile" onPress={() => {
                const newArr = showing.filter((file: any) => file.name !== img.name && file.lastModified !== img.lastModified);
                setimg(newArr);
              }} />
            </Col>
          )
        })}
      </Row>
    )
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <h4 className="card-title">{id ? `UPDATE AUTHOR (❤️: ${likes ? likes : ''})` : 'ADD AUTHOR'}</h4>
                  <form onSubmit={onSubmit} encType="multipart/form-data">
                    <Row>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>AUTHOR FIRST NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            // id="exampleFormControlInput1"
                            id="autosuggest-input"
                            placeholder="Enter Author First Name"
                            name="author_first_name"
                            value={user.author_first_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              e.persist();
                              setUser({ ...user, [e.target.name]: e.target.value });
                              setIsSaved() as any;
                              const selectedValue = document.getElementById('autosuggest-input') as HTMLInputElement;

                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, author_first_name_Error: "" })
                              } else {
                                setValid({ ...valid, author_first_name_Error: "*Enter Author First name" });

                              }
                            }}

                          />
                          {valid?.author_first_name_Error && <div style={{ color: 'red' }}>{valid?.author_first_name_Error} </div>}
                          {/* {valid?.author_first_name_Error?.length
                            ? valid?.author_first_name_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null} */}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>AUTHOR LAST NAME*</label>
                          <input
                            type="text"
                            className="form-control"
                            // id="exampleFormControlInput1"
                            id="autosuggest-input-1"
                            placeholder="Enter Author Last Name"
                            name="author_last_name"
                            value={user.author_last_name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                              const selectedValue = document.getElementById('autosuggest-input-1') as HTMLInputElement;

                              e.persist();
                              setUser({ ...user, [e.target.name]: e.target.value });
                              setIsSaved() as any;

                              if ((selectedValue.value.length)) {
                                setValid({ ...valid, author_last_name_Error: "" })
                              } else {
                                setValid({ ...valid, author_last_name_Error: "*Enter Author Last name" });

                              }
                            }}
                          />
                          {valid?.author_last_name_Error && <div style={{ color: 'red' }}>{valid?.author_last_name_Error} </div>}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CO AUTHOR FIRST NAME</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Author First Name"
                            name="coauthor_fname"
                            value={user.coauthor_fname}
                            onChange={onInputChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>CO AUTHOR LAST NAME</label>
                          <input
                            type="text"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter  Author Last Name"
                            name="coauthor_lname"
                            value={user.coauthor_lname}
                            onChange={onInputChange}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>PUBLIC MOBILE NO</label>
                          <PhoneInput
                            defaultCountry="US"
                            placeholder="Enter Your Mobile No"
                            name="public_phone"
                            value={user.public_phone}
                            onChange={(e) => {
                              setIsSaved() as any;
                              try {
                                if ((e as string).length > 0) {
                                  if (!isValidPhoneNumber(e as string)) {
                                    setValid({ ...valid, authors_phone_number: ["Invalid Phone Number"] });
                                  } else {
                                    setValid({ ...valid, authors_phone_number: [] });
                                  }
                                } setUser({ ...user, ['public_phone']: e as any });

                              } catch (error) {
                                setValid({ ...valid, authors_phone_number: [] });
                              }
                            }
                            }
                          />

                        </div>
                        {valid?.authors_phone_number?.length
                          ? valid?.authors_phone_number.map((error: any, i: React.Key | null | undefined) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>PRIVATE MOBILE NO</label>
                          <PhoneInput
                            defaultCountry="US"
                            placeholder="Enter Your Mobile No"
                            name="public_phone"
                            value={user.private_phone}
                            onChange={(e) => {
                              setIsSaved() as any;
                              try {
                                if ((e as string).length > 0) {
                                  if (!isValidPhoneNumber(e as string)) {
                                    setValid({ ...valid, private_phone_number: ["Invalid Phone Number"] });
                                  } else {
                                    setValid({ ...valid, private_phone_number: [] });
                                  }
                                } setUser({ ...user, ['private_phone']: e as any });

                              } catch (error) {
                                setValid({ ...valid, private_phone_number: [] });
                              }
                            }
                            }
                          />
                        </div>
                        {valid?.private_phone_number?.length
                          ? valid?.private_phone_number.map((error: any, i: React.Key | null | undefined) => (
                            <ErrorSpan key={i} error={error} />
                          ))
                          : null}
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>EMAIL</label>
                          <input
                            type="email"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter Email"
                            name="author_email"
                            value={user.author_email}
                            onChange={onInputChange}
                          />
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          <label>GENRE</label>
                          <AsyncSelect placeholder="Genre" isMulti value={genre} cacheOptions defaultOptions loadOptions={loadGenre} onChange={setGenre} />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>MEMBER SINCE</label>
                          <input
                            type="text"
                            className="form-control"
                            name="member_since"
                            id="exampleFormControlInput1"
                            placeholder="Enter Member Since"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={4} value={user.member_since}

                            onChange={onInputChange}

                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>LANGUAGE</label>
                          <Select
                            className="select"
                            name="lang_id"
                            placeholder="Select Language"
                            isMulti
                            value={language}
                            onChange={(e) => {
                              setLanguage(e)
                              setIsSaved() as any;
                            }}
                            options={options2}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>GOODREADS URL</label>
                          <input
                            type="text"
                            className="form-control"
                            name="goodread_url"
                            id="exampleFormControlInput1"
                            placeholder="Enter Goodreads Url"
                            value={user.goodread_url}
                            onChange={onInputChange}
                          />
                          {valid?.goodread_url_Error?.length
                            ? valid?.goodread_url_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>LOCATION</label>
                          <Select
                            className="select"
                            name="location_id"
                            placeholder="Select Location"
                            isMulti
                            value={location}
                            onChange={(e) => {
                              setLocation(e)
                              setIsSaved() as any;
                            }}
                            options={options1}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>AUTHOR'S BIO *...(3000 Characters)</label>
                          <TextareaAutosize
                            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                            aria-label="minimum height"
                            maxLength={3000}
                            name="authors_bio"
                            minRows={4}
                            id="exampleFormControlInput1"
                            placeholder="Enter Bio"
                            value={user.authors_bio}

                            onChange={onTextareaChange}
                          />
                          {valid?.authors_bio_Error && <div style={{ color: 'red' }}>{valid?.authors_bio_Error} </div>}
                          <label style={{ color: "#0069d9" }}>{user.authors_bio.length} characters</label>
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group">
                          <label>SOCIAL LINKS</label>
                          <br />
                          <Select
                            className="select"
                            name="genre_id"
                            placeholder="Select Social Links"
                            isMulti
                            value={social}
                            onChange={(e) => {
                              setSocial(e)
                              setIsSaved() as any;
                            }}
                            options={socialData}
                          />
                          {/* {valid?.social_Error && <div style={{ color: 'red' }}>{valid?.social_Error}
                          </div>} */}
                          {social?.map((temp: { iconpath: any; }, i: any) => (
                            <InputGroup style={{ marginTop: '4px' }}>
                              <InputGroupAddon addonType="prepend">
                                <img src={`${process.env.REACT_APP_S3_APP_API}/social/${temp.iconpath}`} height="38px" width="30px" alt="sc" />

                              </InputGroupAddon>
                              <Input placeholder="Place Your Link Here" value={social[i].url} onChange={(e) => {
                                const t = [...social];
                                t[i]['url'] = e.target.value;

                                setSocial(t);
                              }} />
                            </InputGroup>

                          ))}
                          {valid?.social_Error?.length
                            ? valid?.social_Error.map((error: any, i: React.Key | null | undefined) => (
                              <ErrorSpan key={i} error={error} />
                            ))
                            : null}
                        </div>
                      </Col>
                      <Col xs={6}>
                        <label>PRIMARY AUTHOR IMAGE</label>
                        <input
                          type="file"
                          ref={coverRef}
                          className="form-control"
                          id="exampleFormControlInput1"
                          name="authors_image"
                          style={{ display: 'none' }}
                          accept="image/*"
                          onChange={(e: any) => {
                            setIsSaved() as any;
                            const file = e.target.files[0];
                            if (e.target.files) {
                              if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                toast.error('only PNG/JPEG/JPG are supported');
                                return;
                              }
                              if (e.target.files[0].size > 5000_000) {
                                toast.error('File too large. Please select file less than 5MB');
                                return;
                              }

                              const options = {
                                maxSizeMB: 1,
                                useWebWorker: true
                              }

                              Compress(file, options)
                                .then((compressedBlob) => {
                                  const convertedBlobFile = new File([compressedBlob], file.name, {
                                    type: file.type,
                                    lastModified: Date.now(),
                                  });
                                  setCoverImage(convertedBlobFile)
                                })
                                .catch((e) => {
                                });
                            }
                          }}
                        />
                        <br />
                        {renderCover()}
                      </Col>

                      <Col xs={6}>
                        <div className="form-group">
                          {" "}
                          <label>AUTHOR IMAGES</label>
                          <input
                            type="file"
                            ref={imgRef}
                            className="form-control"
                            id="exampleFormControlInput1"
                            name="authors_image"
                            style={{ display: 'none' }}
                            accept="image/*"
                            multiple
                            onChange={async (e) => {
                              setIsSaved() as any;
                              const files: File[] = []
                              const previews = []
                              if (e.target.files) {
                                if (!e.target.files[0]?.name.match(/\.(png|jpeg|jpg)$/i)) {
                                  toast.error('only PNG/JPEG/JPG are supported');
                                  return;
                                }
                                if (e.target.files[0].size > 5000_000) {
                                  toast.error('File too large. Please select file less than 5MB');
                                  return;
                                }
                                const y = e.target.files;
                                for (var i = 0; i < y.length; i++) {
                                  const file = y[i];
                                  if (file.name.match(/\.(png|jpeg|jpg)$/i)) {
                                    const options = {
                                      maxSizeMB: 1,
                                      useWebWorker: true
                                    };

                                    await Compress(file, options)
                                      .then((compressedBlob) => {
                                        console.log(imgcompressor);
                                        setImgcompressor({
                                          imageUrl: URL.createObjectURL(compressedBlob)
                                        })

                                        const convertedBlobFile = new File([compressedBlob], file.name, {
                                          type: file.type,
                                          lastModified: Date.now(),
                                        });

                                        files.push(convertedBlobFile);

                                        previews.push(URL.createObjectURL(convertedBlobFile))

                                      })
                                      .catch((e) => {
                                      });

                                  }
                                }
                                files.map(e => console.log("File Path: ", URL.createObjectURL(e)))
                                setimg(files)
                              }
                            }}
                          />
                          <br />
                          <button className="btn btn-primary" onClick={(e) => {
                            e.preventDefault();

                            imgRef?.current?.click();
                          }}>Add Images</button>
                          {renderShowing()}
                        </div>
                      </Col>

                      <Col xs={6}>
                        <div className="form-group publication-input">
                          <label>AUTHOR'S DOB</label>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <MobileDatePicker
                              label="Author Dob"
                              value={user.authors_dob}
                              onChange={date => {
                                setUser({ ...user, authors_dob: date! });
                                setIsSaved() as any;
                              }}
                              renderInput={(params) => <TextField className={classes.removeBorder} {...params} error={false} />}
                            />
                          </LocalizationProvider>

                        </div>
                      </Col>
                      <div
                        style={{
                          display: "flex",
                          margin: "5px",
                          padding: "10px",
                        }}
                      >
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          <input
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="btn btn-primary"
                            onClick={() => {
                              setPristine()
                            }}
                          />
                        </div>
                        <div
                          style={{
                            margin: "5px",
                            padding: "10px",
                          }}
                        >
                          {" "}
                          <Link
                            to="/ebookflutter_adminpanel/author"
                            style={{ color: "white" }}
                          >
                            <button type="button" className="btn btn-primary">
                              Back
                            </button>
                          </Link>
                        </div>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {id == undefined || isUpdateCalled ?
          <></> : <EditAuthorLogs />}
        {isLoading ? <Loader /> : null}
      </div>
      {Prompt}
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  }
};

export default connect(mapStateToProps)(AddAuthor);


