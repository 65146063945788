import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import {
    Paper,
    TableContainer,
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import AsyncSelect from 'react-select/async';
import "./datatables.scss";
import axiosInstance from "../../helpers/axios";
import { connect } from 'react-redux';
import Loader from "../../components/ui/loader/Loader";
import { useFetchHook } from "../../utils/fetchHook";
import Select from 'react-select';
import { UserRoles } from "../../store/auth/admins/user";
import { StoreState } from "../../store";
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { toast } from "react-toastify";

interface Props {
    token: string;
    user: UserRoles;
}

type AuthorReviewType = {
    _id: number;
    user: string;
    description: string;
    fname: string;
    module: any;
    users: String;
    status: any;
    action: any;
    createdAt: Date;
    values: any;
    modulename: any;
    total: number;
    action_performed_by: any;
    arr: any;
    myarr: any;
    test: any;
};

const AuthorReview = (props: Props) => {
    const tableRef = useRef<HTMLTableElement>(null);
    const [dates, setDates] = useState<any>([null, null]);
    const [users, setLogusers] = useState<{ value: string; label: string } | null>(null);
    const [modulename, setModulename] = useState<{ value: string; label: string } | null>(null);
    const [action, setAction] = useState<{ value: string; label: string } | null>(null);
    const [author_name, setAuthor] = useState('');
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [showError, setShowError] = useState(false);
    const [PageData, setPageData] = useState(false);
    const [show, setShow] = useState([] as any);
   
    const [isExpanded, setIsExpanded] = useState([] as any);
    const [newUsers, setNewusers] = useState({
        modulename: null as any,
        action: null as any,
        users: null as any,
    })



const removeElement = (_id: any) => {
    const temp = [isExpanded];
    temp.splice(temp.indexOf(_id), 1);
    setIsExpanded(temp); 
   
  };



    const [{ isLoading, isError, data }, { setOptions }] = useFetchHook
        <{ data: AuthorReviewType[]; numberOfPage: number; total: number | null, }>
        (`${process.env.REACT_APP_API_URL}/log/admin-logs`,
            { data: [], numberOfPage: 0, total: null },
            {
               params: { page },
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });

    useEffect(() => {
        onFilterApply(null as any, false)
    }, [newUsers])

    useEffect(() => {
        var myarr1 = [] as any;
        var d = data.data;
        try {
            d.map((t, index) => {
                var temp = [] as any;
                Object.keys(t.values).forEach(key => {
                    temp.push({
                        name: key, value: t.values[key]

                    })
                })
                myarr1.push(temp);
            });
        } catch (e) {
            myarr1 = [];
        }
        setShow(myarr1);
    }, [data])

    useEffect(() => {
        if (PageData) {
            setOptions({
                params: {
                    page,
                    modulename: modulename?.value,
                    action: action?.value,
                    users: users?.value,
                    author_name: author_name,
                    dates,
                },
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });
          
        } else {
            setPageData(true);
          
        }
        setCurrentPage(page + 1);
        return () => {
        }
    }, [page]);

    if (isError) {
        return (
            <p>Something went wrong please try again later</p>
        );
    }

    const loadLogUsers = async (logusers: String) => {
        try {
            const pubs = await axiosInstance.get('/users/drop-log-users',
                {
                    params: { logusers, },
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
            return pubs.data;
        } catch (e) {
        }
    };

    const moduleList = [
        {
            label: "All",
            value: "All",
        },
        {
            label: "Category",
            value: "categories",
        },
        {
            label: "Top Category",
            value: "categories",
        },
        {
            label: "Sub Category",
            value: "subcategories",
        },
        {
            label: "Author",
            value: "v_authors",
        },
        {
            label: "Book",
            value: "books",
        },
        {
            label: "Feed",
            value: "feed",
        },
        {
            label: "Coupons",
            value: "coupons",
        },
        {
            label: "TopSelling",
            value: "books",
        },
        {
            label: "Mobileslider",
            value: "mobile_slider",
        },
        {
            label: "Socialmedia",
            value: "social_media",
        },
        {
            label: "Publisher",
            value: "publisher",
        },
        {
            label: "Genre",
            value: "genre",
        },
        {
            label: "Roles",
            value: "roles",
        },
        {
            label: "Users",
            value: "admins",
        },
        {
            label: "Feedback",
            value: "feedback",
        },
        {
            label: "Author Review",
            value: "author_review",
        },
        {
            label: "Book Review",
            value: "book_review",
        },

        {
            label: "Coach Review ",
            value: "coach_review",
        },
        {
            label: "Audio Review ",
            value: "audio_review",
        },
        {
            label: "Video Review ",
            value: "video_review",
        },

    ];
    const options1 = [
        {
            label: "All",
            value: "All",
        },
        {
            label: "Add",
            value: "post",
        },
        {
            label: "Update",
            value: "update",
        },
        {
            label: "Delete",
            value: "delete",
        },
    ];


    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (modulename != null || users != null || action != null || (dates[0] != null && dates[1] != null)) {
                e.preventDefault();
                setPage(1);
                setShowError(false);
                setOptions({
                    params: {
                        page,
                        modulename: modulename?.value,
                        action: action?.value,
                        users: users?.value,
                        author_name: author_name,
                        dates,
                    },
                    headers: {
                        Authorization: `Bearer ${props.token}`,
                    }
                });
            }
            else {
                setShowError(true);

            }
        }
    }
    const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {
        if (modulename != null || users != null || action != null || (dates[0] != null && dates[1] != null)) {
            if (e !== null) {
                e.preventDefault();
            }
            setPage(1);
            setShowError(false);
            setOptions({
                params: {
                    page,
                    modulename: modulename?.value,
                    action: action?.value,
                    users: users?.value,
                    author_name: author_name,
                    dates,
                },
                headers: {
                    Authorization: `Bearer ${props.token}`,
                }
            });
        }
        else {
            if (isFilterapply === true) {
                setShowError(true)
            } else {
                setShowError(false);
            }

        }
    };

    const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setShowError(false);
        setModulename(null);
        setAction(null);
        setLogusers(null);
        setDates([null, null]);
        setAuthor('');
        setOptions({
            params: {
                page,
            },
            headers: {
                Authorization: `Bearer ${props.token}`,
            }
        });
    }

    const copyTable = () => {
        const table = tableRef.current;
        if (table) {
            const range = document.createRange();
            range.selectNode(table);
            window.getSelection()?.removeAllRanges();
            window.getSelection()?.addRange(range);
            document.execCommand('copy');
            window.getSelection()?.removeAllRanges();
            toast.info("Copied To Clipboard")
        }
    }

    const exportToExcel = () => {
        const table = document.querySelector('table');
        const html = table?.outerHTML;
        if (html) {
            const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
            const dataUrl = URL.createObjectURL(blob);
            const downloadLink = document.createElement('a');
            downloadLink.href = dataUrl;
            downloadLink.download = 'table.xls';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(dataUrl);
        }
    }

    const convertTableToCSV = (table: any) => {
        let csv = '';
        const rows = table.querySelectorAll('tr');
        for (let i = 0; i < rows.length; i++) {
            const cells = rows[i].querySelectorAll('th, td');
            for (let j = 0; j < cells.length; j++) {
                csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
            }
        }
        return csv;
    };

console.log("data---",data)

    const handleDownloadCSV = () => {
        const tableNode = document.getElementById('table-data');
        const csvData = convertTableToCSV(tableNode);
        const downloadLink = document.createElement('a');
        downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
        downloadLink.download = 'table-data.csv';
        downloadLink.click();
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs={6}>
                                            <h4 className="card-title">LOG LIST ({data.total})</h4>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row>
                                        <Col xs={3}>
                                            <AsyncSelect placeholder="All" value={users}
                                                cacheOptions defaultOptions loadOptions={loadLogUsers}
                                                // onChange={setLogusers}
                                                onChange={(value) => {
                                                    setLogusers(value);
                                                    setNewusers({ ...newUsers, users: value })
                                                }}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Select placeholder="Module Name" name="modulename" options={moduleList} value={modulename}
                                                //  onChange={setModulename}
                                                onChange={(value) => {
                                                    setModulename(value);
                                                    setNewusers({ ...newUsers, modulename: value })
                                                }} />
                                        </Col>
                                        <Col xs={3}>
                                            <div>
                                                <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress={handleKeyPress} onChange={setDates} value={dates} />
                                            </div>
                                        </Col>
                                        <Col xs={3}>
                                            <Select placeholder="Action" name="action" options={options1} value={action}
                                                // onChange={setAction}
                                                onChange={(value) => {
                                                    setAction(value);
                                                    setNewusers({ ...newUsers, action: value })
                                                }} />
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col xs="auto">
                                            <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                                        </Col>
                                        <Col xs="auto">
                                            <button className="btn btn-primary" onClick={onResetApply}>Reset</button>
                                        </Col>
                                    </Row>
                                    <div className="msg-box">
                                        {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                                    </div>
                                    <br />
                                    <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                                    <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                                    <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                                    <TableContainer component={Paper}>
                                        <Table id="table-data" ref={tableRef}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>SNO</TableCell>
                                                    <TableCell>ACTION</TableCell>
                                                    <TableCell>MODULENAME</TableCell>
                                                    <TableCell>USERS</TableCell>
                                                    <TableCell>DATA</TableCell>
                                                    <TableCell>DATE</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.data
                                                    .map((t, index) => (
                                                        <TableRow key={t._id}>
                                                  
                                                            <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                                                            {(t.action.name === 'POST') ?
                                                                <>
                                                                    <TableCell>ADD</TableCell>

                                                                </>
                                                                : <><TableCell>{t.action.name}</TableCell>
                                                                </>
                                                            }
                                                            <TableCell>{t.modulename?.name}</TableCell>
                                                            <TableCell>{t.action_performed_by.name}</TableCell>
                                                            <TableCell style={{ width: "200px" }}>
                                                                                                   
                                                                {(t.action.name === "POST") ?
                                                                    <>
                                                              
                                                                      {Object.keys(t.values).length == 2 ?<>
                                                                        {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div> <b> {g1?.name} -- </b>
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> :
                                                                                    (g1?.value === Date) ? `${new Date(g1?.value).getMonth() + 1} - ${new Date(g1?.value).getDate() + "  "} -${new Date(g1?.value).getFullYear()}` : g1?.value} </b>
                                                                            </div>
                                                                        )) : "" : ""}</>:<>
                                                                          {isExpanded.includes(t._id) ? <>
                                                                            {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div> <b> {g1?.name} -- </b>
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> :
                                                                                    (g1?.value === Date) ? `${new Date(g1?.value).getMonth() + 1} - ${new Date(g1?.value).getDate() + "  "} -${new Date(g1?.value).getFullYear()}` : g1?.value} </b>
                                                                            </div>
                                                                        )) : "" : ""}
                                                                          
                                                                          </> : <>
                                                                          
                                                                          {show.length > 0 ? show[index] != undefined ? show[index].slice(0,2).map((g1: any, ssindex: any) => (
                                                                         
                                                                         <div> 
                                                                             
                                                                             <b> {g1?.name} -- </b>
                                                                             <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> :
                                                                                 (g1?.value === Date) ? `${new Date(g1?.value).getMonth() + 1} - ${new Date(g1?.value).getDate() + "  "} -${new Date(g1?.value).getFullYear()}` : g1?.value}... </b>
                                                                                  
                                                                         </div>
                                                                        
                                                                     )) : "" : "" + '...'}
                                                                          </>}

                                                                          {Object.keys(t.values).length > 2 &&(
                                                                              <button className="desc-btn" onClick={()=>{
                                                                                isExpanded.includes(t._id) ? removeElement(t._id) : setIsExpanded([...isExpanded, t._id])
                                                                           }}> {isExpanded.includes(t._id) ? 'Read Less' : 'Read More'}</button>
                                                                          )}                                                                            
                                                                           
                                                                            
                                                                        </>}
                                                                    </> : <></>
                                                                }
                                                                {(t.action.name === "UPDATE") ?
                                                                    <>         
                                                                    {Object.keys(t.values).length == 2 ?<>
                                                                        {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div>
                                                                                <b> {g1?.name} -- </b>
                                                                                                                              
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value} </b>

                                                                            </div>
                                                                        )) : "" : ""}</>
                                                                              :<>
                                                                           {isExpanded.includes(t._id) ? <>
                                                                            {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div>
                                                                                <b> {g1?.name} -- </b>
                                                                                                                              
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value} </b>

                                                                            </div>
                                                                        )) : "" : ""}
                                                                           
                                                                           </>:<>
                                                                           {show.length > 0 ? show[index] != undefined ?show[index].slice(0,2).map((g1: any, ssindex: any) => (
                                                                            <div>
                                                                              
                                                                                <b> {g1?.name} -- </b>
                                                                                                                              
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value}... </b>
                                                                             
                                                                            </div>
                                                                        )) : "" : ""}
                                                                           
                                                                           </>}
                                                                           
                                                                           {Object.keys(t.values).length > 2 &&(
                                                                              <button className="desc-btn" onClick={()=>{
                                                                                isExpanded.includes(t._id) ? removeElement(t._id) : setIsExpanded([...isExpanded, t._id])
                                                                           }}>{(isExpanded.includes(t._id) ? 'Read Less' : 'Read More')}</button>
                                                                          )}
                                                                              </>  }
                                                                    </> : <></>
                                                                }

                                                                {(t.action.name === "DELETE") ?
                                                                    <>
                                                                     {Object.keys(t.values).length == 2 ?<>
                                                                        {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div> 
                                                                                <b> {g1?.name}-- </b>
                                                                                {/* <b> {g1?.value} </b> */}
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value} </b>
                                                                            </div>
                                                                        )) : "" : ""}</>:<>

                                                        {isExpanded.includes(t._id) ? <>
                                                        
                                                            {show.length > 0 ? show[index] != undefined ? show[index].map((g1: any, ssindex: any) => (
                                                                            <div> 
                                                                                <b> {g1?.name}-- </b>
                                                                                {/* <b> {g1?.value} </b> */}
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value} </b>
                                                                            </div>
                                                                        )) : "" : ""}
                                                        </>:<>
                                                        
                                                        {show.length > 0 ? show[index] != undefined ? show[index].slice(0,2).map((g1: any, ssindex: any) => (
                                                                            <div> 
                                                                                <b> {g1?.name}-- </b>
                                                                                {/* <b> {g1?.value} </b> */}
                                                                                <b>{(g1?.value === null) ? <b>NA</b> : (g1?.value === "") ? <b>NA</b> : (g1?.value === true) ? g1?.value.toString() : (g1?.value === false) ? g1?.value.toString() : ((g1.value.length) === 0) ? <><b>NA</b></> : g1?.value} </b>
                                                                            </div>
                                                                        )) : "" : ""}
                                                        </>}

                                                             {Object.keys(t.values).length > 2 &&(
                                                                              <button className="desc-btn" onClick={()=>{
                                                                                isExpanded.includes(t._id) ? removeElement(t._id) : setIsExpanded([...isExpanded, t._id])
                                                                           }}> {isExpanded.includes(t._id) ? 'Read Less' : 'Read More'}</button>
                                                                          )}                                                                       
                                                                        
                                                                        </>}
                                                                    </> : <></>
                                                                }
                                                            </TableCell>
                                                            <TableCell> {`${new Date(t.createdAt).getMonth() + 1} - ${new Date(t.createdAt).getDate() + "  "} -${new Date(t.createdAt).getFullYear()}`}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                        <Pagination
                                            count={data.numberOfPage}
                                            page={currentPage - 1}
                                            color="primary"
                                            variant="outlined"
                                            showFirstButton={true}
                                            showLastButton={true}
                                            onChange={(_e, value) =>{
                                                setPage(value)
                                                removeElement(_e)
                                               
                                            } }
                                        />
                                    </TableContainer>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                                                               
                {isLoading ? <Loader /> : null}
            </div >
        </React.Fragment >
    );
};

const mapStateToProps = (state: StoreState) => {
    return {
        token: state.token!,
        user: state.user,
    };
};

export default connect(mapStateToProps)(AuthorReview);
