import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";

// Dashboard
// import Dashboard from "../pages/Dashboard/index";
import NewDashboard from '../pages/Dashboard/NewDashboard';
//eBook

import Category from "../pages/eBook/Category";
import SubCategory from "../pages/eBook/subCategory";
import Author from "../pages/eBook/Author";
import Book from "../pages/eBook/Book";
// import Chapter from "../pages/eBook/Chapter";
import MobileSlider from "../pages/eBook/MobileSlider";
import TopSelling from "../pages/eBook/TopSelling";
import Recommended from "../pages/eBook/Recommended";
import Genre from "../pages/eBook/Genre";
import Publisher from "../pages/eBook/Publisher";
import SocialMedia from "../pages/eBook/SocialMedia";
import Feedback from '../pages/eBook/Feedback';
import AuthorReview from '../pages/eBook/AuthorReview';
import BookReview from '../pages/eBook/BookReview';
import TopAuthor from '../pages/eBook/TopAuthor';
import TopCategory from '../pages/eBook/TopCategory';
import MobileUsers from '../pages/mobileUsers/MobileUsers';
import Feed from '../pages/eBook/Feed';
import Coupon from '../pages/eBook/Coupon';
import EmailTemplates from '../pages/eBook/EmailTemplates';
import Log from '../pages/eBook/Log';
import NotificationTemplate from '../pages/eBook/NotificationTemplate';
import Notification from "../pages/eBook/Notification";
import Coach from "../pages/eBook/Coach";
import Audio from "../pages/eBook/Audio";
import Video from "../pages/eBook/Video";
import CoachReview from "../pages/eBook/CoachReview";
import AudioReview from "../pages/eBook/AudioReview";
import VideoReview from "../pages/eBook/VideoReview";
// ADD
import AddCategory from "../pages/eBook/Add/AddCategory";
import AddCoach from "../pages/eBook/Add/AddCoach";
import AddSubCategory from "../pages/eBook/Add/AddSubCategory";
import AddAuthor from "../pages/eBook/Add/AddAuthour";
import AddBook from "../pages/eBook/Add/AddBook";
import AddChapter from "../pages/eBook/Add/AddChapter";
import AddTopTen from "../pages/eBook/Add/AddTopTen";
import AddMobileSlider from "../pages/eBook/Add/AddMobileSlider";
import AddGenre from "../pages/eBook/Add/AddGenre";
import AddCoupon from "../pages/eBook/Add/AddCoupon";
import AddSocialMedia from "../pages/eBook/Add/AddSocialMedia";
import AddPublisher from "../pages/eBook/Add/AddPublisher";
import AddFeed from '../pages/eBook/Add/AddFeed';
import AddEmailTemplate from '../pages/eBook/Add/AddEmailTemplate';
import EditTemplate from '../pages/eBook/Edit/EditTemplate';
//edit
import EditSubCategory from "../pages/eBook/Edit/EditSubCategory";
import EditGenre from "../pages/eBook/Edit/EditGenre";
import EditPublisher from "../pages/eBook/Edit/EditPublisher";

//view
import ViewAthour from "../pages/eBook/View/ViewAuthor";
import ViewCoach from "../pages/eBook/View/ViewCoach";

import ViewBook from "../pages/eBook/View/ViewBook";
import AuthorReviewView from '../pages/eBook/View/AuthorReviewView';
import BookReviewView from "../pages/eBook/View/BookReviewView";
import CoachReviewView from '../pages/eBook/View/CoachReviewView'
import AudioReviewView from '../pages/eBook/View/AudioReviewView'
import VideoReviewView from '../pages/eBook/View/VideoReviewView'
// import VideoView from '../pages/ebook/View/VideoView'
import AudioView from '../pages/eBook/View/AudioView'
import VideoView from "../pages/eBook/View/VideoView";


//Roles
import Roles from '../pages/admin/Roles';
import AddRoles from '../pages/admin/AddRoles';
import ViewRole from '../pages/eBook/View/ViewRole';
// import AddUser from '../pages/admin/AddAdminUser';
import Users from '../pages/admin/Users';
// import NewUsers from '../pages/admin/AddUsers';

import ViewUser from '../pages/eBook/View/ViewUser';

import UserInfo from '../pages/eBook/UserInfo';
import ViewMobileUsers from "../pages/mobileUsers/ViewMobileUsers";
import EditMobileUsers from "../pages/mobileUsers/EditMobileUsers";
import EditCoupon from "../pages/eBook/Edit/EditCoupon";

import Payment from '../pages/Payment';
import ForgetMobileNumber from "../pages/Authentication/FogetMobileNumber";
import Sendotp from "../pages/Authentication/Sendotp";
// import ChangeOldPassword from "../pages/Authentication/ChangeOldPassword";
import ChangePassword from "../pages/Authentication/ChangePassword";
import NewPassword from "../pages/Authentication/NewPassword";
import TopCoach from "../pages/eBook/TopCoach";
import UserFile from "../pages/eBook/UserFile";
import AddAdminUser from "../pages/admin/AddAdminUser";
import AddUsers from "../pages/admin/AddUsers";

const authProtectedRoutes = [
  { path: "/ebookflutter_adminpanel/user/info", component: UserInfo },
  // eBook

  { path: "/ebookflutter_adminpanel/feedback", component: Feedback },

  { path: "/ebookflutter_adminpanel/mobileUsers", component: MobileUsers },
  { path: "/ebookflutter_adminpanel/category", component: Category },


  { path: "/ebookflutter_adminpanel/users", component: UserFile },
  { path: "/ebookflutter_adminpanel/sub-category", component: SubCategory },
  { path: "/ebookflutter_adminpanel/author", component: Author },
  { path: "/ebookflutter_adminpanel/book", component: Book },
  // { path: "/ebookflutter_adminpanel/chapter", component: Chapter },
  { path: "/ebookflutter_adminpanel/mobile", component: MobileSlider },
  { path: "/ebookflutter_adminpanel/top-selling", component: TopSelling },
  { path: "/ebookflutter_adminpanel/recommended", component: Recommended },
  { path: "/ebookflutter_adminpanel/genre", component: Genre },
  { path: "/ebookflutter_adminpanel/publisher", component: Publisher },
  { path: "/ebookflutter_adminpanel/socialMedia", component: SocialMedia },
  { path: "/ebookflutter_adminpanel/author_review", component: AuthorReview },
  { path: "/ebookflutter_adminpanel/book_review", component: BookReview },
  {
    path: "/ebookflutter_adminpanel/view/author_review/:id",
    component: AuthorReviewView,
  },
  {
    path: "/ebookflutter_adminpanel/view/coach_review/:id",
    component: CoachReviewView,
  },
  {
    path: "/ebookflutter_adminpanel/view/audio_review/:id",
    component: AudioReviewView,
  },
  {
    path: "/ebookflutter_adminpanel/view/video_review/:id",
    component: VideoReviewView,
  },
  {
    path: "/ebookflutter_adminpanel/view/video_view/:id",
    component: VideoView,
  },
  {
    path: "/ebookflutter_adminpanel/view/audio_view/:id",
    component: AudioView,
  },


  {
    path: "/ebookflutter_adminpanel/view/book_review/:id",
    component: BookReviewView,
  },
  { path: "/ebookflutter_adminpanel/top_author", component: TopAuthor },
  { path: "/ebookflutter_adminpanel/top_category", component: TopCategory },
  { path: "/ebookflutter_adminpanel/top_coach", component: TopCoach },
  { path: "/ebookflutter_adminpanel/feed", component: Feed },
  { path: "/ebookflutter_adminpanel/coupons", component: Coupon },
  { path: "/ebookflutter_adminpanel/email_templates", component: EmailTemplates },
  { path: "/ebookflutter_adminpanel/log", component: Log },
  { path: "/ebookflutter_adminpanel/notificationtemplate", component: NotificationTemplate },

  { path: "/ebookflutter_adminpanel/notification", component: Notification },

  { path: "/ebookflutter_adminpanel/coach", component: Coach },
  { path: "/ebookflutter_adminpanel/audio", component: Audio },
  { path: "/ebookflutter_adminpanel/video", component: Video },
  { path: "/ebookflutter_adminpanel/coachReview", component: CoachReview },
  { path: "/ebookflutter_adminpanel/audioReview", component: AudioReview },
  { path: "/ebookflutter_adminpanel/videoReview", component: VideoReview },
  { path: "/ebookflutter_adminpanel/videoReview", component: VideoReview },

  //add
  { path: "/ebookflutter_adminpanel/add-category", component: AddCategory },
  { path: "/ebookflutter_adminpanel/add-coach", component: AddCoach },
  {
    path: "/ebookflutter_adminpanel/add-subcategory",
    component: AddSubCategory,
  },
  { path: "/ebookflutter_adminpanel/add-author", component: AddAuthor },
  { path: "/ebookflutter_adminpanel/add-book", component: AddBook },

  {
    path: "/ebookflutter_adminpanel/edit-book/add-chapter/:book_id",
    component: AddChapter,
  },
  { path: "/ebookflutter_adminpanel/add-top-ten/:book_id/:topid", component: AddTopTen },
  { path: "/ebookflutter_adminpanel/add-mobile", component: AddMobileSlider },
  { path: "/ebookflutter_adminpanel/add-genre", component: AddGenre },
  { path: "/ebookflutter_adminpanel/add-coupon", component: AddCoupon },
  { path: "/ebookflutter_adminpanel/add-publisher", component: AddPublisher },
  {
    path: "/ebookflutter_adminpanel/add-socialMedia",
    component: AddSocialMedia,
  },
  { path: "/ebookflutter_adminpanel/add-feed", component: AddFeed },
  { path: "/ebookflutter_adminpanel/add-emailtemplate", component: AddEmailTemplate },
  { path: "/ebookflutter_adminpanel/edit_template", component: EditTemplate },

  //edit
  {
    path: "/ebookflutter_adminpanel/edit-category/:id",
    component: AddCategory,
  },
  {
    path: "/ebookflutter_adminpanel/edit-coach/:id",
    component: AddCoach,
  },
  {
    path: "/ebookflutter_adminpanel/edit-subcategory/:id",
    component: EditSubCategory,
  },
  { path: "/ebookflutter_adminpanel/edit-author/:id", component: AddAuthor },
  { path: "/ebookflutter_adminpanel/edit-book/:id", component: AddBook },
  {
    path: "/ebookflutter_adminpanel/edit-mobile/:id",
    component: AddMobileSlider,
  },
  {
    path: "/ebookflutter_adminpanel/edit-chapter/:book_id/:id",
    component: AddChapter,
  },
  { path: "/ebookflutter_adminpanel/edit-top/:book_id/:topid/:editId", component: AddTopTen },
  { path: "/ebookflutter_adminpanel/edit-genre/:id", component: EditGenre },
  { path: "/ebookflutter_adminpanel/edit-coupon/:id", component: EditCoupon },
  {
    path: "/ebookflutter_adminpanel/edit-socialMedia/:id",
    component: AddSocialMedia,
  },
  {
    path: "/ebookflutter_adminpanel/edit-publisher/:id",
    component: EditPublisher,
  },
  { path: "/ebookflutter_adminpanel/edit-feed/:id", component: AddFeed },

  { path: "/ebookflutter_adminpanel/roles", component: Roles },
  { path: "/ebookflutter_adminpanel/add-role", component: AddRoles },
  { path: "/ebookflutter_adminpanel/edit-role/:id", component: AddRoles },
  { path: "/ebookflutter_adminpanel/admin_user", component: Users },
  // { path: "/ebookflutter_adminpanel/Users", component: NewUsers },
  { path: "/ebookflutter_adminpanel/add-adminuser", component: AddAdminUser },
  { path: "/ebookflutter_adminpanel/edit-adminuser/:id", component: AddAdminUser },
  { path: "/ebookflutter_adminpanel/edit-user/:id", component: AddUsers },
  { path: "/ebookflutter_adminpanel/add-Users", component: AddUsers },

  // { path: "/ebookflutter_adminpanel/edit-user/:id", component: AddAdminUser },
  { path: "/ebookflutter_adminpanel/edit-mobile-user/:id", component: EditMobileUsers },
  { path: "/ebookflutter_adminpanel/view-role/:id", component: ViewRole },
  { path: "/ebookflutter_adminpanel/view-user/:id", component: ViewUser },
  {
    path: "/ebookflutter_adminpanel/view-mobile-user/:id",
    component: ViewMobileUsers,
  },

  //view
  { path: "/ebookflutter_adminpanel/view-author/:id", component: ViewAthour },
  { path: "/ebookflutter_adminpanel/view-coach/:id", component: ViewCoach },
  { path: "/ebookflutter_adminpanel/view-book/:id", component: ViewBook },
  //dashboard
  // { path: "/ebookflutter_adminpanel/dashboard/v2", component: Dashboard },

  { path: '/ebookflutter_adminpanel/dashboard', component: NewDashboard },

  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/ebookflutter_adminpanel/dashboard" />,
  },
];

const publicRoutes = [
  { path: "/ebookflutter_adminpanel/logout", component: Logout },
  { path: "/ebookflutter_adminpanel/login", component: Login },
  { path: "/ebookflutter_adminpanel/forgot-password", component: ForgetPasswordPage },
  { path: "/ebookflutter_adminpanel/register", component: Register },
  { path: "/payment", component: Payment },
  { path: "/ebookflutter_adminpanel/forget-password-mobilenumber", component: ForgetMobileNumber },
  { path: "/ebookflutter_adminpanel/send-otp/:id", component: Sendotp },
  { path: "/ebookflutter_adminpanel/confirm-password", component: ChangePassword },
  { path: "/ebookflutter_adminpanel/change-password/:id", component: NewPassword }
];

export { authProtectedRoutes, publicRoutes };
