import React from "react";
import { Link } from "react-router-dom";
import { CardBody } from "reactstrap";
import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Card, Stack, TextField, Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import axiosInstance from "../../helpers/axios";
import { connect } from "react-redux";
import "./NotificationTemplate.css";
import Loader from "../../components/ui/loader/Loader";

const NotificationTemplate = () => {
  // States of SetData
  // const { id } = useParams();
  const [users, setUsers] = useState({
    // otp_text: "",
    reg_text: "",
    forget_pswd_text: "",
    change_pswd_text: "",
    plan_expired_text: "",
    plan_purchase_text: "",
    plan_renew_text: "",
    otp_text_Error: [],
  }) as any;
  const [isLoading, setIsLoading] = useState(false);

  // Static Dropdown
  const top100Films = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  // Set Token
  const token = useSelector((state: any) => state.token);

  // Call get API using useEffect
  useEffect(() => {
    loadUser();
  }, []);

  // Put API on Submit Button
  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const id = localStorage.getItem("id");

    e.preventDefault();

    const data = {
      // otp_confirmation: users.otp_confirmation,
      // otp_text: users.otp_text,
      reg_notification: users.reg_notification,
      reg_text: users.reg_text,
      forget_pswd_notification: users.forget_pswd_notification,
      forget_pswd_text: users.forget_pswd_text,
      change_pswd_notification: users.change_pswd_notification,
      change_pswd_text: users.change_pswd_text,
      plan_expired_notification: users.plan_expired_notification,
      plan_expired_text: users.plan_expired_text,
      plan_purchase_notification: users.plan_purchase_notification,
      plan_purchase_text: users.plan_purchase_text,
      plan_renew_notification: users.plan_renew_notification,
      plan_renew_text: users.plan_renew_text,
    };

    if (isLoading === false) {
      setIsLoading(true);
      try {
        await axiosInstance
          .put(`${process.env.REACT_APP_API_URL}/notification/${id}`, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(() => {
            swal({
              title: "Notification Updated Successfully!",
              icon: "success",
            });
          })
          .catch((e) => {
            console.log(e);
            const error = e.response.data.error;
            if (error) {
              toast.error(error.details[0].message);
            } else {
              toast.error("Something went wrong. Please try again later");
            }
          });
      } catch (e: any) {
        const error = e;
        if (error) {
          toast.error(error.details[0].message);
        } else {
          toast.error("Something went wrong. Please try again later");
        }
      }
      setIsLoading(false);
    }
  };

  // SetStates of InputChange
  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.persist();
    setUsers({ ...users, [e.target.name]: e.target.value });
  };

  // Get API
  const loadUser = async () => {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL}/notification/notification`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // var id = res?.data?.data[0]?.id;
    // console.log(id)
    setUsers({
      ...users,
      change_pswd_notification: res?.data?.data[0]?.change_pswd_notification,
      change_pswd_text: res?.data?.data[0]?.change_pswd_text,
      forget_pswd_notification: res?.data?.data[0]?.forget_pswd_notification,
      forget_pswd_text: res?.data?.data[0]?.forget_pswd_text,
      // otp_confirmation: res.data.data[0].otp_confirmation,
      // otp_text: res.data.data[0].otp_text,
      plan_expired_notification: res?.data?.data[0]?.plan_expired_notification,
      plan_expired_text: res?.data?.data[0]?.plan_expired_text,
      plan_purchase_notification:
        res?.data?.data[0]?.plan_purchase_notification,
      plan_purchase_text: res?.data?.data[0]?.plan_purchase_text,
      plan_renew_notification: res?.data?.data[0]?.plan_renew_notification,
      plan_renew_text: res?.data?.data[0]?.plan_renew_text,
      reg_notification: res?.data?.data[0]?.reg_notification,
      reg_text: res?.data?.data[0]?.reg_text,
    });
    localStorage.setItem("id", res?.data?.data[0]?.id);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Card sx={{ p: 3 }}>
          <CardBody>
            <form onSubmit={(e) => onSubmit(e)}>
              <h4 style={{ padding: "10px 0" }} className="heading">
                Registration Notification
              </h4>

              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                <div className="data-stack">
                  <span className="data-span2"> Template Variable:</span>
                  <p>
                    {" "}
                    Use these super helpful variables to customize your course
                    enrollment firstname ,lastname
                  </p>
                </div>
                <div className="data-stack-ul">
                  <span className="data-span2"></span>
                  <ul className="ul-data-2">
                    <li>{"{firstname , lastname}"}</li>
                  </ul>
                </div>

                <div className="data-stack">
                  <span className="data-span"> Notification :</span>{" "}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="reg_notification"
                    className="reg_notification"
                    // value={users.reg_notification}
                    value={
                      users.reg_notification === true
                        ? { label: "Yes", value: true }
                        : users.reg_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({ ...users, reg_notification: val.value });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>

                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="reg_text"
                    rows={2}
                    value={users.reg_text}
                    onChange={onInputChange}
                  />
                </div>
              </Stack>
              <hr />
              <br />

              <h4 style={{ padding: "10px 0" }} className="heading">
                Forgot Password Notification
              </h4>
              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                <div className="data-stack">
                  <span className="data-span2"> Template Variable:</span>
                  <p>
                    {" "}
                    Use these super helpful variables to customize your course
                    enrollment otp
                  </p>
                </div>
                <div className="data-stack-ul">
                  <span className="data-span2"></span>
                  <ul className="ul-data-2">
                    <li>{"{otp}"}</li>
                  </ul>
                </div>
                <div className="data-stack">
                  <span className="data-span"> Notification :</span>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="forget_pswd_notification"
                    className="forget_pswd_notification"
                    value={
                      users.forget_pswd_notification === true
                        ? { label: "Yes", value: true }
                        : users.forget_pswd_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    // value={users.forget_pswd_notification}
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({
                        ...users,
                        forget_pswd_notification: val.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>
                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="forget_pswd_text"
                    rows={2}
                    value={users.forget_pswd_text}
                    onChange={onInputChange}
                  />
                </div>
              </Stack>
              <hr />
              <br />

              <h4 style={{ padding: "10px 0" }} className="heading">
                Change Password Notification
              </h4>
              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                <div className="data-stack">
                  <span className="data-span2"> Template Variable:</span>
                  <p>
                    Use these super helpful variables to customize your course
                    enrollment otp
                  </p>
                </div>
                <div className="data-stack-ul">
                  <span className="data-span2"></span>
                  <ul className="ul-data-2">
                    <li>{"{otp}"}</li>
                  </ul>
                </div>
                <div className="data-stack">
                  <span className="data-span"> Notification :</span>{" "}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="change_pswd_notification"
                    className="change_pswd_notification"
                    // value={users.change_pswd_notification}
                    value={
                      users.change_pswd_notification === true
                        ? { label: "Yes", value: true }
                        : users.change_pswd_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({
                        ...users,
                        change_pswd_notification: val.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>
                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="change_pswd_text"
                    rows={2}
                    value={users.change_pswd_text}
                    onChange={onInputChange}
                  />
                </div>
              </Stack>
              <hr />
              <br />

              <h4 style={{ padding: "10px 0" }} className="heading">
                {" "}
                Plan Expired Notification
              </h4>
              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                <div className="data-stack">
                  <span className="data-span"> Notification :</span>{" "}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="plan_expired_notification"
                    className="plan_expired_notification"
                    value={
                      users.plan_expired_notification === true
                        ? { label: "Yes", value: true }
                        : users.plan_expired_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    // value={users.plan_expired_notification}
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({
                        ...users,
                        plan_expired_notification: val.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>
                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="plan_expired_text"
                    rows={2}
                    value={users.plan_expired_text}
                    onChange={onInputChange}
                  />
                </div>
              </Stack>
              <hr />
              <br />

              <h4 style={{ padding: "10px 0" }} className="heading">
                Plan Purchase Notification
              </h4>
              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                {" "}
                <div className="data-stack">
                  <span className="data-span"> Notification :</span>{" "}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="plan_purchase_notification"
                    className="plan_purchase_notification"
                    // value={users.plan_purchase_notification}
                    value={
                      users.plan_purchase_notification === true
                        ? { label: "Yes", value: true }
                        : users.plan_purchase_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({
                        ...users,
                        plan_purchase_notification: val.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>
                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="plan_purchase_text"
                    rows={2}
                    value={users.plan_purchase_text}
                    onChange={onInputChange}
                  />
                </div>
              </Stack>
              <hr />
              <br />

              <h4 style={{ padding: "10px 0" }} className="heading">
                Plan Renew Notification
              </h4>
              <Stack sx={{ p: 5, width: "100%", margin: "0 auto" }} spacing={3}>
                {" "}
                <div className="data-stack">
                  <span className="data-span"> Notification :</span>{" "}
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={top100Films}
                    // name="plan_renew_notification"
                    className="plan_renew_notification"
                    // value={users.plan_renew_notification}
                    value={
                      users.plan_renew_notification === true
                        ? { label: "Yes", value: true }
                        : users.plan_renew_notification === false
                        ? { label: "No", value: false }
                        : null
                    }
                    onChange={(e, val: any) => {
                      e.persist();
                      setUsers({
                        ...users,
                        plan_renew_notification: val.value,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Yes" />
                    )}
                  />
                </div>
                <div className="data-stack">
                  <span className="data-span">Notification Text : </span>
                  <TextField
                    fullWidth
                    label="Notification Text"
                    id="fullWidth"
                    multiline
                    placeholder="Hello Your Otp for Registration on"
                    name="plan_renew_text"
                    rows={2}
                    value={users.plan_renew_text}
                    onChange={onInputChange}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    margin: "5px",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    <input
                      name="submit"
                      type="submit"
                      value="Submit"
                      className="btn btn-primary"
                    />
                  </div>
                  <div
                    style={{
                      margin: "5px",
                      padding: "10px",
                    }}
                  >
                    {" "}
                    <Link
                      to="/ebookflutter_adminpanel/author"
                      style={{ color: "white" }}
                    ></Link>
                  </div>
                </div>
              </Stack>
            </form>
          </CardBody>
        </Card>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    token: state.token,
    user: state.user,
  };
};
export default connect(mapStateToProps)(NotificationTemplate);

// export default NotificationTemplate;
