import React, { useEffect, useState,useRef } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Container, Input } from "reactstrap";
import {
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@material-ui/core";
import Select from 'react-select';
import './style.css';
import { Pagination } from "@material-ui/lab";
import "./datatables.scss";
import IconButton from "@material-ui/core/IconButton";
import Loader from "../../components/ui/loader/Loader";
import { connect } from 'react-redux';
import { useFetchHook } from "../../utils/fetchHook";
import { StoreState } from '../../store';
import { UserRoles } from '../../store/auth/admins/user';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'
import ErrorSpan from "../../components/ui/errorSpan/ErrorSpan";
import { toast } from "react-toastify";
import { errorMsg, modules, permissions } from "../../utils/constants";
import axiosInstance from "../../helpers/axios";
import StarRatings from 'react-star-ratings';
import Switch from "@material-ui/core/Switch";
import { AxiosError } from "axios";

interface Props {
  user: UserRoles;
  token: string | null;
}
// API data format
type AudioType = {
  id: number,
  rating: number;
  description: string;
  approval_status: boolean;
  created_at: string;
  audio_name: string;
  user: string;
  approved_by: string;
  coach_first_name: string;

}
const AudioReview = (props: Props) => {
  const tableRef = useRef<HTMLTableElement>(null);
  // States for SetData
  const history = useHistory();
  const [dates, setDates] = useState<any>([null, null]);
  const [userData, setUserData] = useState('');
  const [audioName, setAudioName] = useState('');
  const [rating, setRating] = useState<{ value: string; label: string } | null>(null);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagedata, setPagedata] = useState(false);
  const [showError, setShowError] = useState(false);
  const [approve, setApprove] = useState<{ value: string; label: string } | null>(null);
  const[newUsers,setNewusers]=useState({
    approve:null as any,
    rating:null as any,
  })
  // Custom Hook
  const [
    { isLoading, data },
    { setOptions, setData }
  ] = useFetchHook<{
    data: AudioType[]; numberOfPage: number; total: number | null
  }>(`${process.env.REACT_APP_API_URL}/coach/audio-review`,
    { data: [], numberOfPage: 1, total: null },
    {
      params: { page },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
    useEffect(()=>{
      onFilterApply(null as any, false)
    },[newUsers])

  // UseEffect
  useEffect(() => {
    if (pagedata) {
      setOptions({
        params: {
          page,
          audio_name: audioName,
          userName: userData,
          rating: rating?.value,
          approve: approve?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      setPagedata(true);
    }
    setCurrentPage(page + 1);
  }, [page]);

  // Audio Approval API
  const updateToggle = async (id: number) => {
    if (!props.user[modules.audio_review].includes(permissions.UPDATE)) {
      toast.error(errorMsg);
      return;
    }
    try {
      const res = await axiosInstance.put(`/coach/audio-review-approval`, { id }, {
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
      const temp = [...data.data];
      const newData = temp.map((t) => {
        if (t.id === id) {
          return { ...t, status: res.data.status, approved_by: res.data.approved_by, approval_status: res.data.status };
        }
        return t;
      });

      setData((prev) => ({ ...prev, data: newData, numberOfPage: data.numberOfPage }));
    } catch (e) {
      const error = e as AxiosError;
      if (error.response?.data.error) {
        toast.error(error.response?.data.error);
      }
      else {
        toast.error('Something went wrong. Please try again later');
      }
    }
  };

  const redirect = (id: number) => {
    if (!props.user[modules.audio_review].includes(permissions.READ)) {
      toast.error(errorMsg);
      return;
    }
    history.push(`/ebookflutter_adminpanel/view/audio_review/${id}`);
  }

  // Select Option
  const ratingOption = [
    {
      label: "One Star",
      value: "1",
    },
    {
      label: "Two Star",
      value: "2",
    },
    {
      label: "Three Star",
      value: "3",
    },
    {
      label: "Four Star",
      value: "4",
    },
    {
      label: "Five Star",
      value: "5",
    },

  ];

  const approveOption = [
    {
      label: "All",
      value: "All",
    },
    {
      label: "Deny",
      value: "false",
    },
    {
      label: "Approve",
      value: "true",
    },
  ];
  const copyTable = () => {
    const table = tableRef.current;
    if (table) {
      const range = document.createRange();
      range.selectNode(table);
      window.getSelection()?.removeAllRanges();
      window.getSelection()?.addRange(range);
      document.execCommand('copy');
      window.getSelection()?.removeAllRanges();
      toast.info("Copied To Clipboard")
    }
  }

  const exportToExcel = () => {
    const table = document.querySelector('table');
    const html = table?.outerHTML;
    if (html) {
      const blob = new Blob([html], { type: 'application/vnd.ms-excel' });
      const dataUrl = URL.createObjectURL(blob);
      const downloadLink = document.createElement('a');
      downloadLink.href = dataUrl;
      downloadLink.download = 'table.xls';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    }
  }

  const convertTableToCSV = (table: any) => {
    let csv = '';
    const rows = table.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('th, td');
      for (let j = 0; j < cells.length; j++) {
        csv += cells[j].innerText + (j < cells.length - 1 ? ',' : '\n');
      }
    }
    return csv;
  };


  const handleDownloadCSV = () => {
    const tableNode = document.getElementById('table-data');
    const csvData = convertTableToCSV(tableNode);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvData);
    downloadLink.download = 'table-data.csv';
    downloadLink.click();
  };

  const setter = (action: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
    action(e.target.value);
  };

  const handleKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter'){
      if (audioName.length > 0 || userData.length > 0 || approve != null || (dates[0] != null && dates[1] != null) || rating != null) {
        e.preventDefault();
        setPage(1);
        setShowError(false);
        setOptions({
          params: {
            page,
            audio_name: audioName,
            userName: userData,
            rating: rating?.value,
            approve: approve?.value,
            dates,
          },
          headers: {
            Authorization: `Bearer ${props.token}`,
          }
        });
      }
      else {
        setShowError(true);
      }
    }}

  // Filter
  const onFilterApply = (e: React.MouseEvent<HTMLButtonElement>, isFilterapply: boolean) => {
    if (audioName.length > 0 || userData.length > 0 || approve != null || (dates[0] != null && dates[1] != null) || rating != null) {
      if(e !== null){
        e.preventDefault();
      }
      setPage(1);
      setShowError(false);
      setOptions({
        params: {
          page,
          audio_name: audioName,
          userName: userData,
          rating: rating?.value,
          approve: approve?.value,
          dates,
        },
        headers: {
          Authorization: `Bearer ${props.token}`,
        }
      });
    }
    else {
      if (isFilterapply === true) {
        setShowError(true)
      } else {
        setShowError(false);
      }
    }
  }

  // Reset
  const onResetApply = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setAudioName('');
    setUserData('');
    setDates([null, null]);
    setApprove(null);
    setRating(null);
    setShowError(false);
    setOptions({
      params: {
        page,
      },
      headers: {
        Authorization: `Bearer ${props.token}`,
      }
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs={6}>
                      <h4 className="card-title">AUDIO REVIEW LIST({data.total})</h4>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    <Col xs={3}>
                      <Input placeholder="Audio Name" value={audioName}  onKeyPress ={handleKeyPress} onChange={setter(setAudioName)} />
                    </Col>
                    <Col xs={3}>
                      <Input placeholder="User Name" value={userData} onKeyPress ={handleKeyPress} onChange={setter(setUserData)} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Rating" name="rating" options={ratingOption} value={rating}
                      //  onChange={setRating}
                       onChange={(value)=>{
												setRating(value);
                        setNewusers({...newUsers, rating: value})
											}} />
                    </Col>
                    <Col xs={3}>
                      <Select placeholder="Approved" name="approve" options={approveOption} value={approve}
                      // onChange={setApprove}
                      onChange={(value)=>{
												setApprove(value);
                        setNewusers({...newUsers, approve: value})
											}}
                       />
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={3}>
                      <div>
                        <DateRangePicker placeholder="Select Date Range" format="MM/dd/yyyy" onKeyPress ={handleKeyPress} onChange={setDates} value={dates} />
                      </div>
                      <br />
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={() => onFilterApply(null as any, true)}>Apply</button>
                    </Col>
                    <Col xs="auto">
                      <button className="btn btn-primary" onClick={onResetApply} >Reset</button>
                    </Col>
                  </Row>
                  <div className="msg-box">
                    {showError ? <ErrorSpan error={"Select atleast any input"} /> : <></>}
                  </div>
                  <button className="btn btn-outline-primary mr-2" onClick={copyTable}>Copy</button>
                  <button className="btn btn-outline-primary mr-2" onClick={exportToExcel}>Excel</button>
                  <button className="btn btn-outline-primary mr-2" onClick={handleDownloadCSV}>CSV</button>
                  <TableContainer component={Paper}>
                    <Table id="table-data" ref={tableRef}>
                      <TableHead>
                        <TableRow>
                          <TableCell>SR NO</TableCell>
                          <TableCell>AUDIO NAME</TableCell>
                          <TableCell>USER NAME</TableCell>
                          <TableCell>REVIEW</TableCell>
                          <TableCell>RATING</TableCell>
                          <TableCell>APPROVAL STATUS</TableCell>
                          <TableCell>CREATED DATE</TableCell>
                          <TableCell>APPROVED BY</TableCell>
                          <TableCell>ACTION</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.data.map((t, index) => (
                          <TableRow key={t.id}>
                            <TableCell>{((page - 1) * 25) + index + 1}</TableCell>
                            <TableCell>{t.audio_name}</TableCell>
                            <TableCell>{t.user}</TableCell>
                            <TableCell>{t.description}</TableCell>
                            <TableCell> <StarRatings
                              numberOfStars={5}
                              rating={t.rating}
                              starRatedColor="#FDCC0D"
                              starDimension="20px"
                              starSpacing="2px"
                            /></TableCell>
                            <TableCell>
                              <Switch checked={t.approval_status} color="primary" onChange={() => updateToggle(t.id)} />
                            </TableCell>
                            <TableCell>{`${new Date(t.created_at).getDate() + "  "} - ${new Date(t.created_at).getMonth() + 1} - ${new Date(t.created_at).getFullYear()}`}</TableCell>
                            <TableCell>{t.approved_by ? t.approved_by : '--'}</TableCell>
                            <TableCell>
                              <div className="innercell">
                                <IconButton color="primary" onClick={() => redirect(t.id)}>
                                  <VisibilityIcon />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))} </TableBody>
                    </Table>
                    <Pagination
                      count={data.numberOfPage}
                      color="primary"
                      variant="outlined"
                      showFirstButton={true}
                      showLastButton={true}
                      page={currentPage - 1}
                      onChange={(_e, value) => setPage(value)}
                    />
                  </TableContainer>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {isLoading ? <Loader /> : null}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => {
  return {
    token: state.token!,
    user: state.user,
  };
};

export default connect(mapStateToProps)(AudioReview);
