// THIS COMPONENT IS USED IN THE HEADER RIGHT SIDE SECTION FOR USER PROFILE ON CLICK OF PROFILE HAVE DROPDOWN FOR
// ROLE INFO ,CHANGE PASSWORD AND LOGOUT ALSO HAVE ZOOM IN ZOOM OUT FUNCTIONALITY
import React, { Component } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import avatar2 from "../../../assets/images/users/avatar-2.jpg";

interface Props{
  user:any;
  t:any;
}

interface ProfileState{
  menu:any;
}

class ProfileMenu extends Component<Props,ProfileState> {
  constructor(props:Props) {
    super(props);
    this.state = {
      menu: false,
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      menu: !prevState.menu,
    }));
  }

  render() {
    let username = this.props.user.user_name;
    if (localStorage.getItem("authUser")) {
      const obj:any = JSON.parse(localStorage.getItem("authUser")as any);
      const uNm = obj.email.split("@")[0];
      username = uNm.charAt(0).toUpperCase() + uNm.slice(1);
    }

    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block user-dropdown"
        >
          <DropdownToggle
            tag="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
          >
            <img
              className="rounded-circle header-profile-user mr-1"
              src={avatar2}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-1 text-transform">
              {username}
            </span>
            <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
          </DropdownToggle>

          <DropdownMenu right>
            <DropdownItem
              className="text-danger"
              href="/ebookflutter_adminpanel/logout"
            >
              <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>
              {this.props.t("Logout")}
            </DropdownItem>
            <DropdownItem href="/ebookflutter_adminpanel/user/info">
              Role Info
            </DropdownItem>
            <DropdownItem href="/ebookflutter_adminpanel/confirm-password">
            Change Password
          </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state:any) => {
  return {
    user: state.user,
  }
};

export default connect(mapStateToProps)(withNamespaces()(ProfileMenu as any));
